import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "styled-components";
import HeaderAdmin from "./HeaderAdmin";

const Body = styled.div`
  margin-top: calc(66px + 1rem);
`;

const LayoutAdmin: FC = () => {
  return (
    <div
      className={`pageWrapper d-md-block d-lg-flex isMiniSidebar`}
    >
    <div className={` contentArea fixedTopbar `}>
      <HeaderAdmin />
      <div
        className={`pageWrapper d-md-block d-lg-flex`}
      >
      <div className={`contentArea`}>
        <Container fluid className="p-4 ">
      <div className="AppBody">
        <Outlet />
      </div>
    </Container>
      </div>
      </div>
    </div>
    </div>
  );
};

export default LayoutAdmin;
