import { FC, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledAccordion,
} from "reactstrap";
import styled from "styled-components";
import tiposDeIndustria from "../../resources/tipos-de-industria.json";

const CustomAccordion = styled(Accordion)`
  .accordion-button:not(.collapsed) {
    background-color: var(--bs-accordion-btn-bg);
    color: var(--bs-accordion-btn-color);
  }
`;

const CustomAccordionBody = styled(AccordionBody)`
  > .accordion-body {
    padding: 0 0 0 30px;
    > .accordion-flush > .accordion-item {
      border-left: 0.625px solid #dee2e6;
    }
    > .list-group-flush > .list-group-item {
      border-left: 0.625px solid #dee2e6;
      padding: 16px 20px;
    }
  }
`;

interface Clase {
  clase: string;
  descripcion: string;
}

interface Grupo {
  grupo: string;
  descripcion: string;
  clases: Clase[];
}

interface Division {
  division: string;
  descripcion: string;
  grupos: Grupo[];
}

interface Seccion {
  seccion: string;
  descripcion: string;
  divisiones: Division[];
}

const secciones: Seccion[] = tiposDeIndustria;

interface TiposDeIndustriaModalProps {
  isOpen: boolean;
  onSelect: (tipoDeIndustria: string) => void;
  toggle: () => void;
}

const TiposDeIndustriaModal: FC<TiposDeIndustriaModalProps> = ({ isOpen, onSelect, toggle }) => {
  const [busqueda, setBusqueda] = useState<string>("");
  const [seccionesFiltradas, setSeccionesFiltradas] = useState<Seccion[]>(secciones);
  const [seccionesAbiertas, setSeccionesAbiertas] = useState<string[]>([]);

  const toggleSeccion = (seccionId: string) => {
    if (seccionesAbiertas.includes(seccionId)) {
      setSeccionesAbiertas(
        seccionesAbiertas.filter((seccionAbierta) => seccionAbierta !== seccionId)
      );
    } else {
      setSeccionesAbiertas([...seccionesAbiertas, seccionId]);
    }
  };

  const buscar = useCallback(() => {
    const busquedaMinuscula = busqueda.toLowerCase();
    const seccionesFiltradas = secciones
      .map((seccion) => {
        if (seccion.descripcion.toLowerCase().includes(busquedaMinuscula)) {
          return seccion;
        }
        const divisionesFiltradas = seccion.divisiones
          .map((division) => {
            if (division.descripcion.toLowerCase().includes(busquedaMinuscula)) {
              return division;
            }
            const gruposFiltrados = division.grupos
              .map((grupo) => {
                if (grupo.descripcion.toLowerCase().includes(busquedaMinuscula)) {
                  return grupo;
                }
                const clasesFiltradas = grupo.clases.filter((clase) =>
                  clase.descripcion.toLowerCase().includes(busquedaMinuscula)
                );
                return { ...grupo, clases: clasesFiltradas };
              })
              .filter(({ clases }) => clases.length > 0);
            return { ...division, grupos: gruposFiltrados };
          })
          .filter(({ grupos }) => grupos.length > 0);
        return { ...seccion, divisiones: divisionesFiltradas };
      })
      .filter(({ divisiones }) => divisiones.length > 0);

    setSeccionesFiltradas(seccionesFiltradas);
    setSeccionesAbiertas(
      !!busqueda.length
        ? seccionesFiltradas
            .filter(({ divisiones }) => divisiones.length === 1)
            .map(({ seccion }) => seccion)
        : []
    );
  }, [busqueda]);

  useEffect(buscar, [buscar]);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Tipos de industria</ModalHeader>
      <ModalBody>
        <FormGroup floating>
          <Input
            type="text"
            className="form-control mb-3"
            placeholder="Buscar"
            value={busqueda}
            onChange={({ target }) => setBusqueda(target.value)}
          />
          <Label>Buscar</Label>
        </FormGroup>
        <CustomAccordion open={seccionesAbiertas} >
          {seccionesFiltradas.map(({ seccion, descripcion, divisiones }) => (
            <AccordionItem key={`${seccion}-${divisiones.length}`}>
              <AccordionHeader targetId={seccion} onClick={() => toggleSeccion(seccion)}>{descripcion}</AccordionHeader>
              <CustomAccordionBody accordionId={seccion}>
                <UncontrolledAccordion
                  flush
                  defaultOpen={divisiones.length === 1 ? divisiones[0]?.division : []}
                >
                  {divisiones.map(({ division, descripcion, grupos }) => (
                    <AccordionItem key={`${division}-${grupos.length}`}>
                      <AccordionHeader targetId={division}>
                        {division} - {descripcion}
                      </AccordionHeader>
                      <CustomAccordionBody accordionId={division}>
                        <UncontrolledAccordion
                          flush
                          defaultOpen={grupos.length === 1 ? grupos[0]?.grupo : []}
                        >
                          {grupos.map(({ grupo, descripcion, clases }) => (
                            <AccordionItem key={`${grupo}-${clases.length}`}>
                              <AccordionHeader targetId={grupo}>
                                {grupo} - {descripcion}
                              </AccordionHeader>
                              <CustomAccordionBody accordionId={grupo}>
                                <ListGroup flush>
                                  {clases.map(({ clase, descripcion }) => (
                                    <ListGroupItem
                                      key={clase}
                                      action
                                      tag="button"
                                      onClick={() => onSelect(`${clase} - ${descripcion}`)}
                                    >
                                      {clase} - {descripcion}
                                    </ListGroupItem>
                                  ))}
                                </ListGroup>
                              </CustomAccordionBody>
                            </AccordionItem>
                          ))}
                        </UncontrolledAccordion>
                      </CustomAccordionBody>
                    </AccordionItem>
                  ))}
                </UncontrolledAccordion>
              </CustomAccordionBody>
            </AccordionItem>
          ))}
        </CustomAccordion>
      </ModalBody>
    </Modal>
  );
};

export default TiposDeIndustriaModal;
