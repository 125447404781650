import { Activo, ActivoResponse, Documento,ArchivoDocumento } from "./Documento";
import { EquipoHerramienta, EquipoHerramientaResponse } from "./EquipoHerramienta";
import { SustanciaQuimica, SustanciaQuimicaResponse } from "./SustanciaQuimica";
import { Vehiculo, VehiculoResponse } from "./Vehiculo";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export interface ArchivoDeDocumentoBase {
  documento: Documento | string;
  nombre: string;
  filepath: string;
  creadoPor: Vinculacion | VinculacionResponse | string;
  activos?: (Activo | ActivoResponse)[];
  tipoDeActivo?: string;
}

export interface ArchivoDeDocumento extends ArchivoDeDocumentoBase {
  _id: string;
  fechaDeCarga: Date;
  fechaDeVencimiento: Date;
  updatedAt:Date;
  creadoPor: Vinculacion;
  activos?: Activo[];
}

export interface ArchivoDeDocumentoResponse extends ArchivoDeDocumentoBase {
  _id: string;
  fechaDeCarga: string;
  fechaDeVencimiento: string;
  updatedAt: string;
  creadoPor: VinculacionResponse;
  activos?: ActivoResponse[];
}

export class ArchivoDeDocumento implements ArchivoDeDocumento {
  constructor({
    _id,
    documento,
    nombre,
    filepath,
    creadoPor,
    fechaDeCarga,
    fechaDeVencimiento,
    updatedAt,
    activos = [],
    tipoDeActivo,
  }: ArchivoDeDocumentoResponse) {
    this._id = _id;
    this.documento = documento;
    this.nombre = nombre;
    this.filepath = filepath;
    this.creadoPor = new Vinculacion(creadoPor);
    this.fechaDeCarga = new Date(fechaDeCarga);
    this.fechaDeVencimiento = new Date(fechaDeVencimiento);
    this.updatedAt = new Date(updatedAt);
    this.tipoDeActivo = tipoDeActivo;
    if (tipoDeActivo) {
      switch (tipoDeActivo) {
        case "Vinculacion":
          this.activos = activos.map((usuario) => new Vinculacion(usuario as VinculacionResponse));
          break;
        case "Vehiculo":
          this.activos = activos.map((vehiculo) => new Vehiculo(vehiculo as VehiculoResponse));
          break;
        case "EquipoHerramienta":
          this.activos = activos.map(
            (equipo) => new EquipoHerramienta(equipo as EquipoHerramientaResponse)
          );
          break;
        case "SustanciaQuimica":
          this.activos = activos.map(
            (sustancia) => new SustanciaQuimica(sustancia as SustanciaQuimicaResponse)
          );
          break;
      }
    }
  }
}
