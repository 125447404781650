import { useCallback, useContext, FC, useState, useEffect } from "react";
import {
  Button,
  Collapse,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Container,
} from "reactstrap";

import { ReactFontSizeByTextLength } from "react-font-size-by-text-length";
import { FaSignOutAlt } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GISSTLogo } from "../../resources/Images";
import { Context } from "../Context";
import LocalStorage from "../../utilities/LocalStorage";
import { PATHS } from "../../utilities/Paths";
import { SeparatorCol } from "../../utilities/utils";

import NavSubItem from './Menu/NavSubItem';
import NavSingleItem from './Menu/NavSingleItem';

import SidebarData from './Menu/menuData';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/Store';



const LogoContainer = styled.div`
  height: 40px;
`;

const HeaderAdmin: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const activeBg = "white";
  const currentURL = location.pathname.split('/').slice(0, -1).join('/');


  const { tituloDeLaPantallaActual } = useContext(Context);
  const [isOpenOffcanvas, setIsOpenOffcanvas] = useState<boolean>(false);
    
   
  const toggleOffcanvas = () => {
    setIsOpenOffcanvas(!isOpenOffcanvas);
  };

  useEffect(() => {
    LocalStorage.isAdminLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        navigate(PATHS.adminLogin.to);
      }
    });
  }, [navigate]);

  return (
    <>
      <Navbar  color={"#FFF"}
       dark={false}
       light={true}
       expand="lg"
       className="shadow HorizontalTopbar p-0">
       <Container fluid className="d-flex align-items-center">
        <LinkContainer to="/admin">
         <div className="pe-4 py-3 ">
            <LogoContainer>
              <GISSTLogo />
            </LogoContainer>
         </div>
        </LinkContainer>
        <Nav className="me-auto flex-row" navbar>
          <NavItem className="d-none d-md-none d-lg-block">
          <h4 style={{"marginBottom":"10xp","marginTop":"10px"}} className="text-primary"><strong>
            {tituloDeLaPantallaActual}
          </strong>
          </h4>
          </NavItem>
        </Nav>
      
        <NavbarToggler onClick={toggleOffcanvas} />
        <Collapse navbar className="flex-grow-0">
          <Nav navbar tag="div">
            <NavItem tag="div">
              <Row className="align-items-center h-100 gx-0">
                <SeparatorCol width={56}>
                  <LinkContainer to={PATHS.logout.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted px-2"
                      title="Cerrar sesión"
                    >
                      <FaSignOutAlt size={25} />
                    </Button>
                  </LinkContainer>
                </SeparatorCol>
              </Row>
            </NavItem>
          </Nav>
        </Collapse>
       </Container>
      </Navbar>
      
      <div
        className={`horizontalNav shadow fixedSidebar `}
        style={{"marginTop":"16px","backgroundColor":"#fad200"}}
      >
      <Container fluid>
      <Nav className={'lightText'}>
        {SidebarData.map((navi) => {
          if (navi.caption) {
            return (
              <div
                className="navCaption fw-bold mt-4 d-none d-sm-block d-md-none"
                key={navi.caption}
              >
                {navi.caption}
              </div>
            );
          }
          
          if (navi.children && Array.isArray(navi.children)) {
            return (
              <NavSubItem
                key={navi.id}
                icon={navi.icon}
                title={navi.title}
                items={navi.children}
                suffix={navi.suffix}
                ddType={navi.ddType}
                activeBck={activeBg}
                suffixColor={navi.suffixColor}
                isUrl={currentURL === navi.href}
              />
            );
          }
          
          return (
            <NavSingleItem
              key={navi.id}
              //toggle={() => toggle(navi.id)}
              className={location.pathname === navi.href ? 'activeLink' : ''}
              to={navi.href}
              title={navi.title}
              suffix={navi.suffix}
              suffixColor={navi.suffixColor}
              icon={navi.icon}
            />
          );
        })}
      </Nav>
      </Container>
      </div>
      
      
      
      <Offcanvas isOpen={isOpenOffcanvas} toggle={toggleOffcanvas} unmountOnClose={false}>
        <OffcanvasHeader toggle={toggleOffcanvas}>
          <LogoContainer>
            <GISSTLogo />
          </LogoContainer>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Nav pills vertical>
                <NavItem>
                  <NavLink to="/admin/registros" className="nav-link" onClick={toggleOffcanvas}>
                    Registros
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/admin/documentos-legales"
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Documentos Legales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/autoridades" className="nav-link" onClick={toggleOffcanvas}>
                    Gestionar Autoridades
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default HeaderAdmin;
