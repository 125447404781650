import { FC, FormEvent, useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import ClampLines from "react-clamp-lines";
import { FaLink } from "react-icons/fa6";
import { Context } from "../../Context";
import { DocumentoLegal } from "../../../models/DocumentoLegal";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  fetchEmpresasVinculadasYSusAdministradores,
  listarDocumentosLegalesNuevos,
  vincularDocumentoLegal,
} from "../../../services/usuario-api-client";
import {
  CustomTable,
  TableContainer,
  TableMessage,
  abrirEnlaceEnNuevaPestaña,
  dateInputToDate,
  dateToISOString,
  obtenerURLCompleta,
  shortDateToString,
} from "../../../utilities/utils";
import { textoClasificacion, textoTipoDeRequisitoLegal } from "../../../models/RequisitoLegal";
import { Empresa } from "../../../models/Empresa";
import Checkbox from "../../../utilities/Checkbox";
import { Vinculacion } from "../../../models/Vinculacion";

const VINCULAR_REQUISITO_LEGAL_MODAL_ID = "vincularRequisitoLegalModal";

const now = new Date();
now.setMonth(now.getMonth() + 1);
const defaultDate = dateToISOString(now);

interface EmpresaYAdministradores {
  empresa: Empresa;
  colaboradores: Vinculacion[];
  responsableDelAnalisis?: string;
}

interface TablaDeRequisitosLegalesNuevosProps {
  onVincularSuccess: () => void;
}

const TablaDeRequisitosLegalesNuevos: FC<TablaDeRequisitosLegalesNuevosProps> = ({
  onVincularSuccess,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [documentosLegales, setDocumentosLegales] = useState<DocumentoLegal[]>([]);
  const [documentoParaVincular, setDocumentoParaVincular] = useState<DocumentoLegal | null>(null);
  const [empresasYAdministradores, setEmpresasYAdministradores] = useState<
    EmpresaYAdministradores[]
  >([]);
  const [vincularModalIsOpen, setVincularModalIsOpen] = useState<boolean>(false);
  const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState<string[]>([]);
  const [fechaLimiteDeAnalisisString, setFechaLimiteDeAnalisisString] =
    useState<string>(defaultDate);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleVincularRequisitoLegalClick = (id: string) => {
    const documento = documentosLegales.find((documento) => documento._id === id);
    if (!documento || documento.vinculado) return;
    setDocumentoParaVincular(documento);
    setVincularModalIsOpen(true);
    if (empresasYAdministradores.length === 0) {
      setIsLoading(true);
      fetchEmpresasVinculadasYSusAdministradores(authToken)
        .then(({ data }) => {
          const empresasYAdministradores = data.map(({ empresa, administradores }) => ({
            empresa,
            colaboradores: administradores.map((administrador) => new Vinculacion(administrador)),
          }));
          setEmpresasYAdministradores(empresasYAdministradores);
          if (empresasYAdministradores.length === 1) {
            setEmpresasSeleccionadas([empresasYAdministradores[0].empresa._id]);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleToggleAllEmpresas = () => {
    if (empresasSeleccionadas.length === empresasYAdministradores.length) {
      setEmpresasSeleccionadas([]);
    } else {
      setEmpresasSeleccionadas(empresasYAdministradores.map(({ empresa }) => empresa._id));
    }
  };

  const handleToggleEmpresa = (idDeLaEmpresa: string) => {
    if (empresasSeleccionadas.includes(idDeLaEmpresa)) {
      setEmpresasSeleccionadas(empresasSeleccionadas.filter((id) => id !== idDeLaEmpresa));
    } else {
      setEmpresasSeleccionadas([...empresasSeleccionadas, idDeLaEmpresa]);
    }
  };

  const handleResponsableDelAnalisisChange = (idDeLaEmpresa: string, idDelResponsable: string) => {
    setEmpresasYAdministradores(
      empresasYAdministradores.map(({ empresa, colaboradores, responsableDelAnalisis }) => ({
        empresa,
        colaboradores,
        responsableDelAnalisis:
          empresa._id === idDeLaEmpresa
            ? colaboradores.find(({ _id }) => _id === idDelResponsable)?._id
            : responsableDelAnalisis,
      }))
    );
  };

  const handleVincularRequisitoLegalSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    
    if (!documentoParaVincular || !empresasSeleccionadas.length) return;
    
    const fechaLimiteDeAnalisis = dateInputToDate(fechaLimiteDeAnalisisString);
    const empresasYPersonasResponsables = empresasYAdministradores
      .filter(({ empresa }) => empresasSeleccionadas.includes(empresa._id))
      .map(({ empresa, responsableDelAnalisis }) => ({
        empresa: empresa._id,
        responsableDelAnalisis: responsableDelAnalisis as string,
      }));
    const body = {
      empresasYPersonasResponsables,
      fechaLimiteDeAnalisis,
    };
    setMensajeDeError("");
    setIsLoading(true);
    vincularDocumentoLegal(authToken, documentoParaVincular._id, body)
      .then(onVincularSuccess)
      .finally(() => setIsLoading(false));
  };

  const limpiarSeleccion = () => {
    setDocumentoParaVincular(null);
    setMensajeDeError("");
    setEmpresasSeleccionadas([]);
    setFechaLimiteDeAnalisisString(defaultDate);
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listarDocumentosLegalesNuevos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const requisitosLegales = data.map((requisitoLegal) => new DocumentoLegal(requisitoLegal));
        setDocumentosLegales(requisitosLegales);
      })
      .catch(({ response }) => setMensajeDeError(response.data.error))
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
    
        <Table className="no-wrap mt-3 align-middle" responsive borderless>
          <thead>
            <tr>
              <th>#</th>
              <th>Requisito Legal</th>
              <th>Tipo</th>
              <th>Autoridad</th>
              <th title="Industrias vinculadas hasta el momento">Industrias Vinculadas</th>
              <th>Clasificación</th>
              <th>Fecha de emisión</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {documentosLegales.map(
              (
                {
                  _id,
                  nombre,
                  filepath,
                  tipo,
                  autoridad,
                  tiposDeIndustria,
                  clasificacion,
                  fechaDeEmision,
                  vinculado,
                },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>
                    <Button
                      type="button"
                      color="link"
                      className="p-0 text-start small"
                      onClick={() => abrirEnlaceEnNuevaPestaña(filepath)}
                      title={obtenerURLCompleta(filepath)}
                    >
                      <ClampLines
                        text={nombre}
                        id={`documento-legal-${_id}`}
                        lines={2}
                        buttons={false}
                      />
                    </Button>
                  </td>
                  <td>{textoTipoDeRequisitoLegal(tipo)}</td>
                  <td>{autoridad.nombre}</td>
                  <td>{tiposDeIndustria.length > 0 ? tiposDeIndustria.join(", ") : "Ninguna"}</td>
                  <td>{textoClasificacion(clasificacion)}</td>
                  <td>{fechaDeEmision ? shortDateToString(fechaDeEmision) : ""}</td>
                  <td>
                    <Row className="gx-0 flex-nowrap justify-content-center">
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="link"
                          size="sm"
                          title="Vincular requisito legal"
                          onClick={() => handleVincularRequisitoLegalClick(_id)}
                          disabled={vinculado}
                        >
                          <FaLink size={15} />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {documentosLegales.length === 0 && (
              <tr>
                <td colSpan={8} className="text-center">
                  <TableMessage>No hay requisitos legales nuevos</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
  
      <Modal
        isOpen={vincularModalIsOpen}
        toggle={() => setVincularModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setVincularModalIsOpen(false)}>
          Vincular Requisito Legal
        </ModalHeader>
        <ModalBody>
          {!!documentoParaVincular && (
            <Form
              id={VINCULAR_REQUISITO_LEGAL_MODAL_ID}
              onSubmit={handleVincularRequisitoLegalSubmit}
            >
              <FormGroup floating>
                <Input
                  type="text"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre del requisito legal"
                  value={documentoParaVincular.nombre}
                  disabled
                />
                <Label for="nombre">Nombre del requisito legal</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="Hipervínculo"
                  value={obtenerURLCompleta(documentoParaVincular.filepath)}
                  disabled
                />
                <Label for="url">Hipervínculo</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="tipo"
                  name="tipo"
                  placeholder="Tipo de requisito legal"
                  value={textoTipoDeRequisitoLegal(documentoParaVincular.tipo)}
                  disabled
                />
                <Label for="tipo">Tipo de requisito legal</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="autoridad"
                  name="autoridad"
                  placeholder="Autoridad"
                  value={documentoParaVincular.autoridad.nombre}
                  disabled
                />
                <Label for="autoridad">Autoridad</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="clasificacion"
                  name="clasificacion"
                  placeholder="Clasificación"
                  value={textoClasificacion(documentoParaVincular.clasificacion)}
                  disabled
                />
                <Label for="clasificacion">Clasificación</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="numeroDeArticulos"
                  name="numeroDeArticulos"
                  placeholder="Número de artículos"
                  value={documentoParaVincular.numeroDeArticulos}
                  disabled
                />
                <Label for="numeroDeArticulos">Número de artículos</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="date"
                  id="fechaLimiteDeAnalisis"
                  placeholder="Fecha límite de análisis"
                  value={fechaLimiteDeAnalisisString}
                  onChange={(e) => setFechaLimiteDeAnalisisString(e.target.value)}
                />
                <Label for="fechaLimiteDeAnalisis">Fecha límite de análisis</Label>
              </FormGroup>
              <Collapse isOpen={empresasYAdministradores.length > 0}>
                {empresasYAdministradores.length === 1 ? (
                  <FormGroup floating>
                    <Input
                      type="select"
                      value={empresasYAdministradores[0].responsableDelAnalisis || ""}
                      onChange={(e) =>
                        handleResponsableDelAnalisisChange(
                          empresasYAdministradores[0].empresa._id,
                          e.target.value
                        )
                      }
                      required
                    >
                      <option value="" disabled>
                        Seleccionar
                      </option>
                      {empresasYAdministradores[0].colaboradores.map(({ _id, nombreCompleto }) => (
                        <option key={_id} value={_id}>
                          {nombreCompleto}
                        </option>
                      ))}
                    </Input>
                    <Label for="responsableDelAnalisis">Responsable del análisis</Label>
                  </FormGroup>
                ) : (
                  <Row className="justify-content-center gx-0">
                    <Col xs="auto">
                      <TableContainer>
                        <CustomTable>
                          <thead>
                            <tr>
                              <th>
                                <Checkbox
                                  checked={
                                    empresasSeleccionadas.length > 0 &&
                                    empresasSeleccionadas.length === empresasYAdministradores.length
                                  }
                                  toggle={handleToggleAllEmpresas}
                                />
                              </th>
                              <th>Empresa</th>
                              <th>Responsable del análisis</th>
                            </tr>
                          </thead>
                          <tbody>
                            {empresasYAdministradores.map(
                              ({ empresa, colaboradores, responsableDelAnalisis }) => (
                                <tr key={empresa._id}>
                                  <td
                                    className="text-center"
                                    onClick={() => handleToggleEmpresa(empresa._id)}
                                  >
                                    <Checkbox
                                      checked={empresasSeleccionadas.includes(empresa._id)}
                                    />
                                  </td>
                                  <td onClick={() => handleToggleEmpresa(empresa._id)}>
                                    {empresa.nombreDeLaEmpresa}
                                  </td>
                                  <td>
                                    <Input
                                      type="select"
                                      value={
                                        empresasSeleccionadas.includes(empresa._id)
                                          ? responsableDelAnalisis || ""
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleResponsableDelAnalisisChange(
                                          empresa._id,
                                          e.target.value
                                        )
                                      }
                                      required={empresasSeleccionadas.includes(empresa._id)}
                                      disabled={!empresasSeleccionadas.includes(empresa._id)}
                                    >
                                      <option value="" disabled>
                                        {empresasSeleccionadas.includes(empresa._id)
                                          ? "Seleccionar"
                                          : ""}
                                      </option>
                                      {colaboradores.map(({ _id, nombreCompleto }) => (
                                        <option key={_id} value={_id}>
                                          {nombreCompleto}
                                        </option>
                                      ))}
                                    </Input>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </CustomTable>
                      </TableContainer>
                    </Col>
                  </Row>
                )}
              </Collapse>
            </Form>
          )}
          {!!mensajeDeError && <p className="mb-0 mt-3 text-danger small">{mensajeDeError}</p>}
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={() => setVincularModalIsOpen(false)} outline>
            Cancelar
          </Button>
          <Button type="submit" color="success" form={VINCULAR_REQUISITO_LEGAL_MODAL_ID} outline>
            Vincular
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TablaDeRequisitosLegalesNuevos;
