import { useState, FC, ChangeEvent, useEffect, useRef } from "react";
import classNames from "classnames";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Row,
  Col,
  Toast,
  ToastHeader,
  ToastBody,
  Collapse,
  Navbar,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import LocalStorage from "../../utilities/LocalStorage";
import LoadingSpinner from "../../utilities/LoadingSpinner";
import ListaInput from "./ListInput";
import ListaDeContactos from "./ListaDeContactos";
import { ImageButton, SeparatorRow, toCamelCase, validarContraseña } from "../../utilities/utils";
import tiposDeIdentificacion, {
  TipoDeIdentificacion,
} from "../../resources/tipos-de-identificacion";
import paises from "../../resources/paises.json";
import tiposDeSangre from "../../resources/tipos-de-sangre.json";
import EmpresaForm from "./EmpresaForm";
import { Contacto } from "../../models/Contacto";
import { UsuarioBase } from "../../models/Usuario";
import { EmpresaBody } from "./EmpresaForm";
import { useTimeout } from "../../utilities/useTimeout";
import { PATHS } from "../../utilities/Paths";
import { nuevoRegistro } from "../../services/public-api-client";

interface SiONoRadioProps {
  label: string;
  checked: boolean | null;
  lineBreak?: boolean;
  required?: boolean;
  onChange: (value: boolean) => void;
}



const SiONoRadio: FC<SiONoRadioProps> = ({ label, checked, lineBreak, required, onChange }) => {
  const [id] = useState<string>(toCamelCase(label));
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value === "true");
  };

  return (
    <>
      <Label className={lineBreak ? "w-100" : "me-2"}>{label}</Label>
      <FormGroup check inline>
        <Input
          name={id}
          id={`${id}-yes`}
          type="radio"
          value="true"
          required={!!required}
          checked={checked === true}
          onChange={handleChange}
        />
        <Label check htmlFor={`${id}-yes`}>
          Sí
        </Label>
      </FormGroup>
      <FormGroup check inline>
        <Input
          name={id}
          id={`${id}-no`}
          type="radio"
          value="false"
          required={!!required}
          checked={checked === false}
          onChange={handleChange}
        />
        <Label check htmlFor={`${id}-no`}>
          No
        </Label>
      </FormGroup>
    </>
  );
};


interface TerminosCondicion {
  label?: string;
  checked: boolean | null;
  lineBreak?: boolean;
  required?: boolean;
  onChange: (value: boolean) => void;
  onClick: ()=> void;
}


const TerminosCondicion: FC<TerminosCondicion> = ({ label, checked, lineBreak, required, onChange, onClick }) => {
  const [id] = useState<string>();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value === "true");
  };


  return (
    <div className={'mt-5'}>
    <hr />
      <FormGroup check inline>
        <Input
          name={id}
          id={`${id}-yes`}
          type="checkbox"
          value="true"
          required={!!required}
          checked={checked === true}
          onChange={handleChange}
        />
       Aceptar los  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={onClick}>Términos y Condiciones</span>
      </FormGroup>

     
    </div>
  );
};


const REGISTRO_DE_USUARIO_FORM_ID = "registro-de-usuario-form";

const PasswordInput = styled(Input)`
  &.form-control.not-empty {
    padding-right: calc(1.5em + 0.75rem);
    &.is-valid,
    &.is-invalid {
      background-position: right calc(0.375em + 1.5875rem) center;
      padding-right: calc(1.5em + 1.75rem);
    }
  }
  ::-ms-reveal {
    display: none;
  }
`;

const MostrarContraseñaButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 0px;
`;

const CustomToast = styled(Toast)`
  top: 80px;
  z-index: 10;
`;

enum VistasDeRegistro {
  Usuario,
  Empresa,
}

interface UsuarioBody extends UsuarioBase {
  contrasena: string;
}

const UsuarioForm: FC = () => {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [nombre, setNombre] = useState<string>("");
  const [apellidos, setApellidos] = useState<string>("");
  const [tipoDeIdentificacion, setTipoDeIdentificacion] = useState<TipoDeIdentificacion>(
    tiposDeIdentificacion[0]
  );
  const [identificacion, setIdentificacion] = useState<string>("");
  const [correo, setCorreo] = useState<string>("");
  const [contraseña, setContraseña] = useState<string>("");
  const [confirmacionDeContraseña, setConfirmacionDeContrasena] = useState<string>("");
  const [nacionalidad, setNacionalidad] = useState<string>("Panamá");
  const [tipoDeSangre, setTipoDeSangre] = useState<string>("");
  const [telefono, setTelefono] = useState<string>("");
  const [direccionDeResidencia, setDireccionDeResidencia] = useState<string>("");
  const [alergias, setAlergias] = useState<string[]>([]);
  const [tieneAlergias, setTieneAlergias] = useState<boolean | null>(null);
  const [discapacidades, setDiscapacidades] = useState<string[]>([]);
  const [tieneDiscapacidades, setTieneDiscapacidades] = useState<boolean | null>(null);
  const [enfermedades, setEnfermedades] = useState<string[]>([]);
  const [tieneEnfermedades, setTieneEnfermedades] = useState<boolean | null>(null);
  const [medicamentos, setMedicamentos] = useState<string[]>([]);
  const [tieneMedicamentos, setTieneMedicamentos] = useState<boolean | null>(null);
  const [contactosDeEmergencia, setContactosDeEmergencia] = useState<Contacto[]>([]);
  const [registrarEmpresa, setRegistrarEmpresa] = useState<boolean | null>(null);
    
  const [terminosCondi, setTerminosCondi] = useState<boolean | null>(null);

  const [nombreDeLaEmpresa, setNombreDeLaEmpresa] = useState<string>("");
  const [ruc, setRuc] = useState<string>("");
  const [digitoVerificador, setDigitoVerificador] = useState<string>("");
  const [tipoDeIndustria, setTipoDeIndustria] = useState<string>("");
  const [direccion, setDireccion] = useState<string>("");
  const [isPersonaNatural, setIsPersonaNatural] = useState<boolean | null>(null);
  const [nombreDelPropietario, setNombreDelPropietario] = useState<string>("");
  const [cedulaDelPropietario, setCedulaDelPropietario] = useState<string>("");

  const [isValidIdentificacion, setIsValidIdentificacion] = useState<boolean>(false);
  const [errorIdentificacion, setErrorIdentificacion] = useState<string>("");
  const [errorCorreo, setErrorCorreo] = useState<string>("");
  const [isValidContaseña, setIsValidContraseña] = useState<boolean>(false);
  const [errorContraseña, setErrorContraseña] = useState<string>("");
  const [errorConfirmacionContraseña, setErrorConfirmacionContraseña] = useState<string>("");
  const [mostrarContraseña, setMostrarContraseña] = useState<boolean>(false);
  const [mostrarConfirmacionContraseña, setMostrarConfirmacionContraseña] =
    useState<boolean>(false);
  const [errorTipoDeSangre, setErrorTipoDeSangre] = useState<string>("");
  const [invalidAlergias, setInvalidAlergias] = useState<boolean>(false);
  const [invalidDiscapacidades, setInvalidDiscapacidades] = useState<boolean>(false);
  const [invalidEnfermedades, setInvalidEnfermedades] = useState<boolean>(false);
  const [invalidMedicamentos, setInvalidMedicamentos] = useState<boolean>(false);
  const [invalidContactosDeEmergencia, setInvalidContactosDeEmergencia] = useState<boolean>(false);
  const [errorRuc, setErrorRuc] = useState<string>("");
  const [errorForm, setErrorForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [vista, setVista] = useState<VistasDeRegistro>(VistasDeRegistro.Usuario);
  const [isLoading, setIsLoading] = useState<boolean>(false);
    
    
  const [terminosCondicionesModalIsOpen, setTerminosCondicionesModalIsOpen] =
  useState<boolean>(false);
    
  const toggleTerminosCondicionesModalIsOpen = () =>
    setTerminosCondicionesModalIsOpen(!terminosCondicionesModalIsOpen);
  
  const handleTerminosCondicionesClick = () => {
    toggleTerminosCondicionesModalIsOpen();
  };
  
  
  

  const irAlFormularioDeEmpresa = () => {
    if (validarFormulario()) {
      setVista(VistasDeRegistro.Empresa);
    }
  };

  const limpiarErrores = () => {
    setErrorForm(false);
    setErrorIdentificacion("");
    setErrorCorreo("");
    setErrorContraseña("");
    setErrorConfirmacionContraseña("");
    setErrorTipoDeSangre("");
    setInvalidAlergias(false);
    setInvalidDiscapacidades(false);
    setInvalidEnfermedades(false);
    setInvalidMedicamentos(false);
    setInvalidContactosDeEmergencia(false);
    setErrorRuc("");
  };

  const validarFormulario = () => {
    limpiarErrores();
    let esValido = true;
    const { isValid, isComplete } = tipoDeIdentificacion.validador(identificacion);
    if (!isValid || !isComplete) {
      if (esValido) scrollIntoView("identificacion");
      setErrorIdentificacion("El formato del documento de identificación no es válido.");
      esValido = false;
    }
    const contraseñaIsValid = validarContraseña(contraseña);
    if (!contraseñaIsValid) {
      if (esValido) scrollIntoView("contrasena");
      setErrorContraseña(
        "La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y caracteres especiales."
      );
      esValido = false;
    }
    if (confirmacionDeContraseña !== contraseña) {
      if (esValido) scrollIntoView("confirmacionContrasena");
      setErrorConfirmacionContraseña("Las contraseñas no coinciden.");
      esValido = false;
    }
    if (tipoDeSangre === "") {
      if (esValido) scrollIntoView("tipoDeSangre");
      setErrorTipoDeSangre("Selecciona tu tipo de sangre.");
      esValido = false;
    }
    switch (true) {
      case invalidAlergias || (!!tieneAlergias && !alergias.length):
        if (esValido) scrollIntoView("nuevaAlergia");
        esValido = false;
        break;
      case invalidDiscapacidades || (!!tieneDiscapacidades && !discapacidades.length):
        if (esValido) scrollIntoView("nuevaDiscapacidad");
        esValido = false;
        break;
      case invalidEnfermedades || (!!tieneEnfermedades && !enfermedades.length):
        if (esValido) scrollIntoView("nuevaEnfermedad");
        esValido = false;
        break;
      case invalidMedicamentos || (!!tieneMedicamentos && !medicamentos.length):
        if (esValido) scrollIntoView("nuevoMedicamento");
        esValido = false;
        break;
      case invalidContactosDeEmergencia || !contactosDeEmergencia.length:
        if (esValido) scrollIntoView("nombreNuevoContacto");
        esValido = false;
        break;
      case !terminosCondi:
      esValido = false;
      break;
    }
    // TODO: validar longitud de campos
    setErrorForm(!esValido);
    return esValido;
  };

  const scrollIntoView = (fieldName: string) => {
    if (formRef.current) {
      const formControl = formRef.current.querySelector<HTMLInputElement | HTMLSelectElement>(
        `#${fieldName}`
      );
      if (formControl) {
        formControl.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validarFormulario()) {
      registrar();
    }
  };

  const registrar = () => {
    setIsLoading(true);

    const usuario: UsuarioBody = {
      nombre,
      apellidos,
      correo,
      contrasena: contraseña,
      identificacion,
      tipoDeIdentificacion: tipoDeIdentificacion.id,
      nacionalidad,
      tipoDeSangre,
      telefono,
      direccionDeResidencia,
      alergias: tieneAlergias ? alergias : [],
      discapacidades: tieneDiscapacidades ? discapacidades : [],
      enfermedades: tieneEnfermedades ? enfermedades : [],
      medicamentos: tieneMedicamentos ? medicamentos : [],
      contactosDeEmergencia,
    };

    let empresa: EmpresaBody | undefined;

    if (registrarEmpresa) {
      if (isPersonaNatural === null) throw new Error("Selecciona el tipo de empresa.");
      empresa = {
        nombreDeLaEmpresa,
        ruc,
        digitoVerificador,
        tipoDeIndustria,
        direccion,
        isPersonaNatural,
      };
      if (isPersonaNatural) {
        empresa!.nombreDelPropietario = nombreDelPropietario;
        empresa!.cedulaDelPropietario = cedulaDelPropietario;
      }
    }

    const body = { usuario, empresa };

    nuevoRegistro(body)
      .then(({ data }) => {
        if (data.creado) {
          if (data.autorizado) {
            navigate({ pathname: PATHS.correoEnviado.to, search: `?correo=${correo}` });
          } else {
            navigate(PATHS.solicitudDeRegistro.to);
          }
        } else {
          throw new Error(data.mensaje);
        }
      })
      .catch((error) => {
        if (error.response) {
          const { data } = error.response;
          if ("dup" in data && "fieldName" in data.dup) {
            const { fieldName } = data.dup;
            scrollIntoView(fieldName);
            switch (fieldName) {
              case "identificacion":
                setIsValidIdentificacion(false);
                setErrorIdentificacion(
                  `Ya existe un usuario registrado con ${tipoDeIdentificacion.nombre.toLowerCase()} ${identificacion}.`
                );
                setVista(VistasDeRegistro.Usuario);
                break;
              case "correo":
                setErrorCorreo(`Ya existe un usuario registrado con el correo <b>${correo}</b>.`);
                setVista(VistasDeRegistro.Usuario);
                break;
              case "ruc":
                setErrorRuc(`Ya existe una empresa registrada con el RUC <b>${ruc}</b>.`);
                setVista(VistasDeRegistro.Empresa);
                break;
              default:
                setErrorMessage(data.message);
                break;
            }
          } else {
            setErrorMessage(data.message);
          }
        } else {
          setErrorMessage("Error de conexión.");
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (identificacion === "") return;
    const validatorResponse = tipoDeIdentificacion.validador(identificacion);
    const isValid = validatorResponse.isValid && validatorResponse.isComplete;
    setIsValidIdentificacion(isValid);
    setErrorIdentificacion("");
  }, [tipoDeIdentificacion, identificacion]);

  useEffect(() => {
    setIdentificacion("");
  }, [tipoDeIdentificacion]);

  useEffect(() => {
    if (correo === "") return;
    setErrorCorreo("");
  }, [correo]);

  useEffect(() => {
    const contraseñaValida = () => {
      if (contraseña === "" || confirmacionDeContraseña === "") return false;
      return contraseña === confirmacionDeContraseña && validarContraseña(contraseña);
    };
    setIsValidContraseña(contraseñaValida());
  }, [contraseña, confirmacionDeContraseña]);

  useEffect(() => {
    setErrorContraseña("");
  }, [contraseña]);

  useEffect(() => {
    setErrorConfirmacionContraseña("");
  }, [confirmacionDeContraseña]);

  useEffect(() => {
    limpiarErrores();
  }, [
    alergias,
    discapacidades,
    enfermedades,
    medicamentos,
    contactosDeEmergencia,
    tieneAlergias,
    tieneDiscapacidades,
    tieneEnfermedades,
    tieneMedicamentos,
    invalidAlergias,
    invalidDiscapacidades,
    invalidEnfermedades,
    invalidMedicamentos,
    invalidContactosDeEmergencia,
  ]);

  useEffect(() => {
    LocalStorage.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) navigate(PATHS.usuarioHome.to);
    });
  }, [navigate]);

  useTimeout(() => setErrorMessage(""), 4000, !!errorMessage);
  
  

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
     <div className="" >
     
     <Container className="h-100">
     <Row className="justify-content-center align-items-center h-100">
       <Col lg="12" style={{"paddingTop":"50px","paddingBottom":"50px"}} >
        {
          {
            [VistasDeRegistro.Usuario]: <h3>Registro de Usuario</h3>,
            [VistasDeRegistro.Empresa]: <h3>Registro de Empresa</h3>,
          }[vista]
        }
        <hr />
        <Form onSubmit={handleSubmit} innerRef={formRef} id={REGISTRO_DE_USUARIO_FORM_ID}>
          {vista === VistasDeRegistro.Usuario && (
            <>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="nombre"
                      name="nombre"
                      placeholder="Nombre"
                      type="text"
                      required
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                    <Label htmlFor="nombre">Nombre</Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="apellidos"
                      name="apellidos"
                      placeholder="Apellido(s)"
                      type="text"
                      required
                      value={apellidos}
                      onChange={(e) => setApellidos(e.target.value)}
                    />
                    <Label htmlFor="apellidos">Apellido(s)</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="tipoDeIdentificacion"
                      name="tipoDeIdentificacion"
                      type="select"
                      value={tipoDeIdentificacion.id}
                      onChange={({ target }) =>
                        setTipoDeIdentificacion(
                          tiposDeIdentificacion.find(({ id }) => id === target.value)!
                        )
                      }
                    >
                      {tiposDeIdentificacion.map(({ id, nombre }) => (
                        <option value={id} key={id}>
                          {nombre}
                        </option>
                      ))}
                    </Input>
                    <Label htmlFor="tipoDeIdentificacion">Tipo de identificación</Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="identificacion"
                      name="identificacion"
                      placeholder={tipoDeIdentificacion.nombre}
                      type="text"
                      required
                      invalid={!!errorIdentificacion}
                      valid={isValidIdentificacion}
                      value={identificacion}
                      onChange={(e) => setIdentificacion(e.target.value)}
                    />
                    <Label htmlFor="identificacion">{tipoDeIdentificacion.nombre}</Label>
                    <FormFeedback
                      tooltip
                      dangerouslySetInnerHTML={{ __html: errorIdentificacion }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup floating>
                <Input
                  id="correo"
                  name="correo"
                  placeholder="Correo"
                  type="email"
                  required
                  invalid={!!errorCorreo}
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                />
                <Label htmlFor="correo">Correo</Label>
                <FormFeedback tooltip dangerouslySetInnerHTML={{ __html: errorCorreo }} />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup floating>
                    <PasswordInput
                      id="contrasena"
                      name="contrasena"
                      placeholder="Contraseña"
                      type={mostrarContraseña ? "text" : "password"}
                      required
                      valid={isValidContaseña}
                      invalid={errorForm && !!errorContraseña}
                      value={contraseña}
                      className={classNames({ "not-empty": contraseña.length > 0 })}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setContraseña(e.target.value)}
                    />
                    <Label htmlFor="contrasena">Contraseña</Label>
                    <FormFeedback tooltip>{errorContraseña}</FormFeedback>
                    {contraseña.length > 0 && (
                      <MostrarContraseñaButton
                        type="button"
                        color="link"
                        className="text-muted"
                        tabIndex={-1}
                        onClick={() => setMostrarContraseña(!mostrarContraseña)}
                      >
                        {mostrarContraseña ? <FaEye /> : <FaEyeSlash />}
                      </MostrarContraseñaButton>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <PasswordInput
                      id="confirmacionDeContrasena"
                      name="confirmacionDeContrasena"
                      placeholder="Confirmar"
                      type={mostrarConfirmacionContraseña ? "text" : "password"}
                      required
                      valid={isValidContaseña}
                      invalid={errorForm && !!errorConfirmacionContraseña}
                      value={confirmacionDeContraseña}
                      className={classNames({ "not-empty": confirmacionDeContraseña.length > 0 })}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setConfirmacionDeContrasena(e.target.value)
                      }
                    />
                    <Label htmlFor="confirmacionDeContrasena">Confirmar</Label>
                    <FormFeedback tooltip>{errorConfirmacionContraseña}</FormFeedback>
                    {confirmacionDeContraseña.length > 0 && (
                      <MostrarContraseñaButton
                        type="button"
                        color="link"
                        className="text-muted"
                        tabIndex={-1}
                        onClick={() =>
                          setMostrarConfirmacionContraseña(!mostrarConfirmacionContraseña)
                        }
                      >
                        {mostrarConfirmacionContraseña ? <FaEye /> : <FaEyeSlash />}
                      </MostrarContraseñaButton>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup floating>
                <Input
                  id="nacionalidad"
                  name="nacionalidad"
                  type="select"
                  value={nacionalidad}
                  onChange={(e) => setNacionalidad(e.target.value)}
                >
                  {paises.map(({ nombre, bandera }) => (
                    <option value={nombre} key={nombre}>
                      {nombre} {bandera}
                    </option>
                  ))}
                </Input>
                <Label htmlFor="nacionalidad">Nacionalidad</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  id="tipoDeSangre"
                  name="tipoDeSangre"
                  type="select"
                  required
                  invalid={errorForm && !!errorTipoDeSangre}
                  value={tipoDeSangre}
                  onChange={(e) => setTipoDeSangre(e.target.value)}
                >
                  <option value="" disabled>
                    Selecciona tu tipo de sangre
                  </option>
                  {tiposDeSangre.map((type) => (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  ))}
                </Input>
                <Label htmlFor="tipoDeSangre">Tipo de sangre</Label>
                <FormFeedback tooltip>{errorTipoDeSangre}</FormFeedback>
              </FormGroup>
              <FormGroup floating>
                <PatternFormat
                  customInput={Input}
                  id="telefono"
                  name="telefono"
                  placeholder="Teléfono"
                  type="tel"
                  format={telefono.startsWith("7") ? "###-####" : "####-####"}
                  mask="_"
                  required
                  value={telefono}
                  onValueChange={({ formattedValue }) => setTelefono(formattedValue)}
                />
                <Label htmlFor="telefono">Teléfono</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  id="direccion"
                  name="direccion"
                  placeholder="Dirección de residencia"
                  type="text"
                  required
                  value={direccionDeResidencia}
                  onChange={(e) => setDireccionDeResidencia(e.target.value)}
                />
                <Label htmlFor="direccion">Dirección de residencia</Label>
              </FormGroup>
              <FormGroup>
                <SiONoRadio
                  label="¿Tienes alergias?"
                  checked={tieneAlergias}
                  onChange={setTieneAlergias}
                  required
                />
                <Collapse isOpen={!!tieneAlergias}>
                  <ListaInput
                    lista={alergias}
                    placeholder="Nueva alergia"
                    mensajeVacio="Ninguna alergia"
                    invalid={
                      errorForm && (invalidAlergias || (!!tieneAlergias && !alergias.length))
                    }
                    setLista={setAlergias}
                    setInvalid={setInvalidAlergias}
                  />
                </Collapse>
              </FormGroup>
              <FormGroup>
                <SiONoRadio
                  label="¿Tienes alguna discapacidad?"
                  checked={tieneDiscapacidades}
                  onChange={setTieneDiscapacidades}
                  required
                />
                <Collapse isOpen={!!tieneDiscapacidades}>
                  <ListaInput
                    lista={discapacidades}
                    placeholder="Nueva discapacidad"
                    mensajeVacio="Ninguna discapacidad"
                    invalid={
                      errorForm &&
                      (invalidAlergias || (!!tieneDiscapacidades && !discapacidades.length))
                    }
                    setLista={setDiscapacidades}
                    setInvalid={setInvalidDiscapacidades}
                  />
                </Collapse>
              </FormGroup>
              <FormGroup>
                <SiONoRadio
                  label="¿Tienes alguna enfermedad o condición médica?"
                  checked={tieneEnfermedades}
                  onChange={setTieneEnfermedades}
                  required
                />
                <Collapse isOpen={!!tieneEnfermedades}>
                  <ListaInput
                    lista={enfermedades}
                    placeholder="Nueva enfermedad"
                    mensajeVacio="Ninguna enfermedad"
                    invalid={
                      errorForm &&
                      (invalidEnfermedades || (!!tieneEnfermedades && !enfermedades.length))
                    }
                    setLista={setEnfermedades}
                    setInvalid={setInvalidEnfermedades}
                  />
                </Collapse>
              </FormGroup>
              <FormGroup>
                <SiONoRadio
                  label="¿Estás tomando algún medicamento en este momento?"
                  checked={tieneMedicamentos}
                  onChange={setTieneMedicamentos}
                  required
                />
                <Collapse isOpen={!!tieneMedicamentos}>
                  <ListaInput
                    lista={medicamentos}
                    placeholder="Nuevo medicamento"
                    mensajeVacio="Ningún medicamento"
                    invalid={
                      errorForm &&
                      (invalidMedicamentos || (!!tieneMedicamentos && !medicamentos.length))
                    }
                    setLista={setMedicamentos}
                    setInvalid={setInvalidMedicamentos}
                  />
                </Collapse>
              </FormGroup>
              <ListaDeContactos
                lista={contactosDeEmergencia}
                label="Contactos de emergencia"
                nombrePlaceholder="Nombre"
                telefonoPlaceholder="Número"
                mensajeVacio="Ningún contacto registrado"
                invalid={
                  errorForm && (invalidContactosDeEmergencia || !contactosDeEmergencia.length)
                }
                setLista={setContactosDeEmergencia}
                setInvalid={setInvalidContactosDeEmergencia}
              />
              <SiONoRadio
                label="¿Deseas registrar una empresa?"
                checked={registrarEmpresa}
                onChange={setRegistrarEmpresa}
                lineBreak
                required
              />
            </>
          )}
          {vista === VistasDeRegistro.Empresa && (
            <EmpresaForm
              {...{
                nombreDeLaEmpresa,
                ruc,
                digitoVerificador,
                tipoDeIndustria,
                direccion,
                isPersonaNatural,
                nombreDelPropietario,
                cedulaDelPropietario,
                errorRuc,
              }}
              {...{
                setNombreDeLaEmpresa,
                setRuc,
                setDigitoVerificador,
                setTipoDeIndustria,
                setDireccion,
                setIsPersonaNatural,
                setNombreDelPropietario,
                setCedulaDelPropietario,
              }}
            />
          )}
          
          <TerminosCondicion
         
            checked={terminosCondi}
            onChange={setTerminosCondi}
            onClick={handleTerminosCondicionesClick}
            lineBreak
            required
          />
          
        </Form>
        <SeparatorRow height={54} />
        <Navbar light container fixed="bottom" className="bg-white">
          {
            {
              [VistasDeRegistro.Usuario]: (
                <LinkContainer to="/">
                  <ImageButton title="Volver" type="button">
                    <FaArrowLeft size={25} />
                  </ImageButton>
                </LinkContainer>
              ),
              [VistasDeRegistro.Empresa]: (
                <ImageButton
                  title="Volver"
                  type="button"
                  onClick={() => setVista(VistasDeRegistro.Usuario)}
                >
                  <FaArrowLeft size={25} />
                </ImageButton>
              ),
            }[vista]
          }
          {vista === VistasDeRegistro.Usuario &&
            (registrarEmpresa ? (
              <Button
                type="button"
                color="primary"
                onClick={irAlFormularioDeEmpresa}
                title="Siguiente"
              >
                <FaArrowRight size={20} />
              </Button>
            ) : (
              <Button type="submit" form={REGISTRO_DE_USUARIO_FORM_ID} color="primary">
                Registrar
              </Button>
            ))}
          {vista === VistasDeRegistro.Empresa && (
            <Button type="submit" form={REGISTRO_DE_USUARIO_FORM_ID} color="primary">
              Registrar
            </Button>
          )}
        </Navbar>
     </Col>
     </Row>
     
     <Modal
       isOpen={terminosCondicionesModalIsOpen}
       toggle={toggleTerminosCondicionesModalIsOpen}
        fullscreen
     >
       <ModalHeader toggle={toggleTerminosCondicionesModalIsOpen}>Términos y Condiciones:</ModalHeader>
       <ModalBody>
         
         <div className="container">
             <h1>Política de Privacidad y Protección de Datos</h1>
             <p><strong>Última actualización:</strong> 24 de febrero de 2024</p>
             <p>Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley le protege.</p>
             
             <h2>Definiciones</h2>
             <ul>
                 <li><strong>Cuenta:</strong> Una cuenta única creada para acceder a nuestro Servicio.</li>
                 <li><strong>Aplicación:</strong> GISST, el software proporcionado por la Empresa.</li>
                 <li><strong>Empresa:</strong> GISST, referida como "Nosotros" o "Nuestro".</li>
                 <li><strong>Cookies:</strong> Archivos pequeños que almacenan información de navegación.</li>
                 <li><strong>Dispositivo:</strong> Cualquier dispositivo como una computadora, móvil o tableta.</li>
             </ul>
         
             <h2>Recopilación y Uso de Sus Datos Personales</h2>
             <h3>Tipos de Datos Recopilados</h3>
             <ul>
                 <li><strong>Datos Personales:</strong> Nombre, identificación, teléfono, correo electrónico y dirección.</li>
                 <li><strong>Datos de Uso:</strong> Información técnica sobre su visita y navegación en el Servicio.</li>
                 <li><strong>Datos de la Aplicación:</strong> Acceso a cámara y fotos, con su permiso.</li>
             </ul>
         
             <h2>Tecnologías de Seguimiento y Cookies</h2>
             <p>Usamos Cookies para mejorar su experiencia en nuestro Servicio.</p>
             <ul>
                 <li><strong>Cookies Necesarias:</strong> Para la autenticación y seguridad del usuario.</li>
                 <li><strong>Cookies de Preferencia:</strong> Guardan configuraciones como idioma y sesión.</li>
             </ul>
             <p>Para más información, consulte nuestra <a href="#">Política de Cookies</a>.</p>
         
             <h2>Uso de Sus Datos Personales</h2>
             <ul>
                 <li>Para proporcionar y mejorar nuestro Servicio.</li>
                 <li>Para gestionar su cuenta y contrato con nosotros.</li>
                 <li>Para enviarle información y notificaciones relevantes.</li>
                 <li>Para fines de análisis y mejora de nuestro servicio.</li>
             </ul>
         
             <h2>Retención y Transferencia de Datos</h2>
             <p>Mantenemos sus datos solo por el tiempo necesario y tomamos medidas para garantizar su seguridad.</p>
         
             <h2>Eliminación de Sus Datos Personales</h2>
             <p>Puede solicitar la eliminación de sus datos en cualquier momento contactándonos.</p>
         
             <h2>Cambios a esta Política</h2>
             <p>Podemos actualizar esta política y le notificaremos los cambios mediante nuestro Servicio.</p>
         
             <h2>Contacto</h2>
             <p>Si tiene preguntas, puede contactarnos a través de:</p>
             <ul>
                 <li>Correo electrónico: <a href="mailto:contacto@gisstpa.com">contacto@gisstpa.com</a></li>
                 <li>Teléfono: +507 6473 8182</li>
                 <li><a href="https://gisstpa.com/contacto/">Página de contacto</a></li>
             </ul>
         </div>
        
       </ModalBody>
       <ModalFooter>
         <Button color="secondary" outline onClick={toggleTerminosCondicionesModalIsOpen}>
           Cerrar
         </Button>
       </ModalFooter>
     </Modal>
     
     
     </Container>
     </div>
      <CustomToast fade isOpen={!!errorMessage} className="position-fixed end-0 p-3">
        <ToastHeader icon="danger">Error</ToastHeader>
        <ToastBody>{errorMessage}</ToastBody>
      </CustomToast>
    </>
  );
};

export default UsuarioForm;
