import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Provider } from 'react-redux';
import store from '../store/Store';
import { Container } from "reactstrap";
import styled from "styled-components";
import '../../assets/scss/style.scss';
import HeaderUsuario from "./HeaderUsuario";
import Footer from "./FooterUsuario";

const Body = styled.div`
  margin-top: calc(80px + 1rem);
`;

const Layout: FC = () => {
  return (
    <Provider store={store}>
    <div
      className={`ltr`}
      dir={'ltr'}
    >
    <main>
    <div
      className={`pageWrapper d-md-block d-lg-flex isMiniSidebar`}
    >
    <div className={` contentArea fixedTopbar `}>
      <HeaderUsuario />
      <div
        className={`pageWrapper d-md-block d-lg-flex`}
      >
      <div className={`contentArea`}>
        <Container fluid className="p-4 ">
      <div className="AppBody">
        <Outlet />
      </div>
        </Container>
      </div>
      </div>
      <Footer />
    </div>
    </div>
    </main>
    </div>
    </Provider>
  );
};

export default Layout;
