import { FC, useContext, useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import {
  Badge,
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Context } from "../../Context";
import {
  CategoryLMS,
  createCategory,
  deleteCurso,
  fetchCategorias,
} from "../../../services/lms-api-client";
import FormularioDeNuevaCategoria from "./FormularioDeNuevaCategoria";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { listCursosDeLaEmpresa } from "../../../services/usuario-api-client";
import { Curso } from "../../../models/Curso";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

import { FaEraser, FaCog, FaPlus } from "react-icons/fa";

import ComponentCard from '../../components/ComponentCard';

import { PATHS, procesarPath } from "../../../utilities/Paths";

const AGREGAR_CATEGORIA_FORM_ID = "agregarCategoriaForm";

interface Categoria {
  id: number;
  nombre: string;
  cantidadDeCursos: number;
}

let categoriasResponse: CategoryLMS[] = [];

const Cursos: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [cursos, setCursos] = useState<Curso[]>([]);
  const [nuevaCategoriaModalIsOpen, setNuevaCategoriaModalIsOpen] = useState<boolean>(false);
  const [confirmarEliminarCursoModalIsOpen, setConfirmarEliminarCursoModalIsOpen] =
    useState<boolean>(false);
  const [cursoAEliminar, setCursoAEliminar] = useState<Curso | null>(null);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleNuevaCategoriaSubmit = (
    body: { nombre: string; parent: number },
    callback: () => void
  ) => {
    setLoadingCounter((prev) => prev + 1);
    createCategory(authToken, body)
      .then(({ data }) => {
        data.forEach((categoria) => {
          categoriasResponse.push(categoria);
        });
        setCategorias(
          categoriasResponse.map(({ id, name, coursecount }) => ({
            id,
            nombre: name,
            cantidadDeCursos: coursecount || 0,
          }))
        );
        setNuevaCategoriaModalIsOpen(false);
        callback();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const handleEliminarCursoClick = (id: string) => {
    const curso = cursos.find((curso) => curso._id === id);
    setCursoAEliminar(curso || null);
    setConfirmarEliminarCursoModalIsOpen(true);
  };

  const handleConfirmarEliminarCursoClick = () => {
    if (!authToken || !cursoAEliminar) return;
    setLoadingCounter((prev) => prev + 1);
    deleteCurso(authToken, cursoAEliminar._id)
      .then(() => {
        setCursos(cursos.filter((curso) => curso._id !== cursoAEliminar._id));
        setConfirmarEliminarCursoModalIsOpen(false);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const handleConfirmarEliminarCursoModalClose = () => {
    setCursoAEliminar(null);
  };

  useEffect(() => {
    if (!authToken) return;
    setLoadingCounter((prev) => prev + 1);
    Promise.all([
      listCursosDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada),
      fetchCategorias(authToken),
    ])
      .then(([{ data: cursosData }, { data: categoriasData }]) => {
        setCursos(cursosData.map((curso) => new Curso(curso)));
        categoriasResponse = categoriasData;
        setCategorias(
          categoriasResponse.map(({ id, name, coursecount }) => ({
            id,
            nombre: name,
            cantidadDeCursos: coursecount,
          }))
        );
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => setTituloDeLaPantallaActual(PATHS.cursos.name), [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.formacion]} />
        
      <ComponentCard
         title="Gestionar Cursos"
         
        >
      <Row className="row-cols-1 row-cols-md-2">
        <Col className="mb-3">
          <Row className="align-items-center mb-3">
            <Col>
              <h4 className="mb-0">Categorías</h4>
            </Col>
            <Col xs="auto" className="ms-auto">
              <Button
                type="button"
                color="primary"
                size={"sm"}
                onClick={() => setNuevaCategoriaModalIsOpen(true)}
                outline
              >
              <FaPlus />  Nueva Categoría
              </Button>
            </Col>
          </Row>
          <ListGroup>
            {categorias.map(({ id, nombre, cantidadDeCursos }) => (
              <ListGroupItem key={id}>
                <Row className="align-items-center">
                  <Col>
                    {nombre} <Badge pill>{cantidadDeCursos}</Badge>
                  </Col>
                  {/* <Col xs="auto">
                    <Button
                      type="button"
                      color="link"
                      title="Editar"
                      onClick={() => alert("Funcionalidad en desarrollo")}
                    >
                      <FaEdit />
                    </Button>
                  </Col> */}
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col className="mb-3">
          <Row className="align-items-center mb-3">
            <Col>
              <h4 className="mb-0">Cursos</h4>
            </Col>
            <Col xs="auto">
              <LinkContainer to={PATHS.nuevoCurso.to}>
                <Button type="button" color="primary" size={"sm"} outline>
                 <FaPlus /> Nuevo Curso
                </Button>
              </LinkContainer>
            </Col>
          </Row>
          <ListGroup>
            {cursos.map(({ _id, id, tema }) => (
              <ListGroupItem key={id}>
                <Row className="align-items-center gx-0">
                  <Col>{tema}</Col>
                  <Col xs="auto">
                    <LinkContainer to={procesarPath(PATHS.editarCurso, _id)}>
                      <Button type="button" color="link" title="Editar" >
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                  </Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      color="danger"
                      size={"sm"}
                      title="Eliminar"
                      onClick={() => handleEliminarCursoClick(_id)}
                      outline
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
      </ComponentCard>
      <Modal isOpen={nuevaCategoriaModalIsOpen} toggle={() => setNuevaCategoriaModalIsOpen(false)}>
        <ModalHeader toggle={() => setNuevaCategoriaModalIsOpen(false)}>
          Nueva Categoría
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormularioDeNuevaCategoria
                formId={AGREGAR_CATEGORIA_FORM_ID}
                categoriasExistentes={categoriasResponse}
                hideBotones
                onSubmit={handleNuevaCategoriaSubmit}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
         
          <Button
            type="button"
            color="secondary"
            onClick={() => setNuevaCategoriaModalIsOpen(false)}
            outline
          >
            Cancelar
          </Button>
          
          <Button
            type="submit"
            color="success"
            onClick={() => setNuevaCategoriaModalIsOpen(false)}
            form={AGREGAR_CATEGORIA_FORM_ID}
            outline
          >
           <FaPlus /> Crear
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarEliminarCursoModalIsOpen}
        toggle={() => setConfirmarEliminarCursoModalIsOpen(false)}
        onClosed={handleConfirmarEliminarCursoModalClose}
      >
        <ModalHeader toggle={() => setConfirmarEliminarCursoModalIsOpen(false)}>
          Confirmar Eliminación
        </ModalHeader>
        <ModalBody>
          ¿Está seguro que desea eliminar el curso <b>{cursoAEliminar?.tema}</b>?
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => setConfirmarEliminarCursoModalIsOpen(false)}
            outline
          >
            Cancelar
          </Button>
          <Button type="button" color="danger" onClick={handleConfirmarEliminarCursoClick} outline>
            Eliminar
          </Button>
          
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Cursos;
