import { useEffect, FC, useContext, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Container, Nav
} from "reactstrap";
import { useLocation } from 'react-router-dom';
import NavSubItem from './Menu/NavSubItem';
import NavSingleItem from './Menu/NavSingleItem';
import SidebarData from './Menu/menuData';
import { Context } from "../Context";
import HexagonalMenu from "./HexagonalMenu";
import LoadingSpinner from "../../utilities/LoadingSpinner";
import { Empresa } from "../../models/Empresa";
import { GISSTLogoFull , GISSTLogo, GISSTLogoHome} from "../../resources/Images";
import {
  actualizarUltimaEmpresaSeleccionada,
  fetchVinculacionesDelUsuario,
} from "../../services/usuario-api-client";
import { PATHS } from "../../utilities/Paths";

const UsuarioHome: FC = () => {
  const location = useLocation();
  const activeBg = "white";
  const currentURL = location.pathname.split('/').slice(0, -1).join('/');
  const {
    authToken,
    idDeLaEmpresaSeleccionada,
    setIdDeLaEmpresaSeleccionada,
    setTituloDeLaPantallaActual,
  } = useContext(Context);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSelectEmpresa = (empresaId: string) => () => {
    setIdDeLaEmpresaSeleccionada(empresaId);
    actualizarUltimaEmpresaSeleccionada(authToken, empresaId);
  };

  useEffect(() => {
    if (!authToken || isLoading || empresas.length > 0) return;
    setIsLoading(true);
    fetchVinculacionesDelUsuario(authToken)
      .then(({ data }) => {
        const empresas = data.map(({ empresa }) => empresa as Empresa);
        setEmpresas(empresas);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setTituloDeLaPantallaActual("Menú principal"), [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
        
      
      
      <Row className="justify-content-center gx-0 h-100">
        <Col xs="9" md="5" style={{ height: "calc(100vh - 190px)", maxWidth: "calc(100vw - 24px)" }}>
          <GISSTLogoHome />
        </Col>
      </Row>
    </>
  );
};

export default UsuarioHome;
