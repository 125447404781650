import { useState, FC, useContext, useEffect, FormEvent } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { FaFlask, FaPlus } from "react-icons/fa";
import { SustanciaQuimica } from "../../../../models/SustanciaQuimica";
import { Context } from "../../../Context";
import { listSustanciasQuimicas } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { CustomTable, IconsContainer, TableContainer } from "../../../../utilities/utils";

interface BusquedaDeSustanciasQuimicasProps {
  formId: string;
  sustanciasQuimicasPreseleccionadas: SustanciaQuimica[];
  isCreating: boolean;
  onCreateToggle: () => void;
  onSubmit: (sustanciasQuimicasSeleccionadas: SustanciaQuimica[]) => void;
}

const BusquedaDeSustanciasQuimicas: FC<BusquedaDeSustanciasQuimicasProps> = ({
  formId,
  sustanciasQuimicasPreseleccionadas,
  isCreating,
  onCreateToggle,
  onSubmit,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [sustanciasQuimicasSeleccionadas, setSustanciasQuimicasSeleccionadas] = useState<
    SustanciaQuimica[]
  >(sustanciasQuimicasPreseleccionadas);
  const [sustanciasQuimicasFiltradas, setSustanciasQuimicasFiltradas] = useState<
    SustanciaQuimica[]
  >([]);
  const [sustanciasQuimicas, setSustanciasQuimicas] = useState<SustanciaQuimica[]>([]);
  const [busqueda, setBusqueda] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(sustanciasQuimicasSeleccionadas);
  };

  useEffect(() => {
    const busquedaLower = busqueda.toLowerCase();
    setSustanciasQuimicasFiltradas(
      sustanciasQuimicas.filter(
        (sustanciaQuimica) =>
          sustanciaQuimica.nombre.toLowerCase().includes(busquedaLower) ||
          (!!sustanciaQuimica.descripcion &&
            sustanciaQuimica.descripcion.toLowerCase().includes(busquedaLower)) ||
          (!!sustanciaQuimica.marca && sustanciaQuimica.marca.toLowerCase().includes(busquedaLower))
      )
    );
  }, [sustanciasQuimicas, busqueda]);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || isCreating) return;
    setIsLoading(true);
    listSustanciasQuimicas(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const sustanciasQuimicas = data.map(
          (sustanciaQuimica) => new SustanciaQuimica(sustanciaQuimica)
        );
        setSustanciasQuimicas(sustanciasQuimicas);
        setSustanciasQuimicasFiltradas(sustanciasQuimicas);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada, isCreating]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="align-items-center">
        <Col xs sm={6} md={4}>
          <FormGroup floating>
            <Input
              type="search"
              name="busqueda"
              id="busqueda"
              placeholder="Filtrar"
              autoComplete="off"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <Label for="busqueda">Filtrar</Label>
          </FormGroup>
        </Col>
      </Row>
      <Form id={formId} onSubmit={handleSubmit}>
        <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th className="text-center">
                  <Input
                    type="checkbox"
                    checked={
                      sustanciasQuimicasFiltradas.length > 0 &&
                      sustanciasQuimicasSeleccionadas.length === sustanciasQuimicasFiltradas.length
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSustanciasQuimicasSeleccionadas(sustanciasQuimicasFiltradas);
                      } else {
                        setSustanciasQuimicasSeleccionadas([]);
                      }
                    }}
                    disabled={sustanciasQuimicasFiltradas.length === 0}
                  />
                </th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Marca</th>
              </tr>
            </thead>
            <tbody>
              {sustanciasQuimicasFiltradas.map((sustanciaQuimica) => (
                <tr key={sustanciaQuimica._id}>
                  <td className="text-center">
                    <Input
                      type="checkbox"
                      checked={sustanciasQuimicasSeleccionadas.some(
                        (s) => s._id === sustanciaQuimica._id
                      )}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setSustanciasQuimicasSeleccionadas([
                            ...sustanciasQuimicasSeleccionadas,
                            sustanciaQuimica,
                          ]);
                        } else {
                          setSustanciasQuimicasSeleccionadas(
                            sustanciasQuimicasSeleccionadas.filter(
                              (s) => s._id !== sustanciaQuimica._id
                            )
                          );
                        }
                      }}
                    />
                  </td>
                  <td>{sustanciaQuimica.nombre}</td>
                  <td>{sustanciaQuimica.descripcion}</td>
                  <td>{sustanciaQuimica.marca}</td>
                </tr>
              ))}
              {sustanciasQuimicas.length === 0 ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    No se encontraron sustancias químicas
                  </td>
                </tr>
              ) : (
                sustanciasQuimicasFiltradas.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No se encontraron sustancias químicas con el filtro actual
                    </td>
                  </tr>
                )
              )}
            </tbody>
        </Table>
      </Form>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button type="button" color="primary" outline title="Agregar vehículo" onClick={onCreateToggle}>
            <IconsContainer>
              <FaFlask size={20} textAnchor="middle" alignmentBaseline="middle" />
              <FaPlus
                textAnchor="middle"
                alignmentBaseline="middle"
                style={{
                  fontSize: ".5em",
                  position: "absolute",
                  left: "2.35em",
                  bottom: "1.5em",
                }}
              />
            </IconsContainer>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BusquedaDeSustanciasQuimicas;
