import { useContext, FC, useEffect, useState, useCallback } from "react";
import { FaExternalLinkAlt,FaTrash } from "react-icons/fa";
import { Button, Col, Row, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Table } from "reactstrap";
import { ArchivoDeDocumento } from "../../../../models/ArchivoDeDocumento";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  getStatus,
  archivarArchivo,
  archivosDeDocumento,
} from "../../../../services/usuario-api-client";

import {
  CustomTable,
  TableCaption,
  TableContainer,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../../utilities/utils";
import { ArchivoDocumento,TiposDeDocumento } from "../../../../models/Documento";

interface TablaDeArchivosProps {
  archivos: ArchivoDeDocumento[];
  idDocumento?: string;
  tipoDeDocumento: TiposDeDocumento;
  titulo?: string;
}

const TablaDeArchivos: FC<TablaDeArchivosProps> = ({ archivos, idDocumento, tipoDeDocumento, titulo }) => {
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [confirmarArchivarModalIsOpen, setConfirmarArchivarModalIsOpen] = useState<boolean>(false);
  const [archSeleccionado, setArchSeleccionado] = useState<ArchivoDeDocumento | null>(null);
  const [archivosLoad, setArchivosLoad] = useState<ArchivoDeDocumento[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modificarDocumentoError, setModificarDocumentoError] = useState<string>("");
  const [idVincul, setIdVincul] = useState<string>("");
  const [UserAdmin, setUserAdmin] = useState<string>("regular");
  const [UserCargo, setUserCargo] = useState<string[]>([]);
      
  const limpiarSeleccion = () => {
    setArchSeleccionado(null);
    setModificarDocumentoError("");
  };
  
    
  const cargarArchivos = useCallback(() => {
    if (!authToken || !idDocumento) return;
    setIsLoading(true)
    archivosDeDocumento(authToken, idDocumento)
    .then(({ data }) => {
      setArchivosLoad(data.map((archivo) => new ArchivoDeDocumento(archivo)));
    })
    .finally(() => setIsLoading(false));
  }, [authToken]);
  
  const confirmarArchivar = () => {
    if (!authToken || !archSeleccionado) return;
    setIsLoading(true);
    setLoadingCounter((prev) => prev + 1);
    archivarArchivo(authToken, archSeleccionado._id)
      .then(() => {
        setIsLoading(false);
        setConfirmarArchivarModalIsOpen(false);
        cargarArchivos();
        //cargarDocumentos();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

    
  const handleArchivarClick = (idDelArchivo: string) => {
    
    const archiv = archivos.find(({ _id }) => _id === idDelArchivo);
    
    if (!archiv) return;
    console.log(archiv);
    setArchSeleccionado(archiv);
   setConfirmarArchivarModalIsOpen(true);
  };
  

  
  
  useEffect(() => {
    if (!authToken) return;
  
    setIsLoading(true);
  
    getStatus(authToken)
      .then(({ data }) => {
        
        const { vinculaciones } = data;
        const currentVinculacion = vinculaciones.find(
          ({ empresa }) =>
            typeof empresa === "string"
              ? empresa === idDeLaEmpresaSeleccionada
              : empresa._id === idDeLaEmpresaSeleccionada
        );
  
        if (currentVinculacion) {
          const { rol, cargo } = currentVinculacion;
  
          setIdVincul(currentVinculacion._id);
          // Establecer el rol y cargo
          setUserAdmin(rol);
          // Handle the cargo field
           if (cargo) {
              if (Array.isArray(cargo)) {
                // If cargo is an array
                const cargoNames = cargo.map((item) => {
                  if (typeof item === "string") {
                    return item;
                  }
                  return ""; // Fallback in case of unexpected structure
                }).filter(Boolean); // Remove empty or invalid items
                setUserCargo(cargoNames); // Directly set the array of cargo names
              }
            }
        }
        
        cargarArchivos();
        
      })
      .catch((error) => {
        console.error("Error obteniendo los datos:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authToken]);
  
  
  return (
    <>
    <LoadingSpinner isLoading={isLoading} />
     
     <Table className="no-wrap mt-3 align-middle" responsive borderless>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              {
                {
                  [TiposDeDocumento.DocumentacionGeneralDeLaEmpresa]: <></>,
                  [TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa]: (
                    <th>Equipo(s)/Herramienta(s)</th>
                  ),
                  [TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa]: <th>Vehículo(s)</th>,
                  [TiposDeDocumento.DocumentacionDeUsuariosDirectos]: <th>Colaborador(es)</th>,
                  [TiposDeDocumento.DocumentacionDeSustanciasQuimicas]: (
                    <th>Sustancia(s) química(s)</th>
                  ),
                }[tipoDeDocumento]
              }
              <th>Creado por</th>
              <th>Fecha de Creación</th>
              <th>Fecha de la actualización</th>
              <th>Fecha de vencimiento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {archivosLoad.map(
              (
                { _id, nombre, filepath, creadoPor, fechaDeCarga, fechaDeVencimiento,updatedAt, activos },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{nombre}</td>
                  {!!activos && activos.length > 0 && (
                    <td>{activos.map(({ nombreCompleto }) => nombreCompleto).join(", ")}</td>
                  )}
                  <td>{creadoPor.nombreCompleto}</td>
                  <td className="text-center">{shortDateToString(fechaDeCarga)}</td>
                  <td className="text-center">{shortDateToString(updatedAt)}</td>
                  <td className="text-center">{shortDateToString(fechaDeVencimiento)}</td>
                  <td>
                    <Row className="justify-content-center align-items-center gx-0 flex-nowrap">
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Descargar"
                          size="sm"
                          href={procesarLinkDeDocumento(filepath)}
                          target="_blank"
                          rel="noreferrer"
                          outline
                        >
                          <FaExternalLinkAlt size={15} />
                        </Button>
                      </Col>
                      
                      <Col xs="auto">
                        <Button
                          color="lin"
                          title="Archivar"
                          size="sm"
                          style={{ color: '#dc3444' }}
                          onClick={() => handleArchivarClick(_id)}
                          target="_blank"
                          rel="noreferrer"
                          outline
                        >
                          <FaTrash size={15} />
                        </Button>
                      </Col>
                      
                    </Row>
                  </td>
                </tr>
              )
            )}
            {archivosLoad.length === 0 && (
              <tr>
                <td
                  colSpan={
                    tipoDeDocumento === TiposDeDocumento.DocumentacionGeneralDeLaEmpresa ? 6 : 7
                  }
                  className="text-center"
                >
                  No hay archivos registrados.
                </td>
              </tr>
            )}
          </tbody>
     </Table>
      
      <Modal
        isOpen={confirmarArchivarModalIsOpen}
        toggle={() => setConfirmarArchivarModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setConfirmarArchivarModalIsOpen(false)}>
          Confirmar eliminación
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea archivar el archivo{" "}
            <b>{archSeleccionado?.nombre}</b>?
          </p>
        </ModalBody>
        <ModalFooter>
        
          <Button color="primary" outline onClick={() => setConfirmarArchivarModalIsOpen(false)}>
            Cancelar
          </Button>
          
          <Button color="danger" outline onClick={confirmarArchivar}>
            Archivar
          </Button>
        </ModalFooter>
      </Modal>
      
      
    </>
  );
};

export default TablaDeArchivos;
