import { useState, FC, useEffect } from "react";
import { Button, Col, Popover, PopoverBody, Row } from "reactstrap";

import { GISSTLogoFull } from "../resources/Images";
import Login from "./Login";
import LocalStorage from "../utilities/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../utilities/Paths";

const Home: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setPopoverIsOpen(!popoverIsOpen);
  };

  useEffect(() => {
    LocalStorage.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) navigate(PATHS.usuarioHome.to);
    });
  }, [navigate]);

  useEffect(() => {
    const { hash } = location;
    if (hash && hash === "#login") {
      setPopoverIsOpen(true);
    }
  }, [location]);

  return (
    <>
 
          <Login />
   
      
    </>
  );
};

export default Home;
