import { useState, FC, FormEvent } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import LoadingSpinner from "../utilities/LoadingSpinner";
import { MdPassword } from "react-icons/md";
import { PATHS } from "../utilities/Paths";
import { restablecerContrasena } from "../services/public-api-client";
import Header from "./Header";

import { GISSTLogoFull } from "../resources/Images";

import styled from "styled-components";

const RecuperarContraseña: FC = () => {
  const [correo, setCorreo] = useState<string>("");
  const [contraseñaRestablecidaModalIsOpen, setContraseñaRestablecidaModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRecuperarContraseña = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    restablecerContrasena(correo)
      .then(() => setContraseñaRestablecidaModalIsOpen(true))
      .finally(() => setIsLoading(false));
  };
  
  const Body = styled.div`
    margin-top: calc(66px + 1rem);
  `;

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div className="loginBox">
      
      <Container fluid className="h-100">
      <Row className="justify-content-center align-items-center h-100">
        <Col lg="12" className="loginContainer">
          <Card>
          <CardBody className="p-4 m-1">
            <div className="text-center">
             
             <GISSTLogoFull />
               
               <h2>Recuperar contraseña</h2>
               
               <hr />
            </div>
      
      <p>Ingrese su correo electrónico para recuperar su contraseña.</p>
      <Form onSubmit={handleRecuperarContraseña} style={{"marginTop":"35px"}}>
        <FormGroup floating>
          <Input
            type="email"
            id="correo"
            placeholder="Correo electrónico"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            required
          />
          <Label for="correo">Correo electrónico</Label>
          <FormText>Se enviará un correo electrónico con una contraseña temporal.</FormText>
        </FormGroup>
        <Button type="submit" color="primary" className="w-100" style={{"marginTop":"20px"}} outline>
          <MdPassword size={20} style={{"marginBottom":"5px","marginRight":"10px"}}  /> Recuperar contraseña
        </Button>
      </Form>
          </CardBody>
          </Card>
     </Col>
      </Row>
      </Container>
      </div>
      
      <Modal isOpen={contraseñaRestablecidaModalIsOpen}>
        <ModalHeader>Contraseña restablecida</ModalHeader>
        <ModalBody>
          Se ha enviado un correo electrónico con una contraseña temporal. Ingrese con la contraseña
          temporal y cámbiela por una nueva.
        </ModalBody>
        <ModalFooter>
          <LinkContainer to={{ pathname: PATHS.inicio.to, hash: "login" }}>
            <Button color="primary" outline>Ir a iniciar sesión</Button>
          </LinkContainer>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RecuperarContraseña;
