import { FaHome, FaUniversity, FaFolder, FaFileSignature, FaFileInvoice, FaGavel, FaTable, FaFileAlt, FaBalanceScale } from "react-icons/fa";
import { PATHS } from "../../../utilities/Paths";

interface SidebarItem {
  id: number;
  title: string;
  href: string;
  icon: React.ReactNode;
  collapsible: boolean;
  caption?: string;
  children?: SidebarItem[]; // Optional nested items
  suffix?: string; // Add this line
  suffixColor?: string; // Add this line if it's used
  ddType?: string;
}



const SidebarData: SidebarItem[] = [
  { caption: 'Inicio' },
  {
    id:1,
    title: 'Inicio',
    href: '/admin',
    icon: <FaHome />,
    collapisble: false,
  },
  { caption: 'AdminRegistros' },
  {
    id:2,
    title: PATHS.adminRegistros?.name || "",
    href: PATHS.adminRegistros?.to || "",
    icon: <FaTable />,
    ddType: 'two-column',
    collapisble: false,
  },
  { caption: 'AdminDocs' },
  {
    id:3,
    title: PATHS.adminDocumentosLegales?.name || "",
    href: PATHS.adminDocumentosLegales?.to || "",
    icon: <FaFileAlt />,
    ddType: 'two-column',
    collapisble: false,
  },
  { caption: 'AdminAutoridades' },
  {
    id:4,
    title:PATHS.adminAutoridades?.name || "",
    href: PATHS.adminAutoridades?.to || "",
    icon: <FaGavel />,
    ddType: 'two-column',
    collapisble: false,
  },
  { caption: 'AdminDocSug' },
  {
    id:5,
    title:PATHS.adminDocumentosSugeridos?.name || "",
    href: PATHS.adminDocumentosSugeridos?.to || "",
    icon: <FaBalanceScale />,
    ddType: 'two-column',
    collapisble: false,
  },
  
];

export default SidebarData;
