import type { FC } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import styled from "styled-components";
import { GISSTLogo } from "../resources/Images";
import { ReactFontSizeByTextLength } from "react-font-size-by-text-length";
import { SeparatorCol } from "../utilities/utils";

const LogoContainer = styled.div`
  height: 40px;
`;

interface Props {
  titulo?: string;
}

const Header: FC<Props> = ({ titulo }) => {
 
  
  return (
    <Navbar
      color={"#FFF"}
      dark={false}
      light={true}
      expand="lg"
      className="shadow HorizontalTopbar p-0"
    >
      <NavbarBrand>
        <LogoContainer>
          <GISSTLogo />
        </LogoContainer>
      </NavbarBrand>
      {!!titulo && (
        <>
          <h2>
            <ReactFontSizeByTextLength startAtChar={15} changePerChar={3} minPercent={50}>
              <b>{titulo}</b>
            </ReactFontSizeByTextLength>
          </h2>
          <SeparatorCol width={56} />
        </>
      )}
    </Navbar>
  );
};

export default Header;
