export const PATHS = {
  inicio: {
    name: "Inicio",
    to: "/",
  },
  login: {
    name: "Iniciar Sesión",
    to: "/login",
  },
  registro: {
    name: "Registrarse",
    to: "/registro",
  },
  logout: {
    name: "Cerrar Sesión",
    to: "/logout",
  },
  confirmarCorreo: {
    name: "Confirmar Correo",
    to: "/confirmar-correo/:token",
    maskName: "token",
  },
  correoEnviado: {
    name: "Correo Enviado",
    to: "/correo-enviado",
  },
  solicitudDeRegistro: {
    name: "Solicitud de Registro Enviada",
    to: "/solicitud-de-registro",
  },
  recuperarContrasena: {
    name: "Recuperar Contraseña",
    to: "/recuperar-contrasena",
  },
  usuarioHome: {
    name: "Inicio",
    to: "/usuario",
  },
  perfil: {
    name: "Mi Perfil",
    to: "/usuario/perfil",
  },
  administracionDeUsuarios: {
    name: "Administración de Usuarios",
    to: "/usuario/usuarios",
  },
  formacion: {
    name: "Formación y Toma de Conciencia",
    to: "/usuario/formacion",
  },
  cursos: {
    name: "Gestión de Cursos",
    to: "/usuario/formacion/cursos",
  },
  curso: {
    name: "Curso",
    to: "/usuario/formacion/cursos/:id",
    maskName: "id",
  },
  nuevoCurso: {
    name: "Nuevo Curso",
    to: "/usuario/formacion/cursos/nuevo",
  },
  editarCurso: {
    name: "Editar Curso",
    to: "/usuario/formacion/cursos/:id/editar",
    maskName: "id",
  },
  requisitosLegales: {
    name: "Requisitos Legales",
    to: "/usuario/requisitos-legales",
  },
  nuevoRequisitoLegal: {
    name: "Nuevo Requisito Legal",
    to: "/usuario/requisitos-legales/nuevo",
  },
  requisitoLegal: {
    name: "Requisito Legal",
    to: "/usuario/requisitos-legales/:id",
    maskName: "id",
  },
  analisisDeRequisitoLegal: {
    name: "Análisis de Requisito Legal",
    to: "/usuario/requisitos-legales/:id/analisis",
    maskName: "id",
  },
  derogacionDeRequisitoLegal: {
    name: "Derogación de Requisito Legal",
    to: "/usuario/requisitos-legales/:id/derogacion",
    maskName: "id",
  },
  nuevoDerogacionDeRequisitoLegal: {
    name: "Nueva Derogación de Requisito Legal",
    to: "/usuario/requisitos-legales/:id/derogacion/nuevo",
    maskName: "id",
  },
  nuevoAnalisisDeRequisitoLegal: {
    name: "Nuevo Análisis de Requisito Legal",
    to: "/usuario/requisitos-legales/:id/analisis/nuevo",
    maskName: "id",
  },
  gestionDocumental: {
    name: "Gestión Documental",
    to: "",
  },
  gestionDocumentalInterna: {
    name: "Gestión Documental Interna",
    to: "/usuario/gestion-documental/interna",
  },
  nuevoDocumentoInterno: {
    name: "Nuevo Documento Interno",
    to: "/usuario/gestion-documental/interna/agregar",
  },
  documentoInterno: {
    name: "Documento Interno",
    to: "/usuario/gestion-documental/interna/:id",
    maskName: "id",
  },
  gestionDocumentalContratos: {
    name: "Administración de Contratos",
    to: "/usuario/gestion-documental/contratos",
  },
  configuracionDeContrato: {
    name: "Configuración de Contrato",
    to: "/usuario/gestion-documental/contratos/:id",
    maskName: "id",
  },
  documentoDelContrato: {
    name: "Documento del Contrato",
    to: "/usuario/gestion-documental/contratos/documento/:id",
    maskName: "id",
  },
  investigacionDeIncidentes: {
    name: "Investigación de Incidentes",
    to: "/usuario/incidentes",
  },
  nuevoIncidente: {
    name: "Agregar Incidente",
    to: "/usuario/incidentes/agregar",
  },
  incidente: {
    name: "Incidente",
    to: "/usuario/incidentes/:id",
    maskName: "id",
  },
  editarIncidente: {
    name: "Editar Incidente",
    to: "/usuario/incidentes/:id/editar",
    maskName: "id",
  },
  planesDeAccion: {
    name: "Planes de Acción",
    to: "/usuario/planes",
  },
  planDeAccion: {
    name: "Plan de Acción",
    to: "/usuario/planes/:id",
    maskName: "id",
  },
  accion: {
    name: "Acción",
    to: "/usuario/planes/acciones/:id",
    maskName: "id",
  },
  miper: {
    name: "MIPER",
    to: "/usuario/miper",
  },
  nuevaActividad: {
    name: "Nueva Actividad",
    to: "/usuario/miper/agregar",
  },
  editarActividad: {
    name: "Editar Actividad",
    to: "/usuario/miper/:id/editar",
    maskName: "id",
  },
  asignarRiesgos: {
    name: "Evaluación de Riesgos",
    to: "/usuario/miper/:id/riesgos/asignar",
    maskName: "id",
  },
  editarEvaluacion: {
    name: "Editar Evaluación de Riesgos",
    to: "/usuario/miper/:id/riesgos/editar",
    maskName: "id",
  },
  resultadosDeRiesgos: {
    name: "Resultados de Evaluación de Riesgos",
    to: "/usuario/miper/:id/riesgos",
    maskName: "id",
  },
  contexto: {
    name: "Contexto",
    to: "/usuario/contexto",
  },
  nuevaParteInteresada: {
    name: "Nueva Parte Interesada",
    to: "/usuario/contexto/agregar",
  },
  indicadores: {
    name: "Indicadores de Gestión",
    to: "/usuario/indicadores",
  },
  nuevaEmpresa: {
    name: "Nueva Empresa",
    to: "/usuario/empresas/nueva",
  },
  editarEmpresa: {
    name: "Editar Empresa",
    to: "/usuario/empresas/editar",
  },
  autorizarUsuario: {
    name: "Autorizar Usuario",
    to: "/usuario/autorizar",
  },
  adminLogin: {
    name: "Iniciar Sesión",
    to: "/admin/login",
  },
  adminHome: {
    name: "Panel de Administración",
    to: "/admin",
  },
  adminRegistros: {
    name: "Registros",
    to: "/admin/registros",
  },
  adminDocumentosLegales: {
    name: "Documentos Legales",
    to: "/admin/documentos-legales",
  },
  adminNuevoDocumentoLegal: {
    name: "Nuevo Documento Legal",
    to: "/admin/documentos-legales/nuevo",
  },
  adminAutoridades: {
    name: "Gestionar Autoridades",
    to: "/admin/autoridades",
  },
  adminDocumentosSugeridos: {
    name: "Gestionar Documentos Sugeridos",
    to: "/admin/documentos-sugeridos",
  },
  widgets: {
    name: "Widgets",
    to: "/widgets",
  },
  widgetDocumentosLegales: {
    name: "Documentos Legales",
    to: "/widgets/documentos-legales",
  },
};

interface Path {
  to: string;
  maskName: string;
}

export const procesarPath = (path: Path, value: string) => {
  const { to, maskName } = path;
  return to.replace(`:${maskName}`, value);
};
