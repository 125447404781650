import { useState, FC, useEffect } from "react";
import {
  Alert,
  Button,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { FaEraser, FaCog, FaPlus, FaSearch } from "react-icons/fa";
import { NuevoUsuarioBody, UsuarioParaAgregar, VinculacionBody } from ".";
import { Cargo } from "../../../models/Cargo";
import tiposDeIdentificacion, {
  TipoDeIdentificacion,
} from "../../../resources/tipos-de-identificacion";

const AGREGAR_USUARIO_FORM_ID = "agregarUsuarioForm";

interface AgregarUsuarioModalProps {
  usuarioParaAgregar: UsuarioParaAgregar | null;
  cargos: Cargo[];
  error?: string;
  errorInsider?: string;
  isOpen: boolean;
  toggle: () => void;
  clear: () => void;
  onBuscarClick: (identificacion: string) => void;
  onAgregarClick: (persona: VinculacionBody | NuevoUsuarioBody) => void;
}

const AgregarUsuarioModal: FC<AgregarUsuarioModalProps> = ({
  usuarioParaAgregar,
  cargos,
  error,
  errorInsider,
  isOpen,
  toggle,
  clear,
  onBuscarClick,
  onAgregarClick,
}) => {
  const [identificacion, setIdentificacion] = useState<string>("");
  const [tipoDeIdentificacion, setTipoDeIdentificacion] = useState<TipoDeIdentificacion>(
    tiposDeIdentificacion[0]
  );
  const [nombre, setNombre] = useState<string>("");
  const [apellidos, setApellidos] = useState<string>("");
  const [correo, setCorreo] = useState<string>("");
  const [rol, setRol] = useState<string>("regular");
  const [cargo, setCargo] = useState<Cargo[]>([]);

  const handleClear = () => {
    setIdentificacion("");
    clear();
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!usuarioParaAgregar) {
      onBuscarClick(identificacion);
      return;
    }
    const esUsuarioNuevo = !usuarioParaAgregar.existe;
    

    
    
    if (esUsuarioNuevo) {
      if (!cargo) return;
      const body: NuevoUsuarioBody = {
        tipoDeIdentificacion: tipoDeIdentificacion.id,
        identificacion,
        nombre,
        apellidos,
        correo,
        rol,
        idCargo: cargo.length > 0 ? cargo.map((item) => item._id) : [],
      };
      onAgregarClick(body);
    } else if (cargo && usuarioParaAgregar.persona) {
      const body: VinculacionBody = {
        idPersona: usuarioParaAgregar.persona._id,
        rol,
        idCargo: cargo.length > 0 ? cargo.map((item) => item._id) : [],
      };
      onAgregarClick(body);
    }
  };


  useEffect(() => {
    if (usuarioParaAgregar && usuarioParaAgregar.persona && usuarioParaAgregar.existe) {
      const { persona } = usuarioParaAgregar;
      setTipoDeIdentificacion(
        tiposDeIdentificacion.find(({ id }) => id === persona.tipoDeIdentificacion)!
      );
      setNombre(persona.nombre);
      setApellidos(persona.apellidos);
      setCorreo(persona.correo);
    } else {
      setTipoDeIdentificacion(tiposDeIdentificacion[0]);
      setNombre("");
      setApellidos("");
      setCorreo("");
    }
  }, [usuarioParaAgregar]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={handleClear}>
      <ModalHeader toggle={toggle}>Agregar Usuario</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleFormSubmit} id={AGREGAR_USUARIO_FORM_ID}>
          <FormGroup floating>
            <Input
              type="text"
              id="identificacion"
              name="identificacion"
              placeholder="Identificación"
              value={identificacion}
              onChange={({ target }) => setIdentificacion(target.value)}
              required={!!usuarioParaAgregar && !usuarioParaAgregar.existe}
            />
            <Label for="identificacion">Identificación</Label>
          </FormGroup>
          {!!usuarioParaAgregar && (
            <>
              <FormGroup floating>
                <Input
                  id="tipoDeIdentificacion"
                  name="tipoDeIdentificacion"
                  type="select"
                  value={tipoDeIdentificacion.id}
                  onChange={({ target }) =>
                    setTipoDeIdentificacion(
                      tiposDeIdentificacion.find(({ id }) => id === target.value)!
                    )
                  }
                >
                  {tiposDeIdentificacion.map(({ id, nombre }) => (
                    <option value={id} key={id}>
                      {nombre}
                    </option>
                  ))}
                </Input>
                <Label htmlFor="tipoDeIdentificacion">Tipo de identificación</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                  value={nombre}
                  onChange={({ target }) => setNombre(target.value)}
                  disabled={usuarioParaAgregar.existe}
                  required={!usuarioParaAgregar.existe}
                />
                <Label for="nombre">Nombre</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="apellidos"
                  name="apellidos"
                  placeholder="Apellidos"
                  value={apellidos}
                  onChange={({ target }) => setApellidos(target.value)}
                  disabled={usuarioParaAgregar.existe}
                  required={!usuarioParaAgregar.existe}
                />
                <Label for="apellidos">Apellidos</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="text"
                  id="correo"
                  name="correo"
                  placeholder="Correo"
                  value={correo}
                  onChange={({ target }) => setCorreo(target.value)}
                  disabled={usuarioParaAgregar.existe}
                  required={!usuarioParaAgregar.existe}
                />
                <Label for="correo">Correo</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="select"
                  id="rol"
                  name="rol"
                  placeholder="Rol"
                  value={rol}
                  onChange={({ target }) => setRol(target.value)}
                  disabled={usuarioParaAgregar.yaVinculado}
                  required
                >
                  <option value="regular">Regular</option>
                  <option value="administrador">Administrador</option>
                </Input>
                <Label for="rol">Rol</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  type="select"
                  id="cargo"
                  name="cargo"
                  multiple
                  placeholder="Cargo"
                  style={{"height":"150px"}}
                  onChange={({ target }) => {
                    if (target instanceof HTMLSelectElement) {
                      const selectedIds = Array.from(target.selectedOptions, (option) => option.value);
                      setCargo(cargos.filter((c) => selectedIds.includes(c._id)));
                    }
                  }}
                  disabled={usuarioParaAgregar.yaVinculado}
                  required
                >
                  {cargos.map(({ _id, cargo }) => (
                    <option key={_id} value={_id}>
                      {cargo}
                    </option>
                  ))}
                </Input>
                <Label for="cargo">Cargo</Label>
              </FormGroup>
            </>
          )}
        </Form>
        <Collapse isOpen={!!error}>
          <Alert color="danger" className="mt-3" onClick={() => alert(errorInsider)}>
            {error}
          </Alert>
        </Collapse>
      </ModalBody>
      <ModalFooter>
        {!usuarioParaAgregar ? (
          <Button type="submit" color="success" form={AGREGAR_USUARIO_FORM_ID}>
           <FaSearch /> Buscar
          </Button>
        ) : (
          <Button
            type="submit"
            color="success"
            disabled={usuarioParaAgregar.yaVinculado}
            form={AGREGAR_USUARIO_FORM_ID}
          >
           <FaPlus /> Agregar
          </Button>
        )}
        <Button type="button" color="secondary" onClick={toggle}>
          Cancelar
        </Button>
        <Button
          type="button"
          title="Limpiar"
          color="link"
          onClick={handleClear}
          disabled={!identificacion}
        >
          <FaEraser size={20} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AgregarUsuarioModal;
