import { configureStore } from '@reduxjs/toolkit';
import customizerReducer from './customizer/CustomizerSlice'; // Path to your customizer slice

const store = configureStore({
  reducer: {
    customizer: customizerReducer,
  },
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;