import { Autoridad } from "./Autoridad";
import { DocumentoLegal, DocumentoLegalResponse } from "./DocumentoLegal";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export enum TiposDeRequisitosLegales {
  Acuerdo = "acuerdo",
  Autorizacion = "autorizacion",
  Constitucion = "constitucion",
  Contrato = "contrato",
  Convencion = "convencion",
  Decreto = "decreto",
  Informe = "informe",
  Ley = "ley",
  Oficio = "oficio",
  Permiso = "permiso",
  Protocolo = "protocolo",
  Requerimiento = "requerimiento",
  Resolucion = "resolucion",
  Otro = "otro",
}

export enum CategoriasDeRequisitosLegales {
  SST = "sst",
  Calidad = "calidad",
  Ambiente = "ambiente",
  Otro = "otro",
}

export enum EstadosDeRequisitosLegales {
  AnalisisVencido = "analisisVencido",
  AnalisisPendiente = "analisisPendiente",
}

export interface BookmarkDeArticulo {
  _id?: string;
  articulo: number;
  pagina: number;
}

export interface RequisitoLegalBase {
  nombre: string;
  filepath: string;
  tipo: TiposDeRequisitosLegales;
  autoridad: Autoridad;
  numeroDeArticulos: number;
  bookmarks: BookmarkDeArticulo[];
  clasificacion?: CategoriasDeRequisitosLegales;
  fechaDeEmision?: string | Date;
  fechaDeVinculacion?: string | Date;
  estado?: EstadosDeRequisitosLegales;
  fechaLimiteDeAnalisis?: string | Date;
  responsableDelAnalisis: string | VinculacionResponse | Vinculacion;
  creadoPor: string | VinculacionResponse | Vinculacion;
  documentoLegal?: string | DocumentoLegalResponse | DocumentoLegal;
  empresa: string;
  analisisCount?: number;
  derogacionCount?: number;
}

export interface RequisitoLegal extends RequisitoLegalBase {
  _id: string;
  clasificacion: CategoriasDeRequisitosLegales;
  fechaDeEmision?: Date;
  fechaDeVinculacion?: Date;
  fechaLimiteDeAnalisis?: Date;
  responsableDelAnalisis: string | Vinculacion;
  responsableDelDerogacion: string | Vinculacion;
  creadoPor: string | Vinculacion;
  documentoLegal?: string | DocumentoLegal;
}

export interface RequisitoLegalResponse extends RequisitoLegalBase {
  _id: string;
  clasificacion: CategoriasDeRequisitosLegales;
  fechaDeEmision?: string;
  fechaDeVinculacion?: string;
  fechaLimiteDeAnalisis?: string;
  responsableDelAnalisis: string | VinculacionResponse;
  creadoPor: string | VinculacionResponse;
  documentoLegal?: string | DocumentoLegalResponse;
}

export class RequisitoLegal implements RequisitoLegal {
  constructor({
    _id,
    nombre,
    filepath,
    tipo,
    autoridad,
    numeroDeArticulos,
    bookmarks,
    clasificacion,
    fechaDeEmision,
    fechaDeVinculacion,
    estado,
    fechaLimiteDeAnalisis,
    responsableDelAnalisis,
    creadoPor,
    documentoLegal,
    empresa,
    analisisCount,
    derogacionCount,
  }: RequisitoLegalResponse) {
    this._id = _id;
    this.nombre = nombre;
    this.filepath = filepath;
    this.tipo = tipo;
    this.autoridad = autoridad;
    this.numeroDeArticulos = numeroDeArticulos;
    this.bookmarks = bookmarks;
    this.clasificacion = clasificacion;
    this.fechaDeEmision = fechaDeEmision ? new Date(fechaDeEmision) : undefined;
    this.fechaDeVinculacion = fechaDeVinculacion ? new Date(fechaDeVinculacion) : undefined;
    this.estado = estado;
    this.analisisCount = analisisCount;
    this.derogacionCount = derogacionCount;
    
    this.fechaLimiteDeAnalisis = fechaLimiteDeAnalisis
      ? new Date(fechaLimiteDeAnalisis)
      : undefined;
    if (typeof responsableDelAnalisis === "string") {
      this.responsableDelAnalisis = responsableDelAnalisis;
    } else {
      this.responsableDelAnalisis = new Vinculacion(responsableDelAnalisis);
    }
    if (typeof creadoPor === "string") {
      this.creadoPor = creadoPor;
    } else {
      this.creadoPor = new Vinculacion(creadoPor);
    }
    if (typeof documentoLegal === "undefined" || typeof documentoLegal === "string") {
      this.documentoLegal = documentoLegal;
    } else {
      this.documentoLegal = new DocumentoLegal(documentoLegal);
    }
    this.empresa = empresa;
  }

  get nombreCompleto() {
    return this.nombre;
  }

  toString() {
    return this._id;
  }
}

export interface RequisitoLegalBody {
  nombreDelRequisitoLegal: string;
  adjunto: File;
  tipoDeRequisitoLegal: TiposDeRequisitosLegales;
  autoridad: string;
  numeroDeArticulos: number;
  fechaLimiteDeAnalisis: Date;
  empresasYPersonasResponsables: { empresa: string; responsableDelAnalisis: string }[];
}

export interface RequisitoLegalEditBody {
  nombre: string;
  tipo: TiposDeRequisitosLegales;
  autoridad: string;
  numeroDeArticulos: number;
  fechaLimiteDeAnalisis: Date;
  clasificacion: CategoriasDeRequisitosLegales;
  responsableDelAnalisis: string;
}

export function textoTipoDeRequisitoLegal(tipo: TiposDeRequisitosLegales) {
  switch (tipo) {
    case TiposDeRequisitosLegales.Acuerdo:
      return "Acuerdo";
    case TiposDeRequisitosLegales.Autorizacion:
      return "Autorización";
    case TiposDeRequisitosLegales.Constitucion:
      return "Constitución";
    case TiposDeRequisitosLegales.Contrato:
      return "Contrato";
    case TiposDeRequisitosLegales.Convencion:
      return "Convención";
    case TiposDeRequisitosLegales.Decreto:
      return "Decreto";
    case TiposDeRequisitosLegales.Informe:
      return "Informe";
    case TiposDeRequisitosLegales.Ley:
      return "Ley";
    case TiposDeRequisitosLegales.Oficio:
      return "Oficio";
    case TiposDeRequisitosLegales.Permiso:
      return "Permiso";
    case TiposDeRequisitosLegales.Protocolo:
      return "Protocolo";
    case TiposDeRequisitosLegales.Requerimiento:
      return "Requerimiento";
    case TiposDeRequisitosLegales.Resolucion:
      return "Resolución";
    case TiposDeRequisitosLegales.Otro:
      return "Otro";
  }
}

export function textoClasificacion(clasificacion: CategoriasDeRequisitosLegales) {
  switch (clasificacion) {
    case CategoriasDeRequisitosLegales.SST:
      return "SST";
    case CategoriasDeRequisitosLegales.Calidad:
      return "Calidad";
    case CategoriasDeRequisitosLegales.Ambiente:
      return "Ambiente";
    case CategoriasDeRequisitosLegales.Otro:
      return "Otro";
  }
}

export function textoEstadosDeRequisitoLegal(estado: EstadosDeRequisitosLegales) {
  switch (estado) {
    case EstadosDeRequisitosLegales.AnalisisVencido:
      return "Análisis vencido";
    case EstadosDeRequisitosLegales.AnalisisPendiente:
      return "Análisis pendiente";
  }
}

export interface IndicadorDeRequisitosLegales {
  autoridad: string;
  cantidad: number;
}
