import { datetimeToISOString } from "../utilities/utils";
import { Cargo, CargoResponse } from "./Cargo";
import { Empresa } from "./Empresa";
import { Permiso } from "./Permiso";
import { Roles, Usuario, UsuarioResponse } from "./Usuario";

export interface VinculacionBase {
  empresa: string | Empresa;
  rol: Roles;
  cargo?: string[] | Cargo[] | CargoResponse[];
  permisos: Permiso[];
}

export interface Vinculacion extends VinculacionBase {
  _id: string;
  persona: Usuario;
  cargo?: string[] | Cargo[];
  fechaDeVinculacion: Date;
}

export interface VinculacionResponse extends VinculacionBase {
  _id: string;
  persona: UsuarioResponse;
  cargo?: string[];
  fechaDeVinculacion: string;
}

export class Vinculacion implements Vinculacion {
  constructor({
    _id,
    persona,
    empresa,
    cargo,
    rol,
    permisos,
    fechaDeVinculacion,
  }: VinculacionResponse) {
    this._id = _id;
    this.persona = new Usuario(persona);
    this.empresa = empresa;
    
    // Process and ensure `this.cargo` matches the expected type
    if (cargo) {
      const processedCargo = cargo.map((item: string | CargoResponse) => {
        if (typeof item === "string") {
          return item; // String item remains a string
        }
        return new Cargo(item); // Convert CargoResponse to Cargo
      });
    
      // Type guard to ensure homogeneity
      if (processedCargo.every((item) => typeof item === "string")) {
        this.cargo = processedCargo as string[]; // All items are strings
      } else if (processedCargo.every((item) => item instanceof Cargo)) {
        this.cargo = processedCargo as Cargo[]; // All items are Cargo instances
      } else {
        throw new Error(
          "Invalid cargo array: must contain either all strings or all Cargo objects."
        );
      }
    } else {
      this.cargo = []; // Default to an empty array
    }
    
    this.rol = rol;
    this.permisos = permisos;
    this.fechaDeVinculacion = new Date(fechaDeVinculacion);
  }

  get nombreCompleto() {
    if (!!this.cargo && typeof this.cargo !== "string") {
      const cargoList = this.cargo.map((item) => (typeof item === "string" ? item : item.cargo)).join(",");
      return `${this.persona.nombre} ${this.persona.apellidos} (${cargoList})`;
    } else {
      return `${this.persona.nombre} ${this.persona.apellidos}`;
    }
  }

  get asResponse(): VinculacionResponse {
    const cargo = this.cargo
    ? this.cargo.map((item) => {
        if (typeof item === "string") {
          return item;
        } else if (item && typeof item.asResponse === "string") {
          return item.asResponse;
        } else {
          throw new Error("Invalid item in cargo array");
        }
      })
    : undefined;
    return {
      _id: this._id,
      persona: this.persona.asResponse,
      empresa: this.empresa,
      cargo,
      rol: this.rol,
      permisos: this.permisos,
      fechaDeVinculacion: datetimeToISOString(this.fechaDeVinculacion),
    };
  }

  toString(): string {
    return this._id;
  }
}
