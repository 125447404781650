import { useState, FC, useContext, FormEvent, useEffect, useCallback } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  FaCheckCircle,
  FaComment,
  FaEdit,
  FaExternalLinkAlt,
  FaHistory,
  FaTrash,
  FaExclamationTriangle,
} from "react-icons/fa";
import { FaFileCircleCheck, FaFileCircleXmark, FaLink, FaLinkSlash } from "react-icons/fa6";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  TableMessage,
  calcularVigencia,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../../utilities/utils";
import { VistasDeContrato } from "./ConfiguracionDeContrato";
import { Contrato } from "../../../../models/Contrato";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  aprobarDocumentoDelContrato,
  createDocumentoDelContrato,
  createDocumentosDelContrato,
  desvincularDocumentoDelContrato,
  eliminarDocumentoDelContrato,
  listDocumentosDelContratoDePersonal,
  rechazarDocumentoDelContrato,
} from "../../../../services/usuario-api-client";
import { DocumentoDelContrato, Evaluacion } from "../../../../models/DocumentoDelContrato";
import {
  Documento,
  FrecuenciaDeActualizacion,
  TiposDeDocumento,
  textoDeLaFrecuenciaDeActualizacion,
  textoDePeriodoDeActualizacion,
} from "../../../../models/Documento";
import HistoricoDeDocumentos from "../Interna/HistoricoDeDocumentos";
import DocumentoDelContratoForm from "./DocumentoDelContratoForm";
import ImportarDocumentos from "./ImportarDocumentos";
import VincularDocumento from "./VincularDocumento";
import DetallesDelDocumento from "./DetallesDelDocumento";
import DetallesDelDocumentoPreview from "./DetallesDelDocumentoPreview";
import { EvaluacionBadge } from "../../../../utilities/EvaluacionBadge";
import ComentariosDelDocumento from "./ComentariosDelDocumento";
import { Vinculacion } from "../../../../models/Vinculacion";
import { Cargo } from "../../../../models/Cargo";
import { useRevertableState } from "../../../../utilities/useRevertableState";

const MODIFICAR_DOCUMENTO_DEL_CONTRATO_FORM_ID = "modificarDocumentoDelContratoForm";
const IMPORTAR_DOCUMENTOS_FORM_ID = "importarDocumentosForm";
const VINCULAR_DOCUMENTO_FORM_ID = "vincularDocumentoForm";

interface DocumentosAgrupadosPorPersona {
  personal: Vinculacion;
  documentos: DocumentoDelContrato[];
}

interface TablaDeDocumentosDePersonalProps {
  contrato: Contrato;
  isOpen: boolean;
  vista: VistasDeContrato;
}

const TablaDeDocumentosDePersonal: FC<TablaDeDocumentosDePersonalProps> = ({
  contrato,
  isOpen,
  vista,
}) => {
  const { authToken } = useContext(Context);
  const [nombreDelDocumento, setNombreDelDocumento] = useState<string>("");
  const [documentosDelPersonal, setDocumentosDelPersonal] = useState<
    DocumentosAgrupadosPorPersona[]
  >([]);
  const [documentosDelContratoSinVinculacion, setDocumentosDelContratoSinVinculacion] = useState<
    DocumentoDelContrato[]
  >([]);
  const [documentosDelContrato, setDocumentosDelContrato] = useState<DocumentoDelContrato[]>([]);
  const [importarDocumentosModalIsOpen, setImportarDocumentosModalIsOpen] =
    useState<boolean>(false);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState<DocumentoDelContrato | null>(
    null
  );
  const [comentariosModalIsOpen, setComentariosModalIsOpen] = useState<boolean>(false);
  const [historicoModalIsOpen, setHistoricoModalIsOpen] = useState<boolean>(false);
  const [evaluarDocumentoModalIsOpen, setEvaluarDocumentoModalIsOpen] = useState<boolean>(false);
  const [evaluarPreviewDocumentoModalIsOpen, setEvaluarPreviewDocumentoModalIsOpen] = useState<boolean>(false);
  const [modificarModalIsOpen, setModificarModalIsOpen] = useState<boolean>(false);
  const [eliminarModalIsOpen, setEliminarModalIsOpen] = useState<boolean>(false);
  const [vincularDocumentoModalIsOpen, setVincularDocumentoModalIsOpen] = useState<boolean>(false);
  const [desvincularDocumentoModalIsOpen, setDesvincularDocumentoModalIsOpen] =
    useState<boolean>(false);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [aprobarSuccessModalIsOpen, setAprobarSuccessModalIsOpen] = useRevertableState<boolean>(
    false,
    3000
  );
  const [rechazarSuccessModalIsOpen, setRechazarSuccessModalIsOpen] = useRevertableState<boolean>(
    false,
    3000
  );
  
  const [pendingFilesSuccessModalIsOpen, setPendingFilesSuccessModalIsOpen] = useState<boolean>(false);

  const handleCrearDocumentoSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    createDocumentoDelContrato(authToken, contrato._id, {
      nombre: nombreDelDocumento,
      tipoDeDocumentoPreliminar: TiposDeDocumento.DocumentacionDeUsuariosDirectos,
    })
      .then(() => {
        setNombreDelDocumento("");
        cargarDocumentosDelContrato();
      })
      .catch(() => setIsLoading(false));
  };

  const importarDocumentos = (documentosParaImportar: { nombre: string }[]) => {
    setImportarDocumentosModalIsOpen(false);
    const body = documentosParaImportar.map(({ nombre }) => ({ nombre }));
    setIsLoading(true);
    createDocumentosDelContrato(authToken, contrato._id, body)
      .then(cargarDocumentosDelContrato)
      .finally(() => setIsLoading(false));
  };

  const handleComentariosClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setComentariosModalIsOpen(true);
  };

  const ultimoComentario = (documentoDelContrato: DocumentoDelContrato) => {
    const { comentarios } = documentoDelContrato;
    if (!!comentarios && comentarios.length > 0) {
      const { updatedAt, usuario, comentario } = comentarios[comentarios.length - 1];
      return (
        <>
          <b>
            {shortDateToString(updatedAt)} {usuario.nombreCompleto}:
          </b>
          <br />
          {comentario}
        </>
      );
    }
  };

  const handleHistoricoClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setHistoricoModalIsOpen(true);
  };
  
  const handleEvaluarPreviewClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setEvaluarPreviewDocumentoModalIsOpen(true);
  };

  const handleEvaluarClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setEvaluarDocumentoModalIsOpen(true);
  };

  const handleAprobarDocumentoDelContratoClick = () => {
    if (!documentoSeleccionado) return;
    
    const pending = (documentoSeleccionado.archivos || []).filter((archivo) => 
      !(documentoSeleccionado.archivosAprobados || []).includes(archivo._id) &&
      !(documentoSeleccionado.archivosRechazados || []).includes(archivo._id)
    );
    
    if(pending.length > 0){
    setPendingFilesSuccessModalIsOpen(true);
    }else{
      
    setIsLoading(true);
    aprobarDocumentoDelContrato(authToken, documentoSeleccionado._id)
      .then(() => {
        setEvaluarDocumentoModalIsOpen(false);
        setAprobarSuccessModalIsOpen(true);
        cargarDocumentosDelContrato();
      })
      .finally(() => setIsLoading(false));
    }
  };

  const handleRechazarDocumentoDelContratoClick = () => {
    if (!documentoSeleccionado) return;
    
    const pending = (documentoSeleccionado.archivos || []).filter((archivo) => 
      !(documentoSeleccionado.archivosAprobados || []).includes(archivo._id) &&
      !(documentoSeleccionado.archivosRechazados || []).includes(archivo._id)
    );
    
    if(pending.length > 0){
    setPendingFilesSuccessModalIsOpen(true);
    }else{
      
    setIsLoading(true);
    rechazarDocumentoDelContrato(authToken, documentoSeleccionado._id)
      .then(() => {
        setEvaluarDocumentoModalIsOpen(false);
        setRechazarSuccessModalIsOpen(true);
        cargarDocumentosDelContrato();
      })
      .finally(() => setIsLoading(false));
    }
  };
  
  const cerrarEvaluarPreviewDocumentoModalYActualizar = () => {
    setEvaluarPreviewDocumentoModalIsOpen(false);
   // setAprobarSuccessModalIsOpen(true);
    cargarDocumentosDelContrato();
  };

  const cerrarEvaluarDocumentoModalYActualizar = () => {
    setEvaluarDocumentoModalIsOpen(false);
    setAprobarSuccessModalIsOpen(true);
    cargarDocumentosDelContrato();
  };

  const handleModificarClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setModificarModalIsOpen(true);
  };

  const handleModificarSuccess = () => {
    setModificarModalIsOpen(false);
    cargarDocumentosDelContrato();
  };

  const handleEliminarClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setEliminarModalIsOpen(true);
  };

  const confirmarEliminar = () => {
    if (!documentoSeleccionado) return;
    setMensajeDeError("");
    setIsLoading(true);
    eliminarDocumentoDelContrato(authToken, contrato._id, documentoSeleccionado._id)
      .then(() => {
        setEliminarModalIsOpen(false);
        cargarDocumentosDelContrato();
      })
      .catch(({ response }) => setMensajeDeError(response.data.message))
      .finally(() => setIsLoading(false));
  };

  const handleVincularDocumentoClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setVincularDocumentoModalIsOpen(true);
  };

  const handleVincularDocumentoSuccess = () => {
    setVincularDocumentoModalIsOpen(false);
    cargarDocumentosDelContrato();
  };

  const handleDesvincularDocumentoClick = (documentoDelContratoId: string) => {
    const documentoSeleccionado = documentosDelContrato.find(
      (documentoDelContrato) => documentoDelContrato._id === documentoDelContratoId
    );
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    setDesvincularDocumentoModalIsOpen(true);
  };

  const handleConfirmarDesvincular = () => {
    if (!documentoSeleccionado) return;
    setMensajeDeError("");
    setIsLoading(true);
    desvincularDocumentoDelContrato(authToken, documentoSeleccionado._id)
      .then(() => {
        setDesvincularDocumentoModalIsOpen(false);
        cargarDocumentosDelContrato();
      })
      .catch(({ response }) => setMensajeDeError(response.data.message))
      .finally(() => setIsLoading(false));
  };

  const limpiarSeleccion = () => {
    setDocumentoSeleccionado(null);
    setMensajeDeError("");
  };

  const cargarDocumentosDelContrato = useCallback(() => {
    if (!authToken || !contrato) return;
    setIsLoading(true);
    listDocumentosDelContratoDePersonal(authToken, contrato._id)
      .then(({ data }) => {
        const documentosDelContrato = data.map((documento) => new DocumentoDelContrato(documento));
        const documentosDelContratoSinVinculacion: DocumentoDelContrato[] = [];
        const documentosAgrupadosPorPersona: DocumentosAgrupadosPorPersona[] = [];
        documentosDelContrato.forEach((documentoDelContrato) => {
          if (!documentoDelContrato.documentoVinculado) {
            documentosDelContratoSinVinculacion.push(documentoDelContrato);
            return;
          }
          const { activos } = documentoDelContrato;
          if (activos) {
            activos.forEach((activo) => {
              const personal = activo as Vinculacion;
              const index = documentosAgrupadosPorPersona.findIndex(
                (documentoAgrupadoPorPersona) =>
                  documentoAgrupadoPorPersona.personal._id === personal._id
              );
              if (index === -1) {
                documentosAgrupadosPorPersona.push({
                  personal,
                  documentos: [documentoDelContrato],
                });
              } else {
                documentosAgrupadosPorPersona[index].documentos.push(documentoDelContrato);
              }
            });
          }
        });
        setDocumentosDelPersonal(documentosAgrupadosPorPersona);
        setDocumentosDelContratoSinVinculacion(documentosDelContratoSinVinculacion);
        setDocumentosDelContrato(documentosDelContrato);
      })
      .finally(() => {
        setIsLoading(false);
        setAlreadyLoaded(true);
      });
  }, [authToken, contrato]);

  useEffect(() => {
    if (!isOpen) return;
    cargarDocumentosDelContrato();
  }, [isOpen, cargarDocumentosDelContrato]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {vista === VistasDeContrato.Cliente && (
        <Form onSubmit={handleCrearDocumentoSubmit}>
          <Row className="mt-2">
            <Col xs sm={6} md={4}>
              <Input
                type="text"
                placeholder="Nombre del documento..."
                value={nombreDelDocumento}
                onChange={(e) => setNombreDelDocumento(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button type="submit" color="primary" disabled={!nombreDelDocumento}>
                Crear
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      <Row className="mt-2">
        <Col>
          <TableCaption>Documentos del contrato</TableCaption>
        </Col>
      </Row>
     <Table className="no-wrap mt-3 align-middle" style={{"marginBottom":"140px"}} responsive borderless>
    
          <thead>
            <tr>
              <th>#</th>
              <th>Listado de Personal</th>
              <th>Cargo</th>
              <th>Nombre del documento</th>
              <th>Detalle</th>
              <th>Periodicidad</th>
              <th>Vigencia (si aplica)</th>
              <th>Documento</th>
              <th>Evaluación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {documentosDelContrato.map((documentoDelContrato, index) => {
            
            const archivos = documentoDelContrato.archivos || [];
            const hasArchivos = archivos.length > 0;
            
            return (
              <tr key={documentoDelContrato._id}>
                <td className="text-end">{index + 1}</td>
                <td colSpan={2} className="text-center">
                  —
                </td>
                <td>{documentoDelContrato.nombre}</td>
                {documentoDelContrato.comentarios?.length > 0 ? (
                  <td>{ultimoComentario(documentoDelContrato)}</td>
                ) : (
                  <td className="text-center">—</td>
                )}
                <td>
                  {!!documentoDelContrato.documentoVinculado ? (
                    documentoDelContrato.documentoVinculado.frecuenciaDeActualizacion ===
                      FrecuenciaDeActualizacion.Otra &&
                    !!documentoDelContrato.documentoVinculado.valorDeLaFrecuenciaDeActualizacion &&
                    !!documentoDelContrato.documentoVinculado
                      .unidadDeLaFrecuenciaDeActualizacion ? (
                      textoDePeriodoDeActualizacion(
                        documentoDelContrato.documentoVinculado.valorDeLaFrecuenciaDeActualizacion,
                        documentoDelContrato.documentoVinculado.unidadDeLaFrecuenciaDeActualizacion
                      )
                    ) : (
                      textoDeLaFrecuenciaDeActualizacion(
                        documentoDelContrato.documentoVinculado.frecuenciaDeActualizacion
                      )
                    )
                  ) : (
                    <div className="text-center">—</div>
                  )}
                </td>
                <td>
                  {!!documentoDelContrato.archivos && documentoDelContrato.archivos.length > 0 ? (
                    calcularVigencia(documentoDelContrato.archivos[0]?.fechaDeVencimiento)
                  ) : (
                    <div className="text-center">—</div>
                  )}
                </td>
                <td>
                  {!!documentoDelContrato.archivos && documentoDelContrato.archivos.length > 0 ? (
                    <Row className="justify-content-center gx-0 flex-nowrap">
                      { vista === VistasDeContrato.Contratista ? (<Col xs="auto">
                          <Button
                            type="button"
                            color="link"
                            size="sm"
                            title="Evaluar"
                            disabled={!documentoDelContrato.documentoVinculado}
                            onClick={() => handleEvaluarPreviewClick(documentoDelContrato._id)}
                          >
                            <FaCheckCircle size={15} />
                          </Button>
                        </Col>
                      ) : (<></>) }
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Abrir"
                          size="sm
                          "
                          href={procesarLinkDeDocumento(documentoDelContrato.archivos[0].filepath)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaExternalLinkAlt size={15} />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Histórico"
                          size="sm"
                          onClick={() => handleHistoricoClick(documentoDelContrato._id)}
                        >
                          <FaHistory size={15} />
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <div className="text-center">—</div>
                  )}
                </td>
                <td>
                  <EvaluacionBadge evaluacion={documentoDelContrato.evaluacion} aprobados={documentoDelContrato.archivosAprobados?.length || 0} activos={archivos.length || 0} rechazado={documentoDelContrato.archivosRechazados?.length || 0} />
                </td>
                <td>
                  {vista === VistasDeContrato.Cliente ? (
                    <Row className="gx-0 flex-nowrap justify-content-center">
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="link"
                          size="sm"
                          title="Vincular"
                          onClick={() => handleComentariosClick(documentoDelContrato._id)}
                        >
                          <FaComment size={15} />
                        </Button>
                      </Col>
                      {documentoDelContrato.evaluacion !== Evaluacion.Aprobado && (
                        <>
                          <Col xs="auto">
                            <Button
                              type="button"
                              color="link"
                              size="sm"
                              title="Evaluar"
                              disabled={!documentoDelContrato.documentoVinculado}
                              onClick={() => handleEvaluarClick(documentoDelContrato._id)}
                            >
                              <FaCheckCircle size={15} />
                            </Button>
                          </Col>
                          <Col xs="auto">
                            <Button
                              type="button"
                              color="link"
                              size="sm"
                              title="Modificar"
                              onClick={() => handleModificarClick(documentoDelContrato._id)}
                            >
                              <FaEdit size={15} />
                            </Button>
                          </Col>
                        </>
                      )}
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          title="Eliminar"
                          className="border-0"
                          onClick={() => handleEliminarClick(documentoDelContrato._id)}
                        >
                          <FaTrash size={15} />
                        </Button>
                      </Col>
                    </Row>
                  ) : vista === VistasDeContrato.Contratista ? (
                    <Row className="gx-0 flex-nowrap justify-content-center">
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="link"
                          size="sm"
                          title="Vincular"
                          onClick={() => handleComentariosClick(documentoDelContrato._id)}
                        >
                          <FaComment size={15} />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        {!!documentoDelContrato.documentoVinculado ? (
                          <Button
                            type="button"
                            color="link"
                            size="sm"
                            title="Vincular"
                            onClick={() =>
                              handleDesvincularDocumentoClick(documentoDelContrato._id)
                            }
                          >
                            <FaLinkSlash size={15} />
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            color="link"
                            size="sm"
                            title="Vincular"
                            onClick={() => handleVincularDocumentoClick(documentoDelContrato._id)}
                          >
                            <FaLink size={15} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <div className="text-center">—</div>
                  )}
                </td>
              </tr>
            )})}
            {documentosDelPersonal.map(({ personal, documentos }, parentIndex) =>
              documentos.map((documentoDelContrato, childIndex) => (
                <tr key={personal._id} id={personal._id}>
                  {childIndex === 0 && (
                    <>
                      <td className="text-end" rowSpan={documentos.length}>
                        {parentIndex + 1}
                      </td>
                      <td rowSpan={documentos.length}>
                        {!!personal ? (
                          <>{personal.persona.nombreCompleto}</>
                        ) : (
                          <div className="text-center">—</div>
                        )}
                      </td>
                      <td rowSpan={documentos.length}>
                        {!!personal ? (
                          <>{(personal.cargo)?JSON.stringify(personal.cargo):"No tiene cargos"}</>
                        ) : (
                          <div className="text-center">—</div>
                        )}
                      </td>
                    </>
                  )}
                  <td>{documentoDelContrato.nombre}</td>
                  {documentoDelContrato.comentarios?.length > 0 ? (
                    <td>{ultimoComentario(documentoDelContrato)}</td>
                  ) : (
                    <td className="text-center">—</td>
                  )}
                  <td>
                    {!!documentoDelContrato.documentoVinculado ? (
                      documentoDelContrato.documentoVinculado.frecuenciaDeActualizacion ===
                        FrecuenciaDeActualizacion.Otra &&
                      !!documentoDelContrato.documentoVinculado
                        .valorDeLaFrecuenciaDeActualizacion &&
                      !!documentoDelContrato.documentoVinculado
                        .unidadDeLaFrecuenciaDeActualizacion ? (
                        textoDePeriodoDeActualizacion(
                          documentoDelContrato.documentoVinculado
                            .valorDeLaFrecuenciaDeActualizacion,
                          documentoDelContrato.documentoVinculado
                            .unidadDeLaFrecuenciaDeActualizacion
                        )
                      ) : (
                        textoDeLaFrecuenciaDeActualizacion(
                          documentoDelContrato.documentoVinculado.frecuenciaDeActualizacion
                        )
                      )
                    ) : (
                      <div className="text-center">—</div>
                    )}
                  </td>
                  <td>
                    {!!documentoDelContrato.archivos && documentoDelContrato.archivos.length > 0 ? (
                      calcularVigencia(documentoDelContrato.archivos[0]?.fechaDeVencimiento)
                    ) : (
                      <div className="text-center">—</div>
                    )}
                  </td>
                  <td>
                    {!!documentoDelContrato.archivos && documentoDelContrato.archivos.length > 0 ? (
                      <Row className="justify-content-center gx-0 flex-nowrap">
                        <Col xs="auto">
                          <Button
                            color="link"
                            title="Abrir"
                            size="sm"
                            href={procesarLinkDeDocumento(
                              documentoDelContrato.archivos[0].filepath
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaExternalLinkAlt size={15} />
                          </Button>
                        </Col>
                        <Col xs="auto">
                          <Button
                            color="link"
                            title="Histórico"
                            size="sm"
                            onClick={() => handleHistoricoClick(documentoDelContrato._id)}
                          >
                            <FaHistory size={15} />
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <div className="text-center">—</div>
                    )}
                  </td>
                  <td>
                    <EvaluacionBadge evaluacion={documentoDelContrato.evaluacion} />
                  </td>
                  <td>
                    {vista === VistasDeContrato.Cliente ? (
                      <Row className="gx-0 flex-nowrap justify-content-center">
                        <Col xs="auto">
                          <Button
                            type="button"
                            color="link"
                            size="sm"
                            title="Vincular"
                            onClick={() => handleComentariosClick(documentoDelContrato._id)}
                          >
                            <FaComment size={15} />
                          </Button>
                        </Col>
                        {documentoDelContrato.evaluacion !== Evaluacion.Aprobado && (
                          <>
                            <Col xs="auto">
                              <Button
                                type="button"
                                color="link"
                                size="sm"
                                title="Evaluar"
                                disabled={!documentoDelContrato.documentoVinculado}
                                onClick={() => handleEvaluarClick(documentoDelContrato._id)}
                              >
                                <FaCheckCircle size={15} />
                              </Button>
                            </Col>
                            <Col xs="auto">
                              <Button
                                type="button"
                                color="link"
                                size="sm"
                                title="Modificar"
                                onClick={() => handleModificarClick(documentoDelContrato._id)}
                              >
                                <FaEdit size={15} />
                              </Button>
                            </Col>
                          </>
                        )}
                        <Col xs="auto">
                          <Button
                            type="button"
                            color="danger"
                            outline
                            size="sm"
                            title="Eliminar"
                            className="border-0"
                            onClick={() => handleEliminarClick(documentoDelContrato._id)}
                          >
                            <FaTrash size={15} />
                          </Button>
                        </Col>
                      </Row>
                    ) : vista === VistasDeContrato.Contratista ? (
                      <Row className="gx-0 flex-nowrap justify-content-center">
                        <Col xs="auto">
                          <Button
                            type="button"
                            color="link"
                            size="sm"
                            title="Vincular"
                            onClick={() => handleComentariosClick(documentoDelContrato._id)}
                          >
                            <FaComment size={15} />
                          </Button>
                        </Col>
                        <Col xs="auto">
                          {!!documentoDelContrato.documentoVinculado ? (
                            <Button
                              type="button"
                              color="link"
                              size="sm"
                              title="Vincular"
                              onClick={() =>
                                handleDesvincularDocumentoClick(documentoDelContrato._id)
                              }
                            >
                              <FaLinkSlash size={15} />
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              color="link"
                              size="sm"
                              title="Vincular"
                              onClick={() => handleVincularDocumentoClick(documentoDelContrato._id)}
                            >
                              <FaLink size={15} />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <div className="text-center">—</div>
                    )}
                  </td>
                </tr>
              ))
            )}
            {documentosDelContrato.length === 0 && (
              <tr>
                <td colSpan={10}>
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">
                        No se han registrado documentos de personal para este contrato
                      </Col>
                    </Row>
                    {vista === VistasDeContrato.Cliente && alreadyLoaded && (
                      <Row className="justify-content-center gx-0 mt-3">
                        <Col xs="auto">
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => setImportarDocumentosModalIsOpen(true)}
                          >
                            Importar documentos
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

      <Modal
        isOpen={importarDocumentosModalIsOpen}
        toggle={() => setImportarDocumentosModalIsOpen(false)}
      >
        <ModalHeader toggle={() => setImportarDocumentosModalIsOpen(false)}>
          Importar documentos de otros contratos
        </ModalHeader>
        <ModalBody>
          <ImportarDocumentos formId={IMPORTAR_DOCUMENTOS_FORM_ID} onSubmit={importarDocumentos} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" form={IMPORTAR_DOCUMENTOS_FORM_ID}>
            Importar
          </Button>
          <Button color="secondary" onClick={() => setImportarDocumentosModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={comentariosModalIsOpen}
        toggle={() => setComentariosModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setComentariosModalIsOpen(false)}>
          Comentarios de {documentoSeleccionado?.nombre}
        </ModalHeader>
        <ModalBody>
          <ComentariosDelDocumento
            documentoDelContrato={documentoSeleccionado as DocumentoDelContrato}
            onComentarioSubmitSuccess={cargarDocumentosDelContrato}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setComentariosModalIsOpen(false)}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      {documentoSeleccionado && documentoSeleccionado.documentoVinculado && (
        <Modal
          size="lg"
          isOpen={historicoModalIsOpen}
          toggle={() => setHistoricoModalIsOpen(false)}
          onClosed={limpiarSeleccion}
        >
          <ModalHeader toggle={() => setHistoricoModalIsOpen(false)}>
            {documentoSeleccionado?.nombre}
          </ModalHeader>
          <ModalBody>
            <HistoricoDeDocumentos
              documento={documentoSeleccionado?.documentoVinculado as Documento}
              contrato={contrato}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setHistoricoModalIsOpen(false)}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Modal
        size="lg"
        isOpen={evaluarDocumentoModalIsOpen}
        toggle={() => setEvaluarDocumentoModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setEvaluarDocumentoModalIsOpen(false)}>
          Evaluar documento
        </ModalHeader>
        <ModalBody>
          <DetallesDelDocumento
            documentoDelContrato={documentoSeleccionado as DocumentoDelContrato}
            cerrar={cerrarEvaluarDocumentoModalYActualizar}
          />
        </ModalBody>
        <ModalFooter>
          {documentoSeleccionado?.evaluacion !== Evaluacion.Aprobado && (
            <Button color="success" type="button" onClick={handleAprobarDocumentoDelContratoClick}>
              Aprobar
            </Button>
          )}
          {documentoSeleccionado?.evaluacion !== Evaluacion.Rechazado && (
            <Button color="danger" type="button" onClick={handleRechazarDocumentoDelContratoClick}>
              Rechazar
            </Button>
          )}
          <Button color="secondary" onClick={() => setEvaluarDocumentoModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      
      
      <Modal
        size="lg"
        isOpen={evaluarPreviewDocumentoModalIsOpen}
        toggle={() => setEvaluarPreviewDocumentoModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setEvaluarPreviewDocumentoModalIsOpen(false)}>
          Evaluar documento
        </ModalHeader>
        <ModalBody>
          <DetallesDelDocumentoPreview
            documentoDelContrato={documentoSeleccionado as DocumentoDelContrato}
            cerrar={cerrarEvaluarPreviewDocumentoModalYActualizar}
          />
        </ModalBody>
        <ModalFooter>
          
          <Button color="secondary" onClick={() => setEvaluarPreviewDocumentoModalIsOpen(false)}>
            Cerrar
          </Button>
          
        </ModalFooter>
      </Modal>
      
      
      <Modal
        isOpen={modificarModalIsOpen}
        toggle={() => setModificarModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setModificarModalIsOpen(false)}>Modificar documento</ModalHeader>
        <ModalBody>
          <DocumentoDelContratoForm
            formId={MODIFICAR_DOCUMENTO_DEL_CONTRATO_FORM_ID}
            documentoDelContrato={documentoSeleccionado as DocumentoDelContrato}
            onSuccess={handleModificarSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" form={MODIFICAR_DOCUMENTO_DEL_CONTRATO_FORM_ID}>
            Modificar
          </Button>
          <Button color="secondary" onClick={() => setModificarModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={eliminarModalIsOpen}
        toggle={() => setEliminarModalIsOpen(!eliminarModalIsOpen)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setEliminarModalIsOpen(!eliminarModalIsOpen)}>
          Eliminar documento
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea eliminar el documento{" "}
            <strong>{documentoSeleccionado?.nombre}</strong>?
          </p>
          <Collapse isOpen={!!mensajeDeError}>
            <p className="text-danger">{mensajeDeError}</p>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmarEliminar}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={() => setEliminarModalIsOpen(!eliminarModalIsOpen)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={vincularDocumentoModalIsOpen}
        toggle={() => setVincularDocumentoModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setVincularDocumentoModalIsOpen(false)}>
          Vincular documento a {documentoSeleccionado?.nombre}
        </ModalHeader>
        <ModalBody>
          <VincularDocumento
            formId={VINCULAR_DOCUMENTO_FORM_ID}
            documentoDelContrato={documentoSeleccionado as DocumentoDelContrato}
            onSuccess={handleVincularDocumentoSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" form={VINCULAR_DOCUMENTO_FORM_ID}>
            Vincular
          </Button>
          <Button color="secondary" onClick={() => setVincularDocumentoModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={desvincularDocumentoModalIsOpen}
        toggle={() => setDesvincularDocumentoModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setDesvincularDocumentoModalIsOpen(false)}>
          Desvincular documento
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea desvincular el documento{" "}
            <strong>{documentoSeleccionado?.documentoVinculado?.nombreDelDocumento}</strong> de{" "}
            <strong>{documentoSeleccionado?.nombre}</strong>?
          </p>
          <Collapse isOpen={!!mensajeDeError}>
            <p className="text-danger">{mensajeDeError}</p>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmarDesvincular}>
            Desvincular
          </Button>
          <Button color="secondary" onClick={() => setDesvincularDocumentoModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={aprobarSuccessModalIsOpen} size="sm">
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaFileCircleCheck size={40} color="#198754" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="text-center">
              <h3>El documento fue aprobado</h3>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={rechazarSuccessModalIsOpen} size="sm">
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaFileCircleXmark size={40} color="#dc3545" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="text-center">
              <h3>El documento fue rechazado</h3>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      
      
      <Modal isOpen={pendingFilesSuccessModalIsOpen}>
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaExclamationTriangle size={40} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="text-center">
              <h3>Existe archivos por algún tipo de acción en este documento.</h3>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
         
          <Button color="secondary" onClick={() => setPendingFilesSuccessModalIsOpen(false)}>
            Entendido
          </Button>
        </ModalFooter>
      </Modal>
      
      
    </>
  );
};

export default TablaDeDocumentosDePersonal;
