import { useState, FC, useEffect, useContext, useCallback } from "react";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  TableMessage,
  shortDateToString,
} from "../../../../utilities/utils";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Button, Col, DropdownItem,DropdownMenu,DropdownToggle,Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledDropdown, } from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  Contrato,
  ContratoBody,
  TiposDeContrato,
  textoDeEstadoDeContrato,
  textoDeTipoDeContrato,
} from "../../../../models/Contrato";
import {
  eliminarContrato,
  listContratos,
  modificarContrato,
} from "../../../../services/usuario-api-client";
import { Context } from "../../../Context";
import { FaCog, FaEdit, FaTrash } from "react-icons/fa";
import { FaGear, FaEllipsisVertical } from "react-icons/fa6";
import ContratoForm from "./ContratoForm";
import { PATHS, procesarPath } from "../../../../utilities/Paths";

const MODIFICAR_CONTRATO_FORM_ID = "modificarContratoForm";

interface ListadoDeContratosProps {
  isOpen: boolean;
  onNuevoContratoClick: () => void;
}

const ListadoDeContratos: FC<ListadoDeContratosProps> = ({ isOpen, onNuevoContratoClick }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [contratos, setContratos] = useState<Contrato[]>([]);
  const [contratoSeleccionado, setContratoSeleccionado] = useState<Contrato | null>(null);
  const [modificarModalIsOpen, setModificarModalIsOpen] = useState<boolean>(false);
  const [eliminarModalIsOpen, setEliminarModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const limpiarSeleccion = () => {
    setContratoSeleccionado(null);
  };

  const handleModificarClick = (idDelContrato: string) => {
    setContratoSeleccionado(contratos.find(({ _id }) => _id === idDelContrato) || null);
    setModificarModalIsOpen(true);
  };

  const handleModificarSubmit = (contrato: ContratoBody) => {
    if (!contratoSeleccionado) return;
    setIsLoading(true);
    const body = {
      titulo: contrato.tituloDelContrato,
      numeroDeDocumento: contrato.numeroDeDocumento,
      idDeLaEmpresaContratista: contrato.idDeLaEmpresaContratista,
      idDelContactoContratista: contrato.idDelContacto,
      estado: contratoSeleccionado.estado,
    };
    modificarContrato(authToken, idDeLaEmpresaSeleccionada, contratoSeleccionado._id, body)
      .then(({ data }) => {
        fetchContratos();
        setModificarModalIsOpen(false);
      })
      .finally(() => setIsLoading(false));
  };

  const handleEliminarClick = (idDelContrato: string) => {
    setContratoSeleccionado(contratos.find(({ _id }) => _id === idDelContrato) || null);
    setEliminarModalIsOpen(true);
  };

  const confirmarEliminar = () => {
    if (!contratoSeleccionado) return;
    setIsLoading(true);
    eliminarContrato(authToken, contratoSeleccionado._id)
      .then(() => {
        setContratos(contratos.filter(({ _id }) => _id !== contratoSeleccionado._id));
        setEliminarModalIsOpen(false);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchContratos = useCallback(async () => {
    return listContratos(authToken, idDeLaEmpresaSeleccionada).then(({ data }) => {
      console.log(data);
      setContratos(data.map((contrato) => new Contrato(contrato)));
    });
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    if (isOpen && !!authToken) {
      setIsLoading(true);
      fetchContratos().finally(() => setIsLoading(false));
    } else {
      setContratos([]);
    }
  }, [isOpen, authToken, fetchContratos]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      

        <Table className="no-wrap mt-3 align-middle" style={{"marginBottom":"140px"}} responsive borderless >
          <thead>
            <tr>
              <th rowSpan={2}>Acciones</th>
              <th rowSpan={2}>Tipo</th>
              <th rowSpan={2}>Número de Documento</th>
              <th colSpan={2}>Contratista</th>
              <th rowSpan={2}>Título del Contrato</th>
              <th colSpan={2}>Cliente</th>
              <th rowSpan={2}>Estado</th>
              <th rowSpan={2}>Fecha de creación del contrato</th>
              <th rowSpan={2}>Fecha de la última actualización</th>
              
            </tr>
            <tr>
              <th style={{"borderLeft":"1px solid #0000001a"}}>Nombre</th>
              <th>Usuario</th>
              <th>Nombre</th>
              <th>Usuario</th>
            </tr>
          </thead>
          <tbody>
            {contratos.map(
              (
                {
                  _id,
                  tipo,
                  numeroDeDocumento: numeroDocumento,
                  empresaContratista,
                  contactoContratista,
                  titulo,
                  empresaContratante,
                  contactoContratante,
                  estado,
                  createdAt: fechaDeCreacion,
                  updatedAt: fechaDeActualizacion,
                },
                index
              ) => (
                <tr key={_id}>
                 <td>
                   
                   <UncontrolledDropdown group>
                   
                      <DropdownToggle caret color="primary" outline size="sm">
                        <FaEllipsisVertical />
                      </DropdownToggle>
                      <DropdownMenu>
                      
                      <LinkContainer to={procesarPath(PATHS.configuracionDeContrato, _id)}>
                       <DropdownItem  className="py-2">
                       <FaGear size={15} /> Configurar
                       </DropdownItem>
                      </LinkContainer>
                       
                        {tipo === TiposDeContrato.Cliente && (
                         <DropdownItem className="py-2"  onClick={() => handleModificarClick(_id)}>
                             <FaEdit size={15} /> Modificar
                         </DropdownItem>
                         ) }

                        {tipo === TiposDeContrato.Cliente && (
                          
                          <DropdownItem  className="py-2 text-danger"  onClick={() => handleEliminarClick(_id)}>
                          <FaTrash size={15} /> Archivar
                          </DropdownItem>
                         
                         )}
                          
                        
                      </DropdownMenu>
                    </UncontrolledDropdown>

                 </td>
                  <td>{textoDeTipoDeContrato(tipo)}</td>
                  <td>{numeroDocumento}</td>
                  <td>{empresaContratista.nombreDeLaEmpresa}</td>
                  <td>{contactoContratista ? contactoContratista.nombreCompleto : "N/A"}</td>
                  <td>{titulo}</td>
                  <td>{empresaContratante.nombreDeLaEmpresa}</td>
                  <td>{contactoContratante.nombreCompleto}</td>
                  <td>{textoDeEstadoDeContrato(estado)}</td>
                  <td>{shortDateToString(fechaDeCreacion)}</td>
                  <td>{shortDateToString(fechaDeActualizacion)}</td>
                  
                </tr>
              )
            )}
            {contratos.length === 0 && (
              <tr>
                <td colSpan={12} className="text-center">
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">No hay contratos para mostrar</Col>
                    </Row>
                    <Row className="justify-content-center gx-0 mt-3">
                      <Col xs="auto">
                        <Button type="button" color="primary" onClick={onNuevoContratoClick}>
                          Nuevo contrato
                        </Button>
                      </Col>
                    </Row>
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      {contratoSeleccionado?.tipo === TiposDeContrato.Cliente && (
        <Modal
          isOpen={modificarModalIsOpen}
          toggle={() => setModificarModalIsOpen(!modificarModalIsOpen)}
          onClosed={limpiarSeleccion}
        >
          <ModalHeader toggle={() => setModificarModalIsOpen(!modificarModalIsOpen)}>
            Modificar contrato
          </ModalHeader>
          <ModalBody>
            <ContratoForm
              formId={MODIFICAR_CONTRATO_FORM_ID}
              contrato={contratoSeleccionado as Contrato}
              onSubmit={handleModificarSubmit}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary" form={MODIFICAR_CONTRATO_FORM_ID}>
              Modificar
            </Button>
            <Button
              color="secondary"
              onClick={() => setModificarModalIsOpen(!modificarModalIsOpen)}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Modal
        isOpen={eliminarModalIsOpen}
        toggle={() => setEliminarModalIsOpen(!eliminarModalIsOpen)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setEliminarModalIsOpen(!eliminarModalIsOpen)}>
          Eliminar contrato
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea eliminar el contrato <b>{contratoSeleccionado?.titulo}</b>?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmarEliminar}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={() => setEliminarModalIsOpen(!eliminarModalIsOpen)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ListadoDeContratos;
