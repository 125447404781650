import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import {
  CategoriasDeRequisitosLegales,
  RequisitoLegal,
  RequisitoLegalEditBody,
  TiposDeRequisitosLegales,
  textoClasificacion,
  textoTipoDeRequisitoLegal,
} from "../../../models/RequisitoLegal";
import { dateInputToDate, dateToISOString, obtenerURLCompleta } from "../../../utilities/utils";
import { Vinculacion } from "../../../models/Vinculacion";
import { Autoridad } from "../../../models/Autoridad";
import { Context } from "../../Context";
import { FaArrowAltCircleRight, FaUserTag, FaDownload, FaLayerGroup, FaPlus, FaPen } from "react-icons/fa";
import {
  editarRequisitoLegal,
  fetchAdministradoresDeLaEmpresa,
  listarAutoridades,
} from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";

interface Props {
  requisitoLegal: RequisitoLegal;
  formId: string;
  onSuccess: () => void;
}

const EditarRequisitoLegalForm: FC<Props> = ({ requisitoLegal, formId, onSuccess }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [nombre, setNombre] = useState<string>(requisitoLegal.nombre);
  const [tipo, setTipo] = useState<TiposDeRequisitosLegales>(requisitoLegal.tipo);
  const [autoridad, setAutoridad] = useState<string>(requisitoLegal.autoridad._id);
  const [numeroDeArticulos, setNumeroDeArticulos] = useState<string>(
    requisitoLegal.numeroDeArticulos.toString()
  );
  const [clasificacion, setClasificacion] = useState<CategoriasDeRequisitosLegales>(
    requisitoLegal.clasificacion
  );
  const [fechaLimiteDeAnalisisString, setFechaLimiteDeAnalisisString] = useState(
    dateToISOString(requisitoLegal.fechaLimiteDeAnalisis)
  );
  const [responsableDelAnalisis, setResponsableDelAnalisis] = useState<string>(
    typeof requisitoLegal.responsableDelAnalisis === "string"
      ? requisitoLegal.responsableDelAnalisis
      : requisitoLegal.responsableDelAnalisis._id
  );
  const [autoridades, setAutoridades] = useState<Autoridad[]>([]);
  const [administradores, setAdministradores] = useState<Vinculacion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditarRequisitoLegalSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!authToken) return;
    const fechaLimiteDeAnalisis = dateInputToDate(fechaLimiteDeAnalisisString);
    const nuevoRequisitoLegal: RequisitoLegalEditBody = {
      nombre,
      tipo,
      autoridad,
      numeroDeArticulos: parseInt(numeroDeArticulos),
      clasificacion,
      fechaLimiteDeAnalisis,
      responsableDelAnalisis,
    };
    setIsLoading(true);
    editarRequisitoLegal(authToken, requisitoLegal._id, nuevoRequisitoLegal)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    Promise.all([
      fetchAdministradoresDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada),
      listarAutoridades(authToken, idDeLaEmpresaSeleccionada),
    ])
      .then(([administradoresResponse, autoridadesResponse]) => {
        setAdministradores(administradoresResponse.data.map((admin) => new Vinculacion(admin)));
        setAutoridades(autoridadesResponse.data);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada, requisitoLegal]);

  const esUnaVinculacion = !!requisitoLegal.documentoLegal;

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleEditarRequisitoLegalSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            id="nombre"
            name="nombre"
            placeholder="Nombre del requisito legal"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            disabled={esUnaVinculacion}
            required
          />
          <Label for="nombre">Nombre del requisito legal</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="text"
            id="url"
            name="url"
            placeholder="Hipervínculo"
            value={obtenerURLCompleta(requisitoLegal.filepath)}
            disabled
            required
          />
          <Label for="url">Hipervínculo</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="tipo"
            name="tipo"
            placeholder="Tipo de requisito legal"
            value={tipo}
            onChange={(e) => setTipo(e.target.value as TiposDeRequisitosLegales)}
            disabled={esUnaVinculacion}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {Object.values(TiposDeRequisitosLegales).map((tipo) => (
              <option key={tipo} value={tipo}>
                {textoTipoDeRequisitoLegal(tipo)}
              </option>
            ))}
          </Input>
          <Label for="tipo">Tipo de requisito legal</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="autoridad"
            name="autoridad"
            placeholder="Autoridad"
            value={autoridad}
            onChange={(e) => setAutoridad(e.target.value)}
            disabled={esUnaVinculacion}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {autoridades.map(({ _id, nombre }) => (
              <option key={_id} value={_id}>
                {nombre}
              </option>
            ))}
          </Input>
          <Label for="autoridad">Autoridad</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="clasificacion"
            name="clasificacion"
            placeholder="Clasificación"
            value={clasificacion}
            onChange={(e) => setClasificacion(e.target.value as CategoriasDeRequisitosLegales)}
            disabled={esUnaVinculacion}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {Object.values(CategoriasDeRequisitosLegales).map((clasificacion) => (
              <option key={clasificacion} value={clasificacion}>
                {textoClasificacion(clasificacion)}
              </option>
            ))}
          </Input>
          <Label for="clasificacion">Clasificación</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="number"
            id="numeroDeArticulos"
            name="numeroDeArticulos"
            placeholder="Número de artículos"
            value={numeroDeArticulos}
            onChange={(e) => setNumeroDeArticulos(e.target.value)}
            disabled={esUnaVinculacion}
            required
          />
          <Label for="numeroDeArticulos">Número de artículos</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="date"
            id="fechaLimiteDeAnalisis"
            placeholder="Fecha límite de análisis"
            value={fechaLimiteDeAnalisisString}
            onChange={(e) => setFechaLimiteDeAnalisisString(e.target.value)}
            required
          />
          <Label for="fechaLimiteDeAnalisis">Fecha límite de análisis</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            value={responsableDelAnalisis}
            onChange={(e) => setResponsableDelAnalisis(e.target.value)}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {administradores.map(({ _id, nombreCompleto }) => (
              <option key={_id} value={_id}>
                {nombreCompleto}
              </option>
            ))}
          </Input>
          <Label for="responsableDelAnalisis">Responsable del análisis</Label>
        </FormGroup>
      </Form>
    </>
  );
};

export default EditarRequisitoLegalForm;
