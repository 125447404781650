import { useState, FC, useCallback, useEffect, useContext } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { Usuario } from "../../../models/Usuario";
import { Empresa, TiposDeEmpresa } from "../../../models/Empresa";
import { Vinculacion } from "../../../models/Vinculacion";
import { identificacionMap } from "../../../resources/tipos-de-identificacion";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { UsuarioLMS } from "../../../models/UsuarioLMS";
import { createUsuario } from "../../../services/lms-api-client";
import { Context } from "../../Context";
import { ImageButton, range } from "../../../utilities/utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Cargo } from "../../../models/Cargo";

import ComponentCard from '../../components/ComponentCard';


import {
  eliminarUsuario,
  enviarCorreoConfirmacion,
  fetchUsuariosLMS,
  obtenerCargos,
  obtenerEmpresas,
  obtenerUsuarios,
  obtenerVinculaciones,
  repararVinculaciones,
  restablecerContrasena,
} from "../../../services/admin-api-client";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import TiempoDePrueba from "./TiempoDePrueba";

const REGISTRAR_USUARIO_LMS_FORM_ID = "registrarUsuarioLmsForm";

const Registros: FC = () => {
  const { adminAuthToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [usuariosLMS, setUsuariosLMS] = useState<UsuarioLMS[]>([]);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [vinculaciones, setVinculaciones] = useState<Vinculacion[]>([]);
  const [cargos, setCargos] = useState<Cargo[]>([]);
  const [usuariosFiltrados, setUsuariosFiltrados] = useState<Usuario[]>([]);

  const [correoParaRestablecer, setCorreoParaRestablecer] = useState<string>("");
  const [nuevaContraseña, setNuevaContraseña] = useState<string>("");
  const [mostrarContraseña, setMostrarContraseña] = useState<boolean>(false);
  const [modalConfirmacionRestablecerIsOpen, setModalConfirmacionRestablecerIsOpen] =
    useState<boolean>(false);
  const [modalNuevaContraseñaIsOpen, setModalNuevaContraseñaIsOpen] = useState<boolean>(false);
  const [modalCorreoEnviadoIsOpen, setModalCorreoEnviadoIsOpen] = useState<boolean>(false);
  const [usuarioParaRegistrarEnLMS, setUsuarioParaRegistrarEnLMS] = useState<Usuario | null>(null);
  const [contraseñaLMS, setContraseñaLMS] = useState<string>("");
  const [modalContraseñaLMSIsOpen, setModalContraseñaLMSIsOpen] = useState<boolean>(false);
  const [usuarioParaEliminar, setUsuarioParaEliminar] = useState<Usuario | null>(null);
  const [modalConfirmacionEliminarIsOpen, setModalConfirmacionEliminarIsOpen] =
    useState<boolean>(false);
  const [verSoloNuevos, setVerSoloNuevos] = useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const restablecerContraseña = (correo: string) => {
    setCorreoParaRestablecer(correo);
    setModalConfirmacionRestablecerIsOpen(true);
  };

  const toggleModalRestablecer = () =>
    setModalConfirmacionRestablecerIsOpen(!modalConfirmacionRestablecerIsOpen);
  const toggleModalNuevaContraseña = () => {
    setNuevaContraseña("");
    setModalNuevaContraseñaIsOpen(!modalNuevaContraseñaIsOpen);
  };

  const confirmarRestablecer = () => {
    if (!correoParaRestablecer) return;
    setModalConfirmacionRestablecerIsOpen(false);
    setLoadingCounter((prev) => prev + 1);
    restablecerContrasena(adminAuthToken, correoParaRestablecer)
      .then(({ data }) => {
        setNuevaContraseña(data.nuevaContrasena);
        setCorreoParaRestablecer("");
        setModalNuevaContraseñaIsOpen(true);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const handleEnviarCorreoDeConfirmacionClick = (correo: string) => {
    setLoadingCounter((prev) => prev + 1);
    enviarCorreoConfirmacion(adminAuthToken, correo)
      .then(() => setModalCorreoEnviadoIsOpen(true))
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const registrarUsuarioEnLMS = (correo: string) => {
    const usuario = usuarios.find((u) => u.correo === correo);
    if (!usuario) return;
    setUsuarioParaRegistrarEnLMS(usuario);
    setModalContraseñaLMSIsOpen(true);
  };

  const confirmarRegistroEnLMS = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!usuarioParaRegistrarEnLMS) return;
    setLoadingCounter((prev) => prev + 1);
    createUsuario({ ...usuarioParaRegistrarEnLMS, contrasena: contraseñaLMS })
      .then(() =>
        fetchUsuariosLMS(adminAuthToken).then(({ data }) => {
          setUsuariosLMS(data.users);
        })
      )
      .finally(() => {
        setLoadingCounter((prev) => prev - 1);
        setModalContraseñaLMSIsOpen(false);
      });
  };

  const clearRegistroEnLMS = () => {
    setUsuarioParaRegistrarEnLMS(null);
    setContraseñaLMS("");
  };

  const handleEliminarUsuarioClick = (idDelUsuario: string) => {
    const usuario = usuarios.find((u) => u._id === idDelUsuario);
    if (!usuario) return;
    setUsuarioParaEliminar(usuario);
    setModalConfirmacionEliminarIsOpen(true);
  };

  const confirmarEliminar = () => {
    if (!usuarioParaEliminar) return;
    setLoadingCounter((prev) => prev + 1);
    eliminarUsuario(adminAuthToken, usuarioParaEliminar._id)
      .then(() => {
        setUsuarios(usuarios.filter((u) => u._id !== usuarioParaEliminar._id));
        setModalConfirmacionEliminarIsOpen(false);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const clearUsuarioParaEliminar = () => {
    setUsuarioParaEliminar(null);
  };

  const handleRepararVinculacionesClick = (idDeLaEmpresa: string) => {
    setLoadingCounter((prev) => prev + 1);
    repararVinculaciones(adminAuthToken, idDeLaEmpresa)
      .then(cargarDatos)
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const toggleVerSoloNuevos = () => setVerSoloNuevos(!verSoloNuevos);

  const cargarUsuarios = useCallback(() => {
    if (!adminAuthToken) return;
    setLoadingCounter((prev) => prev + 1);
    obtenerUsuarios(adminAuthToken)
      .then(({ data }) => {
        setUsuarios(data.map((u) => new Usuario(u)));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [adminAuthToken]);

  const cargarEmpresas = useCallback(() => {
    if (!adminAuthToken) return;
    setLoadingCounter((prev) => prev + 1);
    obtenerEmpresas(adminAuthToken)
      .then(({ data }) => {
        setEmpresas(data);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [adminAuthToken]);

  const cargarVinculaciones = useCallback(() => {
    if (!adminAuthToken) return;
    setLoadingCounter((prev) => prev + 1);
    obtenerVinculaciones(adminAuthToken)
      .then(({ data }) => {
        setVinculaciones(data.map((v) => new Vinculacion(v)));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [adminAuthToken]);

  const cargarCargos = useCallback(() => {
    if (!adminAuthToken) return;
    setLoadingCounter((prev) => prev + 1);
    obtenerCargos(adminAuthToken)
      .then(({ data }) => {
        setCargos(data.map((c) => new Cargo(c)));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [adminAuthToken]);

  const cargarUsuariosLMS = useCallback(() => {
    if (!adminAuthToken) return;
    setLoadingCounter((prev) => prev + 1);
    fetchUsuariosLMS(adminAuthToken)
      .then(({ data }) => {
        setUsuariosLMS(data.users);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [adminAuthToken]);

  const cargarDatos = useCallback(() => {
    cargarUsuarios();
    cargarEmpresas();
    cargarVinculaciones();
    cargarCargos();
    cargarUsuariosLMS();
  }, [cargarUsuarios, cargarEmpresas, cargarVinculaciones, cargarCargos, cargarUsuariosLMS]);

  useEffect(() => {
    cargarDatos();
  }, [cargarDatos]);

  useEffect(() => {
    const usuariosFiltrados = verSoloNuevos
      ? usuarios.filter((u) => !u.correoConfirmado)
      : usuarios;
    setUsuariosFiltrados(usuariosFiltrados);
  }, [verSoloNuevos, usuarios]);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.adminRegistros.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Breadcrumbs items={[PATHS.adminHome]} />
        <ComponentCard
          title="Registros"
          btnRight ={
          <Button type="button" color="primary" onClick={toggleVerSoloNuevos}>
            {verSoloNuevos ? "Ver todos" : "Ver solo nuevos"}
          </Button>
         }
        >
      <Row>
        <Col>
          <h2>Usuarios</h2>
        </Col>
        
      </Row>
      <UncontrolledAccordion>
        {usuariosFiltrados.map(
          ({
            _id,
            nombre,
            apellidos,
            identificacion,
            tipoDeIdentificacion,
            correo,
            correoConfirmado,
            telefono,
            nacionalidad,
            direccionDeResidencia,
            tipoDeSangre,
            alergias,
            discapacidades,
            enfermedades,
            medicamentos,
            contactosDeEmergencia,
            tiempoDePrueba,
          }) => (
            <AccordionItem key={_id}>
              <AccordionHeader targetId={_id}>
                <Row className="w-100 me-0">
                  <Col>
                    {nombre} {apellidos}
                  </Col>
                  {!correoConfirmado && (
                    <Col xs="auto">
                      <Badge color="secondary" className="ms-auto">
                        Nuevo
                      </Badge>
                    </Col>
                  )}
                </Row>
              </AccordionHeader>
              <AccordionBody accordionId={_id}>
                <ListGroup>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>{identificacionMap.get(tipoDeIdentificacion)}</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{identificacion}</ListGroupItemText>
                    <ListGroupItemText>
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => handleEliminarUsuarioClick(_id)}
                      >
                        Eliminar
                      </Button>
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Correo</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{correo}</ListGroupItemText>
                    <ListGroupItemText>
                      <Row>
                        <Col xs="auto">
                          <Button
                            type="button"
                            color="secondary"
                            onClick={() => restablecerContraseña(correo)}
                          >
                            Restablecer contraseña
                          </Button>
                        </Col>
                        {!correoConfirmado && (
                          <>
                            <div className="w-100 d-md-none mt-3" />
                            <Col xs="auto">
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => handleEnviarCorreoDeConfirmacionClick(correo)}
                              >
                                Enviar correo de confirmación
                              </Button>
                            </Col>
                          </>
                        )}
                      </Row>
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Teléfono</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{telefono}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Nacionalidad</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{nacionalidad}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Tipo de sangre</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{tipoDeSangre}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Dirección</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{direccionDeResidencia}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Alergias</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {alergias.length > 0 ? alergias.join(", ") : "Ninguna"}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Discapacidades</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {discapacidades.length > 0 ? discapacidades.join(", ") : "Ninguna"}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Enfermedades</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {enfermedades.length > 0 ? enfermedades.join(", ") : "Ninguna"}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Medicamentos</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {medicamentos.length > 0 ? medicamentos.join(", ") : "Ninguno"}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Contactos de emergencia</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {contactosDeEmergencia
                        .map(({ nombre, telefono }) => `${nombre}: ${telefono}`)
                        .join("\n")}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Empresas vinculadas</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {vinculaciones
                        .filter(({ persona }) => persona._id === _id)
                        .map(({ empresa }) =>
                          typeof empresa === "string" ? empresa : empresa.nombreDeLaEmpresa
                        )
                        .join(", ")}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>LMS</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {usuariosLMS.some(({ email }) => email === correo) ? (
                        "Sí"
                      ) : (
                        <Button
                          type="button"
                          color="secondary"
                          onClick={() => registrarUsuarioEnLMS(correo)}
                        >
                          Registrar usuario
                        </Button>
                      )}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Tiempo de prueba</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      <TiempoDePrueba id={_id} tiempoDePrueba={tiempoDePrueba} />
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>
              </AccordionBody>
            </AccordionItem>
          )
        )}
      </UncontrolledAccordion>
      <h2>Empresas</h2>
      <UncontrolledAccordion>
        {empresas.map(
          ({
            _id,
            nombreDeLaEmpresa,
            ruc,
            digitoVerificador,
            direccion,
            tipoDeIndustria,
            tipoDeEmpresa,
            nombreDelPropietario,
            cedulaDelPropietario,
            empresasContratadas,
            empresasContratrantes,
          }) => (
            <AccordionItem key={_id}>
              <AccordionHeader targetId={_id}>{nombreDeLaEmpresa}</AccordionHeader>
              <AccordionBody accordionId={_id}>
                <ListGroup>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>RUC</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {ruc} DV {digitoVerificador}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Dirección</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{direccion}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Tipo de industria</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>{tipoDeIndustria}</ListGroupItemText>
                  </ListGroupItem>
                  {tipoDeEmpresa === TiposDeEmpresa.Natural && (
                    <>
                      <ListGroupItem>
                        <ListGroupItemHeading>
                          <small>Nombre del propietario</small>
                        </ListGroupItemHeading>
                        <ListGroupItemText>{nombreDelPropietario}</ListGroupItemText>
                      </ListGroupItem>
                      <ListGroupItem>
                        <ListGroupItemHeading>
                          <small>Cédula</small>
                        </ListGroupItemHeading>
                        <ListGroupItemText>{cedulaDelPropietario}</ListGroupItemText>
                      </ListGroupItem>
                    </>
                  )}
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Empleados</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {vinculaciones
                        .filter(({ empresa }) =>
                          typeof empresa === "string" ? empresa === _id : empresa._id === _id
                        )
                        .map(({ persona: { nombre, apellidos } }) => `${nombre} ${apellidos}`)
                        .join(", ")}
                    </ListGroupItemText>
                    <ListGroupItemText>
                      {vinculaciones
                        .filter(({ empresa }) =>
                          typeof empresa === "string" ? empresa === _id : empresa._id === _id
                        )
                        .some(({ cargo }) => cargo === null) && (
                        <Button
                          type="button"
                          color="info"
                          onClick={() => handleRepararVinculacionesClick(_id)}
                        >
                          Reparar vinculaciones
                        </Button>
                      )}
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <small>Cargos</small>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      {cargos
                        .filter(({ empresa }) => empresa === _id)
                        .map(({ cargo }) => cargo)
                        .join(", ")}
                    </ListGroupItemText>
                  </ListGroupItem>
                  {!!empresasContratadas && empresasContratadas.length > 0 && (
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        <small>Empresas contratadas</small>
                      </ListGroupItemHeading>
                      <ListGroupItemText>
                        {empresasContratadas
                          .map(({ nombreDeLaEmpresa }) => `${nombreDeLaEmpresa}`)
                          .join(", ")}
                      </ListGroupItemText>
                    </ListGroupItem>
                  )}
                  {!!empresasContratrantes && empresasContratrantes.length > 0 && (
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        <small>Empresas contratantes</small>
                      </ListGroupItemHeading>
                      <ListGroupItemText>
                        {empresasContratrantes
                          .map(({ nombreDeLaEmpresa }) => `${nombreDeLaEmpresa}`)
                          .join(", ")}
                      </ListGroupItemText>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </AccordionBody>
            </AccordionItem>
          )
        )}
      </UncontrolledAccordion>
        </ComponentCard>
      <Modal isOpen={modalConfirmacionRestablecerIsOpen} toggle={toggleModalRestablecer}>
        <ModalHeader toggle={toggleModalRestablecer}>¿Estás seguro?</ModalHeader>
        <ModalBody>
          ¿Estás seguro de querer generar una nueva contraseña para el usuario con el correo{" "}
          <b>{correoParaRestablecer}</b>?
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={confirmarRestablecer}>
            Sí
          </Button>
          <Button type="button" color="secondary" onClick={toggleModalRestablecer}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalNuevaContraseñaIsOpen} toggle={toggleModalNuevaContraseña}>
        <ModalHeader toggle={toggleModalNuevaContraseña}>
          Contraseña restablecida con éxito
        </ModalHeader>
        <ModalBody>
          <p>Se ha generado una nueva contraseña para el usuario con el correo</p>
          <p>
            <b>{correoParaRestablecer}</b>
          </p>
          <p>Se le envió un correo electrónico con la nueva contraseña.</p>
          <p>
            <Row className="align-items-center">
              <Col xs="auto">Nueva contraseña:</Col>
              <Col xs="auto" className="font-monospace">
                {mostrarContraseña ? (
                  <span>{nuevaContraseña}</span>
                ) : (
                  nuevaContraseña &&
                  range(0, nuevaContraseña.length - 1).map((i) => <span key={i}>•</span>)
                )}
              </Col>
              <Col className="ps-0">
                <ImageButton
                  type="button"
                  className="text-muted"
                  tabIndex={-1}
                  onClick={() => setMostrarContraseña(!mostrarContraseña)}
                >
                  {mostrarContraseña ? <FaEye /> : <FaEyeSlash />}
                </ImageButton>
              </Col>
            </Row>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={toggleModalNuevaContraseña}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalContraseñaLMSIsOpen}
        toggle={() => setModalContraseñaLMSIsOpen(false)}
        onClosed={clearRegistroEnLMS}
      >
        <ModalHeader toggle={() => setModalContraseñaLMSIsOpen(false)}>
          Contraseña para el LMS
        </ModalHeader>
        <ModalBody>
          <Form id={REGISTRAR_USUARIO_LMS_FORM_ID} onSubmit={confirmarRegistroEnLMS}>
            <FormGroup floating>
              <Input
                type="password"
                name="contrasena"
                id="contrasena"
                placeholder="Contraseña"
                value={contraseñaLMS}
                onChange={({ target: { value } }) => setContraseñaLMS(value)}
                required
              />
              <Label for="contrasena">Contraseña</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={REGISTRAR_USUARIO_LMS_FORM_ID}>
            Registrar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalConfirmacionEliminarIsOpen}
        toggle={() => setModalConfirmacionEliminarIsOpen(false)}
        onClosed={clearUsuarioParaEliminar}
      >
        <ModalHeader toggle={() => setModalConfirmacionEliminarIsOpen(false)}>
          ¿Estás seguro?
        </ModalHeader>
        <ModalBody>
          ¿Estás seguro de querer eliminar el usuario con el correo{" "}
          <b>{usuarioParaEliminar?.correo}</b>?
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={confirmarEliminar}>
            Sí
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => setModalConfirmacionEliminarIsOpen(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalCorreoEnviadoIsOpen} toggle={() => setModalCorreoEnviadoIsOpen(false)}>
        <ModalHeader toggle={() => setModalCorreoEnviadoIsOpen(false)}>
          Correo enviado con éxito
        </ModalHeader>
        <ModalBody>El correo de confirmación fue enviado con éxito</ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={() => setModalCorreoEnviadoIsOpen(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Registros;
