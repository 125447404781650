import { FC, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import HexButton, { HexMenuContainer } from "../../utilities/HexButton";
import { SeparatorCol } from "../../utilities/utils";
import { Context } from "../Context";
import { PATHS } from "../../utilities/Paths";
import { GISSTLogoFull , GISSTLogo, GISSTLogoHome} from "../../resources/Images";

const AdminHome: FC = () => {
  const { setTituloDeLaPantallaActual } = useContext(Context);

  useEffect(() => setTituloDeLaPantallaActual(PATHS.adminHome.name), [setTituloDeLaPantallaActual]);

  return (
    <Row className="justify-content-center gx-0 h-100">
      <Col xs="9" md="5" style={{ height: "calc(100vh - 190px)", maxWidth: "calc(100vw - 24px)" }}>
        <GISSTLogoHome />
      </Col>
    </Row>
  );
};

export default AdminHome;
