import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { RiListSettingsLine } from "react-icons/ri";
import { Link, useLocation, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import { obtenerRequisitoLegal } from "../../../services/usuario-api-client";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import ComponentCard from '../../components/ComponentCard';

import { CustomTable, TableCaption, TableContainer, TableMessage } from "../../../utilities/utils";
import {
  DerogacionDeRequisitoLegal,
  Modulos,
  Submodulos,
  textoDeModulo,
  textoDeTipoDeVinculacion,
} from "../../../models/DerogacionDeRequisitoLegal";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS, procesarPath } from "../../../utilities/Paths";
import VincularAnalisisModal from "./VincularAnalisisModal";

interface EnlaceProps {
  enlace: string;
  nombre?: string;
  moduloVinculado?: Modulos | Submodulos;
}

const enlaceAEvidencia = ({ enlace, nombre, moduloVinculado }: EnlaceProps) => {
  if (!!moduloVinculado) {
    let path = "";
    switch (moduloVinculado) {
      case Modulos.Formacion:
        path = PATHS.cursos.to;
        break;
      case Modulos.RequisitosLegales:
        path = PATHS.requisitosLegales.to;
        break;
      case Modulos.GestionDocumental:
        path = PATHS.gestionDocumental.to;
        break;
      case Modulos.InvestigacionDeIncidentes:
        path = PATHS.investigacionDeIncidentes.to;
        break;
      case Modulos.PlanesDeAccion:
        path = PATHS.planesDeAccion.to;
        break;
      case Modulos.MIPER:
        path = PATHS.miper.to;
        break;
      case Modulos.Contexto:
        path = PATHS.contexto.to;
        break;
      case Modulos.IndicadoresDeGestion:
        path = PATHS.indicadores.to;
        break;
      case Submodulos.GestionDocumentalInterna:
        path = PATHS.gestionDocumentalInterna.to;
        break;
      case Submodulos.AdministracionDeContratos:
        path = PATHS.gestionDocumentalContratos.to;
        break;
    }
    return <Link to={path}>{textoDeModulo(moduloVinculado)}</Link>;
  } else if (!!nombre) {
    return <Link to={enlace}>{nombre}</Link>;
  }
};

const sortByArticulos = (a: DerogacionDeRequisitoLegal, b: DerogacionDeRequisitoLegal) => {
  const min = Math.min(a.articulos.length, b.articulos.length);
  for (let i = 0; i < min; i++) {
    if (a.articulos[i] < b.articulos[i]) return -1;
    if (a.articulos[i] > b.articulos[i]) return 1;
  }
  return a.articulos.length - b.articulos.length;
};

const TablaDeDerogacionDeRequisitoLegal: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    state: { prevHash },
  } = useLocation();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [requisitoLegal, setRequisitoLegal] = useState<RequisitoLegal | null>(null);
  const [derogacion, setDerogacion] = useState<DerogacionDeRequisitoLegal[]>([]);
  const [derogacionSeleccionado, setDerogacionSeleccionado] = useState<DerogacionDeRequisitoLegal | null>(
    null
  );
  const [vincularModalIsOpen, setVincularModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleVincularModal = () => setVincularModalIsOpen((prev) => !prev);

  const handleDerogacionVinculadoSuccessfully = () => {
    toggleVincularModal();
    loadDerogacion();
  };

  const handleModalClosed = () => {
    setDerogacionSeleccionado(null);
  };

  const handleVincularDerogacionClick = (derogacionId: string) => {
    const derogacionSeleccionado = derogacion.find(({ _id }) => _id === derogacionId);
    if (derogacionSeleccionado) {
      setDerogacionSeleccionado(derogacionSeleccionado);
      toggleVincularModal();
    }
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.derogacionDeRequisitoLegal.name),
    [setTituloDeLaPantallaActual]
  );

  const loadDerogacion = useCallback(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    obtenerRequisitoLegal(authToken, id)
      .then(({ data }) => {
        setRequisitoLegal(new RequisitoLegal(data.requisitoLegal));
        const derogacion = data.derogacion
          .map((a) => new DerogacionDeRequisitoLegal(a))
          .sort(sortByArticulos);
        setDerogacion(derogacion);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  useEffect(loadDerogacion, [loadDerogacion]);

  const hash = prevHash || "#todos";

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
     
          <Breadcrumbs
            items={[
              PATHS.usuarioHome,
              {
                ...PATHS.requisitosLegales,
                to: { pathname: PATHS.requisitosLegales.to, hash },
              },
            ]}
          />
        
        <ComponentCard
          title={!!requisitoLegal ? "Requisito Legar: "+requisitoLegal.nombre : "Cargando..."}
         btnRight= {
              <>
              <LinkContainer
                to={procesarPath(PATHS.nuevoDerogacionDeRequisitoLegal, id || "")}
                state={{ prevHash }}
              >
                <Button color="primary" disabled={!requisitoLegal} title="Agregar Derogación" outline>
                  <RiListSettingsLine size={20} />
                </Button>
              </LinkContainer>
              </>
            }
        >
    
   
        <Table className="no-wrap mt-1 align-middle" responsive borderless>
          <thead>
            <tr>
              <th>#</th>
              <th>Derogación</th>
              <th>Artículos</th>
             
             
              <th>Responsable</th>

              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {derogacion.map(
              (
                {
                  _id,
                  derogacion,
                  articulos,
                 
                  
                  
                  tipoDeEvidencia,

                },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{derogacion}</td>
                  <td>{articulos.join(", ")}</td>
                 
                  
                  <td>
                    {typeof requisitoLegal?.responsableDelAnalisis === "string"
                      ? requisitoLegal?.responsableDelAnalisis
                      : requisitoLegal?.responsableDelAnalisis.nombreCompleto}
                  </td>
                 
                 
                  <td>
                    <Row className="justify-content-center gx-0 flex-nowrap">
                      <Col xs="auto">
                        <LinkContainer
                          to={procesarPath(PATHS.nuevoDerogacionDeRequisitoLegal, id || "")}
                          state={{ prevHash, derogacionId: _id }}
                        >
                          <Button type="button" color="link" size="sm" title="Editar">
                            <FaEdit size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      
                    </Row>
                  </td>
                </tr>
              )
            )}
            {derogacion.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">No hay derogaciones para mostrar</Col>
                    </Row>
                    <Row className="justify-content-center gx-0 mt-3">
                      <Col xs="auto">
                        <LinkContainer
                          to={procesarPath(PATHS.nuevoDerogacionDeRequisitoLegal, id || "")}
                          state={{ prevHash }}
                        >
                          <Button type="button" color="primary" disabled={!requisitoLegal} outline>
                            Agregar Derogación
                          </Button>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        
        </ComponentCard>
  
    </>
  );
};

export default TablaDeDerogacionDeRequisitoLegal;
