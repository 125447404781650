import { useCallback, useContext, FC, useState, useEffect } from "react";
import {
  Button,
  Container,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Placeholder,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";


import ProfileDD from './ProfileDD';
import { ReactFontSizeByTextLength } from "react-font-size-by-text-length";
import { FaSignOutAlt, FaTh, FaUserCircle, FaBars } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/Store';
import { ToggleMobileSidebar } from '../store/customizer/CustomizerSlice';


import { NavLink, useNavigate,Link } from "react-router-dom";
import styled from "styled-components";
import { GISSTLogo } from "../../resources/Images";
import { Context } from "../Context";
import LocalStorage from "../../utilities/LocalStorage";
import { range, toTitleCase } from "../../utilities/utils";
import { getStatus } from "../../services/usuario-api-client";
import CambiarContrasenaModal from "./CambiarContrasenaModal";
import { Usuario } from "../../models/Usuario";
import { Vinculacion } from "../../models/Vinculacion";
import { PATHS } from "../../utilities/Paths";

import NavSubItem from './Menu/NavSubItem';
import NavSingleItem from './Menu/NavSingleItem';
import SidebarData from './Menu/menuData';

import { Empresa } from "../../models/Empresa";

import { FaBriefcase } from "react-icons/fa";


import {
  actualizarUltimaEmpresaSeleccionada,
  fetchVinculacionesDelUsuario,
} from "../../services/usuario-api-client";

const LogoContainer = styled.div`
  height: 40px;
`;

const InfoContainer = styled.div`
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 8px;
    border: 2px solid #474747;
    border-radius: 10px;
    margin: auto 12px;
    padding: 4px 8px;
    height: 48px;
    min-width: 100px;
  }
`;

const user1 = require('../../assets/images/users/user7.png');

interface CargoItem {
  cargo: string[];
}

const HeaderUsuario: FC = () => {
  const location = useLocation();
  const activeBg = "white";
  const currentURL = location.pathname.split('/').slice(0, -1).join('/');
  
  const dispatch = useDispatch();
 const isMobileSidebar = useSelector(
   (state: RootState) => state.customizer.isMobileSidebar
 );

 
  
  const navigate = useNavigate();
  const {
    authToken,
    idDeLaEmpresaSeleccionada,
    tituloDeLaPantallaActual,
    setIdDeLaEmpresaSeleccionada,
    setUsuarioEnSesion,
  } = useContext(Context);
  
  
  const [UserAdmin, setUserAdmin] = useState<string>("regular");
  const [nombreDelUsuario, setNombreDelUsuario] = useState<string>("");
  const [tipoDeUsuario, setTipoDeUsuario] = useState<string>("");
  const [rolDeUsuario, setRolDeUsuario] = useState<string>("");
  const [nombreDeLaEmpresa, setNombreDeLaEmpresa] = useState<string>("");
  const [rucYDigitoVerificador, setRucYDigitoVerificador] = useState<string>("");
  const [isOpenOffcanvas, setIsOpenOffcanvas] = useState<boolean>(false);
  const [cambiarContraseñaModalIsOpen, setCambiarContraseñaModalIsOpen] = useState<boolean>(false);
  const [contraseñaCambiadaModalIsOpen, setContraseñaCambiadaModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
    
  const [empresas, setEmpresas] = useState<Empresa[]>([]);

  const toggleOffcanvas = () => {
    setIsOpenOffcanvas(!isOpenOffcanvas);
  };

  const handleContraseñaCambiada = () => {
    setCambiarContraseñaModalIsOpen(false);
    setContraseñaCambiadaModalIsOpen(true);
  };

  const handleUsuarioEnSesionChange = useCallback(
    (nuevoUsuario: Usuario | Vinculacion | null) => {
      setUsuarioEnSesion(nuevoUsuario);
    },
    [setUsuarioEnSesion]
  );

  const handleSelectEmpresa = (empresaId: string) => () => {
    setIdDeLaEmpresaSeleccionada(empresaId);
    actualizarUltimaEmpresaSeleccionada(authToken, empresaId);
    navigate(PATHS.inicio.to);
  };


  useEffect(() => {
    if (!authToken || isLoading) return;
    setIsLoading(true);
    getStatus(authToken)
      .then(({ data }) => {
        const prevIdDeLaEmpresaSeleccionada = idDeLaEmpresaSeleccionada;
        LocalStorage.getIdEmpresa().then((idDeLaEmpresaAlmacenado) => {
          const { persona, vinculaciones, cambiarContrasena } = data;
          setNombreDelUsuario(`${persona.nombre} ${persona.apellidos}`);
          let idDeLaEmpresaSeleccionada: string;
          if (!prevIdDeLaEmpresaSeleccionada && !!persona.ultimaEmpresaSeleccionada) {
            idDeLaEmpresaSeleccionada = persona.ultimaEmpresaSeleccionada;
            setIdDeLaEmpresaSeleccionada(idDeLaEmpresaSeleccionada);
          }
          if (!!idDeLaEmpresaAlmacenado) {
            idDeLaEmpresaSeleccionada = idDeLaEmpresaAlmacenado;
          } else if (!!prevIdDeLaEmpresaSeleccionada) {
            idDeLaEmpresaSeleccionada = prevIdDeLaEmpresaSeleccionada;
          } else {
            const empresa = vinculaciones[0].empresa;
            idDeLaEmpresaSeleccionada = typeof empresa === "string" ? empresa : empresa._id;
            setIdDeLaEmpresaSeleccionada(idDeLaEmpresaSeleccionada);
          }
          const currentVinculacion = vinculaciones.find(({ empresa }) =>
            typeof empresa === "string"
              ? empresa === idDeLaEmpresaSeleccionada
              : empresa._id === idDeLaEmpresaSeleccionada
          );
          if (!!currentVinculacion) {
            const rol = toTitleCase(currentVinculacion.rol);
            const { cargo } = currentVinculacion;
            if (Array.isArray(cargo)) {
             console.log(cargo);
             const cargoList = cargo.map((item) => 
               typeof item === "object" && item !== null && "cargo" in item && typeof (item as CargoItem).cargo === "string"
                 ? (item as CargoItem).cargo
                 : ""
             );
             
             console.log(cargoList);
             
             const formattedCargoList = cargoList.filter(Boolean).join("\n");
             
              if (rol === "Administrador") {
                setTipoDeUsuario(`${formattedCargoList}`);
              } else {
                setTipoDeUsuario(`${formattedCargoList}`);
              }
            } else if (typeof cargo === "string") {
              setTipoDeUsuario(cargo); // Handle if cargo is a string
            } else {
              setTipoDeUsuario(rol); // Handle undefined or unexpected types
            }
            
            setRolDeUsuario(rol);
            
            
            const { empresa } = currentVinculacion;
            if (typeof empresa !== "string") {
              setNombreDeLaEmpresa(empresa.nombreDeLaEmpresa);
              setRucYDigitoVerificador(`${empresa.ruc} DV ${empresa.digitoVerificador}`);
            }
            handleUsuarioEnSesionChange(new Vinculacion({ ...currentVinculacion, persona }));
          } else {
            // handleUsuarioEnSesionChange(persona);
          }
          if (cambiarContrasena) {
            setCambiarContraseñaModalIsOpen(true);
          }
        });
      })
      .finally(() => setIsLoading(false));
      
      
      fetchVinculacionesDelUsuario(authToken)
      .then(({ data }) => {
        const empresas = data.map(({ empresa }) => empresa as Empresa);
        setEmpresas(empresas);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
      
      
  }, [authToken, idDeLaEmpresaSeleccionada]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    LocalStorage.isLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        navigate(PATHS.inicio.to);
      }
    });
    

      
      
  }, [navigate]);
  
  




  return (
    <>

     <Navbar
       color={"#FFF"}
       dark={false}
       light={true}
       expand="lg"
       className="shadow HorizontalTopbar p-0"
     >
     <Container fluid className="d-flex align-items-center">
        <LinkContainer to={PATHS.usuarioHome.to}>
         <div className="pe-4 py-3 ">
            <LogoContainer>
              <GISSTLogo />
            </LogoContainer>
         </div>
        </LinkContainer>

        <Nav className="me-auto flex-row" navbar>
          <Button
            className="md-md-block d-lg-none" color="primary"
            onClick={() => dispatch(ToggleMobileSidebar())}
          >
            <FaBars />
          </Button>
          
          
          <NavItem className="d-none d-md-none d-lg-block">
              <h4 style={{"marginBottom":"10xp","marginTop":"10px"}} className="text-primary"><strong>{tituloDeLaPantallaActual}</strong></h4>
          </NavItem>
        </Nav>
       
       
       {rolDeUsuario == 'Administrador' && (
        <NavItem className="d-none d-sm-none d-md-block d-lg-block mx-2"> 
          <Badge color="success">{rolDeUsuario}</Badge>
        </NavItem> 
       )}
       
       {empresas.length > 1 && (
    
             <UncontrolledDropdown className="mx-2">
               <DropdownToggle color="primary" outline caret  size="sm">
               <FaBriefcase />  {empresas.find(({ _id }) => _id === idDeLaEmpresaSeleccionada)?.nombreDeLaEmpresa}
               </DropdownToggle>
               <DropdownMenu>
                 {empresas.map(({ _id, nombreDeLaEmpresa }) => (
                   <DropdownItem
                     key={_id}
                     onClick={handleSelectEmpresa(_id)}
                     active={_id === idDeLaEmpresaSeleccionada}
                   >
                     {nombreDeLaEmpresa}
                   </DropdownItem>
                 ))}
               </DropdownMenu>
             </UncontrolledDropdown>
    
       )}
       
       
        
       
        <UncontrolledDropdown className="ms-2" direction={"up"} inNavbar={true}>
           <DropdownToggle tag="span" className="p-2 cursor-pointer ">
             <img src={user1} alt="profile" className="rounded-circle" width="30" />
           </DropdownToggle>
           <DropdownMenu  className="dropdown-menu-custom">
             <ProfileDD User={nombreDelUsuario} Empresa={nombreDeLaEmpresa} Titulo={tipoDeUsuario} />
         
             <div className="p-2 px-3">
              <LinkContainer to={PATHS.logout.to}> 
                <Button  color="danger" size="sm">
                 Logout
               </Button>
              </LinkContainer>
             </div>
           </DropdownMenu>
         </UncontrolledDropdown>
        
     </Container>
      </Navbar>
      
      
      <div
        className={`horizontalNav shadow fixedSidebar ${
            isMobileSidebar ? 'showSidebar' : ''
          }`}
        style={{"marginTop":"16px","backgroundColor":"#fad200"}}
      >
      <Container fluid>
      <Nav className={'lightText'}>
        {SidebarData.map((navi) => {
          if (navi.caption) {
            return (
              <div
                className="navCaption fw-bold mt-4 d-none d-sm-block d-md-none"
                key={navi.caption}
              >
                {navi.caption}
              </div>
            );
          }
          
          if (navi.children && Array.isArray(navi.children)) {
            return (
              <NavSubItem
                key={navi.id}
                icon={navi.icon}
                title={navi.title}
                items={navi.children}
                suffix={navi.suffix}
                ddType={navi.ddType}
                activeBck={activeBg}
                suffixColor={navi.suffixColor}
                isUrl={currentURL === navi.href}
              />
            );
          }
          
          return (
            <NavSingleItem
              key={navi.id}
              //toggle={() => toggle(navi.id)}
              className={location.pathname === navi.href ? 'activeLink' : ''}
              to={navi.href}
              title={navi.title}
              suffix={navi.suffix}
              suffixColor={navi.suffixColor}
              icon={navi.icon}
            />
          );
        })}
      </Nav>
      </Container>
      </div>
    
      
   
      <Offcanvas isOpen={isOpenOffcanvas} toggle={toggleOffcanvas} unmountOnClose={false}>
        <OffcanvasHeader toggle={toggleOffcanvas}>
          <LogoContainer>
            <GISSTLogo />
          </LogoContainer>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    to={PATHS.nuevaEmpresa.to}
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Registrar empresa
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to={PATHS.editarEmpresa.to}
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Editar datos de la empresa
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div>
              <Row>
                <Col>{nombreDelUsuario}</Col>
              </Row>
              <Row>
                <Col>{tipoDeUsuario}</Col>
              </Row>
              <Row>
                <Col>{nombreDeLaEmpresa}</Col>
              </Row>
              <Row>
                <Col>{rucYDigitoVerificador}</Col>
              </Row>
              <Row className="mb-3">
                <Col xs="auto">
                  <LinkContainer to={PATHS.usuarioHome.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted"
                      title="Menú principal"
                      onClick={toggleOffcanvas}
                    >
                      <FaTh size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
                <Col xs="auto">
                  <LinkContainer to={PATHS.perfil.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted"
                      title="Mi perfil"
                      onClick={toggleOffcanvas}
                    >
                      <FaUserCircle size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
                <Col xs="auto">
                  <LinkContainer to={PATHS.logout.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted"
                      title="Cerrar sesión"
                      onClick={toggleOffcanvas}
                    >
                      <FaSignOutAlt size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
      

      
      
      <CambiarContrasenaModal
        isOpen={cambiarContraseñaModalIsOpen}
        forzarCambio
        onContraseñaCambiada={handleContraseñaCambiada}
        setIsLoading={setIsLoading}
      />
      <Modal
        isOpen={contraseñaCambiadaModalIsOpen}
        toggle={() => setContraseñaCambiadaModalIsOpen(false)}
      >
        <ModalHeader toggle={() => setContraseñaCambiadaModalIsOpen(false)}>
          Contraseña Cambiada
        </ModalHeader>
        <ModalBody>
          <p>La contraseña se ha cambiado correctamente.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setContraseñaCambiadaModalIsOpen(false);
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
   
    </>
  );
};

export default HeaderUsuario;
