import { useContext, FC, useEffect, useState, FormEvent, useRef } from "react";
import { FaMinusCircle, FaPlus } from "react-icons/fa";
import { PatternFormat } from "react-number-format";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Context } from "../Context";
import { Usuario, UsuarioBase } from "../../models/Usuario";
import { actualizarDatosDelUsuario, getStatus } from "../../services/usuario-api-client";
import LoadingSpinner from "../../utilities/LoadingSpinner";
import CambiarContrasenaModal from "./CambiarContrasenaModal";
import ComponentCard from '../components/ComponentCard';
import Breadcrumbs from "../../utilities/Breadcrumbs";
import { PATHS } from "../../utilities/Paths";
import tiposDeIdentificacion, {
  TipoDeIdentificacion,
} from "../../resources/tipos-de-identificacion";
import paises from "../../resources/paises.json";
import tiposDeSangre from "../../resources/tipos-de-sangre.json";

const ACTUALIZAR_DATOS_FORM_ID = "actualizarDatosForm";

const Perfil: FC = () => {
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [usuario, setUsuario] = useState<Usuario>();
  const [nombre, setNombre] = useState<string>("");
  const [apellidos, setApellidos] = useState<string>("");
  const [tipoDeIdentificacion, setTipoDeIdentificacion] = useState<TipoDeIdentificacion>(
    tiposDeIdentificacion[0]
  );
  const [identificacion, setIdentificacion] = useState<string>("");
  const [nacionalidad, setNacionalidad] = useState<string>("");
  const [tipoDeSangre, setTipoDeSangre] = useState<string>("");
  const [telefono, setTelefono] = useState<string>("");
  const [direccion, setDireccion] = useState<string>("");
  const [alergias, setAlergias] = useState<string[]>([]);
  const [discapacidades, setDiscapacidades] = useState<string[]>([]);
  const [enfermedades, setEnfermedades] = useState<string[]>([]);
  const [medicamentos, setMedicamentos] = useState<string[]>([]);
  const [contactosDeEmergencia, setContactosDeEmergencia] = useState<
    { nombre: string; telefono: string }[]
  >([]);
  const [cambiarContraseñaModalIsOpen, setCambiarContraseñaModalIsOpen] = useState<boolean>(false);
  const [contraseñaCambiadaModalIsOpen, setContraseñaCambiadaModalIsOpen] =
    useState<boolean>(false);

  const [showNuevaAlergia, setShowNuevaAlergia] = useState<boolean>(false);
  const [nuevaAlergia, setNuevaAlergia] = useState<string>("");
  const [showNuevaDiscapacidad, setShowNuevaDiscapacidad] = useState<boolean>(false);
  const [nuevaDiscapacidad, setNuevaDiscapacidad] = useState<string>("");
  const [showNuevaEnfermedad, setShowNuevaEnfermedad] = useState<boolean>(false);
  const [nuevaEnfermedad, setNuevaEnfermedad] = useState<string>("");
  const [showNuevoMedicamento, setShowNuevoMedicamento] = useState<boolean>(false);
  const [nuevoMedicamento, setNuevoMedicamento] = useState<string>("");
  const [showNuevoContactoDeEmergencia, setShowNuevoContactoDeEmergencia] =
    useState<boolean>(false);
  const [nuevoContactoDeEmergenciaNombre, setNuevoContactoDeEmergenciaNombre] =
    useState<string>("");
  const [nuevoContactoDeEmergenciaTelefono, setNuevoContactoDeEmergenciaTelefono] =
    useState<string>("");

  const [isValidIdentificacion, setIsValidIdentificacion] = useState<boolean>(false);
  const [errorIdentificacion, setErrorIdentificacion] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const scrollIntoView = (fieldName: string) => {
    if (formRef.current) {
      const formControl = formRef.current.querySelector<HTMLInputElement | HTMLSelectElement>(
        `#${fieldName}`
      );
      if (formControl) {
        formControl.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const handleNuevaAlergiaSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (nuevaAlergia === "") return;
    setAlergias([...alergias, nuevaAlergia]);
    setNuevaAlergia("");
    setShowNuevaAlergia(false);
  };

  const handleNuevaDiscapacidadSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (nuevaDiscapacidad === "") return;
    setDiscapacidades([...discapacidades, nuevaDiscapacidad]);
    setNuevaDiscapacidad("");
    setShowNuevaDiscapacidad(false);
  };

  const handleNuevaEnfermedadSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (nuevaEnfermedad === "") return;
    setEnfermedades([...enfermedades, nuevaEnfermedad]);
    setNuevaEnfermedad("");
    setShowNuevaEnfermedad(false);
  };

  const handleNuevoMedicamentoSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (nuevoMedicamento === "") return;
    setMedicamentos([...medicamentos, nuevoMedicamento]);
    setNuevoMedicamento("");
    setShowNuevoMedicamento(false);
  };

  const handleNuevoContactoDeEmergenciaSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (nuevoContactoDeEmergenciaNombre === "" || nuevoContactoDeEmergenciaTelefono === "") return;
    setContactosDeEmergencia([
      ...contactosDeEmergencia,
      {
        nombre: nuevoContactoDeEmergenciaNombre,
        telefono: nuevoContactoDeEmergenciaTelefono,
      },
    ]);
    setNuevoContactoDeEmergenciaNombre("");
    setNuevoContactoDeEmergenciaTelefono("");
    setShowNuevoContactoDeEmergencia(false);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrorIdentificacion("");
    const { isValid, isComplete } = tipoDeIdentificacion.validador(identificacion);
    if (!isValid || !isComplete) {
      scrollIntoView("identificacion");
      setErrorIdentificacion("El formato del documento de identificación no es válido.");
      return;
    }
    setIsLoading(true);
    const nuevoUsuario: UsuarioBase = {
      nombre,
      apellidos,
      correo: usuario!.correo,
      tipoDeIdentificacion: tipoDeIdentificacion.id,
      identificacion,
      nacionalidad,
      tipoDeSangre,
      telefono,
      direccionDeResidencia: direccion,
      alergias,
      discapacidades,
      enfermedades,
      medicamentos,
      contactosDeEmergencia,
    };
    actualizarDatosDelUsuario(authToken, usuario!._id, nuevoUsuario)
      .then(({ data }) => setUsuario(new Usuario(data)))
      .finally(() => setIsLoading(false));
  };

  const hayCambios = () => {
    const hayCambiosDeAlergias = alergias.join(",") !== usuario?.alergias.join(",");
    const hayCambiosDeDiscapacidades =
      discapacidades.join(",") !== usuario?.discapacidades.join(",");
    const hayCambiosDeEnfermedades = enfermedades.join(",") !== usuario?.enfermedades.join(",");
    const hayCambiosDeMedicamentos = medicamentos.join(",") !== usuario?.medicamentos.join(",");
    const hayCambiosDeContactosDeEmergencia =
      contactosDeEmergencia.map(({ nombre, telefono }) => `${nombre} ${telefono}`).join(",") !==
      usuario?.contactosDeEmergencia
        .map(({ nombre, telefono }) => `${nombre} ${telefono}`)
        .join(",");
    return (
      nombre !== usuario?.nombre ||
      apellidos !== usuario?.apellidos ||
      tipoDeIdentificacion.id !== usuario?.tipoDeIdentificacion ||
      identificacion !== usuario?.identificacion ||
      nacionalidad !== usuario?.nacionalidad ||
      tipoDeSangre !== usuario?.tipoDeSangre ||
      telefono !== usuario?.telefono ||
      direccion !== usuario?.direccionDeResidencia ||
      hayCambiosDeAlergias ||
      hayCambiosDeDiscapacidades ||
      hayCambiosDeEnfermedades ||
      hayCambiosDeMedicamentos ||
      hayCambiosDeContactosDeEmergencia
    );
  };

  const toggleCambiarContraseñaModal = () => {
    setCambiarContraseñaModalIsOpen(!cambiarContraseñaModalIsOpen);
  };

  const handleContraseñaCambiada = () => {
    toggleCambiarContraseñaModal();
    setContraseñaCambiadaModalIsOpen(true);
  };

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.perfil.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(() => {
    if (identificacion === "") return;
    const validatorResponse = tipoDeIdentificacion.validador(identificacion);
    const isValid = validatorResponse.isValid && validatorResponse.isComplete;
    setIsValidIdentificacion(isValid);
    setErrorIdentificacion("");
  }, [tipoDeIdentificacion, identificacion]);

  useEffect(() => {
    if (!authToken) return;
    setIsLoading(true);
    getStatus(authToken)
      .then(({ data }) => {
        const usuario = new Usuario(data.persona);
        setUsuario(usuario);
      })
      .finally(() => setIsLoading(false));
  }, [authToken]);

  useEffect(() => {
    if (!usuario) return;
    setNombre(usuario.nombre);
    setApellidos(usuario.apellidos);
    setTipoDeIdentificacion(
      tiposDeIdentificacion.find(({ id }) => id === usuario.tipoDeIdentificacion)!
    );
    setIdentificacion(usuario.identificacion);
    setNacionalidad(usuario.nacionalidad);
    setTipoDeSangre(usuario.tipoDeSangre);
    setTelefono(usuario.telefono);
    setDireccion(usuario.direccionDeResidencia);
    setAlergias(usuario.alergias);
    setDiscapacidades(usuario.discapacidades);
    setEnfermedades(usuario.enfermedades);
    setMedicamentos(usuario.medicamentos);
    setContactosDeEmergencia(usuario.contactosDeEmergencia);
  }, [usuario]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome]} />
        
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          
          <ComponentCard
            title="Datos Personales"
            subtitle={
              <p>
                Puedes editar tus datos personales en este formulario.
              </p>
            }
            btnRight={
              <></>
            }
          >
          
          
              <Form
                onSubmit={handleSubmit}
                id={ACTUALIZAR_DATOS_FORM_ID}
                innerRef={formRef}
                className="mb-3"
              >
                <FormGroup floating>
                  <Input
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    required
                    value={nombre}
                    onChange={({ target }) => setNombre(target.value)}
                  />
                  <Label htmlFor="nombre">Nombre</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    type="text"
                    id="apellidos"
                    placeholder="Apellidos"
                    required
                    value={apellidos}
                    onChange={({ target }) => setApellidos(target.value)}
                  />
                  <Label htmlFor="apellidos">Apellidos</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    type="text"
                    id="correo"
                    placeholder="Correo"
                    required
                    value={usuario?.correo}
                    disabled
                  />
                  <Label htmlFor="correo">Correo</Label>
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup floating>
                      <Input
                        id="tipoDeIdentificacion"
                        name="tipoDeIdentificacion"
                        type="select"
                        required
                        value={tipoDeIdentificacion.id}
                        onChange={({ target }) =>
                          setTipoDeIdentificacion(
                            tiposDeIdentificacion.find(({ id }) => id === target.value)!
                          )
                        }
                      >
                        {tiposDeIdentificacion.map(({ id, nombre }) => (
                          <option value={id} key={id}>
                            {nombre}
                          </option>
                        ))}
                      </Input>
                      <Label htmlFor="tipoDeIdentificacion">Tipo de identificación</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup floating>
                      <Input
                        type="text"
                        id="identificacion"
                        placeholder="Identificación"
                        required
                        valid={isValidIdentificacion}
                        invalid={!!errorIdentificacion}
                        value={identificacion}
                        onChange={({ target }) => setIdentificacion(target.value)}
                      />
                      <Label htmlFor="identificacion">Identificación</Label>
                      <FormFeedback
                        tooltip
                        dangerouslySetInnerHTML={{ __html: errorIdentificacion }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup floating>
                  <Input
                    id="nacionalidad"
                    name="nacionalidad"
                    type="select"
                    required
                    value={nacionalidad}
                    onChange={(e) => setNacionalidad(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecciona tu nacionalidad
                    </option>
                    {paises.map(({ nombre, bandera }) => (
                      <option value={nombre} key={nombre}>
                        {nombre} {bandera}
                      </option>
                    ))}
                  </Input>
                  <Label htmlFor="nacionalidad">Nacionalidad</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="tipoDeSangre"
                    name="tipoDeSangre"
                    type="select"
                    required
                    value={tipoDeSangre}
                    onChange={(e) => setTipoDeSangre(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecciona tu tipo de sangre
                    </option>
                    {tiposDeSangre.map((type) => (
                      <option value={type} key={type}>
                        {type}
                      </option>
                    ))}
                  </Input>
                  <Label htmlFor="tipoDeSangre">Tipo de sangre</Label>
                </FormGroup>
                <FormGroup floating>
                  <PatternFormat
                    customInput={Input}
                    id="telefono"
                    name="telefono"
                    placeholder="Teléfono"
                    type="tel"
                    format={telefono.startsWith("7") ? "###-####" : "####-####"}
                    mask="_"
                    required
                    value={telefono}
                    onValueChange={({ formattedValue }) => setTelefono(formattedValue)}
                  />
                  <Label htmlFor="telefono">Teléfono</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    type="text"
                    id="direccion"
                    placeholder="Dirección de residencia"
                    required
                    value={direccion}
                    onChange={({ target }) => setDireccion(target.value)}
                  />
                  <Label htmlFor="direccion">Dirección de residencia</Label>
                </FormGroup>
              </Form>
              <Card className="mb-3">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col>Alergias</Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        color="primary"
                        size="sm"
                        title="Agregar nueva alergia"
                        onClick={() => setShowNuevaAlergia(true)}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ListGroup flush>
                  {alergias.length > 0 ? (
                    alergias.map((alergia) => (
                      <ListGroupItem key={alergia}>
                        <Row className="align-items-center">
                          <Col>{alergia}</Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setAlergias(
                                  alergias.filter((alergiaFiltrada) => alergiaFiltrada !== alergia)
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem>No hay alergias</ListGroupItem>
                  )}
                  {showNuevaAlergia && (
                    <ListGroupItem>
                      <Form onSubmit={handleNuevaAlergiaSubmit}>
                        <Row>
                          <Col>
                            <Input
                              type="text"
                              placeholder="Nueva alergia"
                              required
                              value={nuevaAlergia}
                              onChange={({ target }) => setNuevaAlergia(target.value)}
                            />
                          </Col>
                          <Col xs="auto">
                            <Button type="submit" color="primary">
                              Agregar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card>
              <Card className="mb-3">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col>Discapacidades</Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        color="primary"
                        size="sm"
                        title="Agregar nueva discapacidad"
                        onClick={() => setShowNuevaDiscapacidad(true)}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ListGroup flush>
                  {discapacidades.length > 0 ? (
                    discapacidades.map((discapacidad) => (
                      <ListGroupItem key={discapacidad}>
                        <Row className="align-items-center">
                          <Col>{discapacidad}</Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setDiscapacidades(
                                  discapacidades.filter(
                                    (discapacidadFiltrada) => discapacidadFiltrada !== discapacidad
                                  )
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem>No hay discapacidades</ListGroupItem>
                  )}
                  {showNuevaDiscapacidad && (
                    <ListGroupItem>
                      <Form onSubmit={handleNuevaDiscapacidadSubmit}>
                        <Row>
                          <Col>
                            <Input
                              type="text"
                              placeholder="Nueva discapacidad"
                              required
                              value={nuevaDiscapacidad}
                              onChange={({ target }) => setNuevaDiscapacidad(target.value)}
                            />
                          </Col>
                          <Col xs="auto">
                            <Button type="submit" color="primary">
                              Agregar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card>
              <Card className="mb-3">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col>Enfermedades o condiciones médicas</Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        color="primary"
                        size="sm"
                        title="Agregar nueva enfermedad o condición médica"
                        onClick={() => setShowNuevaEnfermedad(true)}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ListGroup flush>
                  {enfermedades.length > 0 ? (
                    enfermedades.map((enfermedad) => (
                      <ListGroupItem key={enfermedad}>
                        <Row className="align-items-center">
                          <Col>{enfermedad}</Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setEnfermedades(
                                  enfermedades.filter(
                                    (enfermedadFiltrada) => enfermedadFiltrada !== enfermedad
                                  )
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem>No hay enfermedades o condiciones médicas</ListGroupItem>
                  )}
                  {showNuevaEnfermedad && (
                    <ListGroupItem>
                      <Form onSubmit={handleNuevaEnfermedadSubmit}>
                        <Row>
                          <Col>
                            <Input
                              type="text"
                              placeholder="Nueva enfermedad o condición médica"
                              required
                              value={nuevaEnfermedad}
                              onChange={({ target }) => setNuevaEnfermedad(target.value)}
                            />
                          </Col>
                          <Col xs="auto">
                            <Button type="submit" color="primary">
                              Agregar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card>
              <Card className="mb-3">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col>Medicamentos</Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        color="primary"
                        size="sm"
                        title="Agregar nuevo medicamento"
                        onClick={() => setShowNuevoMedicamento(true)}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ListGroup flush>
                  {medicamentos.length > 0 ? (
                    medicamentos.map((medicamento) => (
                      <ListGroupItem key={medicamento}>
                        <Row className="align-items-center">
                          <Col>{medicamento}</Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setMedicamentos(
                                  medicamentos.filter(
                                    (medicamentoFiltrado) => medicamentoFiltrado !== medicamento
                                  )
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem>No hay medicamentos</ListGroupItem>
                  )}
                  {showNuevoMedicamento && (
                    <ListGroupItem>
                      <Form onSubmit={handleNuevoMedicamentoSubmit}>
                        <Row>
                          <Col>
                            <Input
                              type="text"
                              placeholder="Nuevo medicamento"
                              required
                              value={nuevoMedicamento}
                              onChange={({ target }) => setNuevoMedicamento(target.value)}
                            />
                          </Col>
                          <Col xs="auto">
                            <Button type="submit" color="primary">
                              Agregar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card>
              <Card className="mb-3">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col>Contactos de emergencia</Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        color="primary"
                        size="sm"
                        title="Agregar nuevo contacto de emergencia"
                        onClick={() => setShowNuevoContactoDeEmergencia(true)}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ListGroup flush>
                  {contactosDeEmergencia.length > 0 ? (
                    contactosDeEmergencia.map(({ nombre, telefono }) => (
                      <ListGroupItem key={`${nombre} ${telefono}`}>
                        <Row className="align-items-center">
                          <Col>
                            <strong>{nombre}</strong>
                            <br />
                            {telefono}
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setContactosDeEmergencia(
                                  contactosDeEmergencia.filter(
                                    (contacto) =>
                                      contacto.nombre !== nombre && contacto.telefono !== telefono
                                  )
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem>No hay contactos de emergencia</ListGroupItem>
                  )}
                  {showNuevoContactoDeEmergencia && (
                    <ListGroupItem>
                      <Form onSubmit={handleNuevoContactoDeEmergenciaSubmit}>
                        <Row>
                          <Col>
                            <Input
                              type="text"
                              placeholder="Nombre"
                              required
                              value={nuevoContactoDeEmergenciaNombre}
                              onChange={({ target }) =>
                                setNuevoContactoDeEmergenciaNombre(target.value)
                              }
                            />
                          </Col>
                          <Col>
                            <PatternFormat
                              customInput={Input}
                              placeholder="Teléfono"
                              format={
                                nuevoContactoDeEmergenciaTelefono.startsWith("7")
                                  ? "###-####"
                                  : "####-####"
                              }
                              mask="_"
                              required
                              value={nuevoContactoDeEmergenciaTelefono}
                              onValueChange={({ formattedValue }) =>
                                setNuevoContactoDeEmergenciaTelefono(formattedValue)
                              }
                            />
                          </Col>
                          <Col xs="auto">
                            <Button type="submit" color="primary">
                              Agregar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card>
              <Row className="mb-3">
                <Col>
                  
                  
                  <FormGroup>
                    
                    <Button
                      type="submit"
                      color="success"
                      form={ACTUALIZAR_DATOS_FORM_ID}
                      disabled={!hayCambios()}
                    >
                      Guardar
                    </Button>
                    
                    <Button type="button" color="warning" onClick={toggleCambiarContraseñaModal}>
                      Cambiar Contraseña
                    </Button>
                  </FormGroup>
                  
                  
                </Col>
              </Row>
              
           
           </ComponentCard>
          
        </Col>
      </Row>
      
      <CambiarContrasenaModal
        isOpen={cambiarContraseñaModalIsOpen}
        toggle={toggleCambiarContraseñaModal}
        onContraseñaCambiada={handleContraseñaCambiada}
        setIsLoading={setIsLoading}
      />
      <Modal
        isOpen={contraseñaCambiadaModalIsOpen}
        toggle={() => setContraseñaCambiadaModalIsOpen(false)}
      >
        <ModalHeader toggle={() => setContraseñaCambiadaModalIsOpen(false)}>
          Contraseña Cambiada
        </ModalHeader>
        <ModalBody>
          <p>La contraseña se ha cambiado correctamente.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setContraseñaCambiadaModalIsOpen(false);
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Perfil;
