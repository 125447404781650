import { useState, FC, FormEvent, useEffect, useContext, ChangeEvent } from "react";
import classNames from "classnames";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row,Container, Card, CardBody, CardTitle } from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash,FaDoorOpen } from "react-icons/fa";
import LoadingSpinner from "../utilities/LoadingSpinner";
import { Context } from "./Context";
import { MostrarContraseñaButton, PasswordInput } from "../utilities/utils";
import { PATHS } from "../utilities/Paths";
import { login } from "../services/public-api-client";





import { GISSTLogoFull, LoginLeft as LeftBg, LoginRight as RightBg } from "../resources/Images";

const Login: FC = () => {
  const navigate = useNavigate();
  const { setAuthToken } = useContext(Context);
  const [correo, setCorreo] = useState<string>("");
  const [contraseña, setContraseña] = useState<string>("");
  const [mostrarContraseña, setMostrarContraseña] = useState<boolean>(false);
  const [errorCorreo, setErrorCorreo] = useState<string>("");
  const [errorContraseña, setErrorContraseña] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrorMessage("");
    setErrorCorreo("");
    setErrorContraseña("");
    setIsLoading(true);
    const data = { correo, contrasena: contraseña };
    login(data)
      .then(({ data }) => setAuthToken(data.token))
      .then(() => navigate(PATHS.usuarioHome.to))
      .catch(({ response }) => {
        if (response.status === 401) {
          setErrorContraseña(response.data.message);
        } else if (response.status === 403) {
          setErrorCorreo(response.data.message);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setErrorCorreo("");
    setErrorMessage("");
  }, [correo]);

  useEffect(() => {
    setErrorContraseña("");
    setErrorMessage("");
  }, [contraseña]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
        <div className="loginBox">
          <RightBg />
           <LeftBg />
            <Container fluid className="h-100">
            <Row className="justify-content-center align-items-center h-100">
              <Col lg="12" className="loginContainer">
                <Card>
                <CardBody className="p-4 m-1">
                  <div className="text-center">
                   
                   <GISSTLogoFull />
                     
                    <h2>Iniciar Sesión</h2>
                     <hr />
                  </div>
                  
                  
                  <Form onSubmit={handleSubmit} style={{"marginTop":"35px"}} >
                    <FormGroup floating>
                      <Input
                        id="correo"
                        name="correo"
                        placeholder="Correo"
                        type="email"
                        value={correo}
                        onChange={(e) => setCorreo(e.target.value)}
                        invalid={!!errorMessage || !!errorCorreo}
                      />
                      <Label for="correo">Correo</Label>
                      <FormFeedback>{errorCorreo || errorMessage}</FormFeedback>
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup floating>
                          <PasswordInput
                            id="contraseña"
                            name="contraseña"
                            placeholder="Contraseña"
                            type={mostrarContraseña ? "text" : "password"}
                            value={contraseña}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setContraseña(e.target.value)}
                            invalid={!!errorContraseña}
                            className={classNames({ "not-empty": contraseña.length > 0 })}
                          />
                          <Label for="contraseña">Contraseña</Label>
                          <FormFeedback>{errorContraseña}</FormFeedback>
                          {contraseña.length > 0 && (
                            <MostrarContraseñaButton
                              type="button"
                              color="link"
                              className="text-muted"
                              tabIndex={-1}
                              onClick={() => setMostrarContraseña(!mostrarContraseña)}
                            >
                              {mostrarContraseña ? <FaEye /> : <FaEyeSlash />}
                            </MostrarContraseñaButton>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Button type="submit" color="primary" block>
                       <FaDoorOpen size={20} style={{"marginBottom":"5px","marginRight":"10px"}} /> Entrar
                      </Button>
                    </FormGroup>
                    
                    <FormGroup>
                      <LinkContainer to={PATHS.registro.to}>
                        <Button type="button" color="secondary" outline block>
                          Registrarse
                        </Button>
                      </LinkContainer>
                    </FormGroup>
                    
                    <FormGroup>
                      <LinkContainer to={PATHS.recuperarContrasena.to}>
                        <Button type="button" color="link" block>
                          Recuperar contraseña
                        </Button>
                      </LinkContainer>
                    </FormGroup>
                    
                  </Form>
                  
                  
                </CardBody>
                </Card>
            </Col>
            </Row>
            </Container>
        </div>
      
    </>
  );
};

export default Login;
