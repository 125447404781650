import { useState, FC, useEffect, useCallback, useContext, FormEvent } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { FaDotCircle, FaCircle, FaCheckSquare, FaSquare } from "react-icons/fa";
import { DocumentoDelContrato } from "../../../../models/DocumentoDelContrato";
import {
  Activo,
  Documento,
  FrecuenciaDeActualizacion,
  textoDeLaFrecuenciaDeActualizacion,
  textoDePeriodoDeActualizacion,
  textoDelTipoDeDocumento,
} from "../../../../models/Documento";
import { Context } from "../../../Context";
import {
  listDocumentos,
  vincularDocumentoDelContrato,
} from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  CustomTable,
  ImageButton,
  TableCaption,
  TableContainer,
  TableMessage,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../../utilities/utils";
import { ArchivoDeDocumento } from "../../../../models/ArchivoDeDocumento";

interface VincularDocumentoProps {
  formId: string;
  documentoDelContrato: DocumentoDelContrato;
  onSuccess: () => void;
}

const VincularDocumento: FC<VincularDocumentoProps> = ({
  formId,
  documentoDelContrato,
  onSuccess,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [documentos, setDocumentos] = useState<Documento[]>([]);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState<Documento | null>(null);
  const [activosDelDocumentoSeleccionado, setActivosDelDocumentoSeleccionado] = useState<Activo[]>(
    []
  );
  const [activosSeleccionados, setActivosSeleccionados] = useState<Activo[]>([]);
  const [showSeleccionDeActivosModal, setShowSeleccionDeActivosModal] = useState<boolean>(false);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggleDocumento = (idDelDocumento: string) => {
    setMensajeDeError("");
    if (documentoSeleccionado?._id === idDelDocumento) {
      setDocumentoSeleccionado(null);
      setActivosDelDocumentoSeleccionado([]);
    } else {
      const documento = documentos.find((d) => d._id === idDelDocumento);
      setDocumentoSeleccionado(documento || null);
      const activosSeleccionados = documento?.activos || [];
      setActivosDelDocumentoSeleccionado(activosSeleccionados);
    }
  };

  const handleToggleActivo = (activo: Activo) => {
    if (activosSeleccionados.some((a) => a._id === activo._id)) {
      setActivosSeleccionados(activosSeleccionados.filter((a) => a._id !== activo._id));
    } else {
      setActivosSeleccionados([...activosSeleccionados, activo]);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMensajeDeError("");
    if (!authToken || !documentoSeleccionado) return;
    if (
      !showSeleccionDeActivosModal &&
      documentoSeleccionado.activos &&
      documentoSeleccionado.activos.length > 1
    ) {
      setShowSeleccionDeActivosModal(true);
    } else {
      setIsLoading(true);
      let activos = activosSeleccionados.map(({ _id }) => _id);
      if (documentoSeleccionado.activos && documentoSeleccionado.activos.length === 1) {
        activos = documentoSeleccionado.activos.map(({ _id }) => _id);
      }
      vincularDocumentoDelContrato(
        authToken,
        documentoDelContrato._id,
        documentoSeleccionado?._id,
        activos
      )
        .then(() => {
          setShowSeleccionDeActivosModal(false);
          onSuccess();
        })
        .catch(({ response }) => {
          setMensajeDeError(response.data.error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const obtenerDocumentos = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listDocumentos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setDocumentos(data.map((documento) => new Documento(documento)));
      })
      .finally(() => {
        setIsLoading(false);
        setAlreadyLoaded(true);
      });
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    obtenerDocumentos();
  }, [obtenerDocumentos]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
       
          <TableCaption>Elije un documento para vincular</TableCaption>
          <Table className="no-wrap mt-3 align-middle SmallFont" responsive borderless hover={documentos.length > 0}>
            <thead>
              <tr>
                <th />
                <th>Nombre del Documento</th>
                <th>Tipo de Documento</th>
                <th>Frecuencia de actualización</th>
                <th>Fecha de la última actualización</th>
                <th>Fecha de vencimiento</th>
                <th>Archivos</th>
              </tr>
            </thead>
            <tbody>
              {documentos.map(
                ({
                  _id,
                  nombreDelDocumento,
                  tipoDeDocumento,
                  frecuenciaDeActualizacion,
                  valorDeLaFrecuenciaDeActualizacion,
                  unidadDeLaFrecuenciaDeActualizacion,
                  archivos,
                }) => (
                  <tr key={_id} onClick={() => handleToggleDocumento(_id)}>
                    <td>
                      
                        {documentoSeleccionado?._id === _id ? (
                          <FaDotCircle size={15} color="#444" />
                        ) : (
                          <FaCircle size={15} color="#ccc" />
                        )}
                      
                    </td>
                    <td>{nombreDelDocumento}</td>
                    <td>{textoDelTipoDeDocumento(tipoDeDocumento)}</td>
                    <td>
                      {frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra &&
                      !!valorDeLaFrecuenciaDeActualizacion &&
                      !!unidadDeLaFrecuenciaDeActualizacion
                        ? textoDePeriodoDeActualizacion(
                            valorDeLaFrecuenciaDeActualizacion,
                            unidadDeLaFrecuenciaDeActualizacion
                          )
                        : textoDeLaFrecuenciaDeActualizacion(frecuenciaDeActualizacion)}
                    </td>
                    <td>
                      {archivos && archivos.length > 0
                        ? shortDateToString((archivos as ArchivoDeDocumento[])[0].fechaDeCarga)
                        : "N/A"}
                    </td>
                    <td>
                      {archivos && archivos.length > 0
                        ? shortDateToString(
                            (archivos as ArchivoDeDocumento[])[0].fechaDeVencimiento
                          )
                        : "N/A"}
                    </td>
                    <td>
                      {!!archivos && archivos.length > 0 ? (
                        <>
                          <ul className="mb-0 ps-3">
                            {(archivos as ArchivoDeDocumento[])
                              .filter((_, index) => index < 3)
                              .map(({ _id, nombre, filepath }) => (
                                <li key={_id}>
                                  <a
                                    key={_id}
                                    href={procesarLinkDeDocumento(filepath)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {nombre}
                                  </a>
                                </li>
                              ))}
                            {archivos.length === 4 && <li>1 archivo más</li>}
                            {archivos.length > 4 && <li>{archivos.length - 3} archivos más</li>}
                          </ul>
                        </>
                      ) : (
                        <p className="text-center mb-0">No hay archivos registrados</p>
                      )}
                    </td>
                  </tr>
                )
              )}
              {documentos.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    <TableMessage>
                      {alreadyLoaded
                        ? "No tienes documentos registrados"
                        : "Cargando documentos..."}
                    </TableMessage>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        <span className="text-danger small">{mensajeDeError}</span>
      </Form>
      <Modal
        isOpen={showSeleccionDeActivosModal}
        toggle={() => setShowSeleccionDeActivosModal(false)}
      >
        <ModalHeader toggle={() => setShowSeleccionDeActivosModal(false)}>
          Selección de activos
        </ModalHeader>
        <ModalBody>
          <TableContainer>
            <TableCaption>Selecciona los activos que desea vincular</TableCaption>
            <CustomTable hover={activosDelDocumentoSeleccionado.length > 0}>
              <thead>
                <tr>
                  <th>
                    {documentoSeleccionado &&
                    documentoSeleccionado.activos &&
                    documentoSeleccionado.activos.length > 0 &&
                    documentoSeleccionado.activos.every((activo) =>
                      activosSeleccionados.some((v) => v._id === activo._id)
                    ) ? (
                      <ImageButton
                        type="button"
                        title="Deseleccionar"
                        className="d-inline-block bg-primary"
                        onClick={() => setActivosSeleccionados([])}
                      >
                        <FaCheckSquare size={15} color="#EAEAEA" />
                      </ImageButton>
                    ) : (
                      <ImageButton
                        type="button"
                        title="Seleccionar"
                        className="d-inline-block bg-primary"
                        onClick={() => setActivosSeleccionados(activosDelDocumentoSeleccionado)}
                      >
                        <FaSquare size={15} color="#EAEAEA" />
                      </ImageButton>
                    )}
                  </th>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody>
                {activosDelDocumentoSeleccionado.map((activo) => (
                  <tr key={activo._id} onClick={() => handleToggleActivo(activo)}>
                    <td className="text-center">
                      <ImageButton
                        type="button"
                        title={
                          activosSeleccionados.includes(activo) ? "Deseleccionar" : "Seleccionar"
                        }
                        className="d-inline-block bg-primary"
                      >
                        {activosSeleccionados.includes(activo) ? (
                          <FaCheckSquare size={15} color="#EAEAEA" />
                        ) : (
                          <FaSquare size={15} color="#EAEAEA" />
                        )}
                      </ImageButton>
                    </td>
                    <td>{activo.nombreCompleto}</td>
                  </tr>
                ))}
                {activosDelDocumentoSeleccionado.length === 0 && (
                  <tr>
                    <td colSpan={3} className="text-center">
                      <TableMessage>No hay activos disponibles en este documento</TableMessage>
                    </td>
                  </tr>
                )}
              </tbody>
            </CustomTable>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => setShowSeleccionDeActivosModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" form={formId} color="primary">
            Vincular
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default VincularDocumento;
