import { FC, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Table } from "reactstrap";
import styled from "styled-components";
import { Context } from "../../Context";
import { PATHS } from "../../../utilities/Paths";
import {
  RequisitoLegal,
  textoClasificacion,
  textoTipoDeRequisitoLegal,
} from "../../../models/RequisitoLegal";
import { obtenerRequisitoLegal } from "../../../services/usuario-api-client";

import ComponentCard from '../../components/ComponentCard';

import { AnalisisDeRequisitoLegal } from "../../../models/AnalisisDeRequisitoLegal";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { obtenerURLCompleta, shortDateToString } from "../../../utilities/utils";

const TableContainer = styled.div`
  th {
    width: 0;
    min-width: fit-content;
    @media (min-width: 768px) {
      white-space: nowrap;
    }
  }
`;

const RequisitoLegalView: FC = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [requisitoLegal, setRequisitoLegal] = useState<RequisitoLegal>();
  const [analisis, setAnalisis] = useState<AnalisisDeRequisitoLegal[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    obtenerRequisitoLegal(authToken, id)
      .then(({ data }) => {
        setRequisitoLegal(new RequisitoLegal(data.requisitoLegal));
        const analisis = data.analisis.map((a) => new AnalisisDeRequisitoLegal(a));
        setAnalisis(analisis);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.requisitoLegal.name),
    [setTituloDeLaPantallaActual]
  );

  const hash = state?.prevHash || "#todos";

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs
        items={[
          PATHS.usuarioHome,
          {
            ...PATHS.requisitosLegales,
            to: { pathname: PATHS.requisitosLegales.to, hash },
          },
        ]}
      />
      <ComponentCard
        title="Requisitos Legales"
       
      >
      {requisitoLegal &&
        (({
          filepath,
          nombre,
          tipo,
          autoridad,
          clasificacion,
          fechaDeEmision,
          fechaDeVinculacion,
          estado,
          fechaLimiteDeAnalisis,
        }) => (
          <TableContainer>
            <Table borderless style={{ tableLayout: "auto" }}>
              <tbody>
                <tr>
                  <th scope="row">Requisito Legal:</th>
                  <td>
                    <a href={obtenerURLCompleta(filepath)} target="_blank" rel="noreferrer">
                      {nombre}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Tipo:</th>
                  <td>{textoTipoDeRequisitoLegal(tipo)}</td>
                </tr>
                <tr>
                  <th scope="row">Autoridad:</th>
                  <td>{autoridad.nombre}</td>
                </tr>
                <tr>
                  <th scope="row">Clasificación:</th>
                  <td>{textoClasificacion(clasificacion)}</td>
                </tr>
                <tr>
                  <th scope="row">Fecha de emisión:</th>
                  <td>{fechaDeEmision && shortDateToString(fechaDeEmision)}</td>
                </tr>
                <tr>
                  <th scope="row">Fecha de vinculación:</th>
                  <td>{fechaDeVinculacion && shortDateToString(fechaDeVinculacion)}</td>
                </tr>
                <tr>
                  <th scope="row">Estado:</th>
                  <td>{estado}</td>
                </tr>
                <tr>
                  <th scope="row">Fecha límite de análisis:</th>
                  <td>{fechaLimiteDeAnalisis && shortDateToString(fechaLimiteDeAnalisis)}</td>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
        ))(requisitoLegal)}
      </ComponentCard>
    </>
  );
};

export default RequisitoLegalView;
