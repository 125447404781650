import { FC, FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import classNames from "classnames";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  Button,
  Card,
  Col,
  Collapse,
  Fade,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  Row,
} from "reactstrap";
import {
  FaBookmark,
  FaChevronLeft,
  FaChevronRight,
  FaRegEdit,
  FaMinus,
  FaPlus,
  FaRegBookmark,
  FaRegTimesCircle,
  FaSave,
  
} from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import ComponentCard from '../../components/ComponentCard';

import LocalStorage from "../../../utilities/LocalStorage";

import { IconsContainer, TableContainer } from "../../../utilities/utils";

import { GiBroom } from "react-icons/gi";
import { Document, Page, pdfjs } from "react-pdf";
import { unstable_usePrompt, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  editarDerogacionDeRequisitoLegal,
  eliminarDerogacionDeRequisitoLegal,
  fetchVinculacionesDelUsuario,
  guardarDerogacionDeRequisitoLegal,
  obtenerRequisitoLegal,
  toggleBookmark,
} from "../../../services/usuario-api-client";
import { BookmarkDeArticulo, RequisitoLegal } from "../../../models/RequisitoLegal";
import {
  ImageButton,
  SeparatorRow,
  abrirEnlaceEnNuevaPestaña,
  obtenerURLCompleta,
  procesarLinkDeDocumento,
  range,
} from "../../../utilities/utils";
import Checkbox from "../../../utilities/Checkbox";
import { Empresa } from "../../../models/Empresa";
import { DerogacionDeRequisitoLegalBody } from "../../../models/DerogacionDeRequisitoLegal";
import { useRevertableState } from "../../../utilities/useRevertableState";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS, procesarPath } from "../../../utilities/Paths";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const resizeObserverOptions = {};

const maxWidth = 800;

const CustomButton = styled(Button)`
  margin: 0 12px;
`;

const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DocumentContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 2em);
  transition: transform 0.2s ease-in-out;
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .react-pdf__Page {
    border: 1px solid #000;
  }
  .react-pdf__message {
    color: #000;
  }
`;

const ArticuloContainer = styled.div`
  border-inline: 1px solid #c8c8c8;
  background-color: #ffffff;
  text-align: center;
  padding: 3px;
  width: 50px;
  height: 32px;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &.active {
    background-color: var(--bs-primary);
    color: #fff;
    font-weight: 700;
  }
`;

const DerogacionCol = styled(Col)`
  min-height: 254px;
  overflow: auto;
  @media (max-width: 768px) {
    max-height: initial !important;
  }
`;

const sortByArticulos = (a: DerogacionDeRequisitoLegalBody, b: DerogacionDeRequisitoLegalBody) => {
  const min = Math.min(a.articulos.length, b.articulos.length);
  for (let i = 0; i < min; i++) {
    if (a.articulos[i] < b.articulos[i]) return -1;
    if (a.articulos[i] > b.articulos[i]) return 1;
  }
  return a.articulos.length - b.articulos.length;
};

const AgregarDerogacionDeRequisitoLegal: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    state: { prevHash, derogacionId },
  } = useLocation();
  const navigate = useNavigate();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [requisitoLegal, setRequisitoLegal] = useState<RequisitoLegal | null>(null);
  const [bookmarks, setBookmarks] = useState<BookmarkDeArticulo[]>([]);
  const [scale, setScale] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [derogacion, setDerogacion] = useState<DerogacionDeRequisitoLegalBody[]>([]);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState<string>("");
  const [textoDelDerogacion, setTextoDelDerogacion] = useState<string>("");
  const [articulosSeleccionados, setArticulosSeleccionados] = useState<number[]>([]);
  const [errorArticulos, setErrorArticulos] = useState<boolean>(false);
  const [disabledOption, setDisabledOption] = useRevertableState<boolean>(true, 300);
  const [indiceDeDerogacionAEliminar, setIndiceDeDerogacionAEliminar] = useState<number>(-1);
  const [indiceDeDerogacionAEditar, setIndiceDeDerogacionAEditar] = useState<number>(-1);
  const [eliminarDerogacionModalIsOpen, setEliminarDerogacionModalIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [finishedExpanding, setFinishedExpanding] = useState<boolean>(false);
  const [loadingCount, setLoadingCount] = useState<number>(0);

  unstable_usePrompt({
    message: "¿Estás seguro que deseas salir? Los cambios no guardados se perderán.",
    when: derogacion.some(({ _id }) => !_id) && !isSaving,
  });

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleBookmarkClick = (articulo: number) => {
    const newArticulos = [...bookmarks];
    const index = newArticulos.findIndex((a) => a.articulo === articulo);
    if (index === -1) return;
    const { pagina } = newArticulos[index];
    if (pagina > 0) {
      newArticulos[index] = { articulo, pagina: -1 };
    } else {
      newArticulos[index] = { articulo, pagina: currentPage };
    }
    setBookmarks(newArticulos);
    if (!id) return;
    toggleBookmark(authToken, id, newArticulos[index]);
  };

  const toggleCheckboxDeArticulo = (articulo: number) => {
    const newArticulosSeleccionados = [...articulosSeleccionados];
    const index = newArticulosSeleccionados.indexOf(articulo);
    if (index === -1) {
      newArticulosSeleccionados.push(articulo);
    } else {
      newArticulosSeleccionados.splice(index, 1);
    }
    setArticulosSeleccionados(newArticulosSeleccionados);
  };

  const handleSubmitNuevoDerogacion = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorArticulos(false);
    //if (!empresaSeleccionada || !textoDelDerogacion) return;
    if (articulosSeleccionados.length === 0) {
      setErrorArticulos(true);
      return;
    }
    const articulos = articulosSeleccionados.sort((a, b) => a - b);
    
    LocalStorage.getIdEmpresa().then((idDeLaEmpresaAlmacenado) => {
    
    if(idDeLaEmpresaAlmacenado != ''){
      
    const nuevoDerogacion = {
      empresa: idDeLaEmpresaAlmacenado,
      textoDelDerogacion,
      articulos,
    };
    setDerogacion((derogacion) => [...derogacion, nuevoDerogacion].sort(sortByArticulos));
    limpiarNuevoDerogacion();
  }else{
    alert("ERROR: Id de empresa vacio.");
  }
  });
  
  };

  const handleSubmitEditarDerogacion = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorArticulos(false);
    if (!empresaSeleccionada || !textoDelDerogacion || indiceDeDerogacionAEditar < 0) return;
    if (articulosSeleccionados.length === 0) {
      setErrorArticulos(true);
      return;
    }
    const derogacionSeleccionado = derogacion[indiceDeDerogacionAEditar];
    if (!derogacionSeleccionado) return;
    const { _id } = derogacionSeleccionado;
    const articulos = articulosSeleccionados.sort((a, b) => a - b);
    
   
    
    LocalStorage.getIdEmpresa().then((idDeLaEmpresaAlmacenado) => {
    
    if(idDeLaEmpresaAlmacenado != ''){
      
      const nuevoDerogacion = {
        empresa: idDeLaEmpresaAlmacenado,
        textoDelDerogacion,
        articulos,
      };
      if (!id || !_id) return;
      setLoadingCount((count) => count + 1);
      
    editarDerogacionDeRequisitoLegal(authToken, id, _id, nuevoDerogacion)
      .then(() => {
        const newDerogacion = [...derogacion];
        newDerogacion.splice(indiceDeDerogacionAEditar, 1, { ...nuevoDerogacion, _id });
        setDerogacion(newDerogacion);
        limpiarNuevoDerogacion();
        setIsEditing(false);
      })
      .finally(() => setLoadingCount((count) => count - 1));
      
      }else{
        alert("ERROR: Id de empresa vacio.");
      }
      });
      
  };

  const limpiarNuevoDerogacion = () => {
    setTextoDelDerogacion("");
    setBookmarks(
      bookmarks.map((articulo) => ({
        ...articulo,
      }))
    );
    setArticulosSeleccionados([]);
    setErrorArticulos(false);
    setIndiceDeDerogacionAEditar(-1);
  };

  const handleLimpiarNuevoDerogacion = () => {
    setDisabledOption(false);
    setEmpresaSeleccionada("");
    limpiarNuevoDerogacion();
  };

  const handleEditarDerogacionClick = (index: number) => {
    const { empresa, textoDelDerogacion, articulos } = derogacion[index];
    setEmpresaSeleccionada(empresa);
    setTextoDelDerogacion(textoDelDerogacion);
    setArticulosSeleccionados(articulos);
    setIndiceDeDerogacionAEditar(index);
    setIsEditing(true);
  };

  const handleCancelarEdicion = () => {
    setIsEditing(false);
    limpiarNuevoDerogacion();
  };

  const irAPaginaDelArticulo = (numeroDeArticulo: number) => {
    const articulo = bookmarks.find((a) => a.articulo === numeroDeArticulo);
    if (!articulo || !articulo.pagina) return;
    setCurrentPage(articulo.pagina);
  };

  const toggleEliminarDerogacionModal = () => setEliminarDerogacionModalIsOpen((isOpen) => !isOpen);

  const confirmarEliminarDerogacion = () => {
    if (!id || indiceDeDerogacionAEliminar === -1) return;
    const idDeDerogacion = derogacion[indiceDeDerogacionAEliminar]._id;
    if (idDeDerogacion) {
      setLoadingCount((count) => count + 1);
      eliminarDerogacionDeRequisitoLegal(authToken, id, idDeDerogacion)
        .then(eliminarDerogacionDeLaLista)
        .finally(() => setLoadingCount((count) => count - 1));
    } else {
      eliminarDerogacionDeLaLista();
    }
  };

  const eliminarDerogacionDeLaLista = () => {
    const newDerogacion = [...derogacion];
    newDerogacion.splice(indiceDeDerogacionAEliminar, 1);
    setDerogacion(newDerogacion);
    toggleEliminarDerogacionModal();
  };

  const handleEliminarDerogacionClick = (index: number) => {
    setIndiceDeDerogacionAEliminar(index);
    toggleEliminarDerogacionModal();
  };

  const limpiarIndiceDeDerogacionAEliminar = () => setIndiceDeDerogacionAEliminar(-1);

  const handleGuardarClick = () => {
    if (derogacion.length === 0) return;
    if (textoDelDerogacion || articulosSeleccionados.length > 0) return;
    confirmarGuardarDerogacion();
  };

  const confirmarGuardarDerogacion = () => {
    if (!authToken || !id) return;
    setLoadingCount((count) => count + 1);
    setIsSaving(true);
    guardarDerogacionDeRequisitoLegal(authToken, id, derogacion)
      .then(() =>
        navigate(procesarPath(PATHS.derogacionDeRequisitoLegal, id), { state: { prevHash } })
      )
      .catch(() => setIsSaving(false))
      .finally(() => setLoadingCount((count) => count - 1));
  };

  const cargarRequisitoLegal = useCallback(() => {
    if (!authToken || !id) return;
    setLoadingCount((count) => count + 1);
    obtenerRequisitoLegal(authToken, id)
      .then(({ data }) => {
        const requisitoLegal = new RequisitoLegal(data.requisitoLegal);
        setRequisitoLegal(requisitoLegal);
        const articulos: BookmarkDeArticulo[] = range(1, requisitoLegal.numeroDeArticulos).map(
          (articulo) => ({
            articulo,
            pagina: -1,
          })
        );
        requisitoLegal.bookmarks.forEach(({ articulo, pagina }) => {
          const index = articulos.findIndex((a) => a.articulo === articulo);
          if (index !== -1) {
            articulos[index].pagina = pagina;
          }
        });
        setBookmarks(articulos);
        const derogacion = data.derogacion.map(({ _id, derogacion, articulos }) => ({
          _id,
          textoDelDerogacion: derogacion,
          articulos,
          empresa: requisitoLegal.empresa,
        }));
        setDerogacion(derogacion.sort(sortByArticulos));
      })
      .finally(() => setLoadingCount((count) => count - 1));
  }, [authToken, id]);

  const cargarEmpresas = useCallback(() => {
    if (!authToken) return;
    setLoadingCount((count) => count + 1);
    fetchVinculacionesDelUsuario(authToken)
      .then(({ data }) => {
        const empresas = data.map(({ empresa }) => empresa as Empresa);
        setEmpresas(empresas);
        if (empresas.length === 1) {
          setEmpresaSeleccionada(empresas[0]._id);
        }
      })
      .finally(() => setLoadingCount((count) => count - 1));
  }, [authToken]);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.nuevoDerogacionDeRequisitoLegal.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(() => {
    cargarRequisitoLegal();
    cargarEmpresas();
  }, [cargarRequisitoLegal, cargarEmpresas]);

  useEffect(() => {
    setErrorArticulos(false);
  }, [articulosSeleccionados, textoDelDerogacion, empresaSeleccionada]);

  useEffect(() => {
    
   
    if (derogacionId) {
      const index = derogacion.findIndex(({ _id }) => _id === derogacionId);
      if (index !== -1) {
        navigate(procesarPath(PATHS.nuevoDerogacionDeRequisitoLegal, id || ""), {
          state: { prevHash },
        });
        const { empresa, textoDelDerogacion, articulos } = derogacion[index];
        setEmpresaSeleccionada(empresa);
        setTextoDelDerogacion(textoDelDerogacion);
        setArticulosSeleccionados(articulos);
        setIndiceDeDerogacionAEditar(index);
        setIsEditing(true);
      }
    }
  }, [derogacion, derogacionId, prevHash, id, navigate]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCount > 0} />
      <Breadcrumbs
        items={[
          PATHS.usuarioHome,
          {
            ...PATHS.requisitosLegales,
            to: { pathname: PATHS.requisitosLegales.to, hash: prevHash },
          },
          {
            ...PATHS.derogacionDeRequisitoLegal,
            to: { pathname: procesarPath(PATHS.derogacionDeRequisitoLegal, id || "") },
            state: { prevHash },
          },
        ]}
      />
      
      <ComponentCard
        title="Derogación de Requisito Legales"
        btnRight ={
         <Button
           type="button"
           color="primary"
           className="ms-auto"
           disabled={derogacion.length === 0 || isEditing}
           onClick={handleGuardarClick}
           outline
         >
          <FaSave /> Guardar
         </Button>
       }
      >
      
      
      <Row className="mb-3">
        <Col className="text-center">
          <Button
            type="button"
            color="link"
            size="sm"
            className="p-0 text-center"
            onClick={() =>
              !!requisitoLegal ? abrirEnlaceEnNuevaPestaña(requisitoLegal.filepath) : ""
            }
            title={!!requisitoLegal ? obtenerURLCompleta(requisitoLegal.filepath) : ""}
            outline
          >
            {requisitoLegal?.nombre}
          </Button>
        </Col>
      </Row>
      <Row className="row-cols-1 row-cols-md-2">
        <Col>
          <Row className="justify-content-center align-items-stretch mb-3">
            <Col>
              <Collapse isOpen={numPages === 0}>
                <Row>
                  <Col className="text-center">
                    <span>Cargando...</span>
                  </Col>
                </Row>
              </Collapse>
              <Collapse isOpen={numPages > 0} onEntered={() => setFinishedExpanding(true)}>
                <Row className="mb-3">
                  <Col>
                    <DocumentWrapper>
                      <DocumentContainer ref={setContainerRef}>
                        <Document
                          file={
                            !!requisitoLegal ? procesarLinkDeDocumento(requisitoLegal.filepath) : ""
                          }
                          onLoadSuccess={handleDocumentLoadSuccess}
                          onLoadStart={() => setLoadingCount((count) => count + 1)}
                          onLoad={() => setLoadingCount((count) => count - 1)}
                        >
                          <Page
                            pageNumber={currentPage}
                            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                            scale={scale}
                          />
                        </Document>
                      </DocumentContainer>
                    </DocumentWrapper>
                  </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                  <Col xs="auto">
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      className="text-nowrap"
                      disabled={currentPage <= 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                      outline
                    >
                      <FaChevronLeft />
                    </Button>
                  </Col>
                  <Col xs="auto">
                    Página {currentPage} de {numPages}
                  </Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      className="text-nowrap"
                      disabled={currentPage >= numPages}
                      onClick={() => setCurrentPage(currentPage + 1)}
                      outline
                    >
                      <FaChevronRight />
                    </Button>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
        </Col>
        <DerogacionCol
          className="mb-5"
          style={{
            maxHeight: finishedExpanding ? (containerRef?.clientHeight || 200) + 54 : "initial",
          }}
        >
          <Row>
            <Col xs={12} sm="auto">
              <Row className="justify-content-center mb-3">
                <Col xs="auto">
                  <Row className="mb-3">
                    <Col className="text-center">
                      <strong>Artículos</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup>
                        {bookmarks.map(({ articulo, pagina }) => (
                          <ListGroupItem key={articulo} className="py-0 px-1">
                            <Row className="align-items-center gx-1">
                              <Col xs="auto">
                                <ImageButton
                                  type="button"
                                  title={pagina > 0 ? "Desmarcar" : "Marcar"}
                                  onClick={() => handleBookmarkClick(articulo)}
                                >
                                  {pagina > 0 ? <FaBookmark /> : <FaRegBookmark />}
                                </ImageButton>
                              </Col>
                              <Col xs="auto">
                                <ArticuloContainer
                                  className={classNames({ active: pagina === currentPage })}
                                  onClick={pagina > 0 ? () => setCurrentPage(pagina) : undefined}
                                >
                                  {articulo}
                                </ArticuloContainer>
                              </Col>
                              <Col xs="auto">
                                <Checkbox
                                  checked={articulosSeleccionados.includes(articulo)}
                                  toggle={() => toggleCheckboxDeArticulo(articulo)}
                                />
                              </Col>
                            </Row>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                      <Fade in={errorArticulos}>
                        <div className="position-relative">
                          <FormFeedback
                            tooltip
                            style={{
                              display: "block",
                              whiteSpace: "nowrap",
                              overflow: "visible",
                              maxWidth: "initial",
                              transform: "translate(-50%)",
                              marginLeft: "50%",
                            }}
                          >
                            Seleccione al menos un artículo
                          </FormFeedback>
                        </div>
                      </Fade>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Card body>
                    <Form
                      onSubmit={isEditing ? handleSubmitEditarDerogacion : handleSubmitNuevoDerogacion}
                      onReset={handleLimpiarNuevoDerogacion}
                    >
                      <Row>
                        <Col>
                          <h5 className="text-center">
                            {isEditing ? "Editar derogación" : "Registrar nueva derogación"}
                          </h5>
                        </Col>
                      </Row>
                     
                      <Row className="align-items-center mt-3">
                        <Col>
                          <Input
                            type="textarea"
                            placeholder="Derogación..."
                            required
                            value={textoDelDerogacion}
                            onChange={(e) => setTextoDelDerogacion(e.target.value)}
                            style={{ height: 100 }}
                          />
                        </Col>
                        <Col xs="auto">
                          <Row>
                            <Col xs="auto">
                              <Button type="submit" size="sm" color="primary" outline>
                                {isEditing ? <FaSave size={20} /> : <FaPlus size={20} />}
                              </Button>
                            </Col>
                          </Row>
                          <SeparatorRow height={10} />
                          <Row>
                            <Col xs="auto">
                              {isEditing ? (
                                <Button
                                  type="button"
                                  color="secondary"
                                  onClick={handleCancelarEdicion}
                                  size="sm"
                                  outline
                                >
                                  <FaRegTimesCircle size={20} />
                                </Button>
                              ) : (
                                <Button type="reset" color="warning" size="sm" outline>
                                  <GiBroom size={20} />
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </Col>
              </Row>
              <Collapse isOpen={derogacion.length > 0 && !isEditing}>
                <Row className="mt-3">
                  <Col>
                    <Card body>
                      <Row className="mb-n3">
                        <Col>
                          <h5 className="text-center">Derogaciones registradas</h5>
                        </Col>
                      </Row>
                      {derogacion.map(({ textoDelDerogacion, articulos }, index) => (
                        <Row key={textoDelDerogacion} className="align-items-center mt-3">
                          <Col>
                            <Card body>
                              <Row>
                                <Col>
                                  <p>{textoDelDerogacion}</p>
                                </Col>
                              </Row>
                              <Row className="gx-1">
                                {articulos.map((articulo) => (
                                  <Col xs="auto" key={articulo}>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      className="px-2 py-0"
                                      onClick={() => irAPaginaDelArticulo(articulo)}
                                      outline
                                    >
                                      {articulo}
                                    </Button>
                                  </Col>
                                ))}
                              </Row>
                            </Card>
                          </Col>
                          <Col xs="auto">
                            <Row>
                              <Col xs="auto">
                                <Button
                                  type="button"
                                  color="primary"
                                  size="sm"
                                  onClick={() => handleEditarDerogacionClick(index)}
                                  outline
                                >
                                  <FaRegEdit size={20} />
                                </Button>
                              </Col>
                            </Row>
                            <SeparatorRow height={10} />
                            <Row>
                              <Col xs="auto">
                                <Button
                                  type="button"
                                  color="danger"
                                  size="sm"
                                  onClick={() => handleEliminarDerogacionClick(index)}
                                  outline
                                >
                                  <FaMinus size={20} />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
        </DerogacionCol>
      </Row>
      
      </ComponentCard>
      
 
      <Modal
        isOpen={eliminarDerogacionModalIsOpen}
        toggle={toggleEliminarDerogacionModal}
        onClosed={limpiarIndiceDeDerogacionAEliminar}
      >
        <ModalHeader toggle={toggleEliminarDerogacionModal}>Eliminar Derogación</ModalHeader>
        <ModalBody>
          <p>¿Está seguro que desea eliminar este derogación?</p>
        </ModalBody>
        <ModalFooter>
         
          <Button color="secondary" outline onClick={toggleEliminarDerogacionModal}>
            Cancelar
          </Button>
          
          <Button color="danger" outline onClick={confirmarEliminarDerogacion}>
            Eliminar
          </Button>
          
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AgregarDerogacionDeRequisitoLegal;
