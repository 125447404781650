import { useContext, FC, useEffect, useState, useCallback } from "react";
import {
  Button,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
  Table,
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FaLinkSlash, FaEllipsisVertical } from "react-icons/fa6";
import { FaHistory, FaEdit, FaArchive, FaFile, FaPlus, FaFileUpload } from "react-icons/fa";
import styled from "styled-components";
import { Context } from "../../../Context";
import {
  CustomTable,
  IconsContainer,
  TableCaption,
  TableContainer,
  TableMessage,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../../utilities/utils";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  Documento,
  DocumentoBase,
  FrecuenciaDeActualizacion,
  TiposDeDocumento,
  textoDeLaFrecuenciaDeActualizacion,
  textoDePeriodoDeActualizacion,
  textoDelTipoDeDocumento,
} from "../../../../models/Documento";
import {
  getStatus,
  archivarDocumento,
  listDocumentos,
  listDocumentosSugeridos,
  updateDocumento,
} from "../../../../services/usuario-api-client";
import { ArchivoDeDocumento } from "../../../../models/ArchivoDeDocumento";
import AgregarActualizacionForm from "./AgregarActualizacionForm";
import HistoricoDeDocumentos from "./HistoricoDeDocumentos";
import DocumentoForm from "./DocumentoForm";
import ComponentCard from '../../../components/ComponentCard';

import { FaArrowAltCircleRight, FaUserTag, FaDownload, FaLayerGroup, FaUserCheck, FaBuilding, FaUsers, FaBookmark, FaTools, FaCar, FaCapsules } from "react-icons/fa";

import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";
import { Cargo } from "../../../../models/Cargo";
import { DocumentoSugerido } from "../../../../models/DocumentoSugerido";


const CustomNavLink = styled(NavLink)`
  height: 66px;
  white-space: nowrap;
`;

const AGREGAR_DOCUMENTO_INTERNO_FORM_ID = "agregar-documento-interno-form";
const MODIFICAR_DOCUMENTO_FORM_ID = "modificar-documento-form";

const tabs = [
  {
    tipoDeDocumento: "todosLosDocumentos",
    texto: (
      <>
      <div className="d-none d-lg-block">
        <div className="d-flex align-items-center justify-content-center">
          <FaBookmark style={{ fontSize: "26px" }} />
          <span className="ms-2">Todos</span>
        </div>
      </div>
      <div className="d-md-block d-lg-none">
        <div className="d-flex align-items-center justify-content-center">
          <FaBookmark style={{ fontSize: "26px" }} />
          <span className="ms-2" style={{ fontSize: "12px" }}>Todos</span>
        </div>
      </div>
      </>
    ),
    hash: "#documentos",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionGeneralDeLaEmpresa,
    texto: (
      <>
      <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaBuilding /> </Col><Col xs={12} md={12} lg={10}>Doc. de
        <br />
        la Empresa</Col></Row></div>
      <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaBuilding /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Doc. de
        <br />
        la Empresa</Col></Row></div>
      </>
    ),
    hash: "#empresa",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeUsuariosDirectos,
    texto: <><div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaUserTag /> </Col><Col xs={12} md={12} lg={10}>Personal</Col></Row></div>
    <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaUserTag /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Personal</Col></Row></div></>,
    hash: "#personal",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa,
    texto: (
      <>
      <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaTools /> </Col><Col xs={12} md={12} lg={10}>Equipos y <br />
        Herramientas</Col></Row></div>
      <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaTools /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Equipos y <br />
        Herramientas</Col></Row></div>
      </>
    ),
    hash: "#equipos-herramientas",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa,
    texto: <> <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaCar /> </Col><Col xs={12} md={12} lg={10}>Vehículos</Col></Row></div>
    <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaCar /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Vehículos</Col></Row></div> </>,
    hash: "#vehiculos",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeSustanciasQuimicas,
    texto: (
      <>
        <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaCapsules /> </Col><Col xs={12} md={12} lg={10}>Sustancias
                  <br />
          Químicas</Col></Row></div>
        <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaCapsules /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Sustancias
                  <br />
          Químicas</Col></Row></div>
      </>
    ),
    hash: "#sustancias-quimicas",
  },
];

const GestionDocumentalInterna: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [documentosInternos, setDocumentosInternos] = useState<Documento[]>([]);
  const [documentosInternosFiltrados, setDocumentosInternosFiltrados] = useState<Documento[]>([]);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState<Documento | null>(null);
  const [activeTab, setActiveTab] = useState<string>(tabs[0].tipoDeDocumento);
  const [actualizacionModalIsOpen, setActualizacionModalIsOpen] = useState<boolean>(false);
  const [historicoModalIsOpen, setHistoricoModalIsOpen] = useState<boolean>(false);
  const [modificarModalIsOpen, setModificarModalIsOpen] = useState<boolean>(false);
  const [confirmarArchivarModalIsOpen, setConfirmarArchivarModalIsOpen] = useState<boolean>(false);
  const [personasResponsablesModalIsOpen, setPersonasResponsablesModalIsOpen] =
    useState<boolean>(false);
  const [documentosSugeridos, setDocumentosSugeridos] = useState<DocumentoSugerido[]>([]);
  const [sugeridosModalIsOpen, setSugeridosModalIsOpen] = useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const [modificarDocumentoError, setModificarDocumentoError] = useState<string>("");
    
  const [idVincul, setIdVincul] = useState<string>("");
    
    
  const [UserAdmin, setUserAdmin] = useState<string>("regular");
  const [UserCargo, setUserCargo] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
    

  const cargarDocumentos = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    listDocumentos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setDocumentosInternos(data.map((documento) => new Documento(documento)));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const cargarDocumentosSugeridos = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    listDocumentosSugeridos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => setDocumentosSugeridos(data))
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const toggleSugeridosModal = () => setSugeridosModalIsOpen((prev) => !prev);

  const handleImportarDocumentosSugeridosClick = () => {
    cargarDocumentosSugeridos();
    toggleSugeridosModal();
  };

  const listadoDePersonasResponsables = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setPersonasResponsablesModalIsOpen(true);
    setDocumentoSeleccionado(documento);
  };

  const actualizarDocumento = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setDocumentoSeleccionado(documento);
    setActualizacionModalIsOpen(true);
  };

  const handleSuccessActualizacion = () => {
    setActualizacionModalIsOpen(false);
    cargarDocumentos();
  };

  const historicoDelDocumento = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setHistoricoModalIsOpen(true);
    setDocumentoSeleccionado(documento);
  };

  const modificarDocumento = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setDocumentoSeleccionado(documento);
    setModificarModalIsOpen(true);
  };

  const handleModificarDocumentoSubmit = (body: DocumentoBase) => {
    if (!authToken || !documentoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    setModificarDocumentoError("");
    updateDocumento(authToken, documentoSeleccionado._id, body)
      .then(() => {
        setModificarModalIsOpen(false);
        cargarDocumentos();
      })
      .catch((err) => {
        console.log(err);
        setModificarDocumentoError("Error al modificar el documento.");
        setLoadingCounter((prev) => prev - 1);
      });
  };

  const handleArchivarClick = (idDelDocumento: string) => {
   
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setDocumentoSeleccionado(documento);
    setConfirmarArchivarModalIsOpen(true);
  };

  const confirmarArchivar = () => {
    if (!authToken || !documentoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    archivarDocumento(authToken, documentoSeleccionado._id)
      .then(() => {
        setConfirmarArchivarModalIsOpen(false);
        cargarDocumentos();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const limpiarSeleccion = () => {
    
    setDocumentoSeleccionado(null);
    setModificarDocumentoError("");
    
    cargarDocumentos();
    cargarDocumentosSugeridos();
    
    if (activeTab === "todosLosDocumentos") {
      setDocumentosInternosFiltrados(documentosInternos);
    } else {
      setDocumentosInternosFiltrados(
        documentosInternos.filter(({ tipoDeDocumento }) => tipoDeDocumento === activeTab)
      );
    }
    
  };

  const handleTabChange = (tipoDeDocumento: string) => {
    setActiveTab(tipoDeDocumento);
  };

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.gestionDocumentalInterna.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(cargarDocumentos, [cargarDocumentos]);

  useEffect(() => {
    if (activeTab === "todosLosDocumentos") {
      setDocumentosInternosFiltrados(documentosInternos);
    } else {
      setDocumentosInternosFiltrados(
        documentosInternos.filter(({ tipoDeDocumento }) => tipoDeDocumento === activeTab)
      );
    }
  }, [activeTab, documentosInternos]);
  
  
  useEffect(() => {
    if (!authToken) return;
  
    setIsLoading(true);
  
    getStatus(authToken)
      .then(({ data }) => {
        
        const { vinculaciones } = data;
        const currentVinculacion = vinculaciones.find(
          ({ empresa }) =>
            typeof empresa === "string"
              ? empresa === idDeLaEmpresaSeleccionada
              : empresa._id === idDeLaEmpresaSeleccionada
        );
  
        if (currentVinculacion) {
          const { rol, cargo } = currentVinculacion;
  
          setIdVincul(currentVinculacion._id);
          // Establecer el rol y cargo
          setUserAdmin(rol);
          // Handle the cargo field
           if (cargo) {
              if (Array.isArray(cargo)) {
                // If cargo is an array
                const cargoNames = cargo.map((item) => {
                  if (typeof item === "string") {
                    return item;
                  }
                  return ""; // Fallback in case of unexpected structure
                }).filter(Boolean); // Remove empty or invalid items
                setUserCargo(cargoNames); // Directly set the array of cargo names
              }
            }
        }
      })
      .catch((error) => {
        console.error("Error obteniendo los datos:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authToken]);
  

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
        <Breadcrumbs items={[PATHS.usuarioHome, PATHS.gestionDocumental]} />
      <ComponentCard
         title="Documentos Internos"
         btnRight= {
           <UncontrolledDropdown group>
             <LinkContainer to={PATHS.nuevoDocumentoInterno.to}>
              <Button type="button"
               color="primary"
               className="ms-auto position-relative" outline caret >
                 <IconsContainer>
                   <FaFile textAnchor="middle" alignmentBaseline="middle" />
                   <FaPlus
                     textAnchor="middle"
                     alignmentBaseline="middle"
                     style={{
                       fontSize: ".6em",
                       position: "absolute",
                       right: "-8px",
                       top: "4px",
                     }}
                   />
                 </IconsContainer>
               </Button>
             </LinkContainer>
             <DropdownToggle caret color="primary" outline size="sm" />
             <DropdownMenu>
               <DropdownItem header>
                 Puedes agregar un documento
                 <br />o importar uno sugerido
               </DropdownItem>
               <LinkContainer to={PATHS.nuevoDocumentoInterno.to}>
                 <DropdownItem>Agregar documento interno</DropdownItem>
               </LinkContainer>
               <DropdownItem onClick={handleImportarDocumentosSugeridosClick}>
                 Importar documento sugerido
               </DropdownItem>
             </DropdownMenu>
           </UncontrolledDropdown>
         }
        >
      
      <Row className="mb-3">
       
        <Col>
          <TableContainer>
            <Nav fill
            pills className="flex-nowrap">
              {tabs.map(({ tipoDeDocumento, texto, hash }) => (
                <NavItem key={tipoDeDocumento}>
                  <CustomNavLink
                    href={hash}
                    onClick={() => handleTabChange(tipoDeDocumento)}
                    active={activeTab === tipoDeDocumento}
                    className="align-items-center text-center h-100"
                  >
                    {texto}
                  </CustomNavLink>
                </NavItem>
              ))}
            </Nav>
          </TableContainer>
        </Col>
      </Row>
 
     <Table className="no-wrap mt-3 align-middle" style={{"marginBottom":"140px"}} responsive borderless>
       
          <thead>
            <tr>
              <th>Acciones</th>
              <th>Nombre del Documento</th>
              <th>Plan de Acción</th>
              <th>Requisito Legal</th>
              <th>Tipo de Documento</th>
              <th>Responsable(s)</th>
              <th>Frecuencia de Actualización</th>
              <th>Fecha de la Última Actualización</th>
              <th>Fecha de Vencimiento</th>
              <th>Archivos</th>
            </tr>
          </thead>
          <tbody>
            {documentosInternosFiltrados.map(
              (
                {
                  _id,
                  nombreDelDocumento,
                  planDeAccion,
                  requisitoLegal,
                  tipoDeDocumento,
                  personasResponsables,
                  creadoPor,
                  frecuenciaDeActualizacion,
                  valorDeLaFrecuenciaDeActualizacion,
                  unidadDeLaFrecuenciaDeActualizacion,
                  archivos,
                },
                index
              ) => (
                <tr key={_id}>
                  <td>
                    
                    <UncontrolledDropdown group>
                    
                       <DropdownToggle caret color="primary" outline size="sm">
                         <FaEllipsisVertical />
                       </DropdownToggle>
                       <DropdownMenu>
                      
                         { (creadoPor === idVincul || personasResponsables.find((item) => item._id === idVincul)) && (
                          <DropdownItem className="py-2"  onClick={() => actualizarDocumento(_id)}>
                              <FaFileUpload size={15} /> Actualizar
                          </DropdownItem>
                          ) }
                          
                          <DropdownItem  className="py-2"  onClick={() => historicoDelDocumento(_id)}>
                          <FaHistory size={15} /> Historial
                          </DropdownItem>
                         
                         { (creadoPor === idVincul || personasResponsables.find((item) => item._id === idVincul)) && (
                           
                           <DropdownItem  className="py-2"  onClick={() => modificarDocumento(_id)}>
                           <FaEdit size={15} /> Editar
                           </DropdownItem>
                          
                          )}
                          
                          { (creadoPor === idVincul || personasResponsables.find((item) => item._id === idVincul)) && (
                           
                           <DropdownItem  className="py-2 text-danger"  onClick={() => handleArchivarClick(_id)}>
                           <FaArchive size={15} /> Archivar
                           </DropdownItem>
                          
                          )}
                           
                         
                       </DropdownMenu>
                     </UncontrolledDropdown>
            
                  </td>
                  <td>{nombreDelDocumento}</td>
                  <td>
                    {planDeAccion ? (
                      <a
                        href={`/usuario/planes/${planDeAccion._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {planDeAccion.descripcion}
                      </a>
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                  <td>
                    {requisitoLegal ? (
                      <a href={requisitoLegal.filepath} target="_blank" rel="noreferrer">
                        {requisitoLegal.nombre}
                      </a>
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                  <td>{textoDelTipoDeDocumento(tipoDeDocumento)}</td>
                  <td>
                    {personasResponsables.length === 0 ? (
                      <span>Ninguno asignado</span>
                    ) : (
                      <>
                        <ul className="mb-0 ps-3">
                          {personasResponsables
                            .filter((_, index) => index < 3)
                            .map((persona) => (
                              <li key={persona.toString()}>
                                {typeof persona === "string" ? persona : persona.nombreCompleto}
                              </li>
                            ))}
                          {personasResponsables.length === 4 && <li>1 persona más</li>}
                          {personasResponsables.length > 4 && (
                            <li>{personasResponsables.length - 3} personas más</li>
                          )}
                        </ul>
                        {personasResponsables.length > 3 && (
                          <Button
                            color="link"
                            size="sm"
                            className="p-0 small"
                            onClick={() => listadoDePersonasResponsables(_id)}
                          >
                            Ver todos
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra &&
                    !!valorDeLaFrecuenciaDeActualizacion &&
                    !!unidadDeLaFrecuenciaDeActualizacion
                      ? textoDePeriodoDeActualizacion(
                          valorDeLaFrecuenciaDeActualizacion,
                          unidadDeLaFrecuenciaDeActualizacion
                        )
                      : textoDeLaFrecuenciaDeActualizacion(frecuenciaDeActualizacion)}
                  </td>
                  <td>
                    {archivos && archivos.length > 0
                      ? shortDateToString((archivos as ArchivoDeDocumento[])[0].fechaDeCarga)
                      : "N/A"}
                  </td>
                 <td>
                   {archivos && archivos.length > 0
                     ? shortDateToString(
                         archivos
                           .map((archivo) => new Date(archivo.fechaDeVencimiento)) // Convertir fechas
                           .sort((a, b) => a.getTime() - b.getTime())[0] // Ordenar por tiempo y tomar la más cercana
                       )
                     : "N/A"}
                 </td>
                  <td>
                    {!!archivos && archivos.length > 0 ? (
                      <>
                        <ul className="mb-0 ps-3">
                          {(archivos as ArchivoDeDocumento[])
                            .filter((_, index) => index < 3)
                            .map(({ _id, nombre, filepath }) => (
                              <li key={_id}>
                                <a
                                  href={procesarLinkDeDocumento(filepath)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {nombre}
                                </a>
                              </li>
                            ))}
                          {archivos.length === 4 && <li>1 archivo más</li>}
                          {archivos.length > 4 && <li>{archivos.length - 3} archivos más</li>}
                        </ul>
                        {archivos.length > 3 && (
                          <Button
                            color="link"
                            size="sm"
                            className="p-0 small"
                            onClick={() => historicoDelDocumento(_id)}
                          >
                            Ver todos
                          </Button>
                        )}
                      </>
                    ) : (
                      <p className="text-center mb-0">No hay archivos registrados</p>
                    )}
                  </td>
                  
                </tr>
              )
            )}
            {documentosInternosFiltrados.length === 0 && (
              <tr>
                <td colSpan={11} className="text-center">
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">
                        {activeTab === "todosLosDocumentos"
                          ? "No hay documentos internos registrados."
                          : "No hay documentos internos de este tipo registrados."}
                      </Col>
                    </Row>
                    <Row className="justify-content-center gx-0 mt-3">
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="primary"
                          onClick={handleImportarDocumentosSugeridosClick}
                        >
                          Importar documento sugerido
                        </Button>
                      </Col>
                    </Row>
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
          </Table>
      
      </ComponentCard>
      
      <Modal isOpen={sugeridosModalIsOpen} toggle={toggleSugeridosModal}>
        <ModalHeader toggle={toggleSugeridosModal}>Importar documento sugerido</ModalHeader>
        <ModalBody>
          <ListGroup>
            {documentosSugeridos.map((documentoSugerido) => (
              <LinkContainer
                key={documentoSugerido._id}
                to={PATHS.nuevoDocumentoInterno.to}
                state={{ documentoSugerido }}
              >
                <ListGroupItem action tag="button">
                  <Row>
                    <Col>{documentoSugerido.nombreDelDocumento}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <small className="text-muted">
                        {textoDelTipoDeDocumento(documentoSugerido.tipoDeDocumento)}
                      </small>
                    </Col>
                  </Row>
                </ListGroupItem>
              </LinkContainer>
            ))}
          </ListGroup>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={actualizacionModalIsOpen}
        toggle={() => setActualizacionModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setActualizacionModalIsOpen(false)}>
          Actualizar {documentoSeleccionado?.nombreDelDocumento}
        </ModalHeader>
        <ModalBody>
          <AgregarActualizacionForm
            formId={AGREGAR_DOCUMENTO_INTERNO_FORM_ID}
            documento={documentoSeleccionado as Documento}
            onSuccess={handleSuccessActualizacion}
          />
        </ModalBody>
        <ModalFooter>
         
          <Button color="danger" outline onClick={() => setActualizacionModalIsOpen(false)}>
            Cancelar
          </Button>
          
          <Button type="submit" outline color="primary" form={AGREGAR_DOCUMENTO_INTERNO_FORM_ID}>
            Agregar
          </Button>
          
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={historicoModalIsOpen}
        toggle={() => setHistoricoModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setHistoricoModalIsOpen(false)}>
        Historico de <b>{documentoSeleccionado?.nombreDelDocumento}</b>
        </ModalHeader>
        <ModalBody>
          <HistoricoDeDocumentos documento={documentoSeleccionado as Documento} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modificarModalIsOpen}
        toggle={() => setModificarModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setModificarModalIsOpen(false)}>
          Modificar {documentoSeleccionado?.nombreDelDocumento}
        </ModalHeader>
        <ModalBody>
          <DocumentoForm
            formId={MODIFICAR_DOCUMENTO_FORM_ID}
            documento={documentoSeleccionado as Documento}
            onSubmit={handleModificarDocumentoSubmit}
          />
          <Collapse isOpen={!!modificarDocumentoError}>
            <p className="text-danger">{modificarDocumentoError}</p>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          
          <Button color="danger" outline onClick={() => setModificarModalIsOpen(false)}>
            Cancelar
          </Button>
          
          <Button type="submit" outline color="primary" form={MODIFICAR_DOCUMENTO_FORM_ID}>
            Modificar
          </Button>
          
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarArchivarModalIsOpen}
        toggle={() => setConfirmarArchivarModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setConfirmarArchivarModalIsOpen(false)}>
          Confirmar eliminación
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea archivar el documento{" "}
            <b>{documentoSeleccionado?.nombreDelDocumento}</b>?
          </p>
        </ModalBody>
        <ModalFooter>
         
          <Button color="primary" outline onClick={() => setConfirmarArchivarModalIsOpen(false)}>
            Cancelar
          </Button>
          
          <Button color="danger" outline onClick={confirmarArchivar}>
            Archivar
          </Button>
          
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={personasResponsablesModalIsOpen}
        toggle={() => setPersonasResponsablesModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setPersonasResponsablesModalIsOpen(false)}>
          {documentoSeleccionado?.nombreDelDocumento}
        </ModalHeader>
        <ModalBody>
          <TableContainer>
            <TableCaption>Personas responsables</TableCaption>
            <CustomTable>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Cargo</th>
                </tr>
              </thead>
              <tbody>
                {documentoSeleccionado?.personasResponsables.map((persona, index) =>
                  typeof persona === "string" ? (
                    <tr key={persona}>
                      <td>{index + 1}</td>
                      <td>{persona}</td>
                      <td>N/A</td>
                    </tr>
                  ) : (
                    <tr key={persona._id}>
                      <td>{index + 1}</td>
                      <td>{persona.nombreCompleto}</td>
                      <td>
                        {Array.isArray(persona.cargo) && persona.cargo.length > 0 ? (
                          persona.cargo.map((c, index) => (
                            <span key={index}>
                              {typeof c === "string" ? c : c.toString()}
                            </span>
                          ))
                        ) : (
                          "No tiene cargos"
                        )}
                      </td>
                      
                    </tr>
                  )
                )}
                {documentoSeleccionado?.personasResponsables.length === 0 && (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No hay personas responsables asignadas.
                    </td>
                  </tr>
                )}
              </tbody>
            </CustomTable>
          </TableContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default GestionDocumentalInterna;
