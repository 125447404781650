import { FC } from "react";
import { Link, To } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import styled from "styled-components";

const BreadcrumbWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

const BreadcrumbContainer = styled.div`
  width: max-content;
  .breadcrumb {
    flex-wrap: nowrap;
    .breadcrumb-item {
      white-space: nowrap;
      font-size: 10px;
      user-select: none;
    }
  }
`;

interface Path {
  name: string;
  to: To;
  maskName?: string;
  maskValue?: string;
  state?: any;
}

interface BreadcrumbsProps {
  items: Path[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ items }) => {
  const processedItems = items.map((item) => {
    if (!!item.maskName && !!item.maskValue && typeof item.to === "string") {
      const to = item.to.replace(`:${item.maskName}`, item.maskValue);
      return {
        ...item,
        to,
      };
    }
    return item;
  });
  return (
    
    <>
        <Breadcrumb>
          {processedItems.map(({ name, to, state }, index) => (
            <BreadcrumbItem key={index} className="text-decoration-none">
              <Link to={to} state={state}>
                {name}
              </Link>
            </BreadcrumbItem>
          ))}
          <BreadcrumbItem active />
        </Breadcrumb>
      </>
  );
};

export default Breadcrumbs;
