import { PATHS, procesarPath } from "../utilities/Paths";
import { Accion, AccionResponse } from "./Accion";
import { Curso, CursoResponse } from "./Curso";
import { Documento, DocumentoResponse } from "./Documento";
import { DocumentoDelContrato, DocumentoDelContratoResponse } from "./DocumentoDelContrato";
import { PlanDeAccion, PlanDeAccionResponse } from "./PlanDeAccion";
import { RequisitoLegal, RequisitoLegalResponse } from "./RequisitoLegal";

export enum Modulos {
  Formacion = "formacion",
  RequisitosLegales = "requisitosLegales",
  GestionDocumental = "gestionDocumental",
  InvestigacionDeIncidentes = "investigacionDeIncidentes",
  PlanesDeAccion = "planesDeAccion",
  MIPER = "miper",
  Contexto = "contexto",
  IndicadoresDeGestion = "indicadoresDeGestion",
}

export enum Submodulos {
  GestionDocumentalInterna = "gestionDocumentalInterna",
  AdministracionDeContratos = "administracionDeContratos",
}

export enum TiposDeVinculacion {
  Curso = "Curso",
  RequisitoLegal = "RequisitoLegal",
  Documento = "Documento",
  DocumentoDelContrato = "DocumentoDelContrato",
  Incidente = "Incidente",
  PlanDeAccion = "PlanDeAccion",
  Accion = "Accion",
  Actividad = "Actividad",
}

type Evidencia = string | Curso | Documento | DocumentoDelContrato | PlanDeAccion | Accion;

type EvidenciaResponse =
  | string
  | CursoResponse
  | DocumentoResponse
  | DocumentoDelContratoResponse
  | PlanDeAccionResponse
  | AccionResponse;

export interface DerogacionDeRequisitoLegalBase {
  derogacion: string;
  accionesDeCumplimiento?: string[];
  articulos: number[];
  estado?: string;
  seguimiento?: string;
  requisitoLegal: string | RequisitoLegalResponse | RequisitoLegal;
  moduloVinculado?: Modulos | Submodulos;
  evidencia?: Evidencia | EvidenciaResponse;
  tipoDeEvidencia?: TiposDeVinculacion;
}

export interface DerogacionDeRequisitoLegal extends DerogacionDeRequisitoLegalBase {
  _id: string;
  requisitoLegal: string | RequisitoLegal;
  evidencia?: Evidencia;
}

export interface DerogacionDeRequisitoLegalResponse extends DerogacionDeRequisitoLegalBase {
  _id: string;
  requisitoLegal: string | RequisitoLegalResponse;
  evidencia?: EvidenciaResponse;
}

export class DerogacionDeRequisitoLegal implements DerogacionDeRequisitoLegal {
  constructor({
    _id,
    derogacion,
    accionesDeCumplimiento,
    articulos,
    estado,
    seguimiento,
    requisitoLegal,
    moduloVinculado,
    evidencia,
    tipoDeEvidencia,
  }: DerogacionDeRequisitoLegalResponse) {
    this._id = _id;
    this.derogacion = derogacion;
    this.accionesDeCumplimiento = accionesDeCumplimiento;
    this.articulos = articulos;
    this.estado = estado;
    this.seguimiento = seguimiento;
    if (typeof requisitoLegal === "string") {
      this.requisitoLegal = requisitoLegal;
    } else {
      this.requisitoLegal = new RequisitoLegal(requisitoLegal);
    }
    this.moduloVinculado = moduloVinculado;
    if (!!evidencia) {
      if (typeof evidencia === "string") {
        this.evidencia = evidencia;
      } else {
        switch (tipoDeEvidencia) {
          case TiposDeVinculacion.Curso:
            this.evidencia = new Curso(evidencia as CursoResponse);
            break;
          case TiposDeVinculacion.Documento:
            this.evidencia = new Documento(evidencia as DocumentoResponse);
            break;
          case TiposDeVinculacion.DocumentoDelContrato:
            this.evidencia = new DocumentoDelContrato(evidencia as DocumentoDelContratoResponse);
            break;
          case TiposDeVinculacion.PlanDeAccion:
            this.evidencia = new PlanDeAccion(evidencia as PlanDeAccionResponse);
            break;
          case TiposDeVinculacion.Accion:
            this.evidencia = new Accion(evidencia as AccionResponse);
            break;
        }
      }
    }
    this.tipoDeEvidencia = tipoDeEvidencia;
  }

  get nombreDeLaEvidencia() {
    return this.evidencia instanceof Curso
      ? this.evidencia.tema
      : this.evidencia instanceof Documento
      ? this.evidencia.nombreDelDocumento
      : this.evidencia instanceof DocumentoDelContrato
      ? this.evidencia.nombre
      : this.evidencia instanceof PlanDeAccion
      ? this.evidencia.descripcion
      : this.evidencia instanceof Accion
      ? this.evidencia.accion
      : this.evidencia;
  }

  get enlaceDeEvidencia() {
    return this.evidencia instanceof Curso
      ? procesarPath(PATHS.curso, this.evidencia._id)
      : this.evidencia instanceof Documento
      ? procesarPath(PATHS.documentoInterno, this.evidencia._id)
      : this.evidencia instanceof DocumentoDelContrato
      ? procesarPath(PATHS.documentoDelContrato, this.evidencia._id)
      : this.evidencia instanceof PlanDeAccion
      ? procesarPath(PATHS.planDeAccion, this.evidencia._id)
      : this.evidencia instanceof Accion
      ? procesarPath(PATHS.accion, this.evidencia._id)
      : "";
  }
}

export interface DerogacionDeRequisitoLegalBody {
  _id?: string;
  textoDelDerogacion: string;
  articulos: number[];
  empresa: string;
}

export const textoDeModulo = (modulo: Modulos | Submodulos) => {
  switch (modulo) {
    case Modulos.Formacion:
      return "Formación";
    case Modulos.RequisitosLegales:
      return "Requisitos Legales";
    case Modulos.GestionDocumental:
      return "Gestión Documental";
    case Modulos.InvestigacionDeIncidentes:
      return "Investigación de Incidentes";
    case Modulos.PlanesDeAccion:
      return "Planes de Acción";
    case Modulos.MIPER:
      return "MIPER";
    case Modulos.Contexto:
      return "Contexto";
    case Modulos.IndicadoresDeGestion:
      return "Indicadores de Gestión";
    case Submodulos.GestionDocumentalInterna:
      return "Gestión Documental Interna";
    case Submodulos.AdministracionDeContratos:
      return "Administración de Contratos";
  }
};

export const textoDeTipoDeVinculacion = (tipo?: TiposDeVinculacion) => {
  switch (tipo) {
    case TiposDeVinculacion.Curso:
      return "Curso";
    case TiposDeVinculacion.RequisitoLegal:
      return "Requisito Legal";
    case TiposDeVinculacion.Documento:
      return "Documento";
    case TiposDeVinculacion.DocumentoDelContrato:
      return "Documento de Contrato";
    case TiposDeVinculacion.Incidente:
      return "Incidente";
    case TiposDeVinculacion.PlanDeAccion:
      return "Plan de Acción";
    case TiposDeVinculacion.Accion:
      return "Acción";
    case TiposDeVinculacion.Actividad:
      return "Actividad";
  }
};
