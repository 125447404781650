import { FC, FormEvent, useContext, useState } from "react";
import { DocumentoDelContrato } from "../../../../models/DocumentoDelContrato";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { EvaluacionBadge } from "../../../../utilities/EvaluacionBadge";
import { calcularVigencia, shortDateToString } from "../../../../utilities/utils";
import {
  FrecuenciaDeActualizacion,
  textoDeLaFrecuenciaDeActualizacion,
  textoDePeriodoDeActualizacion,
} from "../../../../models/Documento";
import TablaDeArchivosPreview from "./TablaDeArchivosPreview";
import { FaPaperPlane } from "react-icons/fa";
import { crearComentario } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Context } from "../../../Context";
import { ComentarioDeDocumento } from "../../../../models/ComentarioDeDocumento";

interface DetallesDelDocumentoProps {
  documentoDelContrato: DocumentoDelContrato;
  cerrar: () => void;
}

const DetallesDelDocumento: FC<DetallesDelDocumentoProps> = ({ documentoDelContrato, cerrar }) => {
  const { authToken } = useContext(Context);
  const [comentario, setComentario] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitComentario = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    crearComentario(authToken, documentoDelContrato._id, comentario)
      .then(({ data }) => {
        setComentario("");
        documentoDelContrato.comentarios.push(new ComentarioDeDocumento(data));
      })
      .finally(() => setIsLoading(false));
  };

  const { archivos, documentoVinculado } = documentoDelContrato;
  if (!documentoVinculado) return null;
  const {
    frecuenciaDeActualizacion,
    valorDeLaFrecuenciaDeActualizacion,
    unidadDeLaFrecuenciaDeActualizacion,
  } = documentoVinculado;
  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="justify-content-center">
        <Col className="mb-3">
          <Card>
            <CardHeader>Detalles del documento</CardHeader>
            <CardBody>
              <Row className="mb-3">
                <Col xs={5} sm={4}>
                  Nombre
                </Col>
                <Col>{documentoDelContrato.nombre}</Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col xs={5} sm={4}>
                  Evaluación
                </Col>
                <Col>
                  <Row>
                    <Col xs="auto">
                      <EvaluacionBadge evaluacion={documentoDelContrato.evaluacion} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={5} sm={4}>
                  Periodicidad
                </Col>
                <Col>
                  {frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra &&
                  !!valorDeLaFrecuenciaDeActualizacion &&
                  !!unidadDeLaFrecuenciaDeActualizacion
                    ? textoDePeriodoDeActualizacion(
                        valorDeLaFrecuenciaDeActualizacion,
                        unidadDeLaFrecuenciaDeActualizacion
                      )
                    : textoDeLaFrecuenciaDeActualizacion(frecuenciaDeActualizacion)}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={5} sm={4}>
                  Vigencia
                </Col>
                <Col>
                  {!!archivos &&
                    archivos.length > 0 &&
                    calcularVigencia(archivos[0]?.fechaDeVencimiento)}
                </Col>
              </Row>
              <Row>
                <Col>
                  <TablaDeArchivosPreview
                    documento={documentoDelContrato}
                    titulo="Archivos"
                    cerrar={cerrar}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={5}>
          <Card>
            <CardHeader>Comentarios</CardHeader>
            <ListGroup flush>
              {documentoDelContrato.comentarios.map(({ _id, comentario, updatedAt, usuario }) => (
                <ListGroupItem key={_id}>
                  <b>
                    {shortDateToString(updatedAt)} {usuario.nombreCompleto}:
                  </b>
                  <br />
                  {comentario}
                </ListGroupItem>
              ))}
              {documentoDelContrato.comentarios.length === 0 && (
                <ListGroupItem>
                  <p className="text-center">No hay comentarios</p>
                </ListGroupItem>
              )}
            </ListGroup>
            <CardFooter>
              <Form onSubmit={handleSubmitComentario}>
                <Row>
                  <Col>
                    <Input
                      type="textarea"
                      placeholder="Escribe un comentario"
                      value={comentario}
                      onChange={(e) => setComentario(e.target.value)}
                    />
                  </Col>
                  <Col xs="auto">
                    <Button color="primary" className="rounded-circle">
                      <Row className="justify-content-center align-items-center gx-0">
                        <Col xs="auto" className="pe-1 pt-1 pb-2">
                          <FaPaperPlane size={20} />
                        </Col>
                      </Row>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DetallesDelDocumento;
