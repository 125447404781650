import { useState, FC, useRef, useEffect, useContext } from "react";
import { Button, Form, Navbar, Toast, ToastBody, ToastHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EmpresaForm, { EmpresaBody } from "../Registro/EmpresaForm";
import LoadingSpinner from "../../utilities/LoadingSpinner";
import { SeparatorRow } from "../../utilities/utils";
import { useTimeout } from "../../utilities/useTimeout";
import { Context } from "../Context";
import { PATHS } from "../../utilities/Paths";
import Breadcrumbs from "../../utilities/Breadcrumbs";
import { crearEmpresa } from "../../services/usuario-api-client";

import { FaPlus } from "react-icons/fa";

import ComponentCard from '../components/ComponentCard';

const AGREGAR_EMPRESA_FORM_ID = "agregarEmpresaForm";

const CustomToast = styled(Toast)`
  top: 70px;
  z-index: 10;
`;

const AgregarEmpresa: FC = () => {
  const navigate = useNavigate();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [nombreDeLaEmpresa, setNombreDeLaEmpresa] = useState<string>("");
  const [ruc, setRuc] = useState<string>("");
  const [digitoVerificador, setDigitoVerificador] = useState<string>("");
  const [tipoDeIndustria, setTipoDeIndustria] = useState<string>("");
  const [direccion, setDireccion] = useState<string>("");
  const [isPersonaNatural, setIsPersonaNatural] = useState<boolean | null>(null);
  const [nombreDelPropietario, setNombreDelPropietario] = useState<string>("");
  const [cedulaDelPropietario, setCedulaDelPropietario] = useState<string>("");
  const [errorRuc, setErrorRuc] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const limpiarErrores = () => {
    setErrorRuc("");
  };

  const validarFormulario = () => {
    limpiarErrores();
    let esValido = true;
    // TODO: validar longitud de campos
    return esValido;
  };

  const scrollIntoView = (fieldName: string) => {
    if (formRef.current) {
      const formControl = formRef.current.querySelector<HTMLInputElement | HTMLSelectElement>(
        `#${fieldName}`
      );
      if (formControl) {
        formControl.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validarFormulario()) {
      registrar();
    }
  };

  const registrar = () => {
    setIsLoading(true);

    if (isPersonaNatural === null) throw new Error("Selecciona el tipo de empresa.");
    const body: EmpresaBody = {
      nombreDeLaEmpresa,
      ruc,
      digitoVerificador,
      tipoDeIndustria,
      direccion,
      isPersonaNatural,
    };
    if (isPersonaNatural) {
      body!.nombreDelPropietario = nombreDelPropietario;
      body!.cedulaDelPropietario = cedulaDelPropietario;
    }

    crearEmpresa(authToken, body)
      .then(() => navigate(PATHS.usuarioHome.to))
      .catch((error) => {
        if (error.response) {
          const { data } = error.response;
          if ("dup" in data && "fieldName" in data.dup) {
            const { fieldName } = data.dup;
            scrollIntoView(fieldName);
            switch (fieldName) {
              case "ruc":
                setErrorRuc(`Ya existe una empresa registrada con el RUC <b>${ruc}</b>.`);
                break;
              default:
                setErrorMessage(data.message);
                break;
            }
          } else {
            setErrorMessage(data.message);
          }
        } else {
          setErrorMessage("Error de conexión.");
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.nuevaEmpresa.name),
    [setTituloDeLaPantallaActual]
  );

  useTimeout(() => setErrorMessage(""), 4000, !!errorMessage);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome]} />
      <ComponentCard
       title="Documentos Internos"
       btnRight= {
         <Button form={AGREGAR_EMPRESA_FORM_ID} type="submit" color="primary" className="ms-auto" outline>
          <FaPlus /> Registrar
         </Button>
       }
      >
      <Form onSubmit={handleSubmit} innerRef={formRef} id={AGREGAR_EMPRESA_FORM_ID}>
        <EmpresaForm
          {...{
            nombreDeLaEmpresa,
            ruc,
            digitoVerificador,
            tipoDeIndustria,
            direccion,
            isPersonaNatural,
            nombreDelPropietario,
            cedulaDelPropietario,
            errorRuc,
          }}
          {...{
            setNombreDeLaEmpresa,
            setRuc,
            setDigitoVerificador,
            setTipoDeIndustria,
            setDireccion,
            setIsPersonaNatural,
            setNombreDelPropietario,
            setCedulaDelPropietario,
          }}
        />
      </Form>
      </ComponentCard>
      <CustomToast fade isOpen={!!errorMessage} className="position-fixed end-0 p-3">
        <ToastHeader icon="danger">Error</ToastHeader>
        <ToastBody>{errorMessage}</ToastBody>
      </CustomToast>
    </>
  );
};

export default AgregarEmpresa;
