import React, { FC } from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

interface SidebarItem {
  title: string;
  href: string;
  icon?: React.ReactNode;
}

interface NavSubItemProps {
  icon: React.ReactNode;
  title: string;
  items: SidebarItem[]; // Nested items
  suffix?: string;
  ddType?: string;
  activeBck?: string;
  suffixColor?: string;
  isUrl?: boolean;
}

const NavSubItem: FC<NavSubItemProps> = ({
  icon,
  title,
  items,
  suffix,
  ddType,
  activeBck,
  suffixColor,
  isUrl,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);

  // Toggle the dropdown menu
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavItem className={`collapsed ${isOpen ? 'activeParent' : ''} ${ddType}`}>
      <NavLink className="gap-3 cursor-pointer" onClick={handleToggle}>
        <span className="sidebarIcon d-flex align-items-center">{icon}</span>
        <div className="d-flex flex-grow-1 align-items-center gap-2">
          <span className="me-auto">{title}</span>
          {suffix ? <span className={`badge ${suffixColor}`}>{suffix}</span> : null}
          <i className={`bi bi-chevron-${isOpen ? 'up' : 'down'}`} />
        </div>
      </NavLink>
      <Nav vertical className={`firstDD  bg-${activeBck} ${isOpen ? 'showfirstDD' : ''}`}>
        {items.map((item) => (
          <NavItem
            key={item.title}
            className={location.pathname === item.href ? 'activeLink' : ''}
          >
            <NavLink tag={Link} to={item.href} className="gap-3">
              <span className="sidebarIcon text-dark">{item.icon}</span>
              <span className="text-dark">{item.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </NavItem>
  );
};

NavSubItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      icon: PropTypes.node,
    })
  ).isRequired,
  suffix: PropTypes.string,
  ddType: PropTypes.string,
  activeBck: PropTypes.string,
  suffixColor: PropTypes.string,
  isUrl: PropTypes.bool,
};

export default NavSubItem;
