import React from 'react';
import { DropdownItem } from 'reactstrap';
import { LinkContainer } from "react-router-bootstrap";
import { FaUser, FaUsers, FaDoorOpen, FaFileSignature, FaFileInvoice, FaGavel, FaBuilding, } from "react-icons/fa";
import { BsBuildingFillAdd, BsBuildingFillGear } from "react-icons/bs";
import { PATHS } from "../../utilities/Paths";

const user1 = require('../../assets/images/users/user7.png');

interface ProfileDDProps {
  User: string; // Specify the type of the proUser: string
  Empresa: string;
  Titulo: string;
}


const ProfileDD: React.FC<ProfileDDProps> = ({User,Empresa,Titulo}) => {
  return (
    <div>
      <div className="p-3 text-center border-bottom pt-2 align-items-center text">
        <img src={user1} alt="user" className="rounded-circle" width="60" />
      </div>
      <div className="p-3">
        <span>
          <h4 className="mb-2">{User}</h4>
          <small className='fs-8 text-muted'><b>Empresa:</b><br />{Empresa}</small>
          <br />
          <small className='fs-8 text-muted'><b>Cargos:</b><br /><div style={{ whiteSpace: "pre-wrap" }}>{(Titulo)}</div></small>
          
          
        </span>
      </div>
      <DropdownItem divider />
      <LinkContainer to={PATHS.perfil.to}>
      <DropdownItem className="px-4 py-3">
        <FaUser size={20} />
        &nbsp; My Profile
      </DropdownItem>
      </LinkContainer>
      <LinkContainer to={PATHS.administracionDeUsuarios.to}>
      <DropdownItem className="px-4 py-3">
        <FaUsers size={20} />
        &nbsp; Admin Usuarios
      </DropdownItem>
      </LinkContainer>

      <DropdownItem divider />
        
        <LinkContainer to={PATHS.nuevaEmpresa.to}>
        <DropdownItem className="px-4 py-3">
          <BsBuildingFillAdd size={20} />
          &nbsp; Registrar Empresa
        </DropdownItem>
        </LinkContainer>
        
        <LinkContainer to={PATHS.editarEmpresa.to}>
        <DropdownItem className="px-4 py-3">
          <BsBuildingFillGear size={20} />
          &nbsp; Editar Datos de la Empresa
        </DropdownItem>
        </LinkContainer>
        
        <DropdownItem divider />
    </div>
  );
};

export default ProfileDD;
