import { useContext, FC, useEffect, useState, HTMLAttributes } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Table,
  Tooltip,
  Row,
  Badge,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import { Context } from "../../Context";
import { fetchCurso, fetchHost } from "../../../services/lms-api-client";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  TableMessage,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../utilities/utils";
import { Curso, textoDeFrecuencia, textoDeModalidad } from "../../../models/Curso";
import {
  asignarCurso,
  listCursos,
  listCursosAsignados,
  listCursosDeLaEmpresa,
  listHistorialDeCursos,
} from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";
import { useLocation } from "react-router-dom";
import { Cargo } from "../../../models/Cargo";
import ComponentCard from '../../components/ComponentCard';
import { PlanDeAccion } from "../../../models/PlanDeAccion";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import BusquedaDeUsuarios from "./BusquedaDeUsuarios";
import { Vinculacion } from "../../../models/Vinculacion";

import { FaArrowAltCircleRight, FaUserTag, FaDownload, FaLayerGroup, FaUserCheck, FaBuilding, FaUsers, FaBookmark } from "react-icons/fa";

import { getStatus } from "../../../services/usuario-api-client";
import LocalStorage from "../../../utilities/LocalStorage";

const USUARIOS_FORM_ID = "usuariosForm";

enum Vistas {
  CursosAsignados = "cursosAsignados",
  CursosDeLaEmpresa = "cursosDeLaEmpresa",
  CursosLibres = "cursosLibres",
  Historial = "historial",
}

const textoDeVista = {
  [Vistas.CursosAsignados]: "Cursos Asignados",
  [Vistas.CursosDeLaEmpresa]: "Cursos de la empresa",
  [Vistas.CursosLibres]: "Cursos Libres",
  [Vistas.Historial]: "Historial",
};

interface BotonesDeAccionProps {
  curso: Curso;
  vistaActual: Vistas;
  lmsHost: string;
  asignarCurso: (curso: Curso) => void;
  verCurso: (curso: Curso) => void;
  rol: string;
}

const BotonesDeAccion: FC<BotonesDeAccionProps> = ({
  curso,
  vistaActual,
  lmsHost,
  asignarCurso,
  verCurso,
  rol, 
}) => {
  
  const [tooltipOpen, setTooltipOpen] = useState(false);
  
  const toggle = () => setTooltipOpen(!tooltipOpen);
  
  
  switch (vistaActual) {
    case Vistas.CursosAsignados:
      return (
        <>
        <Button
          href={`${lmsHost}/course/view.php?id=${curso.id}`}
          target="_blank"
          color="primary"
          id={'Tooltip-1'}
          outline size="sm"
        >
          <FaArrowAltCircleRight />
        </Button>
        
        <Tooltip
          placement={'left'}
          isOpen={tooltipOpen}
          target={'Tooltip-1'}
          toggle={toggle}
        >
        Ir al Curso
        </Tooltip>
        
        </>
      );
    case Vistas.CursosDeLaEmpresa:
    return rol === 'administrador' ? (
      <>
      <Button  color="primary" onClick={() => asignarCurso(curso)} id={'Tooltip-2'} outline size="sm">
        <FaUserTag />
      </Button>
      <Tooltip
        placement={'left'}
        isOpen={tooltipOpen}
        target={'Tooltip-2'}
        toggle={toggle}
      >
      Asignar Curso
      </Tooltip>
      </>
    ) : null;
    case Vistas.CursosLibres:
      return (
        <>
        <Button color="primary"  onClick={() => asignarCurso(curso)} id={'Tooltip-3'} outline size="sm">
          <FaUserTag />
        </Button>
        <Tooltip
          placement={'left'}
          isOpen={tooltipOpen}
          target={'Tooltip-3'}
          toggle={toggle}
        >
        Asignar Curso
        </Tooltip>
        </>
      );
    case Vistas.Historial:
      return (
        <>
        <Button  color="primary" onClick={() => verCurso(curso)} id={'Tooltip-4'} outline size="sm">
         <FaDownload />
        </Button>
        <Tooltip
          placement={'left'}
          isOpen={tooltipOpen}
          target={'Tooltip-4'}
          toggle={toggle}
        >
        Ver/Descargar certificado
        </Tooltip>
        </>
      );
  }
};

interface ProgressOuterRimProps
  extends React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  progress: number;
}

const ProgressOuterRim = styled.div<ProgressOuterRimProps>`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${({ progress }) => `conic-gradient(transparent 0%, transparent ${
    progress * 100
  }%, #fff ${progress * 100}%, #fff 100%),
    radial-gradient(at 0px 0px, rgb(255, 255, 255) 0%, rgb(17, 85, 204) 100%)`};
  border: 1px solid #595959;
  transition: all 0.5s ease-in-out;
`;

const ProgressCenter = styled.div`
  margin: 15px;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 1px solid #595959;
  background: #fff;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const FormacionYTomaDeConciencia: FC = () => {
  const location = useLocation();
  const { setTituloDeLaPantallaActual, authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [vistaActual, setVistaActual] = useState<Vistas>(Vistas.CursosAsignados);
  const [cursos, setCursos] = useState<Curso[]>([]);
  const [cursoSeleccionado, setCursoSeleccionado] = useState<Curso | null>(null);
  const [lmsHost, setLmsHost] = useState<string>("");
  const [progress] = useState<number>(0.75);
  const [asignarCursoModalIsOpen, setAsignarCursoModalIsOpen] = useState<boolean>(false);
  const [alreadyFetched, setAlreadyFetched] = useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);
    
  const [UserAdmin, setUserAdmin] = useState<string>("regular");
  const [UserCargo, setUserCargo] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  

  const toggleAsignarCursoModal = () => setAsignarCursoModalIsOpen(!asignarCursoModalIsOpen);

  const handleAsignarCursoClick = ({ id }: Curso) => {
    const curso = cursos.find((c) => c.id === id);
    if (!curso) return;
    setCursoSeleccionado(curso);
    toggleAsignarCursoModal();
  };

  const handleAsignarCursosSubmit = (vinculaciones: Vinculacion[]) => {
    if (!authToken || !cursoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    const vinculacionesIds = vinculaciones.map((v) => v._id);
   
    asignarCurso(authToken, cursoSeleccionado._id, vinculacionesIds)
      .then(toggleAsignarCursoModal)
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const verCurso = ({ id }: Curso) => {
    if (!authToken) return;
    fetchCurso(authToken, id).then(({ data }) => {
      console.log("verCurso", data);
      alert("Funcionalidad en desarrollo");
    });
  };

  useEffect(() => setTituloDeLaPantallaActual(PATHS.formacion.name), [setTituloDeLaPantallaActual]);

  useEffect(() => {
    if (!authToken || isLoading) return;
    setIsLoading(true);
    getStatus(authToken)
    .then(({ data }) => {
  
      const prevIdDeLaEmpresaSeleccionada = idDeLaEmpresaSeleccionada;
      LocalStorage.getIdEmpresa().then((idDeLaEmpresaAlmacenado) => {
      const { persona, vinculaciones, cambiarContrasena } = data;
      
      let idDeLaEmpresaSeleccionada: string;
      if (!prevIdDeLaEmpresaSeleccionada && !!persona.ultimaEmpresaSeleccionada) {
        idDeLaEmpresaSeleccionada = persona.ultimaEmpresaSeleccionada;
      }
      if (!!idDeLaEmpresaAlmacenado) {
        idDeLaEmpresaSeleccionada = idDeLaEmpresaAlmacenado;
      } else if (!!prevIdDeLaEmpresaSeleccionada) {
        idDeLaEmpresaSeleccionada = prevIdDeLaEmpresaSeleccionada;
      } else {
        const empresa = vinculaciones[0].empresa;
        idDeLaEmpresaSeleccionada = typeof empresa === "string" ? empresa : empresa._id;
      }
      
      const currentVinculacion = vinculaciones.find(({ empresa }) =>
         typeof empresa === "string"
           ? empresa === idDeLaEmpresaSeleccionada
           : empresa._id === idDeLaEmpresaSeleccionada
       );
       
       console.log(currentVinculacion);
       
       if (currentVinculacion) {
         const { rol, cargo } = currentVinculacion;
         // Set the role
         setUserAdmin(rol);
       
         // Handle the cargo field
         if (cargo) {
           if (Array.isArray(cargo)) {
             // If cargo is an array
             const cargoNames = cargo.map((item) => {
               if (typeof item === "string") {
                 return item;
               }
               return ""; // Fallback in case of unexpected structure
             }).filter(Boolean); // Remove empty or invalid items
             setUserCargo(cargoNames); // Directly set the array of cargo names
           }
         }
       }
      });
    }).finally(() => setIsLoading(false));
    
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    setAlreadyFetched(false);
    setCursos([]);
    switch (vistaActual) {
      case Vistas.CursosAsignados:
        listCursosAsignados(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
      case Vistas.CursosDeLaEmpresa:
        listCursosDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
      case Vistas.CursosLibres:
        listCursos(authToken)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
      case Vistas.Historial:
        listHistorialDeCursos(authToken,idDeLaEmpresaSeleccionada)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
    }
  }, [vistaActual, authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    if (!authToken) return;
    fetchHost(authToken).then(({ data }) => setLmsHost(data.host));
  }, [authToken]);

  useEffect(() => {
    if (location.hash) {
      setVistaActual(location.hash.substring(1) as Vistas);
    }
  }, [location.hash]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Breadcrumbs items={[PATHS.usuarioHome]} />
        
        <ComponentCard
         title="Requisitos de Formación"
         btnRight= {
           <>
           { UserAdmin == 'administrador' && !isLoading && (
          
                 <LinkContainer to={PATHS.cursos.to}>
                   <Button type="button" color="primary" outline size="sm">
                   <FaLayerGroup />  Gestionar cursos
                   </Button>
                 </LinkContainer>
      )
           }
           </>
         }
        >
      <Row>
        <Col>
          
          
          <TableContainer className="mb-0">
            <Nav fill pills className="flex-nowrap">
              {Object.values(Vistas).map((tab) => (
                <NavItem key={tab}>
                  <NavLink
                    active={vistaActual === tab}
                    onClick={() => setVistaActual(tab)}
                    href={`#${tab}`}
                    className="align-items-center text-center h-100"
                  >
                 <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> { (tab == Vistas.CursosAsignados) ? <FaUserCheck /> : (tab == Vistas.CursosDeLaEmpresa) ? <FaBuilding /> : (tab == Vistas.CursosLibres) ? <FaUsers /> : <FaBookmark />} </Col><Col xs={12} md={12} lg={10}>{textoDeVista[tab]} </Col></Row></div>
                 
                 <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> { (tab == Vistas.CursosAsignados) ? <FaUserCheck /> : (tab == Vistas.CursosDeLaEmpresa) ? <FaBuilding /> : (tab == Vistas.CursosLibres) ? <FaUsers /> : <FaBookmark />} </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>{textoDeVista[tab]} </Col></Row></div>
                 
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </TableContainer>
        </Col>
        <Col xs="auto" className="mb-1 d-none">
          <ProgressOuterRim progress={progress}>
            <ProgressCenter>
              <div className="d-flex flex-column h-100 justify-content-center text-center">
                <NumericFormat
                  value={progress * 100}
                  displayType="text"
                  decimalScale={0}
                  suffix="%"
                />
              </div>
            </ProgressCenter>
          </ProgressOuterRim>
        </Col>
      </Row>
    
      <Table className="no-wrap mt-3 align-middle" responsive borderless>
          <thead>
            <tr>
              <th></th>
             
              <th>Tema del curso (Código)</th>
              <th>Plan de acción</th>
              <th>Requisito legal</th>
              <th>Modalidad</th>
              <th>Objetivo</th>
              <th>Contenido</th>
              <th>Duración (horas)</th>
              <th>Frecuencia</th>
              <th>Evaluación de efectividad</th>
              <th>Cargo</th>
              <th>Fecha de vencimiento</th>
              <th>Avance</th>
              
            </tr>
          </thead>
          <tbody>
            {cursos.map((curso) => (
              <tr key={curso.id}>
                
                <td>
                  <BotonesDeAccion
                    curso={curso}
                    vistaActual={vistaActual}
                    lmsHost={lmsHost}
                    asignarCurso={handleAsignarCursoClick}
                    verCurso={verCurso}
                    rol={UserAdmin}
                    
                  /> 
                </td>
                
                
                <td>
                  {curso.tema} ({curso.codigo})
                </td>
                <td>
                  {curso.planDeAccion ? (
                    (curso.planDeAccion as PlanDeAccion).descripcion
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
                <td>
                  {curso.requisitosLegales.length > 0 ? (
                    curso.requisitosLegales.map((requisito) => (
                      <a
                        href={procesarLinkDeDocumento((requisito as RequisitoLegal).filepath)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {(requisito as RequisitoLegal).nombre}
                      </a>
                    ))
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
                <td>{textoDeModalidad(curso.modalidad)}</td>
                <td>{curso.objetivo}</td>
                {curso.contenidoEsHTML ? (
                  <td dangerouslySetInnerHTML={{ __html: curso.contenido }} />
                ) : (
                  <td>{curso.contenido}</td>
                )}
                <td>{curso.duracionEnHoras}</td>
                <td>{textoDeFrecuencia(curso.frecuencia)}</td>
                <td>{curso.evaluacionEfectividad ? "Sí" : "No"}</td>
                <td>{curso.cargos.map((cargo) => (cargo as Cargo).cargo).join(", ")}</td>
                <td>
                  {curso.fechaDeVencimiento ? shortDateToString(curso.fechaDeVencimiento) : ""}
                </td>
                <td>{curso.avance !== null && curso.avance !== undefined ? (
                    <Badge pill style={{ fontSize: "12px", marginTop: "6px" }} color={(curso.avance == 0)?"danger":(curso.avance>0.25 && curso.avance<100)?"warning":(curso.avance==100)? "success" :"primary"}>
                      {Math.round(curso.avance)}%
                    </Badge>
                  ) : (
                    <span>No tiene avance</span> // Fallback in case avance is null or undefined
                  )} </td>
                
              </tr>
            ))}
            {cursos.length === 0 && alreadyFetched && (
              <tr>
                <td colSpan={14} className="text-center">
                  <TableMessage>No hay cursos disponibles</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
      </Table>
      
      
        </ComponentCard>
    
      
      <Modal size="lg" isOpen={asignarCursoModalIsOpen} toggle={toggleAsignarCursoModal}>
        <ModalHeader toggle={toggleAsignarCursoModal}>Asignar curso</ModalHeader>
        <ModalBody>
          <BusquedaDeUsuarios
            formId={USUARIOS_FORM_ID}
            curso={cursoSeleccionado as Curso}
            onSubmit={handleAsignarCursosSubmit}
          />
        </ModalBody>
        <ModalFooter>
          
          <Button color="secondary" onClick={toggleAsignarCursoModal} outline>
            Cancelar
          </Button>
          
          <Button color="primary" type="submit" form={USUARIOS_FORM_ID} outline>
            Asignar
          </Button>
          
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormacionYTomaDeConciencia;
