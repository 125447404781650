import { FC, useContext, useEffect, useState } from "react";
import { Button, Navbar } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { DocumentoBase } from "../../../../models/Documento";
import { createDocumento } from "../../../../services/usuario-api-client";
import DocumentoForm from "./DocumentoForm";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";

import { FaHistory, FaEdit, FaArchive, FaFile, FaPlus, FaFileUpload } from "react-icons/fa";
import ComponentCard from '../../../components/ComponentCard';

const AGREGAR_DOCUMENTO_INTERNO_FORM_ID = "agregarDocumentoInternoForm";

const AgregarDocumentoInterno: FC = () => {
  const navigate = useNavigate();
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (body: DocumentoBase) => {
    setIsLoading(true);
    createDocumento(authToken, idDeLaEmpresaSeleccionada, body)
      .then(() => navigate(PATHS.gestionDocumentalInterna.to))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.nuevoDocumentoInterno.name);
  }, [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs
        items={[PATHS.usuarioHome, PATHS.gestionDocumental, PATHS.gestionDocumentalInterna]}
      />
      <ComponentCard
       title="Agregar documento interno"
       btnRight= {
         <Button
           form={AGREGAR_DOCUMENTO_INTERNO_FORM_ID}
           type="submit"
           color="primary"
           className="ms-auto"
           outline
         >
          <FaPlus /> Crear
         </Button>
       }
      >
      <DocumentoForm formId={AGREGAR_DOCUMENTO_INTERNO_FORM_ID} onSubmit={handleSubmit} />
      </ComponentCard>
    </>
  );
};

export default AgregarDocumentoInterno;
