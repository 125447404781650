import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table, Badge } from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { RiListSettingsLine } from "react-icons/ri";
import { Link, useLocation, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import { obtenerRequisitoLegal } from "../../../services/usuario-api-client";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import ComponentCard from '../../components/ComponentCard';

import { CustomTable, TableCaption, TableContainer, TableMessage } from "../../../utilities/utils";
import {
  AnalisisDeRequisitoLegal,
  Modulos,
  Submodulos,
  textoDeModulo,
  textoDeTipoDeVinculacion,
} from "../../../models/AnalisisDeRequisitoLegal";
import {
  DerogacionDeRequisitoLegal,
} from "../../../models/DerogacionDeRequisitoLegal";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS, procesarPath } from "../../../utilities/Paths";
import VincularAnalisisModal from "./VincularAnalisisModal";

interface EnlaceProps {
  enlace: string;
  nombre?: string;
  moduloVinculado?: Modulos | Submodulos;
}

const enlaceAEvidencia = ({ enlace, nombre, moduloVinculado }: EnlaceProps) => {
  if (!!moduloVinculado) {
    let path = "";
    switch (moduloVinculado) {
      case Modulos.Formacion:
        path = PATHS.cursos.to;
        break;
      case Modulos.RequisitosLegales:
        path = PATHS.requisitosLegales.to;
        break;
      case Modulos.GestionDocumental:
        path = PATHS.gestionDocumental.to;
        break;
      case Modulos.InvestigacionDeIncidentes:
        path = PATHS.investigacionDeIncidentes.to;
        break;
      case Modulos.PlanesDeAccion:
        path = PATHS.planesDeAccion.to;
        break;
      case Modulos.MIPER:
        path = PATHS.miper.to;
        break;
      case Modulos.Contexto:
        path = PATHS.contexto.to;
        break;
      case Modulos.IndicadoresDeGestion:
        path = PATHS.indicadores.to;
        break;
      case Submodulos.GestionDocumentalInterna:
        path = PATHS.gestionDocumentalInterna.to;
        break;
      case Submodulos.AdministracionDeContratos:
        path = PATHS.gestionDocumentalContratos.to;
        break;
    }
    return <Link to={path}>{textoDeModulo(moduloVinculado)}</Link>;
  } else if (!!nombre) {
    return <Link to={enlace}>{nombre}</Link>;
  }
};

const sortByArticulos = (a: AnalisisDeRequisitoLegal, b: AnalisisDeRequisitoLegal) => {
  const min = Math.min(a.articulos.length, b.articulos.length);
  for (let i = 0; i < min; i++) {
    if (a.articulos[i] < b.articulos[i]) return -1;
    if (a.articulos[i] > b.articulos[i]) return 1;
  }
  return a.articulos.length - b.articulos.length;
};

const TablaDeAnalisisDeRequisitoLegal: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    state: { prevHash },
  } = useLocation();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [requisitoLegal, setRequisitoLegal] = useState<RequisitoLegal | null>(null);
  const [analisis, setAnalisis] = useState<AnalisisDeRequisitoLegal[]>([]);
  const [derogacion, setDerogacion] = useState<DerogacionDeRequisitoLegal[]>([]);
  const [analisisSeleccionado, setAnalisisSeleccionado] = useState<AnalisisDeRequisitoLegal | null>(
    null
  );
  const [vincularModalIsOpen, setVincularModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleVincularModal = () => setVincularModalIsOpen((prev) => !prev);

  const handleAnalisisVinculadoSuccessfully = () => {
    toggleVincularModal();
    loadAnalisis();
  };

  const handleModalClosed = () => {
    setAnalisisSeleccionado(null);
  };

  const handleVincularAnalisisClick = (analisisId: string) => {
    const analisisSeleccionado = analisis.find(({ _id }) => _id === analisisId);
    if (analisisSeleccionado) {
      setAnalisisSeleccionado(analisisSeleccionado);
      toggleVincularModal();
    }
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.analisisDeRequisitoLegal.name),
    [setTituloDeLaPantallaActual]
  );

  const loadAnalisis = useCallback(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    obtenerRequisitoLegal(authToken, id)
      .then(({ data }) => {
        setRequisitoLegal(new RequisitoLegal(data.requisitoLegal));
        const analisis = data.analisis
          .map((a) => new AnalisisDeRequisitoLegal(a))
          .sort(sortByArticulos);
        setAnalisis(analisis);
        
        const derogacion = data.derogacion
        .map((a) => new DerogacionDeRequisitoLegal(a))
        
        setDerogacion(derogacion);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  useEffect(loadAnalisis, [loadAnalisis]);

  const hash = prevHash || "#todos";

  
  const renderBadge = (estado?:string): JSX.Element | null => {
    if (estado != '') {
      if(estado == 'Evidencia Vencida'){
      return (
        <Badge
          color="danger"
          pill
          style={{
            paddingTop: "6px",
            paddingBottom: "5px",
            fontSize: "11px",
          }}
        >
          <b>{estado}</b>
        </Badge>
      );
    }else{
      return (
        <Badge
          color="success"
          pill
          style={{
            paddingTop: "6px",
            paddingBottom: "5px",
            fontSize: "11px",
          }}
        >
          <b>{estado}</b>
        </Badge>
      );
    }
    }else{
      return ( <div style={{"fontSize":"11px"}}>Sin Evidencia</div> );
    }
    return null; // Explicitly return null for fallback
  };
  
  
  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
     
          <Breadcrumbs
            items={[
              PATHS.usuarioHome,
              {
                ...PATHS.requisitosLegales,
                to: { pathname: PATHS.requisitosLegales.to, hash },
              },
            ]}
          />
        
        <ComponentCard
          title={!!requisitoLegal ? "Requisito Legar: "+requisitoLegal.nombre : "Cargando..."}
         btnRight= {
              <>
              <LinkContainer
                to={procesarPath(PATHS.nuevoAnalisisDeRequisitoLegal, id || "")}
                state={{ prevHash }}
              >
                <Button color="primary" disabled={!requisitoLegal} title="Agregar análisis" outline>
                  <RiListSettingsLine size={20} />
                </Button>
              </LinkContainer>
              </>
            }
        >
       
   
        <Table className="no-wrap mt-1 align-middle" responsive borderless>
          <thead>
            <tr>
              <th>#</th>
              <th>Análisis</th>
              <th>Artículos</th>
              <th>Acciones de cumplimiento</th>
              <th>Evidencias</th>
              <th>Responsable</th>
              <th>Estado</th>
              <th>Seguimiento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {analisis.map((item, index) => {
              const {
                _id,
                analisis,
                articulos,
                accionesDeCumplimiento,
                estado,
                seguimiento,
                tipoDeEvidencia,
                enlaceDeEvidencia,
                nombreDeLaEvidencia,
                moduloVinculado,
              } = item;
          
              const derogatedArticles = new Set(
                derogacion.flatMap((d) => d.articulos)
              );
          
              return (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{analisis}</td>
                  <td>
                    {articulos.map((articulo, i) => (
                      <span
                        key={i}
                        style={{
                          color: derogatedArticles.has(articulo) ? "red" : "inherit",
                          fontWeight: derogatedArticles.has(articulo) ? "bold" : "normal",
                        }}
                      >
                        {articulo}
                        {i < articulos.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </td>
                  <td>{accionesDeCumplimiento}</td>
                  <td>
                    {!!tipoDeEvidencia && (
                      <Row className="gx-0">
                        <Col>{textoDeTipoDeVinculacion(tipoDeEvidencia)}:</Col>
                      </Row>
                    )}
                    <Row className="gx-0">
                      <Col>
                        {enlaceAEvidencia({
                          enlace: enlaceDeEvidencia,
                          nombre: nombreDeLaEvidencia,
                          moduloVinculado,
                        })}
                      </Col>
                    </Row>
                  </td>
                  <td>
                    {typeof requisitoLegal?.responsableDelAnalisis === "string"
                      ? requisitoLegal?.responsableDelAnalisis
                      : requisitoLegal?.responsableDelAnalisis.nombreCompleto}
                  </td>
                  <td>{renderBadge(estado)}</td>
                  <td>{seguimiento}</td>
                  <td>
                    <Row className="justify-content-center gx-0 flex-nowrap">
                      <Col xs="auto">
                        <LinkContainer
                          to={procesarPath(PATHS.nuevoAnalisisDeRequisitoLegal, id || "")}
                          state={{ prevHash, analisisId: _id }}
                        >
                          <Button type="button" color="link" size="sm" title="Editar">
                            <FaEdit size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="link"
                          size="sm"
                          title="Vincular"
                          onClick={() => handleVincularAnalisisClick(_id)}
                        >
                          <FaLink size={15} />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              );
            })}
          
            {analisis.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">No hay análisis para mostrar</Col>
                    </Row>
                    <Row className="justify-content-center gx-0 mt-3">
                      <Col xs="auto">
                        <LinkContainer
                          to={procesarPath(PATHS.nuevoAnalisisDeRequisitoLegal, id || "")}
                          state={{ prevHash }}
                        >
                          <Button type="button" color="primary" disabled={!requisitoLegal} outline>
                            Agregar análisis
                          </Button>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        
        </ComponentCard>
  
      <VincularAnalisisModal
        isOpen={vincularModalIsOpen}
        analisis={analisisSeleccionado as AnalisisDeRequisitoLegal}
        requisitoLegal={requisitoLegal as RequisitoLegal}
        toggle={toggleVincularModal}
        onSuccess={handleAnalisisVinculadoSuccessfully}
        onClosed={handleModalClosed}
      />
    </>
  );
};

export default TablaDeAnalisisDeRequisitoLegal;
