import { useState, FC, useContext, useEffect, useCallback } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle } from "react-icons/fa";
import { FaFileCircleCheck, FaFileCircleXmark } from "react-icons/fa6";
import { FaArrowAltCircleRight, FaUserTag, FaDownload, FaLayerGroup, FaUserCheck, FaBuilding, FaUsers, FaBookmark, FaTools, FaCar, FaCapsules } from "react-icons/fa";
import styled from "styled-components";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Context } from "../../../Context";
import {
  aceptarContrato,
  aprobarContrato,
  finalizarContrato,
  getContrato,
  rechazarContrato,
} from "../../../../services/usuario-api-client";
import { Contrato, EstadosDeContrato, textoDeEstadoDeContrato } from "../../../../models/Contrato";
import { TiposDeDocumento } from "../../../../models/Documento";
import { TableContainer } from "../../../../utilities/utils";
import TablaDeDocumentosDelContrato from "./TablaDeDocumentosDelContrato";
import { useRevertableState } from "../../../../utilities/useRevertableState";
import TablaDeDocumentosDePersonal from "./TablaDeDocumentosDePersonal";
import TablaDeDocumentosDeEquiposYHerramientas from "./TablaDeDocumentosDeEquiposYHerramientas";
import TablaDeDocumentosDeVehiculos from "./TablaDeDocumentosDeVehiculos";
import TablaDeDocumentosDeSustanciasQuimicas from "./TablaDeDocumentosDeSustanciasQuimicas";
import TablaDeDocumentosDeLaEmpresa from "./TablaDeDocumentosDeLaEmpresa";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";

import ComponentCard from '../../../components/ComponentCard';


const ContratoInfoInput = styled(Input)`
  text-align: center;
  font-weight: bold;
`;

const CustomNavLink = styled(NavLink)`
  height: 66px;
  white-space: nowrap;
`;

const tabs = [
  {
    tipoDeDocumento: "documentacionDelContrato",
    texto: (
      <>
      <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaBookmark /> </Col><Col xs={12} md={12} lg={10}>Todos</Col></Row></div>
      <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaBookmark /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Todos</Col></Row></div>
      </>
    ),
    hash: "#documentos",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionGeneralDeLaEmpresa,
    texto: (
     <>
     <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaBuilding /> </Col><Col xs={12} md={12} lg={10}>Doc. de
        <br />
        la Empresa</Col></Row></div>
     <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaBuilding /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Doc. de
        <br />
        la Empresa</Col></Row></div>
     </>
    ),
    hash: "#empresa",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeUsuariosDirectos,
    texto: <><div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaUserTag /> </Col><Col xs={12} md={12} lg={10}>Personal</Col></Row></div>
     <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaUserTag /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Personal</Col></Row></div></>,
    hash: "#personal",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa,
    texto: (
      <>
      <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaTools /> </Col><Col xs={12} md={12} lg={10}>Equipos y <br />
        Herramientas</Col></Row></div>
       <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaTools /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Equipos y <br />
         Herramientas</Col></Row></div>
            </>
    ),
    hash: "#equipos-herramientas",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa,
    texto: <> <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaCar /> </Col><Col xs={12} md={12} lg={10}>Vehículos</Col></Row></div>
     <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaCar /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Vehículos</Col></Row></div> </>,
    hash: "#vehiculos",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeSustanciasQuimicas,
    texto: (
      <>
      <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaCapsules /> </Col><Col xs={12} md={12} lg={10}>Sustancias
                <br />
        Químicas</Col></Row></div>
       <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaCapsules /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Sustancias
                 <br />
         Químicas</Col></Row></div>
            </>
    ),
    hash: "#sustancias-quimicas",
  },
];

export enum VistasDeContrato {
  Ninguna,
  Contratista,
  Cliente,
}

const ConfiguracionDeContrato: FC = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [contrato, setContrato] = useState<Contrato | null>(null);
  const [activeTab, setActiveTab] = useState<string>(tabs[0].tipoDeDocumento);
  const [vista, setVista] = useState<VistasDeContrato>(VistasDeContrato.Ninguna);
  const [confirmarRechazarContratoModalOpen, setConfirmarRechazarContratoModalOpen] =
    useState<boolean>(false);
  const [confirmarFinalizarContratoModalOpen, setConfirmarFinalizarContratoModalOpen] =
    useState<boolean>(false);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [aceptarContratoSuccessModalOpen, setAceptarContratoSuccessModalOpen] =
    useRevertableState<boolean>(false, 1500);
  const [aprobarContratoSuccessModalOpen, setAprobarContratoSuccessModalOpen] =
    useRevertableState<boolean>(false, 1500);
  const [rechazarContratoSuccessModalOpen, setRechazarContratoSuccessModalOpen] =
    useRevertableState<boolean>(false, 1500);
  const [finalizarContratoSuccessModalOpen, setFinalizarContratoSuccessModalOpen] =
    useRevertableState<boolean>(false, 1500);

  const handleAceptarContratoClick = () => {
    if (!authToken || !id) return;
    setIsLoading(true);
    aceptarContrato(authToken, id)
      .then(() => {
        setAceptarContratoSuccessModalOpen(true);
        fetchContrato();
      })
      .catch(({ response }) => {
        setMensajeDeError(response.data.error);
        setErrorModalIsOpen(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleAprobarContratoClick = () => {
    if (!authToken || !id) return;
    setIsLoading(true);
    aprobarContrato(authToken, id)
      .then(() => {
        setAprobarContratoSuccessModalOpen(true);
        fetchContrato();
      })
      .catch(({ response }) => {
        setMensajeDeError(response.data.error);
        setErrorModalIsOpen(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleRechazarContratoClick = () => {
    setConfirmarRechazarContratoModalOpen(true);
  };

  const handleConfirmarRechazarContratoClick = () => {
    if (!authToken || !id) return;
    setIsLoading(true);
    rechazarContrato(authToken, id)
      .then(() => {
        setConfirmarRechazarContratoModalOpen(false);
        setRechazarContratoSuccessModalOpen(true);
        fetchContrato();
      })
      .catch(({ response }) => {
        setMensajeDeError(response.data.error);
        setErrorModalIsOpen(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleFinalizarContratoClick = () => {
    setConfirmarFinalizarContratoModalOpen(true);
  };

  const handleConfirmarFinalizarContratoClick = () => {
    if (!authToken || !id) return;
    setIsLoading(true);
    finalizarContrato(authToken, id)
      .then(() => {
        setConfirmarFinalizarContratoModalOpen(false);
        setFinalizarContratoSuccessModalOpen(true);
        fetchContrato();
      })
      .catch(({ response }) => {
        setMensajeDeError(response.data.error);
        setErrorModalIsOpen(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleTabChange = (tipoDeDocumento: string) => {
    setActiveTab(tipoDeDocumento);
  };

  const limpiarMensajeDeError = () => {
    setMensajeDeError("");
  };

  const fetchContrato = useCallback(() => {
    if (!!authToken && !!id && !!idDeLaEmpresaSeleccionada) {
      setIsLoading(true);
      getContrato(authToken, idDeLaEmpresaSeleccionada, id)
        .then(({ data }) => {
          setContrato(new Contrato(data));
          setVista(
            data.empresaContratante._id === idDeLaEmpresaSeleccionada
              ? VistasDeContrato.Cliente
              : VistasDeContrato.Contratista
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [authToken, idDeLaEmpresaSeleccionada, id]);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.gestionDocumentalContratos.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(fetchContrato, [fetchContrato]);

  useEffect(() => {
    const { hash } = location;
    const tab = tabs.find((tab) => tab.hash === hash);
    if (tab) {
      setActiveTab(tab.tipoDeDocumento);
    }
  }, [location]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
        
        <Breadcrumbs
          items={[PATHS.usuarioHome, PATHS.gestionDocumental, PATHS.gestionDocumentalContratos]}
        />
        
      <ComponentCard title="Contrato:" 
      btnRight= {
        <></>
       }> 
      <Row className="mb-3">
      
        <Col xs="auto" md={{ size: "auto", order: 5 }}>
          <Row className="justify-content-end align-items-end h-100 gx-2">
            {vista !== VistasDeContrato.Ninguna && (
              <>
                {contrato?.estado === EstadosDeContrato.Solicitado && (
                  <>
                    {vista === VistasDeContrato.Contratista ? (
                      <>
                        <Col xs="auto">
                          <Button
                            color="success"
                            outline
                            className="p-1 border-0"
                            title="Aceptar"
                            onClick={handleAceptarContratoClick}
                          >
                            <FaCheckCircle size={30} />
                          </Button>
                        </Col>
                        <Col xs="auto">
                          <Button
                            color="danger"
                            outline
                            className="p-1 border-0"
                            title="Rechazar"
                            onClick={handleRechazarContratoClick}
                          >
                            <FaTimesCircle size={30} />
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <Col xs="auto">
                        <Button
                          color="danger"
                          outline
                          className="p-1 border-0"
                          title="Cancelar"
                          onClick={handleFinalizarContratoClick}
                        >
                          <FaTimesCircle size={30} />
                        </Button>
                      </Col>
                    )}
                  </>
                )}
                {vista === VistasDeContrato.Cliente &&
                  contrato?.estado === EstadosDeContrato.EnRevision && (
                    <Col xs="auto">
                      <Button
                        color="success"
                        outline
                        className="p-1 border-0"
                        title="Aprobar"
                        onClick={handleAprobarContratoClick}
                      >
                        <FaCheckCircle size={30} />
                      </Button>
                    </Col>
                  )}
                {(contrato?.estado === EstadosDeContrato.EnRevision ||
                  contrato?.estado === EstadosDeContrato.Autorizado) && (
                  <Col xs="auto">
                    <Button
                      color="danger"
                      outline
                      className="p-1 border-0"
                      title="Finalizar"
                      onClick={handleFinalizarContratoClick}
                    >
                      <FaTimesCircle size={30} />
                    </Button>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Col>
        <div className="w-100 my-1 d-md-none" />
        <Col xs={12} sm>
          <Row>
            <Col className="text-center">
              <span>Número de Documento</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContratoInfoInput type="text" readOnly value={contrato?.numeroDeDocumento} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm>
          <Row>
            <Col className="text-center">
              <span>Título</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContratoInfoInput type="text" readOnly value={contrato?.titulo} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={3}>
          <Row>
            <Col className="text-center">
              <span>Estado</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContratoInfoInput
                type="text"
                readOnly
                value={contrato && textoDeEstadoDeContrato(contrato.estado)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
      <Col>
        <Nav fill
        pills className="flex-nowrap">
          {tabs.map(({ tipoDeDocumento, texto, hash }) => (
            <NavItem key={tipoDeDocumento} >
              <NavLink
                href={hash}
                onClick={() => handleTabChange(tipoDeDocumento)}
                active={activeTab === tipoDeDocumento}
                className="align-items-center text-center h-100"
              >
                {texto}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Col>
      </Row>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="documentacionDelContrato">
          <TablaDeDocumentosDelContrato
            contrato={contrato as Contrato}
            isOpen={activeTab === "documentacionDelContrato"}
            vista={vista}
          />
        </TabPane>
        <TabPane tabId={TiposDeDocumento.DocumentacionGeneralDeLaEmpresa}>
          <TablaDeDocumentosDeLaEmpresa
            contrato={contrato as Contrato}
            isOpen={activeTab === TiposDeDocumento.DocumentacionGeneralDeLaEmpresa}
            vista={vista}
          />
        </TabPane>
        <TabPane tabId={TiposDeDocumento.DocumentacionDeUsuariosDirectos}>
          <TablaDeDocumentosDePersonal
            contrato={contrato as Contrato}
            isOpen={activeTab === TiposDeDocumento.DocumentacionDeUsuariosDirectos}
            vista={vista}
          />
        </TabPane>
        <TabPane tabId={TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa}>
          <TablaDeDocumentosDeEquiposYHerramientas
            contrato={contrato as Contrato}
            isOpen={activeTab === TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa}
            vista={vista}
          />
        </TabPane>
        <TabPane tabId={TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa}>
          <TablaDeDocumentosDeVehiculos
            contrato={contrato as Contrato}
            isOpen={activeTab === TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa}
            vista={vista}
          />
        </TabPane>
        <TabPane tabId={TiposDeDocumento.DocumentacionDeSustanciasQuimicas}>
          <TablaDeDocumentosDeSustanciasQuimicas
            contrato={contrato as Contrato}
            isOpen={activeTab === TiposDeDocumento.DocumentacionDeSustanciasQuimicas}
            vista={vista}
          />
        </TabPane>
      </TabContent>
      </ComponentCard>
      <Modal
        isOpen={aceptarContratoSuccessModalOpen}
        toggle={() => setAceptarContratoSuccessModalOpen(!aceptarContratoSuccessModalOpen)}
        size="sm"
      >
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaFileCircleCheck size={70} color="#198754" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <h4>Contrato aceptado</h4>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={aprobarContratoSuccessModalOpen}
        toggle={() => setAprobarContratoSuccessModalOpen(!aprobarContratoSuccessModalOpen)}
        size="sm"
      >
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaFileCircleCheck size={70} color="#198754" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <h4>Contrato aprobado</h4>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={confirmarRechazarContratoModalOpen}
        toggle={() => setConfirmarRechazarContratoModalOpen(!confirmarRechazarContratoModalOpen)}
      >
        <ModalBody>
          <ModalHeader toggle={() => setConfirmarRechazarContratoModalOpen(false)}>
            Rechazar Contrato
          </ModalHeader>
          <ModalBody>
            ¿Estás seguro de que deseas rechazar el contrato{" "}
            <strong>
              {contrato?.numeroDeDocumento} - {contrato?.titulo}
            </strong>
            ?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" className="px-4" onClick={handleConfirmarRechazarContratoClick}>
              Rechazar
            </Button>
            <Button
              color="secondary"
              className="px-4"
              onClick={() => setConfirmarRechazarContratoModalOpen(false)}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={rechazarContratoSuccessModalOpen}
        toggle={() => setRechazarContratoSuccessModalOpen(!rechazarContratoSuccessModalOpen)}
        size="sm"
      >
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaFileCircleXmark size={70} color="#dc3545" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <h4>Contrato rechazado</h4>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={confirmarFinalizarContratoModalOpen}
        toggle={() => setConfirmarFinalizarContratoModalOpen(!confirmarFinalizarContratoModalOpen)}
      >
        <ModalHeader toggle={() => setConfirmarFinalizarContratoModalOpen(false)}>
          Finalizar Contrato
        </ModalHeader>
        <ModalBody>
          ¿Estás seguro de que deseas finalizar el contrato{" "}
          <strong>
            {contrato?.numeroDeDocumento} - {contrato?.titulo}
          </strong>
          ?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" className="px-4" onClick={handleConfirmarFinalizarContratoClick}>
            Finalizar
          </Button>
          <Button
            color="secondary"
            className="px-4"
            onClick={() => setConfirmarFinalizarContratoModalOpen(false)}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={finalizarContratoSuccessModalOpen}
        toggle={() => setFinalizarContratoSuccessModalOpen(!finalizarContratoSuccessModalOpen)}
        size="sm"
      >
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaFileCircleXmark size={70} color="#dc3545" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <h4>Contrato finalizado</h4>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        size="sm"
        isOpen={errorModalIsOpen}
        toggle={() => setErrorModalIsOpen(!errorModalIsOpen)}
        onClosed={limpiarMensajeDeError}
      >
        <ModalBody>
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <FaExclamationTriangle size={70} color="#ffc107" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="text-center">
              <h5>{mensajeDeError}</h5>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="px-4" onClick={() => setErrorModalIsOpen(false)}>
            Entendido
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfiguracionDeContrato;
