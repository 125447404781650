import axios from "axios";
import { CargoResponse } from "../models/Cargo";
import { CursoResponse, IndicadorDeFormacion } from "../models/Curso";
import { ContratoBody, ContratoResponse } from "../models/Contrato";
import { UsuarioBase, UsuarioResponse } from "../models/Usuario";
import { Vinculacion, VinculacionResponse } from "../models/Vinculacion";
import {
  NuevoUsuarioBody,
  UsuarioParaAgregar,
  VinculacionBody,
} from "../components/usuario/AdministracionDeUsuarios";
import { DocumentoBase, DocumentoResponse } from "../models/Documento";
import { ArchivoDeDocumentoResponse } from "../models/ArchivoDeDocumento";
import { ArchivoDeIncidenteResponse } from "../models/ArchivoDeIncidente";
import { Empresa } from "../models/Empresa";
import { Vehiculo, VehiculoBase } from "../models/Vehiculo";
import { EquipoHerramienta, EquipoHerramientaBase } from "../models/EquipoHerramienta";
import { SustanciaQuimica, SustanciaQuimicaBase } from "../models/SustanciaQuimica";
import {
  DocumentoDelContratoBase,
  DocumentoDelContratoResponse,
} from "../models/DocumentoDelContrato";
import { ComentarioDeDocumentoResponse } from "../models/ComentarioDeDocumento";
import {
  BookmarkDeArticulo,
  IndicadorDeRequisitosLegales,
  RequisitoLegalBody,
  RequisitoLegalEditBody,
  RequisitoLegalResponse,
} from "../models/RequisitoLegal";
import { DocumentoLegalResponse } from "../models/DocumentoLegal";
import { Autoridad } from "../models/Autoridad";
import {
  AnalisisDeRequisitoLegalBody,
  AnalisisDeRequisitoLegalResponse,
  Modulos,
  Submodulos,
} from "../models/AnalisisDeRequisitoLegal";
import {
  DerogacionDeRequisitoLegalBody,
  DerogacionDeRequisitoLegalResponse,
} from "../models/DerogacionDeRequisitoLegal";
import { ActividadPayload, ActividadResponse } from "../models/Actividad";
import {
  EvaluacionDeRiesgoPayload,
  EvaluacionDeRiesgoResponse,
} from "../models/EvaluacionDeRiesgo";
import {
  IndicadorDePlanesDeAccion,
  OrigenDePlanDeAccion,
  PlanDeAccionPayload,
  PlanDeAccionResponse,
} from "../models/PlanDeAccion";
import { AccionPayload, AccionResponse } from "../models/Accion";
import {
  IncidenteBase,
  IncidenteResponse,
  IndicadorDeInvestigacionDeIncidentes,
} from "../models/Incidente";
import {
  UsuarioNoRegistradoPayload,
  UsuarioNoRegistradoResponse,
} from "../models/UsuarioNoRegistrado";
import { ParteInteresadaPayload, ParteInteresadaResponse } from "../models/ParteInteresada";
import { TipoDeContexto } from "../models/TipoDecontexto";
import { Contexto, ContextoPayload } from "../models/Contexto";
import { EmpresaBody } from "../components/Registro/EmpresaForm";
import { DocumentoSugerido } from "../models/DocumentoSugerido";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      if (window.location.pathname !== "/") window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

interface StatusResponse {
  persona: UsuarioResponse;
  vinculaciones: VinculacionResponse[];
  cambiarContrasena?: boolean;
}

export const getStatus = (authToken: string) => {
  return axios.get<StatusResponse>("/api/usuarios/status", {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const actualizarUltimaEmpresaSeleccionada = (
  authToken: string,
  idDeLaEmpresaSeleccionada: string
) => {
  return axios.put(
    "/api/usuarios/empresa",
    { idDeLaEmpresaSeleccionada },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const actualizarDatosDelUsuario = (
  authToken: string,
  idUsuario: string,
  body: UsuarioBase
) => {
  return axios.put<UsuarioResponse>(`/api/usuarios/${idUsuario}`, body, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

interface CambiarContrasenaBody {
  contraseñaActual: string;
  contraseñaNueva: string;
}

export const cambiarContrasena = (authToken: string, body: CambiarContrasenaBody) => {
  return axios.put("/api/usuarios/contrasena/cambiar", body, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const fetchVinculacionesDelUsuario = (authToken: string) => {
  return axios.get<VinculacionResponse[]>("/api/usuarios/empresas", {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

interface EmpresaYAdministradores {
  empresa: Empresa;
  ultimoAsignado?:string;
  administradores: VinculacionResponse[];
}

export const fetchEmpresasVinculadasYSusAdministradores = (authToken: string) => {
  return axios.get<EmpresaYAdministradores[]>("/api/usuarios/empresas/administradores", {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const fetchAdministradoresDeLaEmpresa = (authToken: string, idEmpresa: string) => {
  return axios.get<VinculacionResponse[]>(`/api/empresa/${idEmpresa}/administradores`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const crearEmpresa = (authToken: string, body: EmpresaBody) => {
  return axios.post("/api/usuarios/empresa/nueva", body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const fetchEmpresa = (authToken: string, idEmpresa: string) => {
  return axios.get<Empresa>(`/api/empresa/${idEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const actualizarEmpresa = (authToken: string, idEmpresa: string, body: EmpresaBody) => {
  return axios.put(`/api/empresa/${idEmpresa}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listCargos = (authToken: string, idEmpresa: string) => {
  return axios.get<CargoResponse[]>(`/api/empresa/${idEmpresa}/cargos`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const getCurso = (authToken: string, id: string) => {
  return axios.get<CursoResponse>(`/api/cursos/${id}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listCursos = (authToken: string) => {
  return axios.get<CursoResponse[]>("/api/cursos", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listCursosAsignados = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<CursoResponse[]>("/api/cursos/asignados", {
    params: { idDeLaEmpresa },
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listCursosDeLaEmpresa = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<CursoResponse[]>(`/api/cursos/empresa/${idDeLaEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
    timeout: 30000,
  });
};

export const listHistorialDeCursos = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<CursoResponse[]>("/api/cursos/historial", {
    params: { idDeLaEmpresa },
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listUsuariosAsignados = (authToken: string, idDelCurso: string) => {
  return axios.get<VinculacionResponse[]>(`/api/cursos/${idDelCurso}/asignados`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const asignarCurso = (authToken: string, idDelCurso: string, vinculacionesIds: string[]) => {
  return axios.post(
    `/api/cursos/${idDelCurso}/asignar`,
    { vinculacionesIds },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listContratos = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<ContratoResponse[]>(`/api/contratos/empresas/${idDeLaEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getContrato = (authToken: string, idDeLaEmpresa: string, idContrato: string) => {
  return axios.get<ContratoResponse>(
    `/api/contratos/empresas/${idDeLaEmpresa}/contrato/${idContrato}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listDocumentosDeContratosParaImportar = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<DocumentoDelContratoResponse[]>(
    `/api/contratos/empresas/${idDeLaEmpresa}/documentos`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const createDocumentoDelContrato = (
  authToken: string,
  idContrato: string,
  body: DocumentoDelContratoBase
) => {
  return axios.post<DocumentoDelContratoResponse>(`/api/contratos/${idContrato}/documento`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const createDocumentosDelContrato = (
  authToken: string,
  idContrato: string,
  body: DocumentoDelContratoBase[]
) => {
  return axios.post<DocumentoDelContratoResponse>(`/api/contratos/${idContrato}/documentos`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listDocumentosDelContrato = (authToken: string, idContrato: string) => {
  return axios.get<DocumentoDelContratoResponse[]>(`/api/contratos/${idContrato}/documentos`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listDocumentosDelContratoDeLaEmpresa = (authToken: string, idContrato: string) => {
  return axios.get<DocumentoDelContratoResponse[]>(
    `/api/contratos/${idContrato}/documentos/empresa`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listDocumentosDelContratoDePersonal = (authToken: string, idContrato: string) => {
  return axios.get<DocumentoDelContratoResponse[]>(
    `/api/contratos/${idContrato}/documentos/personal`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listDocumentosDelContratoDeEquiposYHerramientas = (
  authToken: string,
  idContrato: string
) => {
  return axios.get<DocumentoDelContratoResponse[]>(
    `/api/contratos/${idContrato}/documentos/equipos-herramientas`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listDocumentosDelContratoDeVehiculos = (authToken: string, idContrato: string) => {
  return axios.get<DocumentoDelContratoResponse[]>(
    `/api/contratos/${idContrato}/documentos/vehiculos`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listDocumentosDelContratoDeSustanciasQuimicas = (
  authToken: string,
  idContrato: string
) => {
  return axios.get<DocumentoDelContratoResponse[]>(
    `/api/contratos/${idContrato}/documentos/sustancias-quimicas`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const getDocumentoDelContrato = (authToken: string, idDelDocumento: string) => {
  return axios.get<DocumentoDelContratoResponse>(`/api/contratos/documentos/${idDelDocumento}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const editDocumentoDelContrato = (
  authToken: string,
  idContrato: string,
  idDocumento: string,
  body: DocumentoDelContratoBase
) => {
  return axios.put<DocumentoDelContratoResponse>(
    `/api/contratos/${idContrato}/documentos/${idDocumento}`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const eliminarDocumentoDelContrato = (
  authToken: string,
  idContrato: string,
  idDocumento: string
) => {
  return axios.delete<DocumentoDelContratoResponse>(
    `/api/contratos/${idContrato}/documentos/${idDocumento}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const contratarEmpresa = (authToken: string, body: ContratoBody) => {
  return axios.post<ContratoResponse>("/api/contratos/solicitar", body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const aceptarContrato = (authToken: string, idContrato: string) => {
  return axios.post<ContratoResponse>(
    `/api/contratos/${idContrato}/aceptar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const rechazarContrato = (authToken: string, idContrato: string) => {
  return axios.post<ContratoResponse>(
    `/api/contratos/${idContrato}/rechazar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const aprobarContrato = (authToken: string, idContrato: string) => {
  return axios.post<ContratoResponse>(
    `/api/contratos/${idContrato}/aprobar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const finalizarContrato = (authToken: string, idContrato: string) => {
  return axios.post<ContratoResponse>(
    `/api/contratos/${idContrato}/finalizar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const createCargo = (
  authToken: string,
  body: { nombreDelCargo: string; empresa: string }
) => {
  return axios.post<CargoResponse>("/api/empresa/cargos/nuevo", body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const deleteCargo = (authToken: string, idDelCargo: string) => {
  return axios.delete(`/api/empresa/cargos/${idDelCargo}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const fetchVinculaciones = (authToken: string, idEmpresa: string) => {
  return axios.get<VinculacionResponse[]>(`/api/empresa/${idEmpresa}/vinculaciones`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const createVinculacion = (authToken: string, idEmpresa: string, body: VinculacionBody) => {
  return axios.post<VinculacionResponse>(`/api/empresa/${idEmpresa}/vinculaciones/nueva`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const crearUsuarioYVincular = (
  authToken: string,
  idEmpresa: string,
  body: NuevoUsuarioBody
) => {
  return axios.post<VinculacionResponse>(`/api/usuarios/vinculado?idEmpresa=${idEmpresa}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const updateVinculacion = (
  authToken: string,
  idDeLaVinculacion: string,
  body: VinculacionBody
) => {
  return axios.put<VinculacionResponse>(`/api/empresa/vinculaciones/${idDeLaVinculacion}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const fetchUsuario = (authToken: string, identificacion: string) => {
  return axios.get<UsuarioParaAgregar>(`/api/usuarios?identificacion=${identificacion}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const deleteVinculacion = (authToken: string, idDeLaVinculacion: string) => {
  return axios.delete(`/api/empresa/vinculaciones/${idDeLaVinculacion}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listDocumentos = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<DocumentoResponse[]>(`/api/documentos/empresa/${idDeLaEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const fetchDocumento = (authToken: string, idDelDocumento: string) => {
  return axios.get<DocumentoResponse>(`/api/documentos/${idDelDocumento}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const createDocumento = (authToken: string, idDeLaEmpresa: string, body: DocumentoBase) => {
  return axios.post<DocumentoResponse>(`/api/documentos/empresa/${idDeLaEmpresa}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const updateDocumento = (authToken: string, idDelDocumento: string, body: DocumentoBase) => {
  return axios.put<DocumentoResponse>(`/api/documentos/${idDelDocumento}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const archivarDocumento = (authToken: string, idDelDocumento: string) => {
  return axios.delete<DocumentoResponse>(`/api/documentos/${idDelDocumento}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const archivarArchivo = (authToken: string, idDelArchivo: string) => {
  return axios.delete<DocumentoResponse>(`/api/documentos/archivo/${idDelArchivo}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const archivosDeDocumento = (authToken: string, idDelDocumento: string) => {
  return axios.get<ArchivoDeDocumentoResponse[]>(`/api/documentos/${idDelDocumento}/archivos`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listDocumentosSugeridos = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<DocumentoSugerido[]>(`/api/documentos/empresa/${idDeLaEmpresa}/sugeridos`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const archivosDeDocumentoPorContrato = (
  authToken: string,
  idDelDocumento: string,
  idDelContrato: string
) => {
  return axios.get<ArchivoDeDocumentoResponse[]>(
    `/api/contratos/${idDelContrato}/documentos/${idDelDocumento}/archivos`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

interface ArchivoPayload {
  nombre: string;
  fechaDeVencimientoString: string;
  archivo: File;
}

export const createArchivoDeDocumento = (
  authToken: string,
  idDelDocumento: string,
  body: ArchivoPayload
) => {
  const { nombre, fechaDeVencimientoString, archivo } = body;
  const formData = new FormData();
  formData.append("archivo", archivo as Blob);
  formData.append("nombre", nombre);
  formData.append("fechaDeVencimiento", fechaDeVencimientoString);
  return axios.post<ArchivoDeDocumentoResponse>(`/api/documentos/${idDelDocumento}`, body, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadFile = (authToken: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post("/api/files/upload", formData, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createEquipoOHerramienta = (
  authToken: string,
  idDeLaEmpresa: string,
  body: EquipoHerramientaBase
) => {
  return axios.post<EquipoHerramienta>(
    `/api/activos/empresas/${idDeLaEmpresa}/equipos-herramientas`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listEquiposYHerramientas = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<EquipoHerramienta[]>(
    `/api/activos/empresas/${idDeLaEmpresa}/equipos-herramientas`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const createVehiculo = (authToken: string, idDeLaEmpresa: string, body: VehiculoBase) => {
  return axios.post<Vehiculo>(`/api/activos/empresas/${idDeLaEmpresa}/vehiculos`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listVehiculos = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<Vehiculo[]>(`/api/activos/empresas/${idDeLaEmpresa}/vehiculos`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const createSustanciaQuimica = (
  authToken: string,
  idDeLaEmpresa: string,
  body: SustanciaQuimicaBase
) => {
  return axios.post<SustanciaQuimica>(
    `/api/activos/empresas/${idDeLaEmpresa}/sustancias-quimicas`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listSustanciasQuimicas = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<SustanciaQuimica[]>(
    `/api/activos/empresas/${idDeLaEmpresa}/sustancias-quimicas`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

interface BuscarEmpresaResponse {
  empresas: Empresa[];
  vinculaciones: Vinculacion[];
}

export const buscarEmpresaParaContratar = (authToken: string, busqueda: string) => {
  return axios.get<BuscarEmpresaResponse>(`/api/contratos/empresas/buscar?busqueda=${busqueda}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

interface ModificarContratoBody {
  numeroDeDocumento: string;
  titulo: string;
  estado: string;
  idDeLaEmpresaContratista: string;
  idDelContactoContratista: string;
}

export const modificarContrato = (
  authToken: string,
  idDeLaEmpresa: string,
  idContrato: string,
  body: ModificarContratoBody
) => {
  return axios.put<ContratoResponse>(
    `/api/contratos/empresas/${idDeLaEmpresa}/contrato/${idContrato}`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const eliminarContrato = (authToken: string, idContrato: string) => {
  return axios.delete(`/api/contratos/${idContrato}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const vincularDocumentoDelContrato = (
  authToken: string,
  idDelDocumentoDeContrato: string,
  idDelDocumento: string,
  activos: string[]
) => {
  return axios.post<DocumentoDelContratoResponse>(
    `/api/contratos/documentos/${idDelDocumentoDeContrato}/vincular/${idDelDocumento}`,
    { activos },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const desvincularDocumentoDelContrato = (
  authToken: string,
  idDelDocumentoDeContrato: string
) => {
  return axios.delete<DocumentoDelContratoResponse>(
    `/api/contratos/documentos/${idDelDocumentoDeContrato}/desvincular`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const aprobarDocumentoDelContrato = (
  authToken: string,
  idDelDocumentoDeContrato: string
) => {
  return axios.post<DocumentoDelContratoResponse>(
    `/api/contratos/documentos/${idDelDocumentoDeContrato}/aprobar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const rechazarDocumentoDelContrato = (
  authToken: string,
  idDelDocumentoDeContrato: string
) => {
  return axios.post<DocumentoDelContratoResponse>(
    `/api/contratos/documentos/${idDelDocumentoDeContrato}/rechazar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const aprobarArchivoDeDocumento = (
  authToken: string,
  idDelDocumentoDeContrato: string,
  idDelArchivo: string
) => {
  return axios.post<DocumentoDelContratoResponse>(
    `/api/contratos/documentos/${idDelDocumentoDeContrato}/archivos/${idDelArchivo}/aprobar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const rechazarArchivoDeDocumento = (
  authToken: string,
  idDelDocumentoDeContrato: string,
  idDelArchivo: string
) => {
  return axios.post<DocumentoDelContratoResponse>(
    `/api/contratos/documentos/${idDelDocumentoDeContrato}/archivos/${idDelArchivo}/rechazar`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const crearComentario = (
  authToken: string,
  idDelDocumentoDeContrato: string,
  comentario: string
) => {
  return axios.post<ComentarioDeDocumentoResponse>(
    `/api/contratos/documentos/${idDelDocumentoDeContrato}/comentarios`,
    { comentario },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const crearRequisitoLegal = (authToken: string, body: RequisitoLegalBody) => {
  return axios.post<RequisitoLegalResponse>(`/api/requisitos-legales`, body, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editarRequisitoLegal = (
  authToken: string,
  idDelRequisitoLegal: string,
  body: RequisitoLegalEditBody
) => {
  return axios.put<RequisitoLegalResponse>(`/api/requisitos-legales/${idDelRequisitoLegal}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

interface RequisitoLegalConAnalisisResponse {
  requisitoLegal: RequisitoLegalResponse;
  analisis: AnalisisDeRequisitoLegalResponse[];
  derogacion: DerogacionDeRequisitoLegalResponse[];
}

export const obtenerRequisitoLegal = (authToken: string, idDelRequisitoLegal: string) => {
  return axios.get<RequisitoLegalConAnalisisResponse>(
    `/api/requisitos-legales/${idDelRequisitoLegal}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const eliminarRequisitoLegal = (authToken: string, idDelRequisitoLegal: string) => {
  return axios.delete<RequisitoLegalResponse>(`/api/requisitos-legales/${idDelRequisitoLegal}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listarRequisitosLegalesInternos = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<RequisitoLegalResponse[]>(
    `/api/requisitos-legales/empresa/${idDeLaEmpresa}/internos`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listarRequisitosLegales = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<RequisitoLegalResponse[]>(`/api/requisitos-legales/empresa/${idDeLaEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listarDocumentosLegalesNuevos = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<DocumentoLegalResponse[]>(
    `/api/requisitos-legales/empresa/${idDeLaEmpresa}/nuevos`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

interface VinculacionLegalBody {
  empresasYPersonasResponsables: {
    empresa: string;
    responsableDelAnalisis: string;
  }[];
  fechaLimiteDeAnalisis: Date;
}

export const vincularDocumentoLegal = (
  authToken: string,
  idDelDocumentoLegal: string,
  body: VinculacionLegalBody
) => {
  return axios.post<RequisitoLegalResponse[]>(
    `/api/requisitos-legales/vincular/${idDelDocumentoLegal}`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const desvincularDocumentoLegal = (authToken: string, idDelDocumentoLegal: string) => {
  return axios.delete<RequisitoLegalResponse[]>(
    `/api/requisitos-legales/desvincular/${idDelDocumentoLegal}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listarAutoridades = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<Autoridad[]>(`/api/requisitos-legales/empresa/${idDeLaEmpresa}/autoridades`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const crearAutoridad = (authToken: string, idDeLaEmpresa: string, nombre: string) => {
  return axios.post<Autoridad>(
    `/api/requisitos-legales/empresa/${idDeLaEmpresa}/autoridades`,
    { nombre },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const toggleBookmark = (
  authToken: string,
  idDelRequisitoLegal: string,
  bookmark: BookmarkDeArticulo
) => {
  return axios.post<RequisitoLegalResponse>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/bookmark`,
    bookmark,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const guardarAnalisisDeRequisitoLegal = (
  authToken: string,
  idDelRequisitoLegal: string,
  analisis: AnalisisDeRequisitoLegalBody[]
) => {
  return axios.post<AnalisisDeRequisitoLegalResponse[]>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/analisis`,
    { analisis },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};


export const guardarDerogacionDeRequisitoLegal = (
  authToken: string,
  idDelRequisitoLegal: string,
  derogacion: DerogacionDeRequisitoLegalBody[]
) => {
  return axios.post<DerogacionDeRequisitoLegalResponse[]>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/derogacion`,
    { derogacion },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const editarAnalisisDeRequisitoLegal = (
  authToken: string,
  idDelRequisitoLegal: string,
  idDelAnalisis: string,
  { textoDelAnalisis, ...analisis }: AnalisisDeRequisitoLegalBody
) => {
  return axios.put<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/analisis/${idDelAnalisis}`,
    { ...analisis, analisis: textoDelAnalisis },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const editarDerogacionDeRequisitoLegal = (
  authToken: string,
  idDelRequisitoLegal: string,
  idDelDerogacion: string,
  { textoDelDerogacion, ...derogacion }: DerogacionDeRequisitoLegalBody
) => {
  return axios.put<DerogacionDeRequisitoLegalResponse>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/derogacion/${idDelDerogacion}`,
    { ...derogacion, derogacion: textoDelDerogacion },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listarAnalisisDeRequisitoLegal = (authToken: string, idDelRequisitoLegal: string) => {
  return axios.get<AnalisisDeRequisitoLegalResponse[]>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/analisis`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listarDerogacionDeRequisitoLegal = (authToken: string, idDelRequisitoLegal: string) => {
  return axios.get<DerogacionDeRequisitoLegalResponse[]>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/derogacion`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const eliminarAnalisisDeRequisitoLegal = (
  authToken: string,
  idDelRequisitoLegal: string,
  idDelAnalisis: string
) => {
  return axios.delete<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/analisis/${idDelAnalisis}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};


export const eliminarDerogacionDeRequisitoLegal = (
  authToken: string,
  idDelRequisitoLegal: string,
  idDelDerogacion: string
) => {
  return axios.delete<DerogacionDeRequisitoLegalResponse>(
    `/api/requisitos-legales/${idDelRequisitoLegal}/derogacion/${idDelDerogacion}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const vincularAnalisisConModulo = (
  authToken: string,
  idDelAnalisis: string,
  modulo: Modulos | Submodulos
) => {
  return axios.post<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/analisis/${idDelAnalisis}/vincular/${modulo}`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const vincularAnalisisConCurso = (
  authToken: string,
  idDelAnalisis: string,
  idDelCurso: string
) => {
  return axios.post<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/analisis/${idDelAnalisis}/vincular/curso/${idDelCurso}`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const vincularAnalisisConDocumentoInterno = (
  authToken: string,
  idDelAnalisis: string,
  idDelDocumento: string
) => {
  return axios.post<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/analisis/${idDelAnalisis}/vincular/documento/${idDelDocumento}`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const vincularAnalisisConDocumentoDeContrato = (
  authToken: string,
  idDelAnalisis: string,
  idDelDocumento: string
) => {
  return axios.post<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/analisis/${idDelAnalisis}/vincular/contrato/documento/${idDelDocumento}`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const vincularAnalisisConPlanDeAccion = (
  authToken: string,
  idDelAnalisis: string,
  idDelPlanDeAccion: string
) => {
  return axios.post<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/analisis/${idDelAnalisis}/vincular/plan-accion/${idDelPlanDeAccion}`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const vincularAnalisisConAccion = (
  authToken: string,
  idDelAnalisis: string,
  idDeLaAccion: string
) => {
  return axios.post<AnalisisDeRequisitoLegalResponse>(
    `/api/requisitos-legales/analisis/${idDelAnalisis}/vincular/accion/${idDeLaAccion}`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const crearActividad = (authToken: string, actividad: ActividadPayload) => {
  return axios.post("/api/actividades", actividad, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listarActividades = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<ActividadResponse[]>(`/api/actividades/empresa/${idDeLaEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getActividadById = (authToken: string, id: string) => {
  return axios.get<ActividadResponse>(`/api/actividades/${id}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const editarActividad = (
  authToken: string,
  idDeLaActividad: string,
  actividad: ActividadPayload
) => {
  return axios.put(`/api/actividades/${idDeLaActividad}`, actividad, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const editCalificacion = (
  authToken: string,
  idDeLaActividad: string,
  actividad: { calificacionMaxima: number }
) => {
  return axios.put(`/api/actividades/${idDeLaActividad}/calificacion`, actividad, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const crearEvaluacionDeRiesgo = (
  authToken: string,
  idActividad: string,
  riesgo: EvaluacionDeRiesgoPayload
) => {
  return axios.post(`/api/evaluacion-riesgos/actividad/${idActividad}`, riesgo, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listarRiesgosEvaluados = (authToken: string, idDeLaActividad: string) => {
  return axios.get<EvaluacionDeRiesgoResponse[]>(
    `/api/evaluacion-riesgos/actividad/${idDeLaActividad}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const editEvaluacionDeRiesgo = (
  authToken: string,
  idDelRiesgo: string,
  riesgo: EvaluacionDeRiesgoPayload
) => {
  return axios.put(`/api/evaluacion-riesgos/${idDelRiesgo}`, riesgo, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listEvaluacionesDeRiesgosDeLaEmpresa = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<EvaluacionDeRiesgoResponse[]>(
    `/api/evaluacion-riesgos/empresa/${idDeLaEmpresa}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const createIncidente = (authToken: string, idDeLaEmpresa: string, body: IncidenteBase) => {
  return axios.post<IncidenteResponse>(`/api/incidentes/empresa/${idDeLaEmpresa}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const archivarIncidente = (authToken: string, idDeLaIncidencia: string) => {
  return axios.delete<IncidenteResponse>(`/api/incidentes/${idDeLaIncidencia}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listIncidentes = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<IncidenteResponse[]>(`/api/incidentes/empresa/${idDeLaEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getIncidente = (authToken: string, idDelIncidente: string) => {
  return axios.get<IncidenteResponse>(`/api/incidentes/${idDelIncidente}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const editIncidente = (authToken: string, idDelIncidente: string, body: IncidenteBase) => {
  return axios.put<IncidenteResponse>(`/api/incidentes/${idDelIncidente}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

interface ArchivoPayloadIncidente {
  nombre: string;
  archivo: File;
}

export const createArchivoDeIncidente = (
  authToken: string,
  idDelIncidente: string,
  body: ArchivoPayloadIncidente
) => {
  const { nombre, archivo } = body;
  const formData = new FormData();
  formData.append("archivo", archivo as Blob);
  formData.append("nombre", nombre);
  return axios.post<ArchivoDeIncidenteResponse>(`/api/incidentes/${idDelIncidente}`, body, {
    headers: { Authorization: `Bearer ${authToken}`, "Content-Type": "multipart/form-data" },
  });
};

export const createUsuarioNoRegistrado = (
  authToken: string,
  idDeLaEmpresa: string,
  body: UsuarioNoRegistradoPayload
) => {
  return axios.post<UsuarioNoRegistradoResponse>(
    `/api/activos/empresas/${idDeLaEmpresa}/usuarios-no-registrados`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listUsuariosNoRegistrados = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<UsuarioNoRegistradoResponse[]>(
    `/api/activos/empresas/${idDeLaEmpresa}/usuarios-no-registrados`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const listPartesInteresadasDeLaEmpresa = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<ParteInteresadaResponse[]>(
    `/api/contexto/empresas/${idDeLaEmpresa}/partes-interesadas`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const getParteInteresada = (authToken: string, idDeLaParteInteresada: string) => {
  return axios.get<ParteInteresadaResponse>(
    `/api/contexto/parte-interesada/${idDeLaParteInteresada}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const createParteInteresada = (
  authToken: string,
  idDeLaEmpresa: string,
  body: ParteInteresadaPayload
) => {
  return axios.post<ParteInteresadaResponse>(
    `/api/contexto/empresas/${idDeLaEmpresa}/partes-interesadas`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const updateParteInteresada = (
  authToken: string,
  idDeLaParteInteresada: string,
  body: ParteInteresadaPayload
) => {
  return axios.put<ParteInteresadaResponse>(
    `/api/contexto/parte-interesada/${idDeLaParteInteresada}`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const addContextoAParteInteresada = (
  authToken: string,
  idDeLaParteInteresada: string,
  body: ContextoPayload
) => {
  return axios.post<ParteInteresadaResponse>(
    `/api/contexto/parte-interesada/${idDeLaParteInteresada}/contextos`,
    body,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const updateContexto = (authToken: string, idDelContexto: string, body: ContextoPayload) => {
  return axios.put<Contexto>(`/api/contexto/${idDelContexto}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const deleteContexto = (authToken: string, idDelContexto: string) => {
  return axios.delete(`/api/contexto/${idDelContexto}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listTiposDeContexto = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<TipoDeContexto[]>(`/api/contexto/empresas/${idDeLaEmpresa}/tipos`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const createTipoDeContexto = (authToken: string, idDeLaEmpresa: string, nombre: string) => {
  return axios.post<TipoDeContexto>(
    `/api/contexto/empresas/${idDeLaEmpresa}/tipos`,
    { nombre },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const updateTipoDeContexto = (authToken: string, idDelTipo: string, nombre: string) => {
  return axios.put<TipoDeContexto>(
    `/api/contexto/tipos/${idDelTipo}`,
    { nombre },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const deleteTipoDeContexto = (authToken: string, idDelTipo: string) => {
  return axios.delete(`/api/contexto/tipos/${idDelTipo}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listarPlanesDeAccion = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<PlanDeAccionResponse[]>(`/api/planes-accion/empresa/${idDeLaEmpresa}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listarPlanesDeAccionPorOrigen = (
  authToken: string,
  idDeLaEmpresa: string,
  origen: OrigenDePlanDeAccion
) => {
  return axios.get<PlanDeAccionResponse[]>(
    `/api/planes-accion/empresa/${idDeLaEmpresa}/origen/${origen}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const crearPlanDeAccion = (
  authToken: string,
  idDeLaEmpresa: string,
  plan: PlanDeAccionPayload
) => {
  return axios.post<PlanDeAccionResponse>(`/api/planes-accion/empresa/${idDeLaEmpresa}`, plan, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const editarPlanDeAccion = (
  authToken: string,
  idDelPlanDeAccion: string,
  plan: PlanDeAccionPayload
) => {
  return axios.put<PlanDeAccionResponse>(`/api/planes-accion/${idDelPlanDeAccion}`, plan, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const obtenerPlanDeAccion = (authToken: string, idDelPlanDeAccion: string) => {
  return axios.get<PlanDeAccionResponse>(`/api/planes-accion/${idDelPlanDeAccion}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const listarAccionesDeLaEmpresa = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<AccionResponse[]>(`/api/planes-accion/empresa/${idDeLaEmpresa}/acciones`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const agregarAccionAlPlanDeAccion = (
  authToken: string,
  idDelPlanDeAccion: string,
  body: AccionPayload
) => {
  return axios.post(`/api/planes-accion/${idDelPlanDeAccion}/acciones`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const obtenerAccion = (authToken: string, idDeLaAccion: string) => {
  return axios.get<AccionResponse>(`/api/planes-accion/acciones/${idDeLaAccion}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const editarAccionDelPlanDeAccion = (
  authToken: string,
  idDeLaAccion: string,
  body: AccionPayload
) => {
  return axios.put(`/api/planes-accion/acciones/${idDeLaAccion}`, body, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const eliminarAccionDelPlanDeAccion = (authToken: string, idDeLaAccion: string) => {
  return axios.delete(`/api/planes-accion/acciones/${idDeLaAccion}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const indicadoresDeFormacion = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<IndicadorDeFormacion[]>(`/api/indicadores/empresa/${idDeLaEmpresa}/formacion`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const indicadoresDeInvestigacionDeIncidentes = (
  authToken: string,
  idDeLaEmpresa: string
) => {
  return axios.get<IndicadorDeInvestigacionDeIncidentes[]>(
    `/api/indicadores/empresa/${idDeLaEmpresa}/incidentes`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const indicadoresDePlanesDeAccion = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<IndicadorDePlanesDeAccion[]>(
    `/api/indicadores/empresa/${idDeLaEmpresa}/planesDeAccion`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const indicadoresDeRequisitosLegales = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get<IndicadorDeRequisitosLegales[]>(
    `/api/indicadores/empresa/${idDeLaEmpresa}/requisitosLegales`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};
