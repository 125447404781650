import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table,UncontrolledDropdown,DropdownItem,
  DropdownMenu,
  DropdownToggle,Badge } from "reactstrap";

import ClampLines from "react-clamp-lines";
import { FaLinkSlash, FaEllipsisVertical, FaBoxArchive } from "react-icons/fa6";

import { FaClipboardCheck, FaEdit, FaFolderOpen, FaTrash, FaPlus, FaPen, FaFile  } from "react-icons/fa";

import { LinkContainer } from "react-router-bootstrap";
import {
  CustomTable,
  TableContainer,
  IconsContainer,
  TableMessage,
  abrirEnlaceEnNuevaPestaña,
  obtenerURLCompleta,
  shortDateToString,
} from "../../../utilities/utils";
import { Context } from "../../Context";
import {
  RequisitoLegal,
  textoClasificacion,
  textoTipoDeRequisitoLegal,
} from "../../../models/RequisitoLegal";
import {
  desvincularDocumentoLegal,
  eliminarRequisitoLegal,
  listarRequisitosLegales,
  listarRequisitosLegalesInternos,
} from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import EditarRequisitoLegalForm from "./EditarRequisitoLegalForm";
import { PATHS, procesarPath } from "../../../utilities/Paths";

const EDITAR_REQUISITO_LEGAL_MODAL_ID = "editarRequisitoLegalModal";

interface Props {
  internos?: boolean;
}

const TablaDeRequisitosLegales: FC<Props> = ({ internos }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [requisitosLegales, setRequisitosLegales] = useState<RequisitoLegal[]>([]);
  const [requisitoLegalSeleccionado, setRequisitoLegalSeleccionado] =
    useState<RequisitoLegal | null>(null);
  const [confirmarEliminarModalIsOpen, setConfirmarEliminarModalIsOpen] = useState<boolean>(false);
  const [confirmarDesvincularModalIsOpen, setConfirmarDesvincularModalIsOpen] =
    useState<boolean>(false);
  const [editarRequisitoLegalModalIsOpen, setEditarRequisitoLegalModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cargarRequisitosLegales = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listarRequisitosLegales(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const requisitosLegales = data.map((requisitoLegal) => new RequisitoLegal(requisitoLegal));
        setRequisitosLegales(requisitosLegales);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const cargarRequisitosLegalesInternos = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listarRequisitosLegalesInternos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const requisitosLegales = data.map((requisitoLegal) => new RequisitoLegal(requisitoLegal));
        setRequisitosLegales(requisitosLegales);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const toggleConfirmarEliminarModal = () =>
    setConfirmarEliminarModalIsOpen(!confirmarEliminarModalIsOpen);

  const handleEliminarClick = (id: string) => {
    const requisitoLegal = requisitosLegales.find((requisitoLegal) => requisitoLegal._id === id);
    if (!requisitoLegal) return;
    setRequisitoLegalSeleccionado(requisitoLegal);
    toggleConfirmarEliminarModal();
  };

  const handleConfirmarEliminarClick = () => {
    if (!authToken || !requisitoLegalSeleccionado) return;
    setIsLoading(true);
    eliminarRequisitoLegal(authToken, requisitoLegalSeleccionado._id)
      .then(() => {
        if (internos) {
          cargarRequisitosLegalesInternos();
        } else {
          cargarRequisitosLegales();
        }
        toggleConfirmarEliminarModal();
      })
      .catch(() => setIsLoading(false));
  };

  const toggleConfirmarDesvincularModal = () =>
    setConfirmarDesvincularModalIsOpen(!confirmarDesvincularModalIsOpen);

  const handleDesvincularClick = (id: string) => {
    const requisitoLegal = requisitosLegales.find((requisitoLegal) => requisitoLegal._id === id);
    if (!requisitoLegal) return;
    setRequisitoLegalSeleccionado(requisitoLegal);
    toggleConfirmarDesvincularModal();
  };

  const handleConfirmarDesvincularClick = () => {
    if (!authToken || !requisitoLegalSeleccionado) return;
    setIsLoading(true);
    desvincularDocumentoLegal(authToken, requisitoLegalSeleccionado._id)
      .then(() => {
        if (internos) {
          cargarRequisitosLegalesInternos();
        } else {
          cargarRequisitosLegales();
        }
        toggleConfirmarDesvincularModal();
      })
      .catch(() => setIsLoading(false));
  };

  const toggleEditarRequisitoLegalModal = () =>
    setEditarRequisitoLegalModalIsOpen(!editarRequisitoLegalModalIsOpen);

  const handleEditarRequisitoLegalClick = (id: string) => {
    const requisitoLegal = requisitosLegales.find((requisitoLegal) => requisitoLegal._id === id);
    if (!requisitoLegal) return;
    setRequisitoLegalSeleccionado(requisitoLegal);
    toggleEditarRequisitoLegalModal();
  };

  const handleEditarSuccess = () => {
    toggleEditarRequisitoLegalModal();
    if (internos) {
      cargarRequisitosLegalesInternos();
    } else {
      cargarRequisitosLegales();
    }
  };

  const limpiarSeleccion = () => {
    setRequisitoLegalSeleccionado(null);
  };

  useEffect(() => {
    setRequisitosLegales([]);
    if (internos) {
      cargarRequisitosLegalesInternos();
    } else {
      cargarRequisitosLegales();
    }
  }, [internos, cargarRequisitosLegales, cargarRequisitosLegalesInternos]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      
        <Table className="no-wrap mt-3 align-middle" style={{"marginBottom":"140px"}} responsive borderless>
          <thead>
            <tr>
              <th>Acciones</th>
              <th>Requisito Legal</th>
              <th>Tipo</th>
              <th>Autoridad</th>
              <th>Clasificación</th>
              <th>Fecha de emisión</th>
              {!internos && <th>Fecha de vinculación</th>}
              <th>Estado Analisis</th>
              <th>Estado Derogaciones</th>
              <th>Fecha límite de análisis</th>
              
            </tr>
          </thead>
          <tbody>
            {requisitosLegales.map(
              (
                {
                  _id,
                  nombre,
                  filepath,
                  tipo,
                  autoridad,
                  clasificacion,
                  fechaDeEmision,
                  fechaDeVinculacion,
                  estado,
                  fechaLimiteDeAnalisis,
                  documentoLegal,
                  analisisCount,
                  derogacionCount,
                  numeroDeArticulos,
                },
                index
              ) => (
                <tr key={_id}>
                  
                  <td>
                    
                  <UncontrolledDropdown group>
                  
                     <DropdownToggle caret color="primary" outline size="sm">
                       <FaEllipsisVertical />
                     </DropdownToggle>
                     <DropdownMenu>
                      
                       <LinkContainer to={procesarPath(PATHS.requisitoLegal, _id)} state={{ prevHash: internos ? "#internos" : "#todos" }}>
                       <DropdownItem className="py-2">
                           <FaFolderOpen size={15} /> Ver Requisito
                       </DropdownItem>
                       </LinkContainer>
                       
                       <DropdownItem  className="py-2" onClick={() => handleEditarRequisitoLegalClick(_id)}>
                       <FaEdit size={15} /> Editar
                       </DropdownItem>
                       
                       <LinkContainer
                         to={procesarPath(PATHS.analisisDeRequisitoLegal, _id)}
                         state={{ prevHash: internos ? "#internos" : "#todos" }}
                       >
                       <DropdownItem  className="py-2">
                            <FaClipboardCheck size={15} /> Análisis
                        </DropdownItem>
                       </LinkContainer>
                       
                       <LinkContainer
                          to={procesarPath(PATHS.derogacionDeRequisitoLegal, _id)}
                          state={{ prevHash: internos ? "#internos" : "#todos" }}
                        >
                        <DropdownItem  className="py-2 text-warning">
                             <FaBoxArchive size={15} style={{"color":"#e7700b"}} /> Derogaciones
                         </DropdownItem>
                        </LinkContainer>
                       
                       {!!documentoLegal ? (
                         <DropdownItem onClick={() => handleDesvincularClick(_id)} className="text-danger py-2">
                          <FaEdit size={15} /> Desvincular
                          </DropdownItem>
                       ) : (
                         <DropdownItem onClick={() => handleEliminarClick(_id)} className="text-danger py-2">
                          <FaEdit size={15} /> Eliminar
                          </DropdownItem>
                       )}
                       
                     </DropdownMenu>
                   </UncontrolledDropdown>
                   
                  </td>
                  
                  
                  <td>
                    <Button
                      type="button"
                      color="link"
                      className="p-0 text-start small"
                      onClick={() => abrirEnlaceEnNuevaPestaña(filepath)}
                      title={obtenerURLCompleta(filepath)}
                    >
                      <ClampLines
                        text={nombre}
                        id={`requisito-legal-${_id}`}
                        lines={2}
                        buttons={false}
                      />
                    </Button>
                  </td>
                  <td>{textoTipoDeRequisitoLegal(tipo)}</td>
                  <td>{autoridad.nombre}</td>
                  <td>{textoClasificacion(clasificacion)}</td>
                  <td>{fechaDeEmision && shortDateToString(fechaDeEmision)}</td>
                  {!internos && (
                    <td>{fechaDeVinculacion && shortDateToString(fechaDeVinculacion)}</td>
                  )}
                  <td>{(analisisCount || 0) > 0 ? ( <Badge color="success" pill style={{"paddingTop":"6px","paddingBottom":"5px","fontSize":"11px"}}><b>Con Análisis</b> ({analisisCount})</Badge> ):( <Badge color="warning" pill style={{"paddingTop":"6px","paddingBottom":"5px","fontSize":"11px"}}>Sin Análisis ({analisisCount})</Badge> )}</td>
                  <td>{(derogacionCount || 0) > 0 ? ( (numeroDeArticulos == derogacionCount) ? (<Badge color="dark" pill style={{"paddingTop":"6px","paddingBottom":"5px","fontSize":"11px"}}><b>Derogación Completa</b> ({derogacionCount})</Badge>):(<Badge color="light" pill style={{"paddingTop":"6px","paddingBottom":"5px","color":"#666","fontSize":"11px"}}>Derogación Parcial ({derogacionCount})</Badge>) ):( <div style={{"fontSize":"11px"}}>Sin Derogación</div> )} </td>
                  <td>{fechaLimiteDeAnalisis && shortDateToString(fechaLimiteDeAnalisis)}</td>
                  
                </tr>
              )
            )}
            {requisitosLegales.length === 0 && (
              <tr>
                <td colSpan={internos ? 10 : 11} className="text-center">
                  <TableMessage>No hay requisitos legales registrados</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      
      <Modal
        isOpen={confirmarEliminarModalIsOpen}
        toggle={toggleConfirmarEliminarModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleConfirmarEliminarModal}>Confirmar eliminación</ModalHeader>
        <ModalBody>
          <p>
            ¿Estás seguro que deseas eliminar el requisito legal{" "}
            <b>{requisitoLegalSeleccionado?.nombre}</b>?
          </p>
          <p className="text-muted">Esta acción no se puede deshacer.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmarEliminarClick}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={toggleConfirmarEliminarModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarDesvincularModalIsOpen}
        toggle={toggleConfirmarDesvincularModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleConfirmarDesvincularModal}>Confirmar desvinculación</ModalHeader>
        <ModalBody>
          <p>
            ¿Estás seguro que deseas desvincular el requisito legal{" "}
            <b>{requisitoLegalSeleccionado?.nombre}</b>?
          </p>
          <p className="text-muted">Esta acción no se puede deshacer.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmarDesvincularClick}>
            Desvincular
          </Button>
          <Button color="secondary" onClick={toggleConfirmarDesvincularModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={editarRequisitoLegalModalIsOpen}
        toggle={toggleEditarRequisitoLegalModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleEditarRequisitoLegalModal}>Editar requisito legal</ModalHeader>
        <ModalBody>
          <EditarRequisitoLegalForm
            requisitoLegal={requisitoLegalSeleccionado as RequisitoLegal}
            formId={EDITAR_REQUISITO_LEGAL_MODAL_ID}
            onSuccess={handleEditarSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={toggleEditarRequisitoLegalModal}>
            Cancelar
          </Button>
          <Button type="submit" color="primary" outline form={EDITAR_REQUISITO_LEGAL_MODAL_ID}>
           <FaPen /> Guardar cambios
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TablaDeRequisitosLegales;
