import { useContext, FC, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { Context } from "../../Context";
import HexButton from "../../../utilities/HexButton";
import { SeparatorCol } from "../../../utilities/utils";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";
import { getStatus } from "../../../services/usuario-api-client";
import LocalStorage from "../../../utilities/LocalStorage";

const HexMenuContainer = styled(Row)`
  max-width: 177px;
  margin-bottom: 30px;
`;



const GestionDocumental: FC = () => {
  const { setTituloDeLaPantallaActual , authToken, idDeLaEmpresaSeleccionada  } = useContext(Context);
  
  const [UserAdmin, setUserAdmin] = useState<string>("regular");
  const [UserCargo, setUserCargo] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.gestionDocumental.name);
  }, [setTituloDeLaPantallaActual]);
  
  
  useEffect(() => {
    if (!authToken) return;
  
    setIsLoading(true);
  
    getStatus(authToken)
      .then(({ data }) => {
        
        const { vinculaciones } = data;
        const currentVinculacion = vinculaciones.find(
          ({ empresa }) =>
            typeof empresa === "string"
              ? empresa === idDeLaEmpresaSeleccionada
              : empresa._id === idDeLaEmpresaSeleccionada
        );
  
        if (currentVinculacion) {
          const { rol, cargo } = currentVinculacion;
          // Establecer el rol y cargo
          setUserAdmin(rol);
          // Handle the cargo field
           if (cargo) {
              if (Array.isArray(cargo)) {
                // If cargo is an array
                const cargoNames = cargo.map((item) => {
                  if (typeof item === "string") {
                    return item;
                  }
                  return ""; // Fallback in case of unexpected structure
                }).filter(Boolean); // Remove empty or invalid items
                setUserCargo(cargoNames); // Directly set the array of cargo names
              }
            }
        }
      })
      .catch((error) => {
        console.error("Error obteniendo los datos:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authToken, idDeLaEmpresaSeleccionada]);

  return (
    <>
      <Breadcrumbs items={[PATHS.usuarioHome]} />
      <Row className="justify-content-center">
        <Col xs="auto">
          <HexMenuContainer className="gx-2">
            <Col xs="auto">
              <HexButton
                label={PATHS.gestionDocumentalInterna.name}
                url={PATHS.gestionDocumentalInterna.to}
                backgroundColor="#FFD848"
              />
            </Col>
            { UserAdmin == 'administrador' && !isLoading && (
              <>
            <div className="w-100" />
            <SeparatorCol width={59} />
            <Col xs="auto">
              <HexButton
                label={PATHS.gestionDocumentalContratos.name}
                url={PATHS.gestionDocumentalContratos.to}
                backgroundColor="#0E0047"
              />
            </Col>
          </>
          ) }
          </HexMenuContainer>
        </Col>
      </Row>
    </>
  );
};

export default GestionDocumental;
