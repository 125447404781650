import styled from "styled-components";
import { Evaluacion } from "../models/DocumentoDelContrato";

const EvaluacionText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 12px;
  height: 28px;
  border-radius: 5px;
  padding: 6px 4px;
  &.aprobado {
    background-color: #28a744;
  }
  &.pendiente {
    background-color: #fec008;
    color: #000;
  }
  &.rechazado {
    background-color: #dc3444;
  }
`;

interface EvaluacionBadgeProps {
  evaluacion: Evaluacion;
  aprobados?: number;
  activos?: number;
  rechazado?: number;
}

export const EvaluacionBadge = ({ evaluacion, activos = 0, aprobados = 0, rechazado = 0 }: EvaluacionBadgeProps) => {
  // Helper function to render evaluation text when activos > 0
  const renderEvaluacionWithActivos = () => {
    switch (evaluacion) {
      case Evaluacion.Aprobado:
        return (
          <EvaluacionText className="aprobado">
            Aprobado <b>({aprobados}/{activos})</b>
          </EvaluacionText>
        );
      case Evaluacion.Pendiente:
        return (
          <EvaluacionText className="pendiente">
            Pendiente <b>({rechazado}/{activos})</b>
          </EvaluacionText>
        );
      case Evaluacion.Rechazado:
        return (
          <EvaluacionText className="rechazado">
            Rechazado <b>({rechazado}/{activos})</b>
          </EvaluacionText>
        );
      default:
        return (
          <EvaluacionText className="sin-evaluacion">
            Evaluación desconocida <b>({activos})</b>
          </EvaluacionText>
        );
    }
  };

  // Helper function to render evaluation text when activos === 0
  const renderEvaluacionWithoutActivos = () => {
    switch (evaluacion) {
      case Evaluacion.Aprobado:
        return <EvaluacionText className="aprobado">Aprobado</EvaluacionText>;
      case Evaluacion.Pendiente:
        return <EvaluacionText className="pendiente">Pendiente</EvaluacionText>;
      case Evaluacion.Rechazado:
        return <EvaluacionText className="rechazado">Rechazado</EvaluacionText>;
      default:
        return <EvaluacionText className="sin-evaluacion">Sin Evaluación</EvaluacionText>;
    }
  };

  // Main render logic
  return (
    <div className="evaluacion-badge">
      {activos > 0 ? renderEvaluacionWithActivos() : renderEvaluacionWithoutActivos()}
    </div>
  );
};