import { useEffect, FC, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Alert } from "reactstrap";
import { FaUserCircle } from "react-icons/fa";
import { Context } from "../../../Context";
import NuevoContrato from "./NuevoContrato";
import ListadoDeContratos from "./ListadoDeContratos";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";
import { getStatus } from "../../../../services/usuario-api-client";
import LocalStorage from "../../../../utilities/LocalStorage";
import ComponentCard from '../../../components/ComponentCard';

import { FaArrowAltCircleRight, FaUserTag, FaDownload, FaLayerGroup, FaUserCheck, FaBuilding, FaUsers, FaBookmark, FaTools, FaCar, FaCapsules, FaFileContract } from "react-icons/fa";

enum Tabs {
  Contratos,
  Nuevo,
}

const AdministracionDeContratos: FC = () => {
  const location = useLocation();
  const { setTituloDeLaPantallaActual , authToken, idDeLaEmpresaSeleccionada  } = useContext(Context);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Contratos);
    
    const [UserAdmin, setUserAdmin] = useState<string>("regular");
    const [UserCargo, setUserCargo] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
      

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.gestionDocumentalContratos.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(() => {
    location.hash === "#nuevo" && setActiveTab(Tabs.Nuevo);
    location.hash === "#lista" && setActiveTab(Tabs.Contratos);
  }, [location]);
  
  
  useEffect(() => {
    if (!authToken) return;
  
    setIsLoading(true);
  
    getStatus(authToken)
      .then(({ data }) => {
        
        const { vinculaciones } = data;
        const currentVinculacion = vinculaciones.find(
          ({ empresa }) =>
            typeof empresa === "string"
              ? empresa === idDeLaEmpresaSeleccionada
              : empresa._id === idDeLaEmpresaSeleccionada
        );
  
        if (currentVinculacion) {
         const { rol, cargo } = currentVinculacion;
          // Establecer el rol y cargo
          setUserAdmin(rol);
         // Handle the cargo field
          if (cargo) {
             if (Array.isArray(cargo)) {
               // If cargo is an array
               const cargoNames = cargo.map((item) => {
                 if (typeof item === "string") {
                   return item;
                 }
                 return ""; // Fallback in case of unexpected structure
               }).filter(Boolean); // Remove empty or invalid items
               setUserCargo(cargoNames); // Directly set the array of cargo names
             }
           }
        }
      })
      .catch((error) => {
        console.error("Error obteniendo los datos:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authToken, idDeLaEmpresaSeleccionada]);
  

  return (
    <>
  <Breadcrumbs items={[PATHS.usuarioHome, PATHS.gestionDocumental]} />
  <ComponentCard title="Administración de Contratos" 
  btnRight= {
    <></>
   }>
      
      { (UserAdmin == 'administrador' && !isLoading) ? (
      <>
      <Row className="mb-3">
        <Col>
          <Nav fill
          pills className="flex-nowrap">
            <NavItem>
              <NavLink
                href="#lista"
                active={activeTab === Tabs.Contratos}
                onClick={() => setActiveTab(Tabs.Contratos)}
                className="align-items-center text-center h-100"
              >
              <>
              <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaFileContract /> </Col><Col xs={12} md={12} lg={10}>Contratos</Col></Row></div>
              <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaFileContract /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Contratos</Col></Row></div>
              </>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#nuevo"
                active={activeTab === Tabs.Nuevo}
                onClick={() => setActiveTab(Tabs.Nuevo)}
                className="align-items-center text-center h-100"
              >
              <>
              <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}> <FaBookmark /> </Col><Col xs={12} md={12} lg={10}>Nuevo</Col></Row></div>
              <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> <FaBookmark /> </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>Nuevo</Col></Row></div>
              </>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.Contratos}>
          <ListadoDeContratos
            isOpen={activeTab === Tabs.Contratos}
            onNuevoContratoClick={() => setActiveTab(Tabs.Nuevo)}
          />
        </TabPane>
        <TabPane tabId={Tabs.Nuevo}>
          <NuevoContrato isOpen={activeTab === Tabs.Nuevo} />
        </TabPane>
      </TabContent>
      </>
    ) : (
      <>
      <Row className="mb-3">
      <Col>
        <Alert color="danger">
         <FaUserCircle size={25} /> No cuenta con acceso a esta sección.
        </Alert>
      </Col>
      </Row>
      </>) }
    </ComponentCard>
    </>
  );
};
export default AdministracionDeContratos;
