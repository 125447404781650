import { FC, useContext, useState } from "react";
import { FaCheckCircle, FaExternalLinkAlt, FaTimesCircle } from "react-icons/fa";
import { Alert, Button, Col, Row, Table, } from "reactstrap";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../../utilities/utils";
import { TiposDeDocumento } from "../../../../models/Documento";
import {
  aprobarArchivoDeDocumento,
  rechazarArchivoDeDocumento,
} from "../../../../services/usuario-api-client";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { DocumentoDelContrato, Evaluacion } from "../../../../models/DocumentoDelContrato";
import { useRevertableState } from "../../../../utilities/useRevertableState";
import { EvaluacionBadge } from "../../../../utilities/EvaluacionBadge";

interface TablaDeArchivosProps {
  documento: DocumentoDelContrato;
  titulo: string;
  cerrar: () => void;
}

const TablaDeArchivos: FC<TablaDeArchivosProps> = ({ documento, titulo, cerrar }) => {
  const { authToken } = useContext(Context);
  const [archivosAprobados, setArchivosAprobados] = useState<string[]>(documento.archivosAprobados);
  const [archivosRechazados, setArchivosRechazados] = useState<string[]>(
    documento.archivosRechazados
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showMensajeDeExitoAprobar, setShowMensajeDeExitoAprobar] = useRevertableState<boolean>(
    false,
    5000
  );
  const [showMensajeDeExitoRechazar, setShowMensajeDeExitoRechazar] = useRevertableState<boolean>(
    false,
    5000
  );

  const handleAprobarArchivoClick = (idDelArchivo: string) => {
    setIsLoading(true);
    aprobarArchivoDeDocumento(authToken, documento._id, idDelArchivo)
      .then(({ data }) => {
        const { archivosAprobados, archivosRechazados, evaluacion } = new DocumentoDelContrato(
          data
        );
        documento.archivosAprobados = archivosAprobados;
        documento.archivosRechazados = archivosRechazados;
        setArchivosAprobados(archivosAprobados);
        setArchivosRechazados(archivosRechazados);
        setShowMensajeDeExitoAprobar(true);
        if (evaluacion === Evaluacion.Aprobado && cerrar) cerrar();
      })
      .finally(() => setIsLoading(false));
  };

  const handleRechazarArchivoClick = (idDelArchivo: string) => {
    setIsLoading(true);
    rechazarArchivoDeDocumento(authToken, documento._id, idDelArchivo)
      .then(({ data }) => {
        const { archivosAprobados, archivosRechazados, evaluacion } = new DocumentoDelContrato(
          data
        );
        documento.archivosAprobados = archivosAprobados;
        documento.archivosRechazados = archivosRechazados;
        setArchivosAprobados(archivosAprobados);
        setArchivosRechazados(archivosRechazados);
        setShowMensajeDeExitoRechazar(true);
        if (evaluacion === Evaluacion.Aprobado && cerrar) cerrar();
      })
      .finally(() => setIsLoading(false));
  };

  const { archivos, documentoVinculado } = documento;
  const { tipoDeDocumento } = documentoVinculado!;

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {!!titulo && (
        <Row>
          <Col>
            <TableCaption>{titulo}</TableCaption>
          </Col>
        </Row>
      )}
      <Table className="no-wrap mt-3 align-middle" responsive borderless>
      
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              {
                {
                  [TiposDeDocumento.DocumentacionGeneralDeLaEmpresa]: <></>,
                  [TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa]: (
                    <th>Equipo(s)/Herramienta(s)</th>
                  ),
                  [TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa]: <th>Vehículo(s)</th>,
                  [TiposDeDocumento.DocumentacionDeUsuariosDirectos]: <th>Colaborador(es)</th>,
                  [TiposDeDocumento.DocumentacionDeSustanciasQuimicas]: (
                    <th>Sustancia(s) química(s)</th>
                  ),
                }[tipoDeDocumento]
              }
              <th>Creado por</th>
              <th>Fecha de la actualización</th>
              <th>Fecha de vencimiento</th>
              <th>Evaluación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {archivos?.map(
              (
                { _id, nombre, filepath, creadoPor, fechaDeCarga, fechaDeVencimiento, activos },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{nombre}</td>
                  {!!activos && activos.length > 0 && (
                    <td>{activos.map(({ nombreCompleto }) => nombreCompleto).join(", ")}</td>
                  )}
                  <td>{creadoPor.nombreCompleto}</td>
                  <td className="text-center">{shortDateToString(fechaDeCarga)}</td>
                  <td className="text-center">{shortDateToString(fechaDeVencimiento)}</td>
                  <td className="text-center">
                    <EvaluacionBadge
                      evaluacion={
                        archivosAprobados?.includes(_id)
                          ? Evaluacion.Aprobado
                          : archivosRechazados?.includes(_id)
                          ? Evaluacion.Rechazado
                          : Evaluacion.Pendiente
                      }
                    />
                  </td>
                  <td>
                    <Row className="justify-content-center align-items-center gx-0 flex-nowrap">
                      {(!archivosAprobados || !archivosAprobados.includes(_id)) && (
                        <Col xs="auto">
                          <Button
                            color="success"
                            outline
                            className="p-1 border-0 lh-1"
                            title="Aprobar archivo"
                            onClick={() => handleAprobarArchivoClick(_id)}
                          >
                            <FaCheckCircle size={15} />
                          </Button>
                        </Col>
                      )}
                      {(!archivosRechazados || !archivosRechazados.includes(_id)) && (
                        <Col xs="auto">
                          <Button
                            color="danger"
                            outline
                            className="p-1 border-0 lh-1"
                            title="Rechazar archivo"
                            onClick={() => handleRechazarArchivoClick(_id)}
                          >
                            <FaTimesCircle size={15} />
                          </Button>
                        </Col>
                      )}
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Descargar"
                          size="sm"
                          href={procesarLinkDeDocumento(filepath)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaExternalLinkAlt size={15} />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {archivos?.length === 0 && (
              <tr>
                <td
                  colSpan={
                    tipoDeDocumento === TiposDeDocumento.DocumentacionGeneralDeLaEmpresa ? 7 : 8
                  }
                  className="text-center"
                >
                  No hay archivos registrados.
                </td>
              </tr>
            )}
          </tbody>
        </Table>

      <Alert color="success" className="mt-3" isOpen={showMensajeDeExitoAprobar}>
        El archivo fue aprobado.
      </Alert>
      <Alert color="success" className="mt-3" isOpen={showMensajeDeExitoRechazar}>
        El archivo fue rechazado.
      </Alert>
    </>
  );
};

export default TablaDeArchivos;
