import { FC, FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Context } from "../Context";
import LoadingSpinner from "../../utilities/LoadingSpinner";
import {
  AutoridadConCantidadDeVinculaciones,
  deleteAutoridad,
  editAutoridad,
  listarAutoridadesConCantidadDeVinculaciones,
} from "../../services/admin-api-client";
import { Autoridad } from "../../models/Autoridad";
import AgregarAutoridadModal from "./DocumentosLegales/AgregarAutoridadModal";
import { PATHS } from "../../utilities/Paths";
import Breadcrumbs from "../../utilities/Breadcrumbs";

import ComponentCard from '../components/ComponentCard';

const EDITAR_AUTORIDAD_FORM_ID = "editarAutoridadForm";

const GestionarAutoridades: FC = () => {
  const { adminAuthToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [autoridades, setAutoridades] = useState<AutoridadConCantidadDeVinculaciones[]>([]);
  const [agregarAutoridadModalIsOpen, setAgregarAutoridadModalIsOpen] = useState<boolean>(false);
  const [autoridadSeleccionada, setAutoridadSeleccionada] = useState<Autoridad | null>(null);
  const [editAutoridadModalIsOpen, setEditAutoridadModalIsOpen] = useState<boolean>(false);
  const [nuevoNombre, setNuevoNombre] = useState<string>("");
  const [eliminarAutoridadModalIsOpen, setEliminarAutoridadModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cargarAutoridades = useCallback(() => {
    if (!adminAuthToken) return;
    setIsLoading(true);
    listarAutoridadesConCantidadDeVinculaciones(adminAuthToken)
      .then(({ data }) => setAutoridades(data))
      .finally(() => setIsLoading(false));
  }, [adminAuthToken]);

  const toggleAgregarAutoridadModal = () =>
    setAgregarAutoridadModalIsOpen(!agregarAutoridadModalIsOpen);

  const handleAgregarAutoridadSuccess = () => {
    cargarAutoridades();
    toggleAgregarAutoridadModal();
  };

  const toggleEditAutoridadModal = () => setEditAutoridadModalIsOpen(!editAutoridadModalIsOpen);

  const handleEditAutoridadClick = (autoridadId: string) => {
    const autoridadSeleccionada = autoridades.find(({ _id }) => _id === autoridadId);
    if (!autoridadSeleccionada) return;
    setAutoridadSeleccionada(autoridadSeleccionada);
    setNuevoNombre(autoridadSeleccionada.nombre);
    toggleEditAutoridadModal();
  };

  const handleEditAutoridadSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!adminAuthToken || !autoridadSeleccionada) return;
    const { _id } = autoridadSeleccionada;
    setIsLoading(true);
    editAutoridad(adminAuthToken, _id, nuevoNombre)
      .then(() => {
        cargarAutoridades();
        toggleEditAutoridadModal();
      })
      .catch(() => setIsLoading(false));
  };

  const toggleEliminarAutoridadModal = () =>
    setEliminarAutoridadModalIsOpen(!eliminarAutoridadModalIsOpen);

  const handleEliminarAutoridadClick = (autoridadId: string) => {
    const autoridadSeleccionada = autoridades.find(({ _id }) => _id === autoridadId);
    if (!autoridadSeleccionada) return;
    setAutoridadSeleccionada(autoridadSeleccionada);
    toggleEliminarAutoridadModal();
  };

  const handleConfirmarEliminarAutoridadClick = () => {
    if (!adminAuthToken || !autoridadSeleccionada) return;
    const { _id } = autoridadSeleccionada;
    setIsLoading(true);
    deleteAutoridad(adminAuthToken, _id)
      .then(() => {
        cargarAutoridades();
        toggleEliminarAutoridadModal();
      })
      .catch(() => setIsLoading(false));
  };

  const limpiarFormulario = () => {
    setNuevoNombre("");
    setAutoridadSeleccionada(null);
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.adminAutoridades.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(cargarAutoridades, [cargarAutoridades]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.adminHome]} />
      <ComponentCard
        title="Documentos Sugeridos"
        btnRight ={
          <Button
            type="button"
            color="primary"
            title="Añadir Autoridad"
            onClick={toggleAgregarAutoridadModal}
          >
            <FaPlus size={20} />
          </Button>
         }
      >
      
      <ListGroup>
        {autoridades.map(({ _id, nombre, cantidadDeVinculaciones }) => (
          <ListGroupItem key={_id}>
            <Row className="align-items-center">
              <Col>{nombre}</Col>
              <Col xs="auto">
                <Badge pill>{cantidadDeVinculaciones}</Badge>
              </Col>
              <Col xs="auto">
                <ButtonGroup>
                  <Button color="primary" onClick={() => handleEditAutoridadClick(_id)}>
                    <FaEdit size={15} />
                  </Button>
                  <Button color="danger" onClick={() => handleEliminarAutoridadClick(_id)}>
                    <FaTrash size={15} />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
      </ComponentCard>
      
      <AgregarAutoridadModal
        isOpen={agregarAutoridadModalIsOpen}
        toggle={toggleAgregarAutoridadModal}
        onSuccess={handleAgregarAutoridadSuccess}
      />
      <Modal
        isOpen={editAutoridadModalIsOpen}
        toggle={toggleEditAutoridadModal}
        onClosed={limpiarFormulario}
      >
        <ModalHeader toggle={toggleEditAutoridadModal}>Editar Autoridad</ModalHeader>
        <ModalBody>
          <Form id={EDITAR_AUTORIDAD_FORM_ID} onSubmit={handleEditAutoridadSubmit}>
            <FormGroup floating>
              <Input
                type="text"
                id="nombre"
                placeholder="Nombre"
                value={nuevoNombre}
                onChange={(e) => setNuevoNombre(e.target.value)}
                required
              />
              <Label for="nombre">Nombre</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" form={EDITAR_AUTORIDAD_FORM_ID} color="primary">
            Guardar
          </Button>
          <Button color="secondary" onClick={toggleEditAutoridadModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={eliminarAutoridadModalIsOpen}
        toggle={toggleEliminarAutoridadModal}
        onClosed={limpiarFormulario}
      >
        <ModalHeader toggle={toggleEliminarAutoridadModal}>Eliminar Autoridad</ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro de que desea eliminar <b>{autoridadSeleccionada?.nombre}?</b>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="danger" onClick={handleConfirmarEliminarAutoridadClick}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={toggleEliminarAutoridadModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default GestionarAutoridades;
