import {useState} from 'react';
import { Container, Row, Col,Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,Button, } from 'reactstrap';

import { LinkContainer } from "react-router-bootstrap";



const Footer = () => {
  
  const [terminosCondicionesModalIsOpen, setTerminosCondicionesModalIsOpen] =
  useState<boolean>(false);
    
  const toggleTerminosCondicionesModalIsOpen = () =>
    setTerminosCondicionesModalIsOpen(!terminosCondicionesModalIsOpen);
  
  const handleTerminosCondicionesClick = () => {
    toggleTerminosCondicionesModalIsOpen();
  };
  
  
    
  return (
    <footer className="text-dark py-4">
      <Container>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="mb-0">&copy; {new Date().getFullYear()} GISST. Todos los derechos reservados. <span
 style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={handleTerminosCondicionesClick}>Términos y Condiciones.</span>
          </p>
          </Col>
        </Row>
      </Container>
      
      <Modal
        isOpen={terminosCondicionesModalIsOpen}
        toggle={toggleTerminosCondicionesModalIsOpen}
         fullscreen
      >
        <ModalHeader toggle={toggleTerminosCondicionesModalIsOpen}>Términos y Condiciones:</ModalHeader>
        <ModalBody>
         
         <div className="container">
             <h1>Política de Privacidad y Protección de Datos</h1>
             <p><strong>Última actualización:</strong> 24 de febrero de 2024</p>
             <p>Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley le protege.</p>
             
             <h2>Definiciones</h2>
             <ul>
                 <li><strong>Cuenta:</strong> Una cuenta única creada para acceder a nuestro Servicio.</li>
                 <li><strong>Aplicación:</strong> GISST, el software proporcionado por la Empresa.</li>
                 <li><strong>Empresa:</strong> GISST, referida como "Nosotros" o "Nuestro".</li>
                 <li><strong>Cookies:</strong> Archivos pequeños que almacenan información de navegación.</li>
                 <li><strong>Dispositivo:</strong> Cualquier dispositivo como una computadora, móvil o tableta.</li>
             </ul>
         
             <h2>Recopilación y Uso de Sus Datos Personales</h2>
             <h3>Tipos de Datos Recopilados</h3>
             <ul>
                 <li><strong>Datos Personales:</strong> Nombre, identificación, teléfono, correo electrónico y dirección.</li>
                 <li><strong>Datos de Uso:</strong> Información técnica sobre su visita y navegación en el Servicio.</li>
                 <li><strong>Datos de la Aplicación:</strong> Acceso a cámara y fotos, con su permiso.</li>
             </ul>
         
             <h2>Tecnologías de Seguimiento y Cookies</h2>
             <p>Usamos Cookies para mejorar su experiencia en nuestro Servicio.</p>
             <ul>
                 <li><strong>Cookies Necesarias:</strong> Para la autenticación y seguridad del usuario.</li>
                 <li><strong>Cookies de Preferencia:</strong> Guardan configuraciones como idioma y sesión.</li>
             </ul>
             <p>Para más información, consulte nuestra <a href="#">Política de Cookies</a>.</p>
         
             <h2>Uso de Sus Datos Personales</h2>
             <ul>
                 <li>Para proporcionar y mejorar nuestro Servicio.</li>
                 <li>Para gestionar su cuenta y contrato con nosotros.</li>
                 <li>Para enviarle información y notificaciones relevantes.</li>
                 <li>Para fines de análisis y mejora de nuestro servicio.</li>
             </ul>
         
             <h2>Retención y Transferencia de Datos</h2>
             <p>Mantenemos sus datos solo por el tiempo necesario y tomamos medidas para garantizar su seguridad.</p>
         
             <h2>Eliminación de Sus Datos Personales</h2>
             <p>Puede solicitar la eliminación de sus datos en cualquier momento contactándonos.</p>
         
             <h2>Cambios a esta Política</h2>
             <p>Podemos actualizar esta política y le notificaremos los cambios mediante nuestro Servicio.</p>
         
             <h2>Contacto</h2>
             <p>Si tiene preguntas, puede contactarnos a través de:</p>
             <ul>
                 <li>Correo electrónico: <a href="mailto:contacto@gisstpa.com">contacto@gisstpa.com</a></li>
                 <li>Teléfono: +507 6473 8182</li>
                 <li><a href="https://gisstpa.com/contacto/">Página de contacto</a></li>
             </ul>
         </div>
         
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={toggleTerminosCondicionesModalIsOpen}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      
    </footer>
  );
};

export default Footer;