import { FaHome, FaUniversity, FaFolder, FaFileSignature, FaFileInvoice, FaGavel } from "react-icons/fa";

interface SidebarItem {
  id: number;
  title: string;
  href: string;
  icon: React.ReactNode;
  collapsible: boolean;
  caption?: string;
  children?: SidebarItem[]; // Optional nested items
  suffix?: string; // Add this line
  suffixColor?: string; // Add this line if it's used
  ddType?: string;
}


const SidebarData: SidebarItem[] = [
  { caption: 'Inicio' },
  {
    id:1,
    title: 'Inicio',
    href: '/usuario',
    icon: <FaHome />,
    collapisble: false,
  },
  { caption: 'Formacion' },
  {
    id:2,
    title: 'Formación y Toma de Conciencia',
    href: '/usuario/formacion',
    icon: <FaUniversity />,
    ddType: 'two-column',
    collapisble: false,
  },
  { caption: 'Requisito' },
  {
    id:3,
    title: 'Requisitos Legales',
    href: '/usuario/requisitos-legales',
    icon: <FaGavel />,
    ddType: 'two-column',
    collapisble: false,
  },
  { caption: 'Gestion' },
  {
    id:4,
    title: 'Gestion Documental',
    icon: <FaFolder />,
    ddType: 'two-column',
    collapisble: true,
    children: [
      {
        title: 'Gestion Documental Interna',
        href: '/usuario/gestion-documental/interna',
        icon: <FaFileInvoice />,
        id: 4.1,
        collapisble: false,
      },
      {
        title: 'Administración de Contratos',
        href: '/usuario/gestion-documental/contratos',
        icon: <FaFileSignature />,
        id: 4.2,
        collapisble: false,
      },
    ]
  },
  
];

export default SidebarData;
