import { useContext, FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Badge,
} from "reactstrap";
import { FaBriefcase, FaPlus, FaTrash, FaUser, FaUserEdit, FaUserPlus } from "react-icons/fa";
import { FaLinkSlash } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";

import { Context } from "../../Context";
import {
  CustomTable,
  IconsContainer,
  TableCaption,
  TableContainer,
  dateToString,
  toTitleCase,
} from "../../../utilities/utils";
import { Cargo } from "../../../models/Cargo";
import { Vinculacion } from "../../../models/Vinculacion";
import AgregarCargoModal from "./AgregarCargoModal";
import AgregarUsuarioModal from "./AgregarUsuarioModal";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Usuario } from "../../../models/Usuario";
import {
  createCargo,
  createVinculacion,
  deleteCargo,
  deleteVinculacion,
  listCargos,
  fetchUsuario,
  fetchVinculaciones,
  updateVinculacion,
  crearUsuarioYVincular,
} from "../../../services/usuario-api-client";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

import ComponentCard from '../../components/ComponentCard';

const MODIFICAR_VINCULACION_FORM_ID = "modificarVinculacionForm";

export interface UsuarioParaAgregar {
  existe: boolean;
  yaVinculado: boolean;
  persona?: Usuario;
}

export interface VinculacionBody {
  idPersona: string;
  rol: string;
  idCargo: string[];
}

export interface NuevoUsuarioBody {
  identificacion: string;
  tipoDeIdentificacion: string;
  nombre: string;
  apellidos: string;
  correo: string;
  rol: string;
  idCargo: string[];
}

const AdministracionDeUsuarios: FC = () => {
  const { setTituloDeLaPantallaActual, idDeLaEmpresaSeleccionada, authToken } = useContext(Context);
  const [cargos, setCargos] = useState<Cargo[]>([]);
  const [agregarCargoModalIsOpen, setAgregarCargoModalIsOpen] = useState<boolean>(false);
  const [cargoParaEliminar, setCargoParaEliminar] = useState<Cargo | null>(null);
  const [confirmarEliminarCargoModalIsOpen, setConfirmarEliminarCargoModalIsOpen] =
    useState<boolean>(false);
  const [vinculaciones, setVinculaciones] = useState<Vinculacion[]>([]);
  const [usuarioParaAgregar, setUsuarioParaAgregar] = useState<UsuarioParaAgregar | null>(null);
  const [agregarUsuarioModalIsOpen, setAgregarUsuarioModalIsOpen] = useState<boolean>(false);
  const [vinculacionParaModificar, setVinculacionParaModificar] = useState<Vinculacion | null>(
    null
  );
  const [modificarVinculacionModalIsOpen, setModificarVinculacionModalIsOpen] =
    useState<boolean>(false);
  const [vinculacionParaEliminar, setVinculacionParaEliminar] = useState<Vinculacion | null>(null);
  const [confirmarDesvincularUsuarioModalIsOpen, setConfirmarDesvincularUsuarioModalIsOpen] =
    useState<boolean>(false);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorAgregarUsuario, setMensajeDeErrorAgregarUsuario] = useState<string>("");
  const [mensajeDeErrorAgregarUsuarioInsider, setMensajeDeErrorAgregarUsuarioInsider] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedCargoValues, setSelectedCargoValues] = useState<string[]>([]);
  
  const handleChangeCargoWrapper = (event: React.ChangeEvent<any>) => {
    const target = event.target as HTMLSelectElement;
    const selectedOptions = Array.from(target.selectedOptions).map(
      (option) => option.value
    );
    setSelectedCargoValues(selectedOptions);
  };

  const handleNuevoCargoSubmit = (nombreDelCargo: string) => {
    const data = {
      nombreDelCargo,
      empresa: idDeLaEmpresaSeleccionada,
    };
    setIsLoading(true);
    createCargo(authToken, data)
      .then(loadCargosAndClose)
      .finally(() => setIsLoading(false));
  };

  const handleDeleteCargoClick = (idDelCargo: string) => {
    const cargo = cargos.find((cargo) => cargo._id === idDelCargo);
    if (!cargo) return;
    setCargoParaEliminar(cargo);
    setConfirmarEliminarCargoModalIsOpen(true);
  };

  const confirmDeleteCargo = () => {
    if (!cargoParaEliminar) return;
    setIsLoading(true);
    deleteCargo(authToken, cargoParaEliminar._id)
      .then(loadCargosAndClose)
      .catch(({ response }) => setMensajeDeError(response.data.error))
      .finally(() => setIsLoading(false));
  };

  const loadCargosAndClose = () => {
    listCargos(authToken, idDeLaEmpresaSeleccionada).then(({ data }) => {
      setCargos(data.map((cargo) => new Cargo(cargo)));
      setCargoParaEliminar(null);
      setAgregarCargoModalIsOpen(false);
      setConfirmarEliminarCargoModalIsOpen(false);
    });
  };

  const buscarUsuario = (identificacion: string) => {
    setIsLoading(true);
    fetchUsuario(authToken, identificacion)
      .then(({ data }) => setUsuarioParaAgregar(data))
      .finally(() => setIsLoading(false));
  };

  const clearUsuarioParaAgregar = () => {
    setUsuarioParaAgregar(null);
  };

  const handleCreateVinculacion = (body: VinculacionBody | NuevoUsuarioBody) => {
    setIsLoading(true);
    if ("identificacion" in body) {
      crearUsuarioYVincular(authToken, idDeLaEmpresaSeleccionada, body)
        .then(loadVinculacionesAndClose)
        .catch(({ response }) => {
          setMensajeDeErrorAgregarUsuario(response?.data?.message || "Error al crear el usuario");
          setMensajeDeErrorAgregarUsuarioInsider(JSON.stringify(response, null, 2));
        })
        .finally(() => setIsLoading(false));
    } else {
      createVinculacion(authToken, idDeLaEmpresaSeleccionada, body)
        .then(loadVinculacionesAndClose)
        .catch(({ response }) => {
          setMensajeDeErrorAgregarUsuario(
            response?.data?.message || "Error al vincular el usuario"
          );
          setMensajeDeErrorAgregarUsuarioInsider(JSON.stringify(response, null, 2));
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleEditVinculoClick = (idDeLaVinculacion: string) => {
    const vinculacion = vinculaciones.find((vinculacion) => vinculacion._id === idDeLaVinculacion);
    if (!vinculacion) return;
    setVinculacionParaModificar(vinculacion);
    
    if(vinculacion.cargo){
    
    const cargoIds = Array.isArray(vinculacion.cargo)
    ? vinculacion.cargo.map((cargo: any) => (typeof cargo === "object" && "_id" in cargo ? cargo._id : cargo))
    : [];
    
    setSelectedCargoValues(cargoIds);
    }else{
    setSelectedCargoValues([]);
    }
  
    setModificarVinculacionModalIsOpen(true);
  };

  const submitModificarVinculacion = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!vinculacionParaModificar) return;
    const formData = new FormData(e.currentTarget);
    const body: VinculacionBody = {
      idPersona: vinculacionParaModificar.persona._id,
      rol: formData.get("rol") as string,
      idCargo: formData.getAll("cargo") as string[],
    };
    setIsLoading(true);
    updateVinculacion(authToken, vinculacionParaModificar._id, body)
      .then(loadVinculacionesAndClose)
      .finally(() => setIsLoading(false));
  };

  const handleDesvincularClick = (idDeLaVinculacion: string) => {
    const vinculacion = vinculaciones.find((vinculacion) => vinculacion._id === idDeLaVinculacion);
    if (!vinculacion) return;
    setVinculacionParaEliminar(vinculacion);
    setConfirmarDesvincularUsuarioModalIsOpen(true);
  };

  const confirmDeleteVinculacion = () => {
    if (!vinculacionParaEliminar) return;
    setMensajeDeError("");
    setIsLoading(true);
    deleteVinculacion(authToken, vinculacionParaEliminar._id)
      .then(loadVinculacionesAndClose)
      .catch(({ response }) => setMensajeDeError(response.data.error))
      .finally(() => setIsLoading(false));
  };

  const loadVinculacionesAndClose = () => {
    fetchVinculaciones(authToken, idDeLaEmpresaSeleccionada).then(({ data }) => {
      setVinculaciones(data.map((vinculacion) => new Vinculacion(vinculacion)));
      setVinculacionParaEliminar(null);
      setAgregarUsuarioModalIsOpen(false);
      setConfirmarDesvincularUsuarioModalIsOpen(false);
      setModificarVinculacionModalIsOpen(false);
    });
  };

  useEffect(() => {
    if (authToken) {
      setIsLoading(true);
      Promise.all([
        listCargos(authToken, idDeLaEmpresaSeleccionada),
        fetchVinculaciones(authToken, idDeLaEmpresaSeleccionada),
      ])
        .then(([{ data: cargosResponse }, { data: vinculacionesResponse }]) => {
          setCargos(cargosResponse.map((cargo) => new Cargo(cargo)));
          setVinculaciones(
            vinculacionesResponse.map((vinculacion) => new Vinculacion(vinculacion))
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.administracionDeUsuarios.name);
  }, [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome]} />
     
      
      <ComponentCard
        title="Usuarios"
        subtitle={
          <p>
            Agregue, edite y elimine usuarios fácilmente desde esta sección.
          </p>
        }
        btnRight={
         <Button
           type="button"
           title="Agregar Usuario"
           color="primary"
           onClick={() => setAgregarUsuarioModalIsOpen(true)}
           outline
         >
          <IconsContainer>
             <FaUserPlus size={20} textAnchor="middle" alignmentBaseline="middle" />
          </IconsContainer>
         </Button>
        }
      >

 
        <Table className="no-wrap mt-3 align-middle" responsive borderless>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Tipo de usuario</th>
              <th>Permisos</th>
              <th>Cargo</th>
              <th>Fecha de vinculación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {vinculaciones.map(({ _id, cargo, fechaDeVinculacion, persona, rol }, i) => (
              <tr key={_id} className="border-top">
                <td className="text-center">{i + 1}</td>
                <td>
                  {persona.nombre} {persona.apellidos} 
                </td>
                <td>{toTitleCase(rol)}</td>
                <td>Total *</td>
                <td>
                  {Array.isArray(cargo) && cargo.length > 0 ? (
                    cargo.map((item, index) => (
                      <>
                        <Badge pill style={{"fontSize":"12px","marginTop":"6px"}} color="primary" outline>
                         {typeof item === "object" && "cargo" in item ? item.cargo : item}
                        </Badge>
                        <br /> {/* Add a <br /> after every item */}
                      </>
                    ))
                  ) : (
                    typeof cargo === "string" ? cargo : "N/A"
                  )}
                </td>
                <td className="text-center">{dateToString(fechaDeVinculacion)}</td>
                <td className="text-center">
                  <Button
                    type="button"
                    title="Modificar"
                    color="link"
                    size="sm"
                    onClick={() => handleEditVinculoClick(_id)}
                  >
                    <FaUserEdit size={15} />
                  </Button>
                  <Button
                    type="button"
                    title="Desvincular"
                    color="danger"
                    outline
                    size="sm"
                    className="border-0"
                    onClick={() => handleDesvincularClick(_id)}
                  >
                    <FaLinkSlash size={15} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

      
      </ComponentCard>
      
      
      <ComponentCard
        title="Cargos de la empresa"
        subtitle={
          <p>
            Cree, edite y elimine cargos para organizar roles y responsabilidades en su aplicación.
          </p>
        }
        btnRight={
         <Button
           type="button"
           title="Agregar Cargo"
           color="primary"
           onClick={() => setAgregarCargoModalIsOpen(true)}
           outline
         >
           <IconsContainer>
             <FaBriefcase textAnchor="middle" alignmentBaseline="middle" size={20} />
             <FaPlus
               textAnchor="middle"
               alignmentBaseline="middle"
               style={{ fontSize: ".5em", position: "absolute", left: "2.55em", bottom: "1.5em" }}
             />
           </IconsContainer>
         </Button>
        }
      >
      
      
        <Table className="no-wrap mt-3 align-middle" responsive borderless>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre del cargo</th>
              <th>Fecha de creación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cargos.map(({ _id, cargo, fechaDeCreacion }, i) => (
              <tr key={_id}>
                <td className="text-center">{i + 1}</td>
                <td>{cargo}</td>
                <td className="text-center">{dateToString(fechaDeCreacion)}</td>
                <td className="text-center">
                  <Button
                    type="button"
                    title="Eliminar"
                    color="danger"
                    outline
                    size="sm"
                    className="border-0"
                    onClick={() => handleDeleteCargoClick(_id)}
                  >
                    <FaTrash size={15} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ComponentCard>
      
      
      <AgregarCargoModal
        isOpen={agregarCargoModalIsOpen}
        toggle={() => setAgregarCargoModalIsOpen(!agregarCargoModalIsOpen)}
        onSubmit={handleNuevoCargoSubmit}
      />
      <Modal isOpen={confirmarEliminarCargoModalIsOpen} toggle={() => {}}>
        <ModalHeader toggle={() => setConfirmarEliminarCargoModalIsOpen(false)}>
          Confirmar eliminación de cargo
        </ModalHeader>
        <ModalBody>
          <p className="mb-0">
            ¿Estás seguro de que deseas eliminar el cargo <b>"{cargoParaEliminar?.cargo}"</b>?
          </p>
          {!!mensajeDeError && <p className="mb-0 mt-3 text-danger small">{mensajeDeError}</p>}
        </ModalBody>
        <ModalFooter>
          
          <Button
            type="button"
            color="secondary"
            onClick={() => setConfirmarEliminarCargoModalIsOpen(false)}
            outline
          >
            Cancelar
          </Button>
          
          <Button type="button" color="danger" onClick={confirmDeleteCargo} outline>
            Eliminar
          </Button>
          
        </ModalFooter>
      </Modal>
      <AgregarUsuarioModal
        usuarioParaAgregar={usuarioParaAgregar}
        cargos={cargos}
        error={mensajeDeErrorAgregarUsuario}
        errorInsider={mensajeDeErrorAgregarUsuarioInsider}
        isOpen={agregarUsuarioModalIsOpen}
        toggle={() => setAgregarUsuarioModalIsOpen(!agregarUsuarioModalIsOpen)}
        clear={clearUsuarioParaAgregar}
        onBuscarClick={buscarUsuario}
        onAgregarClick={handleCreateVinculacion}
        
      />
      <Modal
        isOpen={modificarVinculacionModalIsOpen}
        toggle={() => setModificarVinculacionModalIsOpen(!modificarVinculacionModalIsOpen)}
      >
        <ModalHeader toggle={() => setModificarVinculacionModalIsOpen(false)}>
          Modificar vinculación del usuario
        </ModalHeader>
        <ModalBody>
          <Form id={MODIFICAR_VINCULACION_FORM_ID} onSubmit={submitModificarVinculacion}>
            <FormGroup floating>
              <Input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre"
                value={`${vinculacionParaModificar?.persona.nombre} ${vinculacionParaModificar?.persona.apellidos}`}
                disabled
              />
              <Label for="nombre">Nombre</Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                type="select"
                name="rol"
                id="rol"
                placeholder="Rol"
                defaultValue={vinculacionParaModificar?.rol}
              >
                <option value="administrador">Administrador</option>
                <option value="regular">Regular</option>
              </Input>
              <Label for="rol">Rol</Label>
            </FormGroup>
            { /*vinculacionParaModificar ? JSON.stringify(vinculacionParaModificar.cargo) : null */}
            
            {vinculacionParaModificar && (
            
            <FormGroup floating>
              <Input
                type="select"
                name="cargo"
                id="cargo"
                placeholder="Cargo"
                multiple
                style={{"height":"150px"}}
                value={selectedCargoValues}
                onChange={handleChangeCargoWrapper}
              >
              
                {cargos.map(({ _id, cargo }) => (
                  <option key={_id} value={_id}>
                    {cargo}
                  </option>
                ))}
              </Input>
              <Label for="cargo">Cargo</Label>
            </FormGroup>
          )}
          </Form>
        </ModalBody>
        <ModalFooter>
          
          <Button
            type="button"
            color="secondary"
            onClick={() => setModificarVinculacionModalIsOpen(false)}
            outline
          >
            Cancelar
          </Button>
          <Button type="submit" color="primary" form={MODIFICAR_VINCULACION_FORM_ID} outline>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={confirmarDesvincularUsuarioModalIsOpen} toggle={() => {}}>
        <ModalHeader toggle={() => setConfirmarDesvincularUsuarioModalIsOpen(false)}>
          Confirmar eliminación de usuario
        </ModalHeader>
        <ModalBody>
          <p className="mb-0">
            ¿Estás seguro de que deseas desvincular el usuario{" "}
            <b>
              "{vinculacionParaEliminar?.persona.nombre}{" "}
              {vinculacionParaEliminar?.persona.apellidos}"
            </b>
            ?
          </p>
          {!!mensajeDeError && <p className="mb-0 mt-3 text-danger small">{mensajeDeError}</p>}
        </ModalBody>
        <ModalFooter>
         
          <Button
            type="button"
            color="secondary"
            onClick={() => setConfirmarDesvincularUsuarioModalIsOpen(false)}
            outline
          >
            Cancelar
          </Button>
          <Button type="button" color="danger" onClick={confirmDeleteVinculacion} outline>
            Desvincular
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AdministracionDeUsuarios;
