import { ReactNode, FC } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import PropTypes from 'prop-types';

interface ProfileDDProps {
  children: ReactNode;
  btnRight?: ReactNode; // Specify the type of the proUser: string
  title?: string;
  subtitle?: ReactNode;
}


const ComponentCard: FC<ProfileDDProps> = ({ children, title, subtitle, btnRight }) => {
  return (
    <Card>
      { title && ( 
      <CardTitle tag="h5" className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
        <span>{title}</span>
        {btnRight}
      </CardTitle>
    )}
      <CardBody className="p-4">
        
        <CardSubtitle className="mb-2 text-muted" tag="h6">{subtitle || ''}</CardSubtitle>
        <div>{children}</div>
      </CardBody>
    </Card>
  );
};


export default ComponentCard;
