import { FC, useContext, useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row, Button, Collapse ,UncontrolledDropdown,DropdownItem,
  DropdownMenu,
  DropdownToggle,} from "reactstrap";

import styled from "styled-components";
import { Context } from "../../Context";
import { IconsContainer, TableContainer } from "../../../utilities/utils";
import TablaDeRequisitosLegales from "./TablaDeRequisitosLegales";
import TablaDeRequisitosLegalesNuevos from "./TablaDeRequisitosLegalesNuevos";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

import ComponentCard from '../../components/ComponentCard';
import { FaBalanceScale, FaBriefcase, FaHistory, FaEdit, FaArchive, FaFile, FaPlus, FaFileUpload, FaTrash, FaUser, FaUserEdit, FaUserPlus, FaCertificate, FaBuilding, FaBookmark } from "react-icons/fa";

import { getStatus } from "../../../services/usuario-api-client";
import LocalStorage from "../../../utilities/LocalStorage";

const CustomButton = styled(Button)`
  margin: 0 12px;
`;

enum Vistas {
  NUEVOS = "nuevos",
  INTERNOS = "internos",
  TODOS = "todos",
}

const allTabs = [
  {
    id: Vistas.NUEVOS,
    texto: "Nuevos",
    hash: "#nuevos",
  },
  {
    id: Vistas.INTERNOS,
    texto: "Internos",
    hash: "#internos",
  },
  {
    id: Vistas.TODOS,
    texto: "Todos",
    hash: "#todos",
  },
];

const RequisitosLegales: FC = () => {
  const location = useLocation();
  const { setTituloDeLaPantallaActual , authToken, idDeLaEmpresaSeleccionada  } = useContext(Context);
  const [activeTab, setActiveTab] = useState<Vistas>(allTabs[2].id);
  const [tabs, setTabs] = useState(allTabs);
  const [UserAdmin, setUserAdmin] = useState<string>("regular");
  const [UserCargo, setUserCargo] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTabChange = (id: Vistas) => {
    setActiveTab(id);
  };

  const handleVincularRequisitoLegalSuccess = () => {
    setActiveTab(Vistas.TODOS);
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.requisitosLegales.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(() => {
    if (!authToken) return;
  
    setIsLoading(true);
  
    getStatus(authToken)
      .then(({ data }) => {
        
        const { vinculaciones } = data;
        const currentVinculacion = vinculaciones.find(
          ({ empresa }) =>
            typeof empresa === "string"
              ? empresa === idDeLaEmpresaSeleccionada
              : empresa._id === idDeLaEmpresaSeleccionada
        );
  
        if (currentVinculacion) {
          const { rol, cargo } = currentVinculacion;
  
          // Filtrar tabs basados en el rol
          let filteredTabs = allTabs;
          if (rol === "administrador") {
            filteredTabs = allTabs; // Mostrar todas las pestañas
          } else {
            filteredTabs = allTabs.filter((tab) => tab.id === Vistas.TODOS); // Mostrar solo "Todos"
          }
  
          setTabs(filteredTabs);
  
          // Establecer el tab activo basado en la URL
          const { hash } = location;
          const matchedTab = filteredTabs.find((tab) => tab.hash === hash);
          if (matchedTab) {
            setActiveTab(matchedTab.id);
          }
  
          // Establecer el rol y cargo
          setUserAdmin(rol);
          // Handle the cargo field
           if (cargo) {
              if (Array.isArray(cargo)) {
                // If cargo is an array
                const cargoNames = cargo.map((item) => {
                  if (typeof item === "string") {
                    return item;
                  }
                  return ""; // Fallback in case of unexpected structure
                }).filter(Boolean); // Remove empty or invalid items
                setUserCargo(cargoNames); // Directly set the array of cargo names
              }
            }
        }
      })
      .catch((error) => {
        console.error("Error obteniendo los datos:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authToken, idDeLaEmpresaSeleccionada, location]);

  return (
    <>
    { !isLoading &&
    (
      <>
      <Breadcrumbs items={[PATHS.usuarioHome]} />
      
      <ComponentCard
        title="Requisitos Legales"
        btnRight ={
         <Collapse horizontal isOpen={activeTab === Vistas.INTERNOS} className="col-auto px-0">
           <LinkContainer to={PATHS.nuevoRequisitoLegal.to}>
             <Button type="button"
             color="primary"
             className="ms-auto position-relative" outline >
              
                 <FaBalanceScale size={20} textAnchor="middle" alignmentBaseline="middle" />
                 <FaPlus
                   textAnchor="middle"
                   alignmentBaseline="middle"
                   style={{
                     fontSize: ".6em",
                     position: "absolute",
                     right: "5px",
                     top: "5px",
                   }}
                 />
             </Button>
           </LinkContainer>
         </Collapse>
       }
      >
      
      <Row>
        <Col>
          <TableContainer>
            <Nav fill
            pills className="flex-nowrap">
              {tabs.map(({ id, texto, hash }) => (
                <NavItem key={id}>
                  <NavLink
                    href={hash}
                    onClick={() => handleTabChange(id)}
                    active={activeTab === id}
                    className="align-items-center text-center h-100"
                  >
                  <div className=" d-none d-sm-none d-md-none d-lg-block"><Row className="h-100"><Col xs={12} md={12} lg={2}>{(id==Vistas.NUEVOS) ? <FaCertificate /> : (id==Vistas.INTERNOS) ? <FaBuilding /> : <FaBookmark /> } </Col><Col xs={12} md={12} lg={10}> {texto} </Col> </Row></div>
                  
                  <div className="d-md-block d-lg-none"><Row className="h-100"><Col xs={12} md={12} lg={2} style={{"fontSize":"26px"}}> {(id==Vistas.NUEVOS) ? <FaCertificate /> : (id==Vistas.INTERNOS) ? <FaBuilding /> : <FaBookmark /> } </Col><Col xs={12} md={12} lg={10} style={{"fontSize":"12px"}}>{texto}  </Col></Row></div>
                  
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </TableContainer>
        </Col>
        
      </Row>
      <Row className="mt-1">
        <Col>
          {
            {
              [Vistas.NUEVOS]: (
                <TablaDeRequisitosLegalesNuevos
                  onVincularSuccess={handleVincularRequisitoLegalSuccess}
                />
              ),
              [Vistas.INTERNOS]: <TablaDeRequisitosLegales internos />,
              [Vistas.TODOS]: <TablaDeRequisitosLegales />,
            }[activeTab]
          }
        </Col>
      </Row>
      </ComponentCard>
    </> )
    
  }
  </>
  );
};

export default RequisitosLegales;
